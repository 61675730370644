import listening1 from '@/assets/gifs/headphones1.gif';

export type AllDoneStepProps = {};

export default function AllDoneStep({}: AllDoneStepProps) {
  return (
    <div className="h-full flex justify-center items-center px-8 pb-8 pt-10">
      <div className="flex flex-col gap-5 justify-center items-center">
        <img alt="&milo listening" className="w-[5rem]" src={listening1} />
        <div className="flex flex-col gap-2 text-center">
          <div className="font-bold ">You've Captured $4,655!</div>
          <div className="text-xs">These new tax deductions are now yours to save big this season.</div>
        </div>
      </div>
    </div>
  );
}
