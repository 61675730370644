import { useEffect } from 'react';
import { ExpensesDrawer } from '@/sections/business/expenses/expenses-drawer';
import { ProfitabilityDrawer } from '@/sections/business/profitability/profitability-drawer';
import { RevenueDrawer } from '@/sections/business/revenue/revenue-drawer';
import { useBusinessStore } from '@/sections/business/use-business-store';
import { ExpensesMetricWidget } from '@/sections/widgets/metrics/expenses-metric-widget';
import { ProfitabilityMetricWidget } from '@/sections/widgets/metrics/profitability-metric-widget';
import { RevenueMetricWidget } from '@/sections/widgets/metrics/revenue-metric-widget';
import { createFileRoute } from '@tanstack/react-router';
import { useShallow } from 'zustand/react/shallow';

export const Route = createFileRoute('/_guarded/_main/finances/business')({
  component: RouteComponent
});

function useCloseDrawerOnUnmount(closeDrawer: () => void) {
  useEffect(() => {
    return () => {
      closeDrawer();
    };
  }, []);
}

function RouteComponent() {
  const { closeDrawer, isOpen, activeDrawer, openDrawer } = useBusinessStore(
    useShallow(state => ({
      isOpen: state.isOpen,
      closeDrawer: state.closeDrawer,
      activeDrawer: state.activeDrawer,
      openDrawer: state.openDrawer
    }))
  );

  useCloseDrawerOnUnmount(closeDrawer);

  return (
    <div className="flex flex-col items-center p-10">
      <div className="flex flex-col gap-4 w-full">
        <ProfitabilityMetricWidget />
        <RevenueMetricWidget />
        <ExpensesMetricWidget />
      </div>

      <ProfitabilityDrawer />
      <RevenueDrawer />
      <ExpensesDrawer />
    </div>
  );
}
