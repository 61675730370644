import TransactionCategoryModal from '@/sections/library/transaction/transaction-category-modal';
import { getTransactionsQueries } from '@repo/features/transactions';
import { useQueryClient } from '@tanstack/react-query';
import { useNavigate } from '@tanstack/react-router';

import axiosInstance from '@/lib/axios';

export type AddNewCategoryProps = {
  id: string;
};
const transactionsQueries = getTransactionsQueries(axiosInstance);

export default function AddNewCategory({ id }: AddNewCategoryProps) {
  const queryClient = useQueryClient();
  const navigate = useNavigate();

  const { mutateAsync: updateTransaction, isPending } = transactionsQueries.patch.useMutation({
    onSuccess: async () => {
      await queryClient.invalidateQueries({
        queryKey: transactionsQueries.getKey()
      });
    }
  });

  const handleOpenChange = async (open: boolean) => {
    if (!open) {
      await navigate({ to: '..' });
    }
  };

  const handleSave = async (value: string) => {
    await updateTransaction({
      id,
      data: {
        suggestedAccountingLedger: {
          name: value,
          score: 1
        }
      }
    });
    await handleOpenChange(false);
  };

  return <TransactionCategoryModal isLoading={isPending} onOpenChange={handleOpenChange} onSave={handleSave} open />;
}
