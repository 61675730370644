import { cn } from '@/lib/utils';

export type RowTitleProps = {
  title: string;
  className?: string;
};

export default function RowTitle({ title, className }: RowTitleProps) {
  return <div className={cn('text-sm font-bold text-ellipsis', className)}>{title}</div>;
}
