import React, { useRef } from 'react';
import SidebarControlButton from '@/layouts/common/sidebar-control-button';
import { MatchRoute, useNavigate } from '@tanstack/react-router';

import { useScrollPreservation } from '@/hooks/use-scroll-preservation';
import { Tabs, TabsList, TabsTrigger } from '@/components/ui/tabs';

export type FinanceNavBarProps = {};

export default function FinanceAppHeader({}: FinanceNavBarProps) {
  const navigate = useNavigate();
  const tabsListRef = useRef<HTMLDivElement>(null);
  const scrollRef = useScrollPreservation({
    scrollKey: 'finance-nav',
    scrollBehavior: 'instant'
  });
  const handleTabChange = async (value: string) => {
    await navigate({
      to: value,
      from: '/finances'
    });
  };

  return (
    <div className="py-4 px-6 lg:hidden" ref={scrollRef}>
      <MatchRoute fuzzy to="/finances">
        {match => (
          <>
            {match ? (
              <Tabs onValueChange={handleTabChange} value={(match as any)['**']}>
                <TabsList className="justify-start flex gap-10" ref={tabsListRef}>
                  <SidebarControlButton />
                  <TabsTrigger value="cashflow">Your Cashflow</TabsTrigger>
                  <TabsTrigger value="business">Your Business</TabsTrigger>
                </TabsList>
              </Tabs>
            ) : null}
          </>
        )}
      </MatchRoute>
    </div>
  );
}
