import CustomerContactsView from '@/sections/library/customer/views/customer-contacts-view'
import { createFileRoute } from '@tanstack/react-router'

export const Route = createFileRoute(
  '/_guarded/_main/library/customers_/$id/contacts',
)({
  component: ContactsPage,
  wrapInSuspense: true,
})

function ContactsPage() {
  const { id } = Route.useParams()
  return <CustomerContactsView id={id} />
}
