import { ProfitabilityChartWidget } from '@/sections/widgets/charts/profitability-chart-widget';
import { ProfitabilitySimpleMetricWidget } from '@/sections/widgets/metrics/profitability-simple-metric-widget';
import { format } from 'date-fns';
import { ArrowLeftIcon } from 'lucide-react';
import { useShallow } from 'zustand/react/shallow';

import { Button } from '@/components/ui/button';
import { Drawer, DrawerClose, DrawerContent, DrawerDescription, DrawerTitle } from '@/components/ui/drawer';

import { useBusinessStore } from '../use-business-store';
import { ProfitableCustomersTable } from './profitable-customers-table';

const thisMonth = format(new Date(), 'MMM yyyy');

export function ProfitabilityDrawer() {
  const { closeDrawer, isOpen, activeDrawer, openDrawer } = useBusinessStore(
    useShallow(state => ({
      isOpen: state.isOpen,
      closeDrawer: state.closeDrawer,
      activeDrawer: state.activeDrawer,
      openDrawer: state.openDrawer
    }))
  );

  return (
    <Drawer
      direction="right"
      onClose={() => {
        document.body.style.pointerEvents = 'auto';
      }}
      onOpenChange={_isOpen => {
        if (_isOpen) {
          openDrawer('profitability');
        } else {
          closeDrawer();
        }
      }}
      open={isOpen ? activeDrawer === 'profitability' : false}
    >
      <DrawerContent
        className="lg:rounded-tr-lg lg:rounded-br-lg h-full w-full lg:w-[30rem] right-0 left-auto rounded-none border-none focus-visible:outline-none overflow-y-scroll"
        hideHandle
      >
        <div className="flex text-start">
          <DrawerClose asChild>
            <Button className="min-w-8" variant="icon">
              <ArrowLeftIcon className="size-8" />
            </Button>
          </DrawerClose>
        </div>

        <div className="flex flex-1 overflow-y-scroll flex-col p-8">
          <DrawerTitle className="text-2xl">
            Profitability, <br />
            {thisMonth}
          </DrawerTitle>
          <DrawerDescription className="text-gray-400">
            Here's your clean profit for this month, showing what's left after all your expenses have been accounted
            for.
          </DrawerDescription>
          <ProfitabilitySimpleMetricWidget />
          <ProfitabilityChartWidget />
          <div className="text-lg font-semibold">Most Profitable Customers</div>
          <div className="text-xs text-gray-400 font-">Based on recorded payments made by customers</div>
          <ProfitableCustomersTable />
        </div>
      </DrawerContent>
    </Drawer>
  );
}
