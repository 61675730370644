import { ExpensesSimpleMetricWidget } from '@/sections/widgets/metrics/expenses-simple-metric-widget';
import { format } from 'date-fns';
import { ArrowLeftIcon } from 'lucide-react';
import { useShallow } from 'zustand/react/shallow';

import { Button } from '@/components/ui/button';
import { Drawer, DrawerClose, DrawerContent, DrawerDescription, DrawerTitle } from '@/components/ui/drawer';
import { Separator } from '@/components/ui/separator';

import { useBusinessStore } from '../use-business-store';
import { CurrentMonthExpenses } from './current-month-expenses';
import { CurrentMonthExpensesByCategoryChart } from './current-month-expenses-by-category-chart';
import { CurrentMonthExpensesByStatus } from './current-month-expenses-by-status';
import { ExpensesByVendorsChart } from './expenses-by-vendors-chart';

const thisMonth = format(new Date(), 'MMM yyyy');

export function ExpensesDrawer() {
  const { closeDrawer, isOpen, activeDrawer, openDrawer } = useBusinessStore(
    useShallow(state => ({
      isOpen: state.isOpen,
      closeDrawer: state.closeDrawer,
      activeDrawer: state.activeDrawer,
      openDrawer: state.openDrawer
    }))
  );

  return (
    <Drawer
      direction="right"
      onClose={() => {
        document.body.style.pointerEvents = 'auto';
      }}
      onOpenChange={_isOpen => {
        if (_isOpen) {
          openDrawer('expenses');
        } else {
          closeDrawer();
        }
      }}
      open={isOpen ? activeDrawer === 'expenses' : false}
    >
      <DrawerContent
        className="lg:rounded-tr-lg lg:rounded-br-lg h-full w-full lg:w-[30rem] right-0 left-auto rounded-none border-none focus-visible:outline-none overflow-y-scroll"
        hideHandle
      >
        <div className="flex text-start">
          <DrawerClose asChild>
            <Button className="min-w-8" variant="icon">
              <ArrowLeftIcon className="size-8" />
            </Button>
          </DrawerClose>
        </div>

        <div className="flex flex-1 overflow-y-scroll flex-col p-8">
          <DrawerTitle className="text-2xl">
            Expenses, <br />
            {thisMonth}
          </DrawerTitle>
          <DrawerDescription className="text-gray-400">
            Here's your total expenses for this month, showing all the money your business spent.
          </DrawerDescription>
          <ExpensesSimpleMetricWidget />
          <div className="text-lg font-semibold">Top Expenses, By Category</div>
          <div className="text-xs text-gray-400 font-">All businesss expenses recorded for this month</div>
          <CurrentMonthExpensesByCategoryChart />
          <div className="text-lg font-semibold">By Payment Status</div>
          <div className="text-xs text-gray-400 font-">
            Based on recorded bills, paid or due to be paid by the end of the month
          </div>
          <CurrentMonthExpensesByStatus />
          <Separator />
          <CurrentMonthExpenses />
          <div className="text-lg font-semibold">Top Vendors</div>
          <div className="text-xs text-gray-400 font-">Ranked by the total expenses paid</div>
          <ExpensesByVendorsChart />
        </div>
      </DrawerContent>
    </Drawer>
  );
}
