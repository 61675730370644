import AlertIcon from '@/icons/Alert.svg?react';
import VIcon from '@/icons/V.svg?react';
import type { VariantProps } from 'class-variance-authority';
import { cva } from 'class-variance-authority';

import { cn } from '@/lib/utils';

export type TransactionTagProps = {
  children: React.ReactNode;
  className?: string;
  alertPosition?: 'left' | 'right';
} & VariantProps<typeof transactionTagVariants>;

const transactionTagVariants = cva(
  'h-6 bg-fun-turquoise/20 inline-flex gap-1 rounded-md text-6xs font-bold px-2 uppercase items-center',
  {
    variants: {
      variant: {
        default: '',
        uncertain: '',
        personal: 'bg-transparent border border-neutral-400',
        business: 'bg-primary text-white'
      }
    },
    defaultVariants: {
      variant: 'default'
    }
  }
);

export default function TransactionCategoryBadge({
  className,
  variant = 'default',
  alertPosition = 'left',
  children
}: TransactionTagProps) {
  const renderAlert = (
    <div>
      <AlertIcon className="fill-fun-red size-[0.875rem]" />
    </div>
  );
  const tag = (
    <div className={cn(transactionTagVariants({ variant }), 'max-w-34', className)}>
      <span className="truncate">{children}</span>
      {variant === 'default' && <VIcon className="shrink-0 w-[0.43rem]" />}
      {variant === 'uncertain' && <span>?</span>}
    </div>
  );
  if (variant === 'uncertain') {
    return (
      <div className="inline-flex gap-3 items-center flex-row">
        {alertPosition === 'left' && renderAlert}
        {tag}
        {alertPosition === 'right' && renderAlert}
      </div>
    );
  }
  return tag;
}
