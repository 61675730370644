import AlertIcon from '@/icons/Alert.svg?react';
import ChevronRightIcon from '@/icons/ChevronRight.svg?react';
import LibraryRow from '@/sections/library/library-row';
import RowSubTitle from '@/sections/library/library-row/row-subtitle';
import RowTitle from '@/sections/library/library-row/row-title';
import { fCurrency } from '@/utils/format-number';
import type { IVendor } from '@repo/features/vendors';

import { cn } from '@/lib/utils';
import { Avatar, AvatarFallback } from '@/components/ui/avatar';

export type VendorRowProps = {
  vendor: IVendor;
  hideChevron?: boolean;
  mode?: 'table' | 'list-item';
  onClick?: () => void;
  className?: string;
};
export default function VendorRow({
  vendor,
  className,
  hideChevron = false,
  mode = 'list-item',
  onClick
}: VendorRowProps) {
  const renderSubtitle = (
    <div className="flex items-center gap-2">
      <RowSubTitle
        className="text-neutral-200 text-3xs font-normal"
        subtitle={vendor?.bills?.aggs.due ? `${fCurrency(vendor.bills?.aggs.due)} Open Bills` : 'No open bills'}
      />
      {vendor.bills?.aggs && vendor.bills.aggs.overdue > 0 ? (
        <>
          <svg className="fill-neutral-200" height="4" viewBox="0 0 3 4" width="3" xmlns="http://www.w3.org/2000/svg">
            <circle cx="1.5" cy="2" r="1.5" />
          </svg>
          <div className="flex gap-1 items-center">
            <div>
              <AlertIcon className="fill-fun-red size-[0.625rem]" />
            </div>
            <RowSubTitle
              className="text-fun-red text-3xs font-normal"
              subtitle={`${fCurrency(vendor.bills?.aggs.overdue)} Overdue`}
            />
          </div>
        </>
      ) : null}
    </div>
  );
  if (mode === 'table') {
    return (
      <div
        className={cn('grid grid-cols-[1fr_auto] p-10 items-center justify-center cursor-pointer')}
        onClick={onClick}
      >
        <div className="flex items-center gap-4">
          <Avatar className="bg-fun-orange text-black">
            <AvatarFallback>{vendor.name[0]}</AvatarFallback>
          </Avatar>
          <div className="flex flex-col gap-1">
            <RowTitle className="font-semibold" title={vendor.name} />
            {renderSubtitle}
          </div>
        </div>
        <div>
          <ChevronRightIcon className="h-3" />
        </div>
      </div>
    );
  }
  return (
    <LibraryRow
      amount={null}
      className={className}
      date={null}
      hideChevron={hideChevron}
      icon={
        <Avatar className="bg-fun-orange text-black">
          <AvatarFallback>{vendor.name[0]}</AvatarFallback>
        </Avatar>
      }
      onClick={onClick}
      subtitle={renderSubtitle}
      tag={null}
      title={<RowTitle className="font-semibold" title={vendor.name} />}
    />
  );
}
