import React, { useRef } from 'react';
import SidebarControlButton from '@/layouts/common/sidebar-control-button';
import { MatchRoute, useNavigate } from '@tanstack/react-router';

import { useScrollPreservation } from '@/hooks/use-scroll-preservation';
import { Tabs, TabsList, TabsTrigger } from '@/components/ui/tabs';

export type LibraryNavBarProps = {};

export default function LibraryAppHeader({}: LibraryNavBarProps) {
  const navigate = useNavigate();
  const tabsListRef = useRef<HTMLDivElement>(null);
  const scrollRef = useScrollPreservation({
    scrollKey: 'library-nav',
    scrollBehavior: 'instant'
  });
  const handleTabChange = async (value: string) => {
    await navigate({
      to: value,
      from: '/library'
    });
  };

  return (
    <div className="py-4 px-6 overflow-x-scroll scrollbar-hidden lg:hidden" ref={scrollRef}>
      <MatchRoute fuzzy to="/library">
        {match => (
          <>
            {match ? (
              <Tabs onValueChange={handleTabChange} value={(match as any)['**']}>
                <TabsList className="justify-start flex gap-4" ref={tabsListRef}>
                  <SidebarControlButton />
                  <TabsTrigger value="transactions">Transactions</TabsTrigger>
                  <TabsTrigger value="invoices">Invoices</TabsTrigger>
                  <TabsTrigger value="customers">Customers</TabsTrigger>
                  <TabsTrigger value="bills">Bills & Receipts</TabsTrigger>
                  <TabsTrigger value="vendors">Vendors</TabsTrigger>
                </TabsList>
              </Tabs>
            ) : null}
          </>
        )}
      </MatchRoute>
    </div>
  );
}
