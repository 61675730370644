import * as React from 'react';
import { Drawer, DrawerContent, DrawerTitle } from '@/components/ui/drawer';

export interface BottomLineDrawerProps {
  isOpen?: boolean;
  onOpenChange: (isOpen: boolean) => void;
}

export default function BottomLineDrawer({ isOpen, onOpenChange }: BottomLineDrawerProps) {
  const renderIntro = (
    <div>
      <DrawerTitle className="font-archivo font-semibold text-5xl text-gray-700 uppercase mb-4">
        bottom line
      </DrawerTitle>
      <div className="font-archivo font-normal text-lg text-gray-500 max-w-[500px] text-justify mt-6">
        Coming soon...
      </div>
    </div>
  );

  return (
    <Drawer direction="right" onOpenChange={onOpenChange} open={isOpen}>
      <DrawerContent
        className="h-full w-full lg:w-3/5 right-0 left-auto rounded-none border-none focus-visible:outline-none"
        hideHandle
      >
        <div className="p-12 h-auto w-full overflow-y-auto">{renderIntro}</div>
      </DrawerContent>
    </Drawer>
  );
}
