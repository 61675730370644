import { forwardRef, useEffect, useState } from 'react';
import AddItem from '@/assets/icons/add-item';
import SecuritySafe from '@/assets/icons/security-safe';
import ShieldIcon from '@/assets/icons/shield-icon';
import { useAppStateStore } from '@/stores/app-state';
import { getFinancialAccountsQueries } from '@repo/features/financial-accounts';
import { useQueryClient } from '@tanstack/react-query';
import type { VariantProps } from 'class-variance-authority';
import { cva } from 'class-variance-authority';
import {PlaidLinkOnSuccessMetadata, usePlaidLink} from 'react-plaid-link';
import { useShallow } from 'zustand/react/shallow';

import ActionsObserver from '@/lib/actions-observer';
import axiosInstance from '@/lib/axios.ts';
import { cn } from '@/lib/utils';
import type { ButtonProps } from '@/components/ui/button';
import { Button } from '@/components/ui/button';

export type ConnectAccountButtonProps = {
  className?: string;
  children: React.ReactNode;
  onRegisterSuccess?: () => void;
  onPlaidConnectSuccess?: (metadata: PlaidLinkOnSuccessMetadata) => void;
} & ButtonProps;

const financialAccountsApi = getFinancialAccountsQueries(axiosInstance);

const ConnectAccountButton = forwardRef<HTMLButtonElement, ConnectAccountButtonProps>(
  ({ children, variant = 'primary', className, onRegisterSuccess, onPlaidConnectSuccess, ...other }, ref) => {
    const { data } = financialAccountsApi.plaid.connect.useQuery();
    const queryClient = useQueryClient();
    const [setIsPlaidModalOpen, isActive, activate, accountsConnected, setAccountsConnected] = useAppStateStore(
      useShallow(state => [
        state.setIsPlaidModalOpen,
        state.isActive,
        state.activate,
        state.accountsConnected,
        state.setAccountsConnected
      ])
    );
    const [token, setToken] = useState<string | null>(null);
    const { mutateAsync: registerPlaid } = financialAccountsApi.plaid.register.useMutation({
      onSuccess: () => {
        ActionsObserver.notify('CONNECT_BANK_SUCCESS');
        activate();
        setAccountsConnected(true);
        if (onRegisterSuccess) {
          onRegisterSuccess();
        }
      },
      onError: error => {
        ActionsObserver.notify('CONNECT_BANK_FAILURE', {
          error: error
        });
        activate(isActive);
        setAccountsConnected(accountsConnected);
      }
    });
    const { open, ready, exit } = usePlaidLink({
      token,
      onSuccess: async (public_token, metadata) => {
        ActionsObserver.notify('PLAID_SUCCESS_RESPONSE', metadata);
        if(onPlaidConnectSuccess) {
          onPlaidConnectSuccess(metadata)
        }
        try {
          activate();
          setAccountsConnected(true);
          await registerPlaid({
            token: public_token,
            accounts: metadata.accounts.map(a => ({ ...a, subType: a.subtype })),
            institution: metadata.institution ?? { name: 'Unknown' }
          });
          setToken(null);
          setIsPlaidModalOpen(false);
          queryClient.removeQueries({
            queryKey: financialAccountsApi.getAll.getKey()
          });
        } catch (e) {
          activate(isActive);
          setAccountsConnected(accountsConnected);
          console.error(e);
        }
      },
      onLoad: () => {
        ActionsObserver.notify('CONNECT_BANK_CLICK');
        setIsPlaidModalOpen(true);
      },
      onExit: (error, metadata) => {
        ActionsObserver.notify('CONNECT_BANK_EXIT', metadata);
        setToken(null);
        setIsPlaidModalOpen(false);
        if (error) {
          console.error('Error occurred during Plaid integration', error, metadata);
        }
      }
    });

    useEffect(() => {
      if (token) {
        open();
      }
      return () => {
        exit({ force: true });
      };
    }, [token, open, exit]);

    return (
      <Button
        className={cn(className)}
        disabled={!data}
        onClick={() => {
          setToken(data?.data!);
        }}
        ref={ref}
        size="lg"
        type="button"
        variant={variant}
        {...other}
      >
        <ShieldIcon
          className={cn('mr-4', {
            'fill-white stroke-white': variant === 'primary'
          })}
        />
        {children}
      </Button>
    );
  }
);

export default ConnectAccountButton;
