import * as React from 'react';
import { Fragment, Suspense, useEffect, useLayoutEffect, useState } from 'react';
import Carrot from '@/assets/icons/carrot';
import CloseIcon from '@/assets/icons/close-icon';
import { useAuth } from '@/auth/hooks';
import { getCashFlowDrawerSuggestions } from '@/mock/suggestions';
import { useAppStateStore } from '@/stores/app-state';
import { fCurrency } from '@/utils/format-number';
import { fDate } from '@/utils/format-time';
import { useGetLedgers } from '@repo/teal/rq-hooks/ledgers';
import { useGetPayableAgingReport } from '@repo/teal/rq-hooks/payables';
import { useGetReceivableAgingReport } from '@repo/teal/rq-hooks/receivables';
import { getGetBalanceSheetQueryKey, useGetBalanceSheet } from '@repo/teal/rq-hooks/reports';
import { useGetTagGroups } from '@repo/teal/rq-hooks/tags';
import { useQueryClient } from '@tanstack/react-query';
import { getRouteApi } from '@tanstack/react-router';
import { format, subMonths } from 'date-fns';
import { X } from 'lucide-react';
import { useShallow } from 'zustand/react/shallow';

import DayPicker from '@/components/ui/daypicker';
import { Drawer, DrawerContent, DrawerOverlay, DrawerTitle, DrawerTrigger } from '@/components/ui/drawer';
import { Separator } from '@/components/ui/separator';
import {
  Table,
  TableBody,
  TableCaption,
  TableCell,
  TableFooter,
  TableHead,
  TableHeader,
  TableRow
} from '@/components/ui/table';
import ConnectAccountButton from '@/components/connect-account-button';
import DrawerSearchHeader from '@/components/drawer-search-header';
import MiloInput from 'src/sections/milo-input';
import Suggestion from '@/components/suggestion';

import { getGetLedgersQueryKey } from '../../../../../packages/teal/rq-hooks/ledgers';
import { PulseNumberChart } from './pulse-number-chart';

export interface CashBalanceDrawerProps {
  isOpen?: boolean;
  onOpenChange: (isOpen: boolean) => void;
}

const routeApi = getRouteApi('/_guarded/oldfinances');

function AccountItem({ name, amount }: { name: string; amount: number }) {
  return (
    <div className="flex justify-between items-center">
      <div className="text-3xs font-semibold">{name}</div>
      <div className="text-md font-bold">{fCurrency(amount)}</div>
    </div>
  );
}

export default function CashFlowDrawer({ isOpen, onOpenChange }: CashBalanceDrawerProps) {
  const queryClient = useQueryClient();
  const { user, isAccountingConnected } = useAuth();
  const [isActive, accountsConnected, financialState, isPlaidModalOpen] = useAppStateStore(
    useShallow(state => [state.isActive, state.accountsConnected, state.financialState, state.isPlaidModalOpen])
  );
  // const { to } = routeApi.useSearch();
  const [date, setDate] = useState<Date>(new Date());

  // TODO fix
  const { data } = useGetBalanceSheet(
    {
      end_date: format(date, 'yyyy-MM-dd'),
      include_empty: true
    },
    {
      query: {
        enabled: isAccountingConnected
      }
    }
  );
  const balanceSheetKeyForInvalidation = getGetBalanceSheetQueryKey({
    end_date: format(date, 'yyyy-MM-dd'),
    include_empty: true
  });
  const { data: ledgers } = useGetLedgers(
    {
      financial_account_type: 'bank_account'
    },
    {
      query: {
        enabled: isAccountingConnected
      }
    }
  );
  const ledgersKeyForInvalidation = getGetLedgersQueryKey({
    financial_account_type: 'bank_account'
  });

  const { data: tagGroups = [] } = useGetTagGroups({
    query: {
      enabled: isAccountingConnected
    }
  });

  const customerTagGroupId = tagGroups.find(c => c.name === 'customer')?.id;
  const supplierTagGroupId = tagGroups.find(c => c.name === 'supplier')?.id;
  const { data: receivableLedgers } = useGetLedgers(
    {
      financial_account_type: 'accounts_receivable'
    },
    {
      query: {
        enabled: isAccountingConnected
      }
    }
  );
  const { data: payableLedgers } = useGetLedgers(
    {
      financial_account_type: 'accounts_payable'
    },
    {
      query: {
        enabled: isAccountingConnected
      }
    }
  );
  const receivablesLedgerId = receivableLedgers?.records[0]?.id;
  const payablesLedgerId = payableLedgers?.records[0]?.id;

  const { data: receivableAgingReport } = useGetReceivableAgingReport(
    {
      date: format(date, 'yyyy-MM-dd'),
      tag_group_id: customerTagGroupId!,
      ledger_id: receivablesLedgerId!
    },
    {
      query: {
        enabled: isAccountingConnected && !!customerTagGroupId && !!receivablesLedgerId
      }
    }
  );
  const { data: payableAgingReport } = useGetPayableAgingReport(
    {
      date: format(date, 'yyyy-MM-dd'),
      tag_group_id: supplierTagGroupId!,
      ledger_id: payablesLedgerId!
    },
    {
      query: {
        enabled: isAccountingConnected && !!supplierTagGroupId && !!payablesLedgerId
      }
    }
  );

  const ledgerIdsToUse = ledgers?.records.map(l => l.id) || [];
  const assets = data?.records.find(r => r.name === 'Asset');
  const liabilities = data?.records.find(r => r.name === 'Liability');
  const currentAssets = assets?.children?.find(c => c.name === 'Current Assets');
  const currentLiabilities = liabilities?.children?.find(c => c.name === 'Current Liabilities');
  const accountPayableAmount =
    currentLiabilities?.children?.find(c => c.name === 'Accounts Payable')?.total_amount || 0;
  const accountReceivableAmount =
    currentAssets?.children?.find(c => c.name === 'Accounts Receivable')?.total_amount || 0;
  const accounts = currentAssets?.children?.filter(c => ledgerIdsToUse.includes(c.ledger_id!)) || [];
  const cashBalance = accounts.reduce((acc, item) => {
    return acc + item.amount;
  }, 0);

  const suggestions = getCashFlowDrawerSuggestions({ isActive, accountsConnected });

  useEffect(() => {
    if (isPlaidModalOpen && isOpen) {
      document.body.style.pointerEvents = 'auto';
    }
  }, [isPlaidModalOpen, isOpen]);

  const renderHeader = (
    <DrawerSearchHeader
      onBackClick={() => {
        onOpenChange(false);
      }}
    />
  );

  const renderIntro = (
    <div className="flex flex-col gap-8">
      <div className="">
        <DrawerTitle className="text-3xl font-semibold">Cash Flow</DrawerTitle>
      </div>
      <DayPicker
        className="w-full lg:w-80"
        onSelect={d => {
          setDate(d!);
        }}
        selected={date}
      />
      <div className="text-sm font-medium">
        Here&apos;s a look at your cash situation. There are some interesting trends we should discuss. Take a look at
        the key numbers below, and shoot me a message here with any questions. I&apos;m here to simplify and translate
        these figures for you!
      </div>
    </div>
  );

  const renderCurrentBalance = (
    <div className="flex flex-col gap-4">
      <div className="text-lg font-bold">Current Balance</div>
      <div className="text-sm font-medium">
        Here is what you have TODAY({fDate(new Date())}) across all connected accounts:
      </div>
      <div className="text-3xl font-semibold">{isAccountingConnected ? fCurrency(cashBalance) : 'NA'}</div>
    </div>
  );

  const renderAccounts = (
    <>
      <div className="flex flex-col gap-4">
        <div className="text-lg font-bold">Accounts</div>
        <div className="text-sm font-medium">Here is your current balance broken down by accounts:</div>
        <div>
          <ConnectAccountButton
            onPlaidConnectSuccess={() => {
              queryClient.invalidateQueries({ queryKey: balanceSheetKeyForInvalidation });
              queryClient.invalidateQueries({ queryKey: ledgersKeyForInvalidation });
            }}
            variant="primary"
          >
            Connect new account
          </ConnectAccountButton>
        </div>
      </div>
      {accounts.length > 0 ? (
        <div className="grid grid-cols-1 gap-y-6">
          {accounts.map(record => (
            <Fragment key={record.ledger_id}>
              <AccountItem amount={record.amount} name={record.name} />
              <Separator />
            </Fragment>
          ))}
        </div>
      ) : null}
    </>
  );

  const renderChart = (
    <div className="flex flex-col gap-4">
      <div className="text-lg font-bold">CashFlow Trend</div>
      <div className="text-sm font-medium">This is what your account trend looks like over the past 6 months</div>
      <Suspense fallback={null}>
        <PulseNumberChart endDate={date} startDate={subMonths(date, 6)} />
      </Suspense>
    </div>
  );

  const renderExpectedBalance = (
    <div className="flex flex-col gap-4">
      <div className="text-lg font-bold">Expected Balance</div>

      <div className="text-sm font-medium">
        {!accountPayableAmount && !accountReceivableAmount
          ? `I don’t see any recorded invoices coming up from customers (account receivables) or vendor bills due till end of ${fDate(new Date(), 'MMMM')}`
          : `Expected balance for end of ${fDate(new Date(), 'MMMM')}:`}
      </div>
      <div className="text-3xl font-semibold">
        {isAccountingConnected ? fCurrency(cashBalance - accountPayableAmount + accountReceivableAmount) : 'NA'}
      </div>
    </div>
  );

  const renderExpectedIn = (
    <div className="flex flex-col gap-4">
      <div className="text-md font-bold">Expected In</div>
      <div className="text-sm font-medium">
        Taking into account what customer owe you based on recorded invoices (account receivables) due till end of{' '}
        {fDate(new Date(), 'MMMM')}
      </div>
      <div>
        <Table className="border-[1.5px] border-neutral-300 text-xs font-medium max-w-[700px]">
          <TableHeader className="font-semibold">
            <TableRow className="bg-neutral-200">
              <TableHead className="font-bold text-black">Customer</TableHead>
              <TableHead className="font-bold text-black">Total</TableHead>
            </TableRow>
          </TableHeader>
          <TableBody>
            {receivableAgingReport?.tag_balances.map(tb => (
              <TableRow key={tb.tag.id}>
                <TableCell className="font-medium">{tb.tag.name}</TableCell>
                <TableCell>{fCurrency(tb.balance.total)}</TableCell>
              </TableRow>
            ))}
          </TableBody>
          <TableFooter className="font-semibold bg-neutral-200">
            <TableRow>
              <TableCell colSpan={1}>Total Unassigned</TableCell>
              <TableCell>{fCurrency(receivableAgingReport?.unassigned_balances.total ?? 0)}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell colSpan={1}>Total</TableCell>
              <TableCell>{fCurrency(receivableAgingReport?.total_balances.total ?? 0)}</TableCell>
            </TableRow>
          </TableFooter>
        </Table>
      </div>
    </div>
  );

  const renderExpectedOut = (
    <div className="flex flex-col gap-4">
      <div className="text-md font-bold">Expected Out</div>
      <div className="text-sm font-medium">
        Taking into account what you owe, upcoming bills recorded (account payable) due till end of{' '}
        {fDate(new Date(), 'MMMM')}
      </div>
      <div className="max-w-[700px]">
        <Table className="border-[1.5px] border-neutral-300 text-xs font-medium">
          <TableHeader className="font-semibold">
            <TableRow className="bg-neutral-200">
              <TableHead className="font-bold text-black">Supplier</TableHead>
              <TableHead className="font-bold text-black">Total</TableHead>
            </TableRow>
          </TableHeader>
          <TableBody>
            {payableAgingReport?.tag_balances.map(tb => (
              <TableRow key={tb.tag.id}>
                <TableCell>{tb.tag.name}</TableCell>
                <TableCell>{fCurrency(tb.balance.total)}</TableCell>
              </TableRow>
            ))}
          </TableBody>
          <TableFooter className="font-semibold bg-neutral-200">
            <TableRow>
              <TableCell colSpan={1}>Total Unassigned</TableCell>
              <TableCell>{fCurrency(payableAgingReport?.unassigned_balances.total ?? 0)}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell colSpan={1}>Total</TableCell>
              <TableCell>{fCurrency(payableAgingReport?.total_balances.total ?? 0)}</TableCell>
            </TableRow>
          </TableFooter>
        </Table>
      </div>
    </div>
  );

  return (
    <Drawer
      direction="right"
      onClose={() => {
        document.body.style.pointerEvents = 'auto';
      }}
      onOpenChange={onOpenChange}
      open={isOpen}
    >
      <DrawerContent
        className="lg:rounded-tl-lg lg:rounded-bl-lg h-full w-full lg:w-[43.75rem] right-0 left-auto rounded-none border-none focus-visible:outline-none"
        hideHandle
      >
        {renderHeader}
        <div className="h-auto flex flex-col w-full overflow-y-auto relative px-10 pb-10 lg:px-14 lg:pb-14 gap-14">
          {renderIntro}
          <Separator />

          {renderCurrentBalance}
          {renderAccounts}
          {accountsConnected ? renderChart : null}
          <Separator />
          {renderExpectedBalance}
          {accountsConnected && receivableAgingReport?.total_balances.total !== 0 ? renderExpectedIn : null}
          {accountsConnected && payableAgingReport?.total_balances.total !== 0 ? renderExpectedOut : null}
        </div>
      </DrawerContent>
    </Drawer>
  );
}
