import { useCallback, useEffect, useRef } from 'react';
import { useRouter } from '@tanstack/react-router';

const scrollPositions = new Map<string, number>();

interface UseScrollPreservationOptions {
  scrollKey?: string;
  scrollBehavior?: ScrollBehavior;
}

export function useScrollPreservation({
  scrollKey = 'default',
  scrollBehavior = 'auto'
}: UseScrollPreservationOptions = {}) {
  const ref = useRef<HTMLDivElement>(null);
  const { subscribe } = useRouter();

  // TODO add debounce 50-100ms!
  const saveScroll = useCallback(
    (position: number) => {
      scrollPositions.set(scrollKey, position);
    },
    [scrollKey]
  );

  useEffect(() => {
    const element = ref.current;
    if (!element) return;

    const unsubscribe = subscribe('onBeforeNavigate', () => {
      const currentScroll = element.scrollLeft;
      scrollPositions.set(scrollKey, currentScroll);
    });

    return () => {
      unsubscribe();
    };
  }, [subscribe, scrollKey, saveScroll]);

  useEffect(() => {
    const element = ref.current;
    if (!element) return;

    const handleScroll = () => {
      saveScroll(element.scrollLeft);
    };

    element.addEventListener('scroll', handleScroll);
    return () => {
      element.removeEventListener('scroll', handleScroll);
      // saveScroll.cancel();
    };
  }, [saveScroll]);

  useEffect(() => {
    const element = ref.current;
    if (!element) return;

    const frame = requestAnimationFrame(() => {
      const savedPosition = scrollPositions.get(scrollKey) ?? 0;
      element.scrollTo({ left: savedPosition, behavior: scrollBehavior });
    });

    return () => {
      cancelAnimationFrame(frame);
    };
  }, []);

  return ref;
}
