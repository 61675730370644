import { Skeleton } from '@/components/ui/skeleton';

// TODO replace with real skeleton component
export default function ChartWidgetEmptyState() {
  return (
    <div>
      <Skeleton className="h-[125px] w-[250px] rounded-xl">
        <div className="text-center">No chart data</div>
      </Skeleton>
    </div>
  );
}
