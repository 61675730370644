import { useId } from 'react';
import type { RadioGroupProps } from '@radix-ui/react-radio-group';
import { AnimatePresence, motion } from 'framer-motion';
import type { Control } from 'react-hook-form';
import { useController } from 'react-hook-form';

import { cn } from '@/lib/utils';
import { RadioGroup, RadioGroupItem } from '@/components/ui/radio-group';

interface RadioSelectorFieldProps extends Omit<RadioSelectorProps, 'value' | 'onChange'> {
  control: Control<any>;
  name: string;
}

export function RadioSelectorField({ control, name, ...props }: RadioSelectorFieldProps) {
  const { field } = useController({
    name,
    control
  });

  return <RadioSelector {...props} onChange={field.onChange} value={field.value} />;
}

export interface RadioOption {
  value: string;
  label: React.ReactNode;
  highlight?: React.ReactNode;
}

export interface RadioSelectorProps extends Omit<RadioGroupProps, 'onValueChange' | 'value' | 'onChange'> {
  options: RadioOption[];
  value?: string;
  onChange?: (value: string) => void;
  defaultValue?: string;
  className?: string;
  size?: 'sm' | 'md' | 'lg';
}

const sizeClasses = {
  sm: 'h-10 text-4xs',
  md: 'h-9 text-base',
  lg: 'h-10 text-lg'
} as const;

export function RadioSelector({
  options,
  value,
  onChange,
  defaultValue = options[0]?.value,
  className,
  size = 'md',
  ...props
}: RadioSelectorProps) {
  const id = useId();
  const selectedValue = value ?? defaultValue;

  // Calculate the translation for the sliding background
  const selectedIndex = options.findIndex(opt => opt.value === selectedValue);

  return (
    <div className={cn('inline-flex rounded-full bg-neutral-900 p-1', sizeClasses[size], className)}>
      <RadioGroup
        className={cn('group w-full relative inline-grid items-center gap-1', `grid-cols-${options.length}`)}
        onValueChange={onChange}
        style={
          {
            '--option-count': options.length
          } as React.CSSProperties
        }
        value={selectedValue}
        {...props}
      >
        {/* Animated background */}
        <motion.div
          animate={{
            width: `${100 / options.length}%`,
            x: `${selectedIndex * 100}%`
          }}
          className="absolute inset-y-0 rounded-full bg-neutral-700 shadow-sm shadow-black/5"
          initial={false}
          transition={{
            type: 'spring',
            stiffness: 400,
            damping: 30
          }}
        />

        {options.map((option, index) => (
          <label
            className={cn(
              'relative z-10 inline-flex h-full cursor-pointer select-none',
              'items-center justify-center whitespace-nowrap px-4',
              {
                'font-semibold': selectedValue === option.value
              }
            )}
            key={option.value}
          >
            <motion.span
              animate={{
                color: selectedValue === option.value ? 'var(--foreground)' : 'var(--muted-foreground)'
              }}
              className="flex items-center gap-1"
              transition={{ duration: 0.2 }}
            >
              {option.label}
              <AnimatePresence mode="wait">
                {option.highlight ? (
                  <motion.span
                    animate={{
                      opacity: 1,
                      y: 0,
                      color:
                        selectedValue === option.value
                          ? 'var(--fun-cyan)' // emerald-500
                          : 'var(--muted-foreground)'
                    }}
                    exit={{ opacity: 0, y: 4 }}
                    initial={{ opacity: 0, y: -4 }}
                    key={`highlight-${option.value}`}
                    transition={{ duration: 0.2 }}
                  >
                    {option.highlight}
                  </motion.span>
                ) : null}
              </AnimatePresence>
            </motion.span>
            <RadioGroupItem className="sr-only" id={`${id}-${index}`} value={option.value} />
          </label>
        ))}
      </RadioGroup>
    </div>
  );
}
