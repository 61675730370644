import ChartWidgetEmptyState from '@/sections/widgets/charts/chart-empty-state';
import { WidgetProps } from '@/sections/widgets/types';
import { WidgetFactory } from '@/sections/widgets/widget-factory';
import { ChevronRightIcon } from '@radix-ui/react-icons';
import { getBillQueries } from '@repo/features/bills';
import { IVendor } from '@repo/features/vendors';
import { useNavigate } from '@tanstack/react-router';
import { startOfMonth } from 'date-fns';

import axiosInstance from '@/lib/axios';
import { cn } from '@/lib/utils';
import { Badge } from '@/components/ui/badge';
import { Button } from '@/components/ui/button';
import { Separator } from '@/components/ui/separator';

const billsQueries = getBillQueries(axiosInstance);
const now = new Date();
const monthStart = startOfMonth(now);

export const CurrentMonthExpenses = WidgetFactory.create(({ className }: WidgetProps) => {
  const navigate = useNavigate();
  const { data: bills } = billsQueries.currentMonth.useSuspenseQuery({
    variables: {
      dueDateFrom: monthStart,
      dueDateTo: now,
      expand: ['aggs', 'vendor'],
      sortBy: 'dueDate',
      sortOrder: 'asc',
      limit: 5
    },
    select(data) {
      return data.records.map(d => ({
        id: d._id,
        vendor: (d.vendor as IVendor)?.name || 'Unknown',
        dueDate: d.dueDate,
        status: d.status,
        amount: d.amount
      }));
    }
  });

  return (
    <div className={cn('flex flex-col gap-4', className)}>
      {!bills?.length && <ChartWidgetEmptyState />}
      {bills?.length > 0 &&
        bills.map(b => (
          <>
            <div key={b.id} className="flex justify-between items-center py-8">
              <div className="flex flex-col gap-1">
                <div className="text-base font-medium">{b.vendor}</div>
                <div className="text-xs">{new Date(b.dueDate).toISOString()}</div>
              </div>
              <div className="flex flex-col gap-1 items-end">
                <div className="text-lg font-semibold">${b.amount}</div>
                <Badge className="text-4xs font-normal uppercase bg-neutral-800 text-white">{b.status}</Badge>
              </div>
            </div>
            <Separator />
          </>
        ))}
      <Button
        asChild
        variant={'ghost'}
        onClick={() => {
          navigate({
            to: '/library/bills'
          });
        }}
      >
        <div className="flex justify-center items-center mx-auto w-fit cursor-pointer">
          <div>View All Bills</div>
          <ChevronRightIcon />
        </div>
      </Button>
    </div>
  );
});
