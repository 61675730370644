import { create } from 'zustand';

type DrawerType = 'current_balance' | 'expected_end_of_month' | 'none';

interface CashflowState {
  activeDrawer: DrawerType;
  isOpen: boolean;
  openDrawer: (drawer: DrawerType) => void;
  closeDrawer: () => void;
  toggleDrawer: (drawer: DrawerType) => void;
}

export const useCashflowStore = create<CashflowState>(set => ({
  activeDrawer: 'none',
  isOpen: false,

  openDrawer: drawer => {
    set({
      activeDrawer: drawer,
      isOpen: true
    });
  },

  closeDrawer: () => {
    set({
      activeDrawer: 'none',
      isOpen: false
    });
  },

  toggleDrawer: drawer => {
    set(state => {
      if (state.activeDrawer === drawer && state.isOpen) {
        return {
          activeDrawer: 'none',
          isOpen: false
        };
      }
      return {
        activeDrawer: drawer,
        isOpen: true
      };
    });
  }
}));
