import { useEffect } from 'react';
import jumping2 from '@/assets/gifs/jumping2.gif';
import { useAuth } from '@/auth/hooks';
import NextStepSection from '@/sections/onboarding/questionnaire/next-step-section';
import Amplitude from '@/utils/pixels/amplitude/amplitude';

import { useBoolean } from '@/hooks/use-boolean';
import { useBreakpoint } from '@/hooks/use-breakpoint';
import MotionTextRevealContainer from '@/components/animate/motion-text-reveal';

export default function IntroStep() {
  const { user } = useAuth();
  const textRevealed = useBoolean(false);
  const { isBelowLg } = useBreakpoint('lg');
  useEffect(() => {
    Amplitude.track('OnBoard_Questionnaire_Start_View');
    Amplitude.setPropertiesOnUser({ OnBoard_Questionnaire_Started: true });
  }, []);
  return (
    <>
      <div className="relative flex h-full flex-col gap-8 py-4 px-14 lg:py-52 lg:items-center">
        <div>
          <img className="w-20 lg:hidden rotate-y-180" src={jumping2} />
        </div>
        <div className="lg:text-center flex flex-col gap-4">
          <div className="text-5xl">
            <MotionTextRevealContainer value={`Nice to meet you ${user?.firstName || ''}!`} />
          </div>
          <div className="text-md lg:text-lg">
            <MotionTextRevealContainer
              value="I am milo, your AI-powered financial companion. Let me ask you some questions so I can tailor my services to your specific needs."
              wrapperProps={{
                onAnimationComplete() {
                  textRevealed.onTrue();
                }
              }}
            />
          </div>
          {!isBelowLg && (
            <div>
              <NextStepSection
                className="lg:justify-center"
                onClick={() => {
                  Amplitude.track('OnBoard_Questionnaire_Start_ButtonClick');
                }}
                startAnimation={textRevealed.value}
              >
                Lets Start
              </NextStepSection>
            </div>
          )}
          <div />
        </div>
      </div>
      {isBelowLg ? (
        <NextStepSection
          onClick={() => {
            Amplitude.track('OnBoard_Questionnaire_Start_ButtonClick');
          }}
          startAnimation={textRevealed.value}
        >
          Lets Start
        </NextStepSection>
      ) : null}
    </>
  );
}
