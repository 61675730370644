import BillDetailsView from '@/sections/library/bill/views/bill-details-view';
import { createFileRoute } from '@tanstack/react-router';

export const Route = createFileRoute('/_guarded/_main/library/vendors_/$id/bills/$billId')({
  component: () => {
    const { billId } = Route.useParams();
    const navigate = Route.useNavigate();
    return <BillDetailsView id={billId} onClose={() => navigate({ to: '../..' })} />;
  },
  wrapInSuspense: true
});
