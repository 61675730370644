import { cn } from '@/lib/utils';

export type LibraryTableHeaderProps = {
  children: React.ReactNode;
  className?: string;
};

export default function LibraryTableHeader({ children, className }: LibraryTableHeaderProps) {
  return <div className={cn('px-16 py-8 grid', className)}>{children}</div>;
}
