import type { WidgetProps } from '@/sections/widgets/types';
import { WidgetFactory } from '@/sections/widgets/widget-factory';
import { fCurrency } from '@/utils/format-number';
import { getAccountingDataQueries } from '@repo/features/accounting-data';
import { getFinancialQueries } from '@repo/features/financial-data';
import { endOfMonth, format } from 'date-fns';

import axiosInstance from '@/lib/axios';
import { cn } from '@/lib/utils';
import { Separator } from '@/components/ui/separator';

const financialQueries = getFinancialQueries(axiosInstance);
const accountingQueries = getAccountingDataQueries(axiosInstance);

const endOfMonthDate = format(endOfMonth(new Date()), 'yyyy-MM-dd');

function TableRow({ bold, label, value }: { bold?: boolean; label: string; value: number }) {
  return (
    <div className="flex items-center gap-6 py-8" key={`table_chart_${label}`}>
      <div className="flex flex-col flex-1 gap-2">
        <div className={cn('text-xs font-medium', bold && 'font-bold')}>{label}</div>
      </div>
      <div
        className={cn('text-sm font-semibold', {
          'text-fun-green': value > 0,
          'text-fun-red': value < 0
        })}
      >
        {fCurrency(value)}
      </div>
    </div>
  );
}

export const ExpectedEndOfMonthBalanceBreakdown = WidgetFactory.create(({ className }: WidgetProps) => {
  const {
    data: { currentBalance, expectedIn, expectedOut, total }
  } = financialQueries.expectedBalance.useSuspenseQuery({
    variables: {
      date: endOfMonthDate
    },

    select(data) {
      return {
        currentBalance: parseFloat(data.data.cash_balance.toFixed(2)),
        expectedIn: parseFloat(data.data.receivable_balance.toFixed(2)),
        expectedOut: parseFloat((Math.abs(data.data.payable_balance) * -1).toFixed(2)),
        total: parseFloat(
          (data.data.cash_balance + data.data.receivable_balance - Math.abs(data.data.payable_balance)).toFixed(2)
        )
      };
    }
  });

  return (
    <div className={cn('flex flex-col', className)}>
      <TableRow label="Current Balance" value={currentBalance} />
      <Separator className="bg-neutral-900" />
      <TableRow label="Expected Income" value={expectedIn} />
      <Separator className="bg-neutral-900" />
      <TableRow label="Expected Outcome" value={expectedOut} />
      <Separator className="bg-neutral-900" />
      <TableRow bold label="Projected End Balance" value={total} />
    </div>
  );
});
