import VendorDocumentsView from '@/sections/library/vendor/views/vendor-documents-view'
import { createFileRoute } from '@tanstack/react-router'

export const Route = createFileRoute(
  '/_guarded/_main/library/vendors_/$id/documents',
)({
  component: () => {
    const { id } = Route.useParams()
    return <VendorDocumentsView id={id} />
  },
})
