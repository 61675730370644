import CustomerInformationView from '@/sections/library/customer/views/customer-information-view'
import { createFileRoute } from '@tanstack/react-router'

export const Route = createFileRoute(
  '/_guarded/_main/library/customers_/$id/info',
)({
  component: CustomerInfoPage,
  wrapInSuspense: true,
})

function CustomerInfoPage() {
  const { id } = Route.useParams()
  return <CustomerInformationView id={id} />
}
