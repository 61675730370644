// just a concept usage of widget
import { Fragment, Suspense } from 'react';
import MessageLikeWidget from '@/sections/widgets/message-like-widget';
import WidgetSkeleton from '@/sections/widgets/message-like-widget/widget-skeleton';
import WidgetErrorFallback from '@/sections/widgets/widget-error-fallback';
import WidgetLoaderWrapper from '@/sections/widgets/widget-loader-wrapper';
import { getFeedQueries } from '@repo/features/feed';
import { useQueryClient } from '@tanstack/react-query';
import { ErrorBoundary } from 'react-error-boundary';

import axiosInstance from '@/lib/axios';
import { Button } from '@/components/ui/button';

function getWidget(widget: any) {
  switch (widget.type) {
    case 'message-like':
      return <MessageLikeWidget message={widget.data.message} />;
    default:
      return null;
  }
}

export default function HomePage() {
  const queryClient = useQueryClient();
  const feedQueries = getFeedQueries(axiosInstance);
  const {
    data: paginated,
    hasNextPage,
    fetchNextPage
  } = feedQueries.infinityPaginated.useInfiniteQuery({
    variables: {
      expand: [],
      limit: 10,
      stub: true
    }
  });

  return (
    <div className="flex flex-col divide-y">
      {paginated?.pages.map((page, i) => (
        <Fragment key={i}>
          {page.records?.map(record => (
            <>
              <ErrorBoundary FallbackComponent={WidgetErrorFallback}>
                <Suspense fallback={<WidgetSkeleton />}>
                  <WidgetLoaderWrapper id={i}>
                    <MessageLikeWidget className="p-10" message={record.subject} />
                  </WidgetLoaderWrapper>
                </Suspense>
              </ErrorBoundary>
              {/*{record?.widgets?.map((widget, index) => (*/}
              {/*  <ErrorBoundary FallbackComponent={WidgetErrorFallback} key={index}>*/}
              {/*    <Suspense fallback={<WidgetSkeleton />}>*/}
              {/*      <WidgetLoaderWrapper id={index}>{getWidget(widget)}</WidgetLoaderWrapper>*/}
              {/*    </Suspense>*/}
              {/*  </ErrorBoundary>*/}
              {/*))}*/}
            </>
          ))}
        </Fragment>
      ))}
      {hasNextPage ? (
        <Button
          onClick={async () => {
            await fetchNextPage();
          }}
        />
      ) : null}
    </div>
  );
}
