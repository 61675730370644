import { useEffect } from 'react';
import { CurrentBalanceDrawer } from '@/sections/cashflow/current-balance/current-balance-drawer';
import { ExpectedEndOfMonthDrawer } from '@/sections/cashflow/expected-end-of-month/expected-end-of-month-drawer';
import { useCashflowStore } from '@/sections/cashflow/use-cashflow-store';
import { CurrentBalanceMetricWidget } from '@/sections/widgets/metrics/current-balance-metric-widget';
import { ExpectedEndOfMonthMetricWidget } from '@/sections/widgets/metrics/expected-eom-metric-widget';
import { createFileRoute } from '@tanstack/react-router';
import { useShallow } from 'zustand/react/shallow';

export const Route = createFileRoute('/_guarded/_main/finances/cashflow')({
  component: RouteComponent
});

function useCloseDrawerOnUnmount(closeDrawer: () => void) {
  useEffect(() => {
    return () => {
      closeDrawer();
    };
  }, []);
}

function RouteComponent() {
  const { closeDrawer, isOpen, activeDrawer, openDrawer } = useCashflowStore(
    useShallow(state => ({
      isOpen: state.isOpen,
      closeDrawer: state.closeDrawer,
      activeDrawer: state.activeDrawer,
      openDrawer: state.openDrawer
    }))
  );

  useCloseDrawerOnUnmount(closeDrawer);

  return (
    <div className="flex flex-col items-center p-10">
      <div className="flex flex-col gap-6 w-full">
        <CurrentBalanceMetricWidget />
        <ExpectedEndOfMonthMetricWidget />
      </div>

      <CurrentBalanceDrawer />
      <ExpectedEndOfMonthDrawer />
    </div>
  );
}
