import AddNewCustomer from '@/sections/library/transaction/add-new-customer';
import { createFileRoute } from '@tanstack/react-router';

export const Route = createFileRoute('/_guarded/_main/library/invoices/$id/customer')({
  component: () => {
    const { id } = Route.useParams();
    return <AddNewCustomer id={id} />;
  },
  wrapInSuspense: true
});
