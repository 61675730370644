import FiltersIcon from '@/icons/Filters.svg?react';
import { Button } from '@/components/ui/button';

export type TransactionFiltersProps = {};

export default function TransactionFilters({}: TransactionFiltersProps) {
  return (
    <div className="px-8 py-6 lg:p-10 lg:h-34 flex items-center justify-between">
      <div className="text-base font-semibold text-white">All Transactions</div>
      <div>
        <Button size="icon-xs" variant="icon-bordered">
          <FiltersIcon className="size-4 fill-white" />
        </Button>
      </div>
    </div>
  );
}
