import ChevronRightIcon from '@/icons/ChevronRight.svg?react';
import TransactionType from '@/sections/library/transaction/transaction-type';
import { fCurrency } from '@/utils/format-number';
import { type ICustomer } from '@repo/features/customers';
import { getTransactionsQueries } from '@repo/features/transactions';
import { type IVendor } from '@repo/features/vendors';
import { useNavigate } from '@tanstack/react-router';
import { format } from 'date-fns';

import axiosInstance from '@/lib/axios';
import { Button } from '@/components/ui/button';
import { Separator } from '@/components/ui/separator';

import type { WidgetProps } from '../types';
import WidgetErrorFallback from '../widget-error-fallback';
import { WidgetFactory } from '../widget-factory';
import PeekWidgetSkeleton from './peek-widget-skeleton';

const transactionsQueries = getTransactionsQueries(axiosInstance);

export const PeekTransactionsWidget = WidgetFactory.create(
  ({ type, startDate }: WidgetProps & { startDate?: string; type?: 'income' | 'expense' }) => {
    const navigate = useNavigate();
    const { data: transactions } = transactionsQueries.paginated.useSuspenseQuery({
      variables: {
        page: 1,
        limit: 5,
        type,
        startDate,
        sortBy: 'time',
        sortOrder: 'asc'
      }
    });

    return (
      <div className="flex flex-col gap-4">
        {transactions.records.map(t => (
          <>
            <div className="flex justify-between" key={t._id}>
              <div className="flex flex-col gap-2">
                <div className="text-sm font-semibold">
                  {(t.customer as ICustomer)?.name || (t.vendor as IVendor)?.name || 'Unknown'}
                </div>
                <div className="text-4xs">{format(t.time, 'MMM dd, yyyy')}</div>
              </div>
              <div className="flex items-center gap-3">
                <div className="text-sm font-medium">{fCurrency(Math.abs(t.amount))}</div>
                <TransactionType type={t.amount >= 0 ? 'received' : 'paid'} />
              </div>
            </div>
            <Separator className="bg-neutral-900" />
          </>
        ))}
        <Button
          asChild
          onClick={async () => {
            await navigate({
              to: '/library/transactions'
            });
          }}
          variant="ghost"
        >
          <div className="flex justify-center items-center mx-auto cursor-pointer text-xs font-medium">
            <div>View All Transactions</div>
            <ChevronRightIcon className="size-3 shrink-0 ml-2" />
          </div>
        </Button>
      </div>
    );
  },
  {
    FallbackComponent: WidgetErrorFallback,
    SuspenseFallbackComponent: PeekWidgetSkeleton
  }
);
