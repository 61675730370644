import { Fragment } from 'react';
import BillRow from '@/sections/library/bill/bill-row';
import LibraryLoadMore from '@/sections/library/library-load-more';
import LibraryTableHead from '@/sections/library/library-table-head';
import LibraryTableHeader from '@/sections/library/library-table-header';
import { getBillQueries } from '@repo/features/bills';
import { createFileRoute, Outlet } from '@tanstack/react-router';

import axiosInstance from '@/lib/axios';
import { useBreakpoint } from '@/hooks/use-breakpoint';

export const Route = createFileRoute('/_guarded/_main/library/bills')({
  component: () => <BillsPage />
});

const billQueries = getBillQueries(axiosInstance);
function BillsPage() {
  const {
    data: paginated,
    hasNextPage,
    fetchNextPage
  } = billQueries.infinityPaginated.useInfiniteQuery({
    variables: {
      expand: ['vendor'],
      limit: 10
    }
  });
  const { isBelowLg } = useBreakpoint('lg');
  const navigate = Route.useNavigate();
  return (
    <>
      <div className="divide-y overflow-y-auto max-h-full">
        <LibraryTableHeader className="grid-cols-[1fr_1fr_1fr_11.5rem_11.5rem_11.5rem_6px] hidden lg:grid">
          <LibraryTableHead>Amount</LibraryTableHead>
          <LibraryTableHead>Vendor</LibraryTableHead>
          <LibraryTableHead>Invoice ID</LibraryTableHead>
          <LibraryTableHead>Issue Date</LibraryTableHead>
          <LibraryTableHead>Due Date</LibraryTableHead>
          <LibraryTableHead>Status</LibraryTableHead>
          <div />
        </LibraryTableHeader>
        {paginated?.pages.map((page, i) => (
          <Fragment key={i}>
            {page.records.map(record => (
              <BillRow
                bill={record}
                className="rounded-none"
                key={record._id}
                mode={isBelowLg ? 'list-item' : 'table'}
                onClick={() =>
                  navigate({
                    to: '/library/bills/$id',
                    params: { id: record._id }
                  })
                }
              />
            ))}
          </Fragment>
        ))}
        {hasNextPage ? (
          <LibraryLoadMore
            onClick={async () => {
              await fetchNextPage();
            }}
          />
        ) : null}
      </div>
      <Outlet />
    </>
  );
}
