import React, { useEffect, useRef, useState } from 'react';
import type { VariantProps } from 'class-variance-authority';
import { cva } from 'class-variance-authority';
import { motion } from 'framer-motion';

import { cn } from '@/lib/utils';
import { Avatar, AvatarFallback, AvatarImage } from '@/components/ui/avatar';

interface ProgressAvatarProps extends VariantProps<typeof progressiveAvatarVariants> {
  imageUrl?: string;
  fallback?: string;
  progress: number;
  className?: string;
}

const progressiveAvatarVariants = cva('[&_.avatar-fallback]:font-semibold [&_.avatar-fallback]:text-2xs', {
  variants: {
    variant: {
      default:
        '[&_.circle-outline]:stroke-white/10 [&_.circle-progress]:stroke-fun-green [&_.avatar-fallback]:bg-white/20 [&_.avatar-fallback]:text-white',
      secondary: '[&_.circle-outline]:stroke-neutral-600 [&_.circle-progress]:stroke-fun-green'
    }
  },
  defaultVariants: {
    variant: 'default'
  }
});

function ProgressAvatar({ imageUrl, variant, fallback = 'User', progress = 0, className = '' }: ProgressAvatarProps) {
  const avatarRef = useRef<HTMLDivElement>(null);
  const [dimensions, setDimensions] = useState({ width: 0, height: 0 });

  useEffect(() => {
    if (avatarRef.current) {
      const { offsetWidth, offsetHeight } = avatarRef.current;
      setDimensions({ width: offsetWidth, height: offsetHeight });
    }
  }, []);

  const strokeWidth = 4;

  const radius = dimensions.width / 2;
  const circumference = 2 * Math.PI * (radius - strokeWidth / 2);
  const progressOffset = circumference - (progress / 100) * circumference;

  return (
    <div className={cn('relative inline-block', progressiveAvatarVariants({ variant, className }))}>
      {/* Container for circles - positioned behind avatar */}
      {dimensions.width > 0 && (
        <div className="absolute inset-0 z-20">
          <svg height={dimensions.height} style={{ transform: 'rotate(-90deg)' }} width={dimensions.width}>
            {/* Background Circle */}
            <circle
              className="circle-outline"
              cx={radius}
              cy={radius}
              fill="none"
              r={radius - strokeWidth / 2}
              strokeWidth={strokeWidth}
            />
            {/* Progress Circle */}
            <motion.circle
              animate={{ strokeDashoffset: progressOffset }}
              className="circle-progress"
              cx={radius}
              cy={radius}
              fill="none"
              initial={{ strokeDashoffset: circumference }}
              r={radius - strokeWidth / 2}
              strokeDasharray={`${circumference} ${circumference}`}
              strokeLinecap="round"
              strokeWidth={strokeWidth}
              transition={{ duration: 0.5, ease: 'easeInOut' }}
            />
          </svg>
        </div>
      )}

      {/* Avatar - positioned above progress circle */}
      <div className="relative z-10">
        <Avatar className="avatar w-16 h-16 bg-none!" ref={avatarRef}>
          {imageUrl ? <AvatarImage alt="User avatar" src={imageUrl} /> : null}
          <AvatarFallback className="avatar-fallback bg-background">{fallback.slice(0, 2).toUpperCase()}</AvatarFallback>
        </Avatar>
      </div>
    </div>
  );
}

export default ProgressAvatar;
