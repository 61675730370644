import ChevronRightIcon from '@/icons/ChevronRight.svg?react';
import BillBadge from '@/sections/library/bill/bill-badge';
import LibraryRow from '@/sections/library/library-row';
import RowAmount from '@/sections/library/library-row/row-amount';
import RowDate from '@/sections/library/library-row/row-date';
import RowSubTitle from '@/sections/library/library-row/row-subtitle';
import RowTitle from '@/sections/library/library-row/row-title';
import type { IBill } from '@repo/features/bills';
import type { IVendor } from '@repo/features/vendors';

import { cn } from '@/lib/utils';

export type BillRowProps = {
  bill: IBill;
  hideTag?: boolean;
  hideChevron?: boolean;
  mode?: 'table' | 'list-item';
  onClick?: () => void;
  className?: string;
};
export default function BillRow({
  bill,
  hideTag = false,
  hideChevron = false,
  mode = 'list-item',
  onClick,
  className
}: BillRowProps) {
  if (mode === 'table') {
    return (
      <div
        className={cn(
          'grid grid-cols-[1fr_1fr_1fr_11.5rem_11.5rem_11.5rem_auto] py-10 px-16 items-center justify-center cursor-pointer'
        )}
        onClick={onClick}
      >
        <div className="flex items-center">
          <div className="flex items-center gap-3">
            <RowAmount amount={bill.amount} />
          </div>
        </div>
        <div className="flex items-center">
          <RowTitle title={(bill.vendor as IVendor).name} />
        </div>
        <div className="flex items-center">
          <RowSubTitle subtitle={bill.invoiceNumber || bill.receiptNumber || ''} />
        </div>
        <div className="flex items-center">
          <RowDate date={bill.createdAt as any as string} />
        </div>
        <div className="flex items-center">
          <RowDate date={bill.dueDate as any as string} />
        </div>
        <div className="flex items-center">
          <BillBadge variant={bill.status as any}>{bill.status}</BillBadge>
        </div>
        <div>
          <ChevronRightIcon className="h-3" />
        </div>
      </div>
    );
  }
  return (
    <LibraryRow
      amount={<RowAmount amount={bill.amount} />}
      className={className}
      date={<RowDate date={bill.createdAt as any as string} />}
      hideChevron={hideChevron}
      icon={null}
      onClick={onClick}
      subtitle={<RowSubTitle subtitle={bill.invoiceNumber || bill.receiptNumber || ''} />}
      tag={<BillBadge variant={bill.status as any}>{bill.status}</BillBadge>}
      title={<RowTitle title={(bill.vendor as IVendor).name} />}
    />
  );
}
