import TransactionDetailsView from '@/sections/library/transaction/views/transaction-details-view'
import { createFileRoute } from '@tanstack/react-router'

export const Route = createFileRoute(
  '/_guarded/_main/library/transactions/$id',
)({
  component: () => {
    const { id } = Route.useParams()
    return <TransactionDetailsView id={id} />
  },
  wrapInSuspense: true,
})
