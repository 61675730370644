import MiloAvatar from '@/assets/icons/milo-avatar';
import MessageFeedback from '@/sections/chat/message-feedback';
import ChatUIActions from '@/sections/chat/ui-actions';
import { fToNow } from '@/utils/format-time';
import type { IMessage } from '@repo/features/conversation';
import { getFileQueries } from '@repo/features/file';
import { useQueries } from '@tanstack/react-query';
import { differenceInSeconds, parseISO } from 'date-fns';

import axiosInstance from '@/lib/axios';
import { cn } from '@/lib/utils';
import FilePreview from '@/components/file-preview';

import MdxPreview from '../mdx/mdx-preview';

// ----------------------------------------------------------------------

type Props = {
  message: IMessage & { _isNew?: boolean };
  className?: string;
  isLast: boolean;
};
const fileQueries = getFileQueries(axiosInstance);

export default function ChatMessageItem({ message, className, isLast }: Props) {
  const me = message.role === 'user';
  const { content, mdContent, fileIds = [] } = message;
  const contentToUse = mdContent ?? content;
  const uiActions = message.metadata?.uiActions;
  const secondsCreatedAgo = message.createdAt ? differenceInSeconds(new Date(), parseISO(message.createdAt)) : 1000;

  const { data: signedUrls, pending } = useQueries({
    queries: fileIds.map(id => fileQueries.getSignedUrl.getOptions({ id })),
    combine: results => {
      return {
        data: results.map(result => result.data),
        pending: results.some(result => result.isPending)
      };
    }
  });

  const renderMessageTime = (
    <div className="text-4xs text-neutral-200">
      {secondsCreatedAgo < 60 ? 'now' : fToNow(message.createdAt ? parseISO(message.createdAt) : '')}
    </div>
  );

  const renderBody = (
    <div
      className={cn('min-w-64 rounded-2xl text-sm prose prose-sm py-4 px-6 text-white', {
        'bg-fun-cyan text-white rounded-tr-none': me,
        'max-w-[90%]': !me,
        hidden: !message.content.trim()
      })}
    >
      {message.role === 'assistant' || message.role === 'support' || message.role === 'system_agent' ? (
        <MdxPreview scope={message.metadata?.scope} source={contentToUse} />
      ) : (
        contentToUse
      )}
      {uiActions ? <ChatUIActions className="mt-3" uiActions={uiActions} /> : null}
    </div>
  );

  if (!['assistant', 'user', 'support', 'system_agent'].includes(message.role)) return null;

  return (
    <div
      className={cn(
        'flex flex-col gap-4',
        {
          'items-end': me,
          'animate-in zoom-in': secondsCreatedAgo < 2
        },
        className
      )}
    >
      <div className={cn('flex flex-row items-center font-inter text-right')}>
        {message.role === 'user' ? (
          <div className="flex items-center gap-3">
            {renderMessageTime}
            <div className="text-2xs font-semibold">Me</div>
          </div>
        ) : (
          <div className="flex items-center gap-3">
            <MiloAvatar className="size-12" />
            {renderMessageTime}
          </div>
        )}
      </div>
      <div className="flex flex-col w-auto max-w-full">
        <div className={cn('break-words overflow-visible')}>{renderBody}</div>
        {isLast && ['assistant', 'support', 'system_agent'].includes(message.role) ? (
          <MessageFeedback message={message} />
        ) : null}
        <div className="flex flex-row gap-2 mt-2">
          {!pending
            ? signedUrls.map(signedUrl => <FilePreview key={signedUrl} progress={1} signedUrl={signedUrl} />)
            : null}
        </div>
      </div>
    </div>
  );
}
