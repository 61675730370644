import { useEffect, useLayoutEffect, useMemo } from 'react';
import { FormProvider, useForm } from 'react-hook-form';

import { getMode, getResolver } from '@/components/form-wizard/helpers/form';
import { buildHashSteps, resolveHashStep, updateHash } from '@/components/form-wizard/helpers/hash';
import { useWizardStore } from '@/components/form-wizard/wizard/context';

import type { WizardProps } from '../types';

function Wizard({ steps, onCompleted, onStepChanged, enableHash, header, wrapper, className, footer }: WizardProps) {
  const store = useWizardStore(state => state);
  const { activeStep, values } = store;
  const hashes = useMemo(() => {
    return enableHash ? buildHashSteps(steps) : {};
  }, [enableHash, steps]);
  // const defaultValues = useMemo(() => {
  //   return values[activeStep.id] || activeStep.initialValues || {};
  // }, [activeStep, values]);
  //
  const defaultValues = useMemo(() => {
    if (values[activeStep.id]) {
      return values[activeStep.id];
    }

    if (activeStep.initialValues) {
      return { ...activeStep.initialValues };
    }
    return {};
  }, [activeStep.id, values, activeStep.initialValues]);

  const methods = useForm({
    defaultValues,
    mode: getMode(activeStep),
    resolver: getResolver(activeStep, values)
    // shouldUnregister: true
  });
  useEffect(() => {
    const initializeWizard = async () => {
      store.setCallbacks({ onCompleted, onStepChanged });

      await store.setSteps(steps);

      if (enableHash) {
        const hashStep = resolveHashStep(hashes);
        if (hashStep) {
          store.setActiveStep(hashStep);
        }
      }
    };

    initializeWizard();
  }, [steps]);

  useEffect(() => {
    methods.reset(defaultValues);
  }, [defaultValues]);

  useEffect(() => {
    if (!enableHash) return;

    const handleHashChange = () => {
      const step = resolveHashStep(hashes);
      if (step.id === activeStep.id) return;
      store.setActiveStep(step);
    };

    window.addEventListener('hashchange', handleHashChange);
    updateHash(hashes, activeStep);
    return () => {
      window.removeEventListener('hashchange', handleHashChange);
    };
  }, [activeStep, enableHash, hashes]);

  if (!activeStep.id) {
    return null; // Or a loading state
  }

  const StepComponent = wrapper ?? activeStep.component;

  return (
    <FormProvider {...methods}>
      <form className={className} onSubmit={methods.handleSubmit(store.handleNext)}>
        {activeStep.header ?? header}
        {wrapper || activeStep.component}
        {activeStep.footer || footer}
      </form>
    </FormProvider>
  );
}

export default Wizard;
