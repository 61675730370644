// src/hooks/useInvoicesQueries.ts
import { AxiosError, AxiosInstance, AxiosProgressEvent } from 'axios';
import { router } from 'react-query-kit';

import { ICustomer } from '../customers';
import { PaginatedResponse } from '../types'; // Define IInvoice interface in your types file

import { GetInvoicesParams, IInvoice, InvoiceExtensions } from './types';

export const getInvoiceQueries = (axiosInstance: AxiosInstance) =>
  router('invoice', {
    // Query to fetch all invoices
    all: router.query<IInvoice[], void, AxiosError>({
      fetcher: () => axiosInstance.get('/invoices/all').then(res => res.data)
    }),
    paginated: router.query<PaginatedResponse<IInvoice>, GetInvoicesParams, AxiosError>({
      fetcher: variables => axiosInstance.get('/invoices', { params: variables }).then(res => res.data)
    }),
    infinityPaginated: router.infiniteQuery<
      PaginatedResponse<IInvoice>,
      { page?: number; limit?: number; expand?: InvoiceExtensions[] },
      AxiosError
    >({
      fetcher: (variables, { pageParam }) =>
        axiosInstance.get('/invoices', { params: { ...variables, page: pageParam } }).then(res => res.data),
      getNextPageParam: lastPage => (lastPage.totalPages >= lastPage.page + 1 ? lastPage.page + 1 : null),
      initialPageParam: 1
    }),
    // Query to fetch a single invoice by ID
    byId: router.query<IInvoice, { id: string; expand?: InvoiceExtensions[] }, AxiosError>({
      fetcher: ({ id, ...params }) => axiosInstance.get(`/invoices/${id}`, { params }).then(res => res.data)
    }),

    // Mutation to create a new invoice
    create: router.mutation<IInvoice, { data: Partial<IInvoice> }, AxiosError>({
      mutationFn: async variables => axiosInstance.post('/invoices', variables.data).then(res => res.data)
    }),

    // Mutation to update an invoice by ID
    update: router.mutation<
      IInvoice,
      { id: string; data: Partial<IInvoice>; context?: { customer: ICustomer } },
      AxiosError
    >({
      mutationFn: async variables =>
        axiosInstance.patch(`/invoices/${variables.id}`, variables.data).then(res => res.data)
    }),

    // Mutation to delete an invoice by ID
    delete: router.mutation<void, { id: string }, AxiosError>({
      mutationFn: async variables => axiosInstance.delete(`/invoices/${variables.id}`).then(res => res.data)
    }),

    // Query to find invoices by customer ID
    byCustomerId: router.query<IInvoice[], { customerId: string }, AxiosError>({
      fetcher: variables => axiosInstance.get(`/invoices/customer/${variables.customerId}`).then(res => res.data)
    }),

    // Query to find an invoice by invoice number
    byInvoiceNumber: router.query<IInvoice, { invoiceNumber: string }, AxiosError>({
      fetcher: variables => axiosInstance.get(`/invoices/number/${variables.invoiceNumber}`).then(res => res.data)
    }),

    // make upload function that is sending post to /invoice/upload
    upload: router.mutation<{ message: string; success: boolean; issues: string[] }, { fileId: string }, AxiosError>({
      mutationFn: async variables => {
        return axiosInstance.post('/invoices/import', { fileId: variables.fileId }).then(res => res.data);
      }
    }),

    currentMonth: router.query<
      PaginatedResponse<IInvoice> & { aggs?: { [key: string]: number } },
      GetInvoicesParams,
      AxiosError
    >({
      fetcher: variables => axiosInstance.get('/invoices', { params: variables }).then(res => res.data)
    }),

    signAndUpload: router.mutation<
      IInvoice,
      {
        invoiceId: string;
        file: File;
        onUploadProgress?: (progressEvent: AxiosProgressEvent) => void;
      },
      AxiosError
    >({
      mutationFn: async ({ file, onUploadProgress, invoiceId }) => {
        const {
          data: { signedUrl, fileId }
        } = await axiosInstance.post<{ signedUrl: string; fileId: string }>(`/files/get-signed-url`, {
          filePath: file.name,
          fileContentType: file.type
        });
        await axiosInstance.put(signedUrl, file, {
          headers: { 'Content-Type': file.type },
          onUploadProgress
        });
        const updatedInvoice = await axiosInstance.post<IInvoice>(`/invoices/${invoiceId}/documentUpload`);
        return updatedInvoice.data;
      }
    })
  });
