import WorkingDog from '@/assets/lottie/working-dog';
import { getAchievementQueries } from '@repo/features/achievements';
import { getFinancialQueries } from '@repo/features/financial-data';
import { subMonths } from 'date-fns';

import axiosInstance from '@/lib/axios';

import type { WidgetProps } from '../types';
import WidgetErrorFallback from '../widget-error-fallback';
import { WidgetFactory } from '../widget-factory';
import MetricWidgetSkeleton from './metric-widget-skeleton';
import { MiloMetric } from './milo-metric';

const achievementsQueries = getAchievementQueries(axiosInstance);

export const TimeSavedMetricWidget = WidgetFactory.create(
  ({ className }: WidgetProps) => {
    const {
      data: timeSaved,
      error,
      isFetching
    } = achievementsQueries.getSum.useSuspenseQuery({
      variables: {
        type: 'timeSaved'
      },

      select(data) {
        const candidate = data.totalSum;

        if (!candidate) {
          throw new Error('Missing Time Saved metric');
        }
        return candidate;
      }
    });

    if (error && !isFetching) {
      throw error;
    }

    return (
      <MiloMetric
        className={className}
        icon={<WorkingDog className="size-32" />}
        label="Time Saved"
        metric={`${timeSaved}`}
      />
    );
  },
  {
    FallbackComponent: WidgetErrorFallback,
    SuspenseFallbackComponent: MetricWidgetSkeleton
  }
);
