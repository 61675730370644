import BackIcon from '@/icons/Back.svg?react';
import FilterIcon from '@/icons/Filters.svg?react';
import { PeekAccountsWidget } from '@/sections/widgets/peek/peek-accounts-widget';
import { PeekTransactionsWidget } from '@/sections/widgets/peek/peek-transactions-widget';
import { format, startOfMonth } from 'date-fns';
import { ArrowLeftIcon } from 'lucide-react';
import { useShallow } from 'zustand/react/shallow';

import { Button } from '@/components/ui/button';
import { Drawer, DrawerClose, DrawerContent, DrawerDescription, DrawerTitle } from '@/components/ui/drawer';

import { CurrentBalanceSimpleMetricWidget } from '../../widgets/metrics/current-balance-simple-metric-widget';
import { useCashflowStore } from '../use-cashflow-store';
import { CurrentMonthCashflow } from './current-month-cashflow';
import { CurrentMonthCashflowGraph } from './current-month-cashflow-graph';

const startOfMonthDate = format(startOfMonth(new Date()), 'yyyy-MM-dd');

export function CurrentBalanceDrawer() {
  const { closeDrawer, isOpen, activeDrawer, openDrawer } = useCashflowStore(
    useShallow(state => ({
      isOpen: state.isOpen,
      closeDrawer: state.closeDrawer,
      activeDrawer: state.activeDrawer,
      openDrawer: state.openDrawer
    }))
  );

  return (
    <Drawer
      direction="right"
      onClose={() => {
        document.body.style.pointerEvents = 'auto';
      }}
      onOpenChange={_isOpen => {
        if (_isOpen) {
          openDrawer('current_balance');
        } else {
          closeDrawer();
        }
      }}
      open={isOpen ? activeDrawer === 'current_balance' : false}
    >
      <DrawerContent
        className="h-full w-full lg:w-[28.125rem] rounded-none lg:rounded-tl-lg lg:rounded-bl-lg lg:right-0 lg:left-auto"
        hideHandle
      >
        <div className="flex justify-between items-center px-6 py-4">
          <DrawerClose asChild>
            <Button className="min-w-8" size="icon" variant="icon">
              <BackIcon className="size-5" />
            </Button>
          </DrawerClose>
          <Button size="icon-lg" variant="icon-bordered">
            <FilterIcon className="size-5" />
          </Button>
        </div>

        <div className="flex flex-1 overflow-y-scroll flex-col py-6 px-8">
          <DrawerTitle className="text-3xl font-semibold">Current Balance</DrawerTitle>
          <DrawerDescription className="text-neutral-200 text-xs">
            Your available balance across all connected accounts.
          </DrawerDescription>
          <div className="flex flex-col gap-14 mt-6">
            <CurrentBalanceSimpleMetricWidget className="text-4xl" />
            <div className="text-lg font-semibold">This Month Cash Position</div>

            <CurrentMonthCashflowGraph />
            <CurrentMonthCashflow />
            <div>
              <div className="text-lg font-semibold">Accounts</div>
              <PeekAccountsWidget />
            </div>
            <div>
              <div className="text-lg font-bold">Top Cash In</div>
              <div className="text-neutral-200 text-xs">Top cash inflows across accounts</div>
            </div>
            <PeekTransactionsWidget startDate={startOfMonthDate} type="income" />
            <div>
              <div className="text-lg font-bold">Top Cash Out</div>
              <div className="text-neutral-200 text-xs">Top cash outflows across accounts</div>
            </div>
            <PeekTransactionsWidget startDate={startOfMonthDate} type="expense" />
          </div>
        </div>
      </DrawerContent>
    </Drawer>
  );
}
