import AppHeader from '@/layouts/common/app-header';
import { HeaderPortal } from '@/layouts/common/header-portal';
import MainLayout from '@/layouts/main-layout';
import { createFileRoute, Outlet } from '@tanstack/react-router';

export const Route = createFileRoute('/_guarded/_main')({
  component: () => (
    <MainLayout
      inputProps={{
        variant: 'dark'
      }}
    >
      <HeaderPortal>
        <AppHeader variant="secondary" />
      </HeaderPortal>
      <Outlet />
    </MainLayout>
  )
});
