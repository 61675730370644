import digging2 from '@/assets/gifs/digging2.gif';
import headphones2 from '@/assets/gifs/headphones2.gif';
import jumping2 from '@/assets/gifs/jumping2.gif';
import presenter2 from '@/assets/gifs/presenter2.gif';
import sniffing2 from '@/assets/gifs/sniffing2.gif';
import working2 from '@/assets/gifs/working2.gif';
import { useAuth } from '@/auth/hooks';
import type AuthHeader from '@/layouts/common/auth-header';
import { useShallow } from 'zustand/react/shallow';

import { cn } from '@/lib/utils';
import { useBreakpoint } from '@/hooks/use-breakpoint';
import { useWizard } from '@/components/form-wizard';
import Logo from '@/components/logo';
import ProgressAvatar from '@/components/progressive-avatar';

// ----------------------------------------------------------------------

interface OnboardingLayoutProps {
  children: React.ReactNode;
  headerProps?: React.ComponentProps<typeof AuthHeader>;
}

const animationsMap = {
  intro: jumping2,
  companyName: jumping2,
  businessExperience: sniffing2,
  industry: headphones2,
  revenueRange: digging2,
  businessEntityType: presenter2,
  businessTasks: working2,
  connectAccount: jumping2
};

export default function OnboardingLayout({ children, headerProps = {} }: OnboardingLayoutProps) {
  const { isBelowLg } = useBreakpoint('lg');
  const { user } = useAuth();
  const [activeStep, progress] = useWizard(useShallow(state => [state.activeStep, state.progress]));
  const isIntroductionShown = false; //isBelowLg && location.pathname === '/auth/signup' && initialScreen === 'introduction';
  const renderSection = (
    <div
      className={cn(
        'min-h-dvh relative lg:basis-2/5 flex flex-col h-dvh p-10 lg:py-14 lg:px-20 lg:flex lg:flex-col bg-primary',
        {
          hidden: isBelowLg
        }
      )}
    >
      <div>
        <Logo beta className="" fill="#fff" />
      </div>
      <div className="flex h-[9rem] flex-1 flex-col gap-4 lg:gap-6 justify-center items-center mt-6 lg:mt-0">
        <div>
          {/*@ts-expect-error*/}
          <img alt="milo Dog" src={animationsMap[activeStep.id] || jumping2} />
        </div>
      </div>
      <div className="flex items-center gap-4">
        <ProgressAvatar fallback={user ? `${user.firstName[0]}${user.lastName[0]}` : ''} progress={progress} />
        <div className="hidden lg:block text-2xs font-semibold text-white">
          {user?.firstName} {user?.lastName}
        </div>
      </div>
    </div>
  );

  const renderContent = (
    <div className="flex flex-1 h-dvh flex-col items-center justify-center overflow-hidden">{children}</div>
  );

  return (
    <main
      className={cn('flex h-dvh flex-1 flex-col lg:flex-row overflow-hidden lg:px-0 lg:py-0', {
        'py-0 px-0': isIntroductionShown
      })}
    >
      {/*<MobileOnboardingHeader {...headerProps} />*/}
      {renderSection}

      {renderContent}
    </main>
  );
}
