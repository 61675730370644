import { Skeleton } from '@/components/ui/skeleton';

// TODO replace with real skeleton component
export default function MetricWidgetSkeleton() {
  return (
    <div className="space-y-5 border-neutral-300 border-2">
      <div className="flex items-center space-x-4">
        <div className="space-y-2">
          <Skeleton className="h-4 w-[4rem]" />
          <Skeleton className="h-3 w-14" />
        </div>
      </div>
      <div className="space-y-3">
        <Skeleton className=" border-neutral-300 border-2 h-3 w-[85%]" />
        <Skeleton className=" border-neutral-300 border-2 h-3 w-[95%]" />
        <Skeleton className=" border-neutral-300 border-2 h-3 w-[85%]" />
        <Skeleton className=" border-neutral-300 border-2 h-3 w-[95%]" />
      </div>
    </div>
  );
}
