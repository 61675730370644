// src/hooks/useBillsQueries.ts
import { AxiosError, AxiosInstance, AxiosProgressEvent } from 'axios';
import { router } from 'react-query-kit';

import { PaginatedResponse } from '../types'; // Define IBill interface in your types file

import { IVendor } from '../vendors';
import { BillExtensions, GetBillsParams, IBill } from './types';

export const getBillQueries = (axiosInstance: AxiosInstance) =>
  router('bill', {
    // Query to fetch all bills
    all: router.query<IBill[], void, AxiosError>({
      fetcher: () => axiosInstance.get('/bills/all').then(res => res.data)
    }),

    paginated: router.query<PaginatedResponse<IBill>, GetBillsParams, AxiosError>({
      fetcher: variables => axiosInstance.get('/bills', { params: variables }).then(res => res.data)
    }),

    infinityPaginated: router.infiniteQuery<
      PaginatedResponse<IBill>,
      { page?: number; limit?: number; expand?: BillExtensions[] },
      AxiosError
    >({
      fetcher: (variables, { pageParam }) =>
        axiosInstance.get('/bills', { params: { ...variables, page: pageParam } }).then(res => res.data),
      getNextPageParam: lastPage => (lastPage.totalPages >= lastPage.page + 1 ? lastPage.page + 1 : null),
      initialPageParam: 1
    }),

    // Query to fetch a single bill by ID
    byId: router.query<IBill, { id: string; expand?: BillExtensions[] }, AxiosError>({
      fetcher: ({ id, ...params }) => axiosInstance.get(`/bills/${id}`, { params }).then(res => res.data)
    }),

    // Mutation to create a new bill
    create: router.mutation<IBill, { data: Partial<IBill> }, AxiosError>({
      mutationFn: async variables => axiosInstance.post('/bills', variables.data).then(res => res.data)
    }),

    // Mutation to update a bill by ID
    update: router.mutation<IBill, { id: string; data: Partial<IBill>; context?: { vendor: IVendor } }, AxiosError>({
      mutationFn: async variables => axiosInstance.patch(`/bills/${variables.id}`, variables.data).then(res => res.data)
    }),

    // Mutation to delete a bill by ID (soft delete)
    delete: router.mutation<void, { id: string }, AxiosError>({
      mutationFn: async variables => axiosInstance.delete(`/bills/${variables.id}`).then(res => res.data)
    }),
    // make upload function that is sending post to /invoice/upload
    upload: router.mutation<{ message: string; success: boolean; issues: String }, { fileId: string }, AxiosError>({
      mutationFn: async variables => {
        return axiosInstance.post('/bills/import', { fileId: variables.fileId }).then(res => res.data);
      }
    }),

    currentMonth: router.query<
      PaginatedResponse<IBill> & { aggs?: { [key: string]: number } },
      GetBillsParams,
      AxiosError
    >({
      fetcher: variables => axiosInstance.get('/bills', { params: variables }).then(res => res.data)
    }),

    // Query to search for bills with various parameters
    search: router.query<
      IBill[],
      {
        vendor?: string;
        minAmount?: number;
        maxAmount?: number;
        startDueDate?: string; // Assuming dates are passed as ISO strings
        endDueDate?: string;
      },
      AxiosError
    >({
      fetcher: params => {
        return axiosInstance.get(`/bills/search`, { params }).then(res => res.data);
      }
    }),
    signAndUpload: router.mutation<
      IBill,
      {
        billId: string;
        file: File;
        onUploadProgress?: (progressEvent: AxiosProgressEvent) => void;
      },
      AxiosError
    >({
      mutationFn: async ({ file, onUploadProgress, billId }) => {
        const {
          data: { signedUrl, fileId }
        } = await axiosInstance.post<{ signedUrl: string; fileId: string }>(`/files/get-signed-url`, {
          filePath: file.name,
          fileContentType: file.type
        });
        await axiosInstance.put(signedUrl, file, {
          headers: { 'Content-Type': file.type },
          onUploadProgress
        });
        const updatedBill = await axiosInstance.post<IBill>(`/bills/${billId}/documentUpload`);
        return updatedBill.data;
      }
    })
  });
