import { AxiosError, AxiosInstance } from 'axios';
import { router } from 'react-query-kit';

import { IConversation } from '../conversation/types';
import { PaginatedResponse } from '../types';
import { FeedExpansions, IFeedItem } from './types';

export const getFeedQueries = (axiosInstance: AxiosInstance) =>
  router('feed', {
    infinityPaginated: router.infiniteQuery<
      PaginatedResponse<IFeedItem>,
      {
        page?: number;
        limit?: number;
        expand?: FeedExpansions[];
        startDate?: string;
        endDate?: string;
        stub?: boolean;
      },
      AxiosError
    >({
      fetcher: (variables, { pageParam }) =>
        axiosInstance.get('/feed', { params: { ...variables, page: pageParam } }).then(res => res.data),
      getNextPageParam: lastPage => (lastPage.totalPages >= lastPage.page + 1 ? lastPage.page + 1 : null),
      initialPageParam: 1
    }),
    // Mutation to start a conversation on an achievement
    startConversation: router.mutation<IConversation, { id: string }, AxiosError>({
      mutationFn: async ({ id }) => axiosInstance.post(`/feed/${id}/start-conversation`).then(res => res.data)
    }),

    byId: router.query<IFeedItem, { id: number | string; expand?: FeedExpansions[] }, AxiosError>({
      fetcher: ({ id, ...params }) => axiosInstance.get(`/feed/${id}`, { params }).then(res => res.data)
    })
  });
