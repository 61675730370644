import { createContext, useContext, useRef } from 'react';
import type { StoreApi } from 'zustand';
import { useStore } from 'zustand';

import type { WizardStore } from '@/components/form-wizard/wizard/store';
import { createWizardStore } from '@/components/form-wizard/wizard/store';

interface WizardContextValue {
  store: StoreApi<WizardStore>;
}

const WizardContext = createContext<WizardContextValue | null>(null);

export function WizardProvider({
  children,
  initialState
}: {
  children: React.ReactNode;
  initialState?: Partial<WizardStore>;
}) {
  const storeRef = useRef<StoreApi<WizardStore>>();

  if (!storeRef.current) {
    storeRef.current = createWizardStore(initialState);
  }

  return <WizardContext.Provider value={{ store: storeRef.current }}>{children}</WizardContext.Provider>;
}

export function useWizardStore<T>(selector: (state: WizardStore) => T): T {
  const context = useContext(WizardContext);
  if (!context) throw new Error('useWizardStore must be used within WizardProvider');

  return useStore(context.store, selector);
}
