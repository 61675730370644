import React from 'react';
import { fCurrency, fPercent } from '@/utils/format-number';

import { cn } from '@/lib/utils';
import ConnectAccountButton from '@/components/connect-account-button';

export type StatisticCardProps = {
  percentageDiff?: number;
  amount: number | string;
  title: string;
  type?: 'positive' | 'negative' | 'neutral';
};
function StatisticCard({ percentageDiff, amount, title, type = 'neutral', ...otherProps }: StatisticCardProps) {
  const colorsMap = {
    positive: 'fun-green',
    negative: 'fun-red',
    neutral: 'gray-400'
  };
  return (
    <div className={cn('')} {...otherProps}>
      <div className="flex justify-between items-center">
        <div>
          <div className="text-3xl font-medium">{typeof amount === 'number' ? fCurrency(amount) : amount}</div>
          <div className="text-xs">{title}</div>
        </div>
      </div>
      {percentageDiff !== undefined ? (
        <div className="mt-[22px] flex flex-row items-center">
          <div className={`text-[11px] font-[600] text-${colorsMap[type]}`}>({fPercent(percentageDiff)})</div>
          <div className="text-[11px] font-[600] text-gray-500">&nbsp;from last month</div>
        </div>
      ) : null}
    </div>
  );
}

export default StatisticCard;
