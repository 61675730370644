import { useEffect } from 'react';
import { useAppStateStore } from '@/stores/app-state';
import Amplitude from '@/utils/pixels/amplitude/amplitude';
import FacebookPixel from '@/utils/pixels/fb/fb';
import HubSpotManager from '@/utils/pixels/hs/hs';
import * as Sentry from '@sentry/react';
import ReactGA from 'react-ga4';

import actionsObserver from '@/lib/actions-observer';

const useAnalytics = () => {
  useEffect(() => {
    // -------------------------------------------------------------------
    actionsObserver.subscribe('HOMEPAGE_ACTION_CLICK', (_, { action }) => {
      ReactGA.event('homepage_action_click', action);
      Amplitude.track('homepage_action_click', action);
    });
    // -------------------------------------------------------------------
    actionsObserver.subscribe('ONBOARDING_USER_UPDATE', (_, { onboardingData }) => {});
    // -------------------------------------------------------------------
    actionsObserver.subscribe('ON_APP_INIT', async () => {
      if (import.meta.env.VITE_AMPLITUDE_API_KEY) {
        await Amplitude.init(
          import.meta.env.VITE_AMPLITUDE_API_KEY,
          !!import.meta.env.DEV ? 'development' : 'production'
        );
      }
      ReactGA.initialize(import.meta.env.VITE_GA_MEASUREMENT_ID, {
        testMode: import.meta.env.DEV
      });
      FacebookPixel.init(import.meta.env.VITE_FB_MEASUREMENT_ID, {}, { debug: import.meta.env.DEV, autoConfig: true });
      HubSpotManager.init(import.meta.env.VITE_HS_PORTAL_ID, APP_ENV === 'production'); // true for enabling debug mode

      FacebookPixel.pageView();
    });
    // -------------------------------------------------------------------
    actionsObserver.subscribe('PAGE_VIEW', (_, { pathname }) => {
      ReactGA.send({ hitType: 'pageview', page: pathname, title: pathname });
      HubSpotManager.pageView({ pathname });
    });
    // -------------------------------------------------------------------
    actionsObserver.subscribe('USER_SETTLED', (_, { user }) => {
      console.log('user settled', user);
      Amplitude.identify(user.email, {
        userId: user._id,
        name: [user.firstName, user.lastName].join(' ').trim(),
        accounts_connected: useAppStateStore.getState().accountsConnected,
        user_active: useAppStateStore.getState().isActive,
        ...(user.utm ?? {})
      });

      Sentry.setUser({ email: user.email, id: user._id, name: user.firstName + ' ' + user.lastName });

      ReactGA.set({
        user_id: user._id,
        user_properties: {
          accounts_connected: useAppStateStore.getState().accountsConnected,
          active: useAppStateStore.getState().isActive
        }
      });

      HubSpotManager.identifyUser({ email: user.email });
    });
    // -------------------------------------------------------------------
    actionsObserver.subscribe('SIGN_UP_BUTTON_CLICK', () => {
      ReactGA.event('sign_up_button_click');
      Amplitude.track('sign_up_button_click');
    });
    // -------------------------------------------------------------------
    actionsObserver.subscribe('GOOGLE_SIGN_IN', () => {
      ReactGA.event('login', {
        method: 'Google'
      });
      Amplitude.track('login', { provider: 'google' });
    });
    // -------------------------------------------------------------------
    actionsObserver.subscribe('GOOGLE_SIGN_UP', () => {
      ReactGA.event('sign_up', {
        method: 'Google'
      });
      Amplitude.track('sign_up', { provider: 'google' });
      // @ts-expect-error
      window.lintrk('track', { conversion_id: import.meta.env.VITE_LINKEDIN_CONVERSION_ID });
      FacebookPixel.track('CompleteRegistration');
    });
    // -------------------------------------------------------------------
    actionsObserver.subscribe('DATABASE_SIGN_IN', () => {
      ReactGA.event('login', {
        method: 'Database'
      });

      Amplitude.track('sign_in', { provider: 'database' });
    });
    // -------------------------------------------------------------------
    actionsObserver.subscribe('DATABASE_SIGN_UP', () => {
      ReactGA.event('sign_up', {
        method: 'Database'
      });

      Amplitude.track('sign_up', { provider: 'database' });
      // @ts-expect-error
      window.lintrk('track', { conversion_id: import.meta.env.VITE_LINKEDIN_CONVERSION_ID });
      FacebookPixel.track('CompleteRegistration');
    });
    // -------------------------------------------------------------------
    actionsObserver.subscribe('FORGOT_PASSWORD_CLICK', () => {
      Amplitude.track('forgot_password_click');
      ReactGA.event('forgot_password_click');
    });
    // -------------------------------------------------------------------
    actionsObserver.subscribe('PULSE_STORY_CLICK', (_, { type }) => {
      ReactGA.event('pulse_story_click', {
        type
      });
      Amplitude.track('pulse_story_click', { type });
    });
    // -------------------------------------------------------------------
    actionsObserver.subscribe('CONNECT_BANK_CLICK', () => {
      ReactGA.event('connect_bank_click');
      Amplitude.track('connect_bank_click');
    });
    // -------------------------------------------------------------------
    actionsObserver.subscribe('PLAID_SUCCESS_RESPONSE', (_, metadata) => {
      ReactGA.event('plaid_success_response', metadata);
      Amplitude.track('plaid_success_response', metadata);
    });
    // -------------------------------------------------------------------
    actionsObserver.subscribe('CONNECT_BANK_SUCCESS', () => {
      ReactGA.event('connect_bank_success');
      ReactGA.set({
        user_properties: {
          accounts_connected: true
        }
      });
      Amplitude.track('connect_bank_success');
      Amplitude.setPropertiesOnUser({ accounts_connected: true });
    });
    // -------------------------------------------------------------------
    actionsObserver.subscribe('CONNECT_BANK_FAILURE', (_, { error }) => {
      ReactGA.event('connect_bank_failure', error);
      Amplitude.track('connect_bank_failure', error);
    });
    // -------------------------------------------------------------------
    actionsObserver.subscribe('CONNECT_BANK_EXIT', (_, metadata) => {
      ReactGA.event('connect_bank_exit', metadata);
      Amplitude.track('connect_bank_exit', metadata);
    });
    // -------------------------------------------------------------------
    actionsObserver.subscribe('USER_SEND_MESSAGE', (_, { message }) => {
      ReactGA.event('send_message', {
        message
      });
      Amplitude.track('send_message', {
        message
      });
    });
    // -------------------------------------------------------------------
    actionsObserver.subscribe('SUGGESTION_CLICKED', (_, { suggestion }) => {
      ReactGA.event('suggestion_clicked', {
        suggestion
      });
      Amplitude.track('suggestion_clicked', {
        suggestion
      });
    });
    // -------------------------------------------------------------------
    actionsObserver.subscribe('MESSAGE_FEEDBACK', (_, { message, feedback }) => {
      ReactGA.event('message_feedback', {
        message_id: message._id,
        feedback
      });
      Amplitude.track('message_feedback', {
        message_id: message._id,
        feedback
      });
    });
    // -------------------------------------------------------------------
    actionsObserver.subscribe('PULSE_FILTER_USED', (_, { range }) => {
      ReactGA.event('pulse_filter_used', {
        range
      });
      Amplitude.track('pulse_filter_used', {
        range
      });
    });
    // -------------------------------------------------------------------
    return () => {
      actionsObserver.unsubscribe('HOMEPAGE_ACTION_CLICK');
      actionsObserver.unsubscribe('ONBOARDING_USER_UPDATE');
      actionsObserver.unsubscribe('ON_APP_INIT');
      actionsObserver.unsubscribe('PAGE_VIEW');
      actionsObserver.unsubscribe('USER_SETTLED');
      actionsObserver.unsubscribe('SIGN_UP_BUTTON_CLICK');
      actionsObserver.unsubscribe('GOOGLE_SIGN_IN');
      actionsObserver.unsubscribe('GOOGLE_SIGN_UP');
      actionsObserver.unsubscribe('DATABASE_SIGN_IN');
      actionsObserver.unsubscribe('DATABASE_SIGN_UP');
      actionsObserver.unsubscribe('FORGOT_PASSWORD_CLICK');
      actionsObserver.unsubscribe('PULSE_STORY_CLICK');
      actionsObserver.unsubscribe('CONNECT_BANK_CLICK');
      actionsObserver.unsubscribe('CONNECT_BANK_SUCCESS');
      actionsObserver.unsubscribe('CONNECT_BANK_FAILURE');
      actionsObserver.unsubscribe('CONNECT_BANK_EXIT');
      actionsObserver.unsubscribe('SUGGESTION_CLICKED');
      actionsObserver.unsubscribe('MESSAGE_FEEDBACK');
    };
  }, []);
};

export default useAnalytics;
