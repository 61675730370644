import { Fragment } from 'react';
import LibraryLoadMore from '@/sections/library/library-load-more';
import LibraryTableHead from '@/sections/library/library-table-head';
import LibraryTableHeader from '@/sections/library/library-table-header';
import TransactionRow from '@/sections/library/transaction/transaction-row';
import { getTransactionsQueries } from '@repo/features/transactions';
import { createFileRoute, Outlet } from '@tanstack/react-router';

import axiosInstance from '@/lib/axios';
import { useBreakpoint } from '@/hooks/use-breakpoint';

export const Route = createFileRoute('/_guarded/_main/library/transactions')({
  component: TransactionsPage
});

const transactionsQueries = getTransactionsQueries(axiosInstance);

function TransactionsPage() {
  const {
    data: paginated,
    fetchNextPage,
    hasNextPage
  } = transactionsQueries.infinityPaginated.useInfiniteQuery({
    variables: {
      limit: 10
    }
  });
  const { isBelowLg } = useBreakpoint('lg');
  const navigate = Route.useNavigate();

  return (
    <>
      <div className="divide-y overflow-y-auto max-h-full">
        <LibraryTableHeader className="grid-cols-[1fr_1fr_1fr_11.5rem_11.5rem_7.5rem_11.5rem_6px] hidden lg:grid">
          <LibraryTableHead>Paid/Received</LibraryTableHead>
          <LibraryTableHead>Amount</LibraryTableHead>
          <LibraryTableHead>From / To</LibraryTableHead>
          <LibraryTableHead>Account</LibraryTableHead>
          <LibraryTableHead>Date</LibraryTableHead>
          <LibraryTableHead>Type</LibraryTableHead>
          <LibraryTableHead>&milo Category</LibraryTableHead>
        </LibraryTableHeader>
        {paginated?.pages.map((page, i) => (
          <Fragment key={i}>
            {page.records.map(record => (
              <TransactionRow
                className="rounded-none"
                key={record._id}
                mode={isBelowLg ? 'list-item' : 'table'}
                onClick={() =>
                  navigate({
                    to: '/library/transactions/$id',
                    params: { id: record._id }
                  })
                }
                transaction={record}
              />
            ))}
          </Fragment>
        ))}
        {hasNextPage ? (
          <LibraryLoadMore
            onClick={async () => {
              await fetchNextPage();
            }}
          />
        ) : null}
      </div>
      <Outlet />
    </>
  );
}
