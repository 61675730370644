// financialQueries.ts
import { AxiosError, AxiosInstance } from 'axios';
import { router } from 'react-query-kit';

import {
  ApiResponse,
  BalanceStatusData,
  CashBalanceData,
  CategoryExpenseResult,
  CurrentMonthCashFlowData,
  DateRangeParams,
  DateRangeWithAccountParams,
  ExpectedBalanceData,
  FinancialStatementData,
  MoneyFlowData,
  MonthlyCashFlowData,
  OrganizationStatisticsEntry,
  ProfitabilityBreakdownItem,
  SingleDateParams,
  SingleDateWithAccountParams,
  TopExpensesItem,
  TopRevnueItem
} from './types';

export const getFinancialQueries = (axiosInstance: AxiosInstance) =>
  router('/reports/finances', {
    topCategories: router.query<ApiResponse<CategoryExpenseResult[]>, DateRangeWithAccountParams, AxiosError>({
      fetcher: ({ startDate, endDate, financialAccountId }) =>
        axiosInstance
          .get(`/reports/finances/expenses/top-categories`, {
            params: { startDate, endDate, financialAccountId }
          })
          .then(({ data }) => data)
    }),
    cashBalance: router.query<ApiResponse<CashBalanceData>, SingleDateWithAccountParams, AxiosError>({
      fetcher: ({ date, financialAccountId }) =>
        axiosInstance
          .get(`/reports/finances/cash-balance`, {
            params: { date, financialAccountId }
          })
          .then(({ data }) => data)
    }),

    expectedBalance: router.query<ApiResponse<ExpectedBalanceData>, SingleDateParams, AxiosError>({
      fetcher: ({ date }) =>
        axiosInstance
          .get(`/reports/finances/expected-balance`, {
            params: { date }
          })
          .then(({ data }) => data)
    }),

    moneyFlow: router.query<ApiResponse<MoneyFlowData>, DateRangeWithAccountParams, AxiosError>({
      fetcher: ({ startDate, endDate, financialAccountId }) =>
        axiosInstance
          .get(`/reports/finances/money-flow`, {
            params: { startDate, endDate, financialAccountId }
          })
          .then(({ data }) => data)
    }),

    balanceStatus: router.query<ApiResponse<BalanceStatusData[]>, SingleDateWithAccountParams, AxiosError>({
      fetcher: ({ date, financialAccountId }) =>
        axiosInstance
          .get(`/reports/finances/balance-status`, {
            params: { date, financialAccountId }
          })
          .then(({ data }) => data)
    }),
    financialSummary: router.query<ApiResponse<FinancialStatementData>, DateRangeParams, AxiosError>({
      fetcher: ({ startDate, endDate }) =>
        axiosInstance
          .get(`/reports/finances/financial-summary`, {
            params: { startDate, endDate }
          })
          .then(({ data }) => data)
    }),

    monthlyCashflow: router.query<ApiResponse<MonthlyCashFlowData[]>, DateRangeWithAccountParams, AxiosError>({
      fetcher: ({ startDate, endDate, financialAccountId }) =>
        axiosInstance
          .get(`/reports/finances/monthly-cashflow`, {
            params: { startDate, endDate, financialAccountId }
          })
          .then(({ data }) => data)
    }),

    currentMonthCashflow: router.query<
      ApiResponse<CurrentMonthCashFlowData[]>,
      DateRangeParams & { interval?: number },
      AxiosError
    >({
      fetcher: variables =>
        axiosInstance
          .get(`/reports/finances/current-month-cashflow`, {
            params: variables
          })
          .then(({ data }) => data)
    }),

    stats: router.query<ApiResponse<OrganizationStatisticsEntry[]>, DateRangeWithAccountParams, AxiosError>({
      fetcher: ({ startDate, endDate, financialAccountId }) =>
        axiosInstance
          .get(`/reports/stats`, {
            params: { startDate, endDate, financialAccountId }
          })
          .then(({ data }) => data)
    }),

    profitabilityBreakdown: router.query<ApiResponse<Array<ProfitabilityBreakdownItem>>, void, AxiosError>({
      fetcher: () => axiosInstance.get(`/reports/finances/profitability`).then(({ data }) => data)
    }),

    topRevenue: router.query<ApiResponse<Array<TopRevnueItem>>, void, AxiosError>({
      fetcher: () => axiosInstance.get(`/reports/finances/top-revenue`).then(({ data }) => data)
    }),

    topExpenses: router.query<ApiResponse<Array<TopExpensesItem>>, void, AxiosError>({
      fetcher: () => axiosInstance.get(`/reports/finances/top-expenses`).then(({ data }) => data)
    }),

    organizationStats: router.query<ApiResponse<Array<OrganizationStatisticsEntry>>, DateRangeParams, AxiosError>({
      fetcher: ({ startDate, endDate }) =>
        axiosInstance
          .get(`/reports/stats`, {
            params: { startDate, endDate }
          })
          .then(({ data }) => data)
    })
  });
