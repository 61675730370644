import { create } from 'zustand';

import type { StepConfig as Step, WizardValues } from '../types';

interface WizardState {
  steps: Step[];
  activeStep: Step;
  values: WizardValues;
  isLoading: boolean;
  stepNumber: number;
  totalSteps: number;
  isFirstStep: boolean;
  isLastStep: boolean;
  callbacks: {
    onCompleted?: (values: WizardValues) => void;
    onStepChanged?: (prevStep: Step, nextStep: Step, stepNumber: number, values: WizardValues) => void;
  };
  progress: number;
}

interface WizardActions {
  goToNextStep: () => void;
  goToPreviousStep: () => void;
  goToStep: (index: number) => void;
  setSteps: (steps: Step[]) => Promise<void>;
  setActiveStep: (step: Step, triggerCallback?: boolean) => void;
  setValues: (values: WizardValues) => void;
  setIsLoading: (loading: boolean) => void;
  updateStep: (key: string, value: any) => void;
  handleNext: (stepValues: any) => Promise<void>;
  handlePrevious: (stepValues: any) => Promise<void>;
  setCallbacks: (callbacks: {
    onCompleted?: (values: WizardValues) => void;
    onStepChanged?: (prevStep: Step, nextStep: Step, stepNumber: number, values: WizardValues) => void;
  }) => void;
  calculateProgress: () => Promise<number>;
  updateProgress: () => void;
}

export type WizardStore = WizardState & WizardActions;

async function _getProceedingStep(remainingSteps: Step[], values: WizardValues, direction: number) {
  let proceedingStep;
  for (let idx = 0; idx < remainingSteps.length; ++idx) {
    const step = remainingSteps[idx];
    if (step.shouldSkip === undefined) {
      proceedingStep = step;
      break;
    }

    const shouldSkip = step.shouldSkip(values, direction);
    if (!shouldSkip) {
      proceedingStep = step;
      break;
    }
  }
  return proceedingStep;
}

// Helper to find initial step
async function _findInitialStep(steps: Step[], values: WizardValues) {
  if (!steps.length) return undefined;

  const valuesWithInitials = { ...values };
  steps.forEach(step => {
    if (step.initialValues) {
      valuesWithInitials[step.id] = { ...step.initialValues };
    }
  });

  const initialStep = await _getProceedingStep(steps, valuesWithInitials, 1);
  return initialStep || steps[0]; // Fallback to first step if all steps should be skipped!!!!!
}

export const createWizardStore = (initialState: Partial<WizardState> = {}) => {
  return create<WizardStore>()((set, get) => ({
    steps: [],
    activeStep: {} as Step,
    values: {},
    isLoading: false,
    stepNumber: 1,
    totalSteps: 0,
    isFirstStep: true,
    isLastStep: false,
    callbacks: {},
    progress: 0,

    calculateProgress: async () => {
      const state = get();
      let activeSteps = 0;
      let completedSteps = 0;

      const currentStepIndex = state.steps.findIndex(s => s.id === state.activeStep.id);
      if (currentStepIndex === -1) return 0;

      for (let i = 0; i < state.steps.length; i++) {
        const step = state.steps[i];

        if (step.ignoreProgress) continue;

        if (step.shouldSkip) {
          const shouldSkip = step.shouldSkip(state.values, 1);
          if (shouldSkip) continue;
        }

        activeSteps++;

        if (i < state.stepNumber) {
          completedSteps++;
        }
      }
      // Calculate progress percentage
      if (activeSteps === 0) return 0;
      const progress = (completedSteps / activeSteps) * 100;
      return Math.min(Math.round(progress), 100);
    },

    updateProgress: async () => {
      const progress = await get().calculateProgress();
      set({ progress });
    },

    goToNextStep: async (formValues?: any) => {
      await get().handleNext(formValues);
    },

    goToPreviousStep: async (formValues?: any) => {
      await get().handlePrevious(formValues);
    },

    goToStep: (index: number) => {
      get().setActiveStep(get().steps[index]);
    },

    setSteps: async (newSteps: Step[]) => {
      const state = get();
      const currentStepId = state.activeStep.id;

      // Find current step in new steps array
      const currentStepIndex = currentStepId ? newSteps.findIndex(step => step.id === currentStepId) : -1;

      // If we found current step, update it while preserving the ID
      if (currentStepIndex !== -1) {
        const updatedSteps = [...newSteps];
        // Preserve the step ID but update everything else
        updatedSteps[currentStepIndex] = {
          ...newSteps[currentStepIndex],
          id: currentStepId
        };

        set({
          steps: updatedSteps,
          totalSteps: updatedSteps.length,
          // Update active step with new props while preserving ID
          activeStep: updatedSteps[currentStepIndex]
        });
      } else {
        // If no current step or not found in new steps, initialize normally
        set({
          steps: newSteps,
          totalSteps: newSteps.length
        });

        if (!state.activeStep.id) {
          const initialStep = await _findInitialStep(newSteps, state.values);
          if (initialStep) {
            state.setActiveStep(initialStep);
          }
        }
      }

      // Update progress after step update
      // await state.updateProgress();
    },

    setActiveStep: (step, triggerCallback = false) => {
      const state = get();
      const currentIndex = state.steps.findIndex(s => s.id === step.id);
      const prevStep = state.activeStep;

      if (triggerCallback && state.callbacks.onStepChanged) {
        state.callbacks.onStepChanged(prevStep, step, currentIndex + 1, state.values);
      }

      set({
        activeStep: step,
        stepNumber: currentIndex + 1,
        isFirstStep: currentIndex === 0,
        isLastStep: currentIndex === state.totalSteps - 1
      });
    },

    setValues: values => {
      set({ values });
      get().updateProgress();
    },
    setIsLoading: loading => {
      set({ isLoading: loading });
    },
    updateStep: (key, value) => {
      set(state => ({
        activeStep: { ...state.activeStep, [key]: value }
      }));
    },

    setCallbacks: callbacks => {
      set({ callbacks });
    },

    handleNext: async stepValues => {
      const state = get();
      try {
        if (state.activeStep.onSubmit) {
          set({ isLoading: true });
          stepValues = await state.activeStep.onSubmit(stepValues, state.values);
          set({ isLoading: false });
        }

        const wizardValues = {
          ...state.values,
          [state.activeStep.id]: { ...stepValues } as WizardValues
        };

        set({ values: wizardValues });
        state.updateProgress();

        const currentIndex = state.steps.findIndex(s => s.id === state.activeStep.id);
        const nextStep = await _getProceedingStep(state.steps.slice(currentIndex + 1), wizardValues, 1);

        if (!nextStep) {
          if (state.callbacks.onCompleted) {
            let result = {};
            Object.keys(wizardValues).forEach(stepId => {
              result = { ...result, ...wizardValues[stepId] } as WizardValues;
            });
            state.callbacks.onCompleted(result);
          }
          return;
        }

        state.setActiveStep(nextStep, true);
      } catch (error) {
        console.error(error);
        set({ isLoading: false });
      }
    },

    handlePrevious: async stepValues => {
      const state = get();
      let wizardValues = state.values;

      if (state.activeStep.keepValuesOnPrevious ?? true) {
        wizardValues = {
          ...state.values,
          [state.activeStep.id]: { ...stepValues } as WizardValues
        };
        set({ values: wizardValues });
      }

      const currentIndex = state.steps.findIndex(s => s.id === state.activeStep.id);
      const previousStep = await _getProceedingStep(state.steps.slice(0, currentIndex).reverse(), wizardValues, -1);

      if (!previousStep) return;

      state.setActiveStep(previousStep, true);
    }
  }));
};
