import LightningIcon from '@/icons/Lightning.svg?react';
import SidebarControlButton from '@/layouts/common/sidebar-control-button';
import { useNavigate } from '@tanstack/react-router';
import type { VariantProps } from 'class-variance-authority';
import { cva } from 'class-variance-authority';

import { cn } from '@/lib/utils';
import { Button } from '@/components/ui/button';

type HomeHeaderProps = VariantProps<typeof headerWrapperVariants> & {
  className?: string;
};
// ----------------------------------------------------------------------

export const headerWrapperVariants = cva(
  'z-50 flex w-full flex-row items-center justify-between lg:h-34 px-6 py-4 lg:px-10',
  {
    variants: {
      variant: {
        primary: 'bg-primary text-white',
        secondary: 'bg-neutral-800 text-white'
      }
    },
    defaultVariants: {
      variant: 'primary'
    }
  }
);
export default function AppHeader({ variant, className }: HomeHeaderProps) {
  const navigate = useNavigate();
  return (
    <div className={cn(headerWrapperVariants({ variant }), className)}>
      <SidebarControlButton />
      <div>
        <Button
          onClick={async () => {
            await navigate({
              to: '/milo'
            });
          }}
          size="icon"
          variant="icon-bordered"
        >
          <LightningIcon className="fill-transparent stroke-white stroke-[1.5px]" />
        </Button>
      </div>
    </div>
  );
}
