import { HeaderPortal } from '@/layouts/common/header-portal';
import FinanceAppHeader from '@/sections/finances/finance-app-header';
import { createFileRoute, Outlet } from '@tanstack/react-router';

export const Route = createFileRoute('/_guarded/_main/finances')({
  component: FinancesPage
});

function FinancesPage() {
  return (
    <>
      <HeaderPortal position="replace">
        <FinanceAppHeader />
      </HeaderPortal>
      <Outlet />
    </>
  );
}
