import { useLayoutEffect, useState } from 'react';
import MiloIcon from '@/icons/Milo.svg?react';
import type { VariantProps } from 'class-variance-authority';
import { cva } from 'class-variance-authority';

import { cn } from '@/lib/utils';
import { useBreakpoint } from '@/hooks/use-breakpoint';
import { Button } from '@/components/ui/button';
import MenuRow from '@/components/menu-row';
import ResponsiveActionsMenu from '@/components/responsive-actions-menu';

export type DoggyActionProps = {} & VariantProps<typeof doggyActionVariants>;

const mockedSuggestions = [
  'How can I save even more?',
  'How can I save on [specific expense]',
  'What’s my biggest saving?',
  'How do these savings compare to last month?',
  'Explain how these tax deductions work',
  'Break down my savings by category'
];

const doggyActionVariants = cva(
  'inline-flex items-center rounded-full justify-center whitespace-nowrap font-semibold',
  {
    variants: {
      variant: {
        primary: '',
        secondary: 'bg-white text-black hover:text-primary'
      },
      size: {
        sm: 'size-14 [&>svg]:h-[1rem] [&>svg]:w-[0.84206rem]',
        lg: 'size-18 [&>svg]:h-[1.5rem] [&>svg]:w-[1.26313rem]'
      }
    },
    defaultVariants: {
      variant: 'primary',
      size: 'sm'
    }
  }
);

export default function DoggyAction({ variant, size }: DoggyActionProps) {
  const { isBelowLg } = useBreakpoint('lg');
  const defaultSize = isBelowLg ? 'sm' : 'lg';
  const [container, setContainer] = useState<HTMLElement | null>(null);
  useLayoutEffect(() => {
    setContainer(document.getElementById('main'));
  }, []);
  return (
    <ResponsiveActionsMenu
      drawerContentProps={{
        className: 'absolute'
      }}
      drawerProps={{
        container,
        modal: false,
        noBodyStyles: true
      }}
      dropdownTrigger={
        <Button
          className={cn('', doggyActionVariants({ variant: variant, size: size || defaultSize }))}
          size="icon-lg"
          variant="icon-bordered"
        >
          <MiloIcon className="size-5 shrink-0" />
        </Button>
      }
    >
      {mockedSuggestions.map(suggestion => (
        <MenuRow icon={null} key={suggestion}>
          {suggestion}
        </MenuRow>
      ))}
    </ResponsiveActionsMenu>
  );
}
