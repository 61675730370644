import VendorInformationView from '@/sections/library/vendor/views/vendor-information-view'
import { createFileRoute } from '@tanstack/react-router'

export const Route = createFileRoute(
  '/_guarded/_main/library/vendors_/$id/info',
)({
  component: CustomerInfoPage,
  wrapInSuspense: true,
})

function CustomerInfoPage() {
  const { id } = Route.useParams()
  return <VendorInformationView id={id} />
}
