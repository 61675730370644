import { HeaderPortal } from '@/layouts/common/header-portal';
import CustomerDetailsHeader from '@/sections/library/customer/customer-details-header';
import InvoiceRow from '@/sections/library/invoice/invoice-row';
import LibraryStatsCard from '@/sections/library/library-stats-card';
import { fCurrency } from '@/utils/format-number';
import { getCustomerQueries } from '@repo/features/customers';
import { Outlet, useNavigate } from '@tanstack/react-router';

import axiosInstance from '@/lib/axios';
import { useBreakpoint } from '@/hooks/use-breakpoint';

const customerQueries = getCustomerQueries(axiosInstance);

export default function CustomerDetailsView({ id }: { id: string }) {
  const { isBelowLg } = useBreakpoint('lg');
  const navigate = useNavigate();
  const { data, isLoading } = customerQueries.byId.useQuery({
    variables: {
      id,
      expand: ['invoices.aggs', 'transactions.aggs']
    }
  });
  const customer = data!;
  if (isLoading) return null;
  return (
    <>
      <HeaderPortal position="replace">
        <CustomerDetailsHeader customer={customer} />
      </HeaderPortal>
      <div className="h-full space-y-3">
        <div className="flex flex-col lg:flex-row gap-4 lg:gap-8 p-8">
          <LibraryStatsCard amount={fCurrency(customer.transactions?.aggs.total || 0)} title="Total Earned" />
          <LibraryStatsCard amount={fCurrency(customer.invoices?.aggs.overdue || 0)} title="Overdue" />
          <LibraryStatsCard amount={fCurrency(customer.invoices?.aggs.due || 0)} title="Open Invoices (Not Due Yet)" />
        </div>
        <div>
          <div className="px-8 lg:px-7 lg:py-0 py-5 text-xs font-bold">Invoices:</div>
          <div className="divide-y lg:p-7">
            {customer.invoices?.records.map(invoice => (
              <InvoiceRow
                className="rounded-none"
                invoice={{ ...invoice, customer }}
                key={invoice._id}
                mode={isBelowLg ? 'list-item' : 'table'}
                onClick={() =>
                  navigate({
                    to: `/library/customers/$id/invoices/$invoiceId`,
                    params: { id: customer._id!, invoiceId: invoice._id }
                  })
                }
              />
            ))}
          </div>
        </div>
      </div>
      <Outlet />
    </>
  );
}
