import ChevronRightIcon from '@/icons/ChevronRight.svg?react';

import { cn } from '@/lib/utils';
import { Button } from '@/components/ui/button';

import type { WidgetComponentProps } from '../types';

export function FinancialFacadeMetric({
  label,
  metric,
  onClick,
  className
}: WidgetComponentProps & { metric: number | string; label: string }) {
  return (
    <div
      className={cn('flex flex-1 items-center p-6 rounded-xl', className, {
        'cursor-pointer bg-neutral-700': !!onClick
      })}
      onClick={onClick}
    >
      <div className="flex flex-col flex-1">
        <div className="text-2xl font-semibold">{metric}</div>
        <div className="text-4xs font-medium">{label}</div>
      </div>
      <div>
        <Button size="icon" variant="icon-filled">
          <ChevronRightIcon className="size-3 shrink-0 text-white" />
        </Button>
      </div>
    </div>
  );
}
