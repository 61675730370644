import { useEffect, useState } from 'react'
import ChevronRightIcon from '@/assets/icons/chevron-right-icon'
import AppHeader from '@/layouts/common/app-header'
import { HeaderPortal } from '@/layouts/common/header-portal'
import MainLayout from '@/layouts/main-layout'
import BottomLineDrawer from '@/sections/pulse/bottom-line-drawer'
import CashFlowDrawerTeal from '@/sections/pulse/cash-flow-drawer-teal.tsx'
import CashFlowDrawerUs from '@/sections/pulse/cash-flow-drawer.tsx'
import CashBalanceMetricTeal from '@/sections/pulse/components/metrics/cash-balance-metric-teal.tsx'
import CashBalanceMetricUs from '@/sections/pulse/components/metrics/cash-balance-metric.tsx'
import ExpectedBalanceMetricTeal from '@/sections/pulse/components/metrics/expected-balance-metric-teal.tsx'
import ExpectedBalanceMetricUs from '@/sections/pulse/components/metrics/expected-balance-metric.tsx'
import ExpensesMetricTeal from '@/sections/pulse/components/metrics/expenses-metric-teal.tsx'
import ExpensesMetricUs from '@/sections/pulse/components/metrics/expenses-metric.tsx'
import MetricWrapper from '@/sections/pulse/components/metrics/metric-wrapper'
import ProfitOrLossMetricTeal from '@/sections/pulse/components/metrics/profit-or-loss-metric-teal.tsx'
import ProfitOrLossMetricUs from '@/sections/pulse/components/metrics/profit-or-loss-metric.tsx'
import RevenueMetricTeal from '@/sections/pulse/components/metrics/revenue-metric-teal.tsx'
import RevenueMetricUs from '@/sections/pulse/components/metrics/revenue-metric.tsx'
import WelcomeCard from '@/sections/pulse/components/welcome-card.tsx'
import { useAppStateStore } from '@/stores/app-state'
import { createFileRoute, useRouterState } from '@tanstack/react-router'
import { zodSearchValidator } from '@tanstack/router-zod-adapter'
import { startOfYear } from 'date-fns'
import Z from 'zod'
import { useShallow } from 'zustand/react/shallow'

import ActionsObserver from '@/lib/actions-observer'
import { useBoolean } from '@/hooks/use-boolean'
import { Button } from '@/components/ui/button'
import type { CalendarDateRange } from '@/components/ui/calendar'
import { DatePickerWithRange } from '@/components/ui/date-range-picker'
import { varSlide } from '@/components/animate'

const { initial, animate } = varSlide().inRight
const numberAnimation = { initial, animate }
const getLastDayOfMonth = (date: Date): Date => {
  const year = date.getFullYear()
  const month = date.getMonth() // Zero-based, so January is 0
  return new Date(year, month + 1, 0) // 0th day of next month is the last day of current month
}

export const Route = createFileRoute('/_guarded/oldfinances')({
  validateSearch: zodSearchValidator(
    Z.object({
      useTealData: Z.boolean().optional().default(false),
    }),
  ),
  component: () => (
    <MainLayout
      headerProps={{
        className: 'hidden lg:flex',
      }}
    >
      <HeaderPortal>
        <AppHeader className="hidden lg:flex" variant="secondary" />
      </HeaderPortal>
      <FinancesPage />
    </MainLayout>
  ),
})

function FinancesPage() {
  const router = useRouterState()

  const { useTealData } = Route.useSearch()

  const cashFlowDrawerOpen = useBoolean()
  const bottomLineDrawerOpen = useBoolean()
  const [isActive, accountsConnected, isUseTealData, setIsUseTealData] =
    useAppStateStore(
      useShallow((state) => [
        state.isActive,
        state.accountsConnected,
        state.isUseTealData,
        state.setIsUseTealData,
      ]),
    )
  const [selectedRange, setSelectedRange] = useState<
    CalendarDateRange | undefined
  >({
    from: startOfYear(new Date()),
    to: new Date(),
  })

  useEffect(() => {
    setIsUseTealData(Boolean(useTealData))
  }, [useTealData])

  const CashBalanceMetric = isUseTealData
    ? CashBalanceMetricTeal
    : CashBalanceMetricUs
  const ExpectedBalanceMetric = isUseTealData
    ? ExpectedBalanceMetricTeal
    : ExpectedBalanceMetricUs
  const ExpensesMetric = isUseTealData ? ExpensesMetricTeal : ExpensesMetricUs
  const ProfitOrLossMetric = isUseTealData
    ? ProfitOrLossMetricTeal
    : ProfitOrLossMetricUs
  const RevenueMetric = isUseTealData ? RevenueMetricTeal : RevenueMetricUs
  const CashflowDrawer = isUseTealData ? CashFlowDrawerTeal : CashFlowDrawerUs
  const lastDayDate = selectedRange?.to
    ? getLastDayOfMonth(selectedRange?.to)
    : null

  return (
    <div className="flex flex-1 justify-center items-center">
      <div className="flex flex-col lg:flex-row lg:gap-20 overflow-x-hidden w-[62.5rem] overflow-y-hidden">
        <WelcomeCard className="" />
        <div className="flex flex-col gap-8 lg:gap-16 w-full lg:w-[21.875rem] p-10 lg:p-0">
          <div className="text-4xl font-medium lg:hidden">
            Your Financial Snapshot
          </div>
          <div>
            <DatePickerWithRange
              onSelect={(range) => {
                setSelectedRange(range)
                ActionsObserver.notify('PULSE_FILTER_USED', { range })
              }}
              selected={selectedRange}
            />
          </div>
          <div className="flex flex-col gap-8">
            <div className="border-y flex justify-between items-center py-5">
              <div className="font-bold text-md">Cash Flow</div>
              <Button
                onClick={() => {
                  ActionsObserver.notify('PULSE_STORY_CLICK', {
                    type: 'cash_flow',
                  })

                  cashFlowDrawerOpen.onTrue()
                }}
                size="icon"
                variant="primary"
              >
                <ChevronRightIcon className="size-6" />
              </Button>
            </div>

            <div className="flex flex-col gap-7">
              <MetricWrapper
                key="cash_balance"
                motionProps={{
                  ...numberAnimation,
                }}
                showConnect={!accountsConnected}
                title="Cash Balance"
              >
                <CashBalanceMetric
                  endDate={selectedRange?.to}
                  title="Cash Balance"
                />
              </MetricWrapper>
              <MetricWrapper
                key="expected_balance"
                motionProps={numberAnimation}
                title="Expected end of month"
              >
                <ExpectedBalanceMetric
                  endDate={lastDayDate!}
                  startDate={selectedRange?.from!}
                  title="Expected end of month"
                />
              </MetricWrapper>
            </div>
          </div>
          <div className="flex flex-col gap-8">
            <div className="border-y flex justify-between items-center py-5">
              <div className="font-bold text-md">Bottom Line</div>
            </div>
            <div className="flex flex-col gap-7 relative">
              <MetricWrapper
                key="revenue_metric"
                motionProps={numberAnimation}
                title="Revenue"
              >
                <RevenueMetric
                  endDate={selectedRange?.to}
                  startDate={selectedRange?.from!}
                  title="Revenue"
                />
              </MetricWrapper>

              <MetricWrapper
                key="expenses_metric"
                motionProps={numberAnimation}
                title="Expenses"
              >
                <ExpensesMetric
                  endDate={selectedRange?.to}
                  startDate={selectedRange?.from!}
                  title="Expenses"
                />
              </MetricWrapper>
              <MetricWrapper
                key="profit_or_loss"
                motionProps={numberAnimation}
                title="Profit or Loss"
              >
                <ProfitOrLossMetric
                  endDate={selectedRange?.to}
                  startDate={selectedRange?.from!}
                  title="Profit or Loss"
                />
              </MetricWrapper>
            </div>
          </div>
        </div>
      </div>
      {selectedRange ? (
        <CashflowDrawer
          isOpen={cashFlowDrawerOpen.value}
          onOpenChange={cashFlowDrawerOpen.setValue}
        />
      ) : null}
      {selectedRange ? (
        <BottomLineDrawer
          isOpen={bottomLineDrawerOpen.value}
          onOpenChange={bottomLineDrawerOpen.setValue}
        />
      ) : null}
    </div>
  )
}
