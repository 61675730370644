import { getFinancialQueries } from '@repo/features/financial-data';

import axiosInstance from '@/lib/axios';

import type { WidgetProps } from '../types';
import WidgetErrorFallback from '../widget-error-fallback';
import { WidgetFactory } from '../widget-factory';
import MetricWidgetSkeleton from './metric-widget-skeleton';
import { SimpleMetric } from './simple-metric';

const financialQueries = getFinancialQueries(axiosInstance);

export const RevnueSimpleMetricWidget = WidgetFactory.create(
  ({ className }: WidgetProps) => {
    const { data: revenue } = financialQueries.profitabilityBreakdown.useSuspenseQuery({
      select(data) {
        return data.data.at(-1)?.revenue ?? 0;
      }
    });

    return <SimpleMetric className={className} metric={`$${revenue}`} />;
  },
  {
    FallbackComponent: WidgetErrorFallback,
    SuspenseFallbackComponent: MetricWidgetSkeleton
  }
);
