import ChevronDownIcon from '@/icons/ChevronDown.svg?react';

export type LibraryLoadMoreProps = {
  onClick?: () => void;
};

export default function LibraryLoadMore({ onClick }: LibraryLoadMoreProps) {
  return (
    <div
      className="flex p-8 text-2xs font-semibold text-secondary lg:justify-center items-center cursor-pointer"
      onClick={onClick}
    >
      Show More
      <ChevronDownIcon className="ml-2 w-3 fill-secondary" />
    </div>
  );
}
