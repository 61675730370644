import { getFinancialQueries } from '@repo/features/financial-data';
import { format, parse } from 'date-fns';

import axiosInstance from '@/lib/axios';

import type { WidgetProps } from '../types';
import WidgetErrorFallback from '../widget-error-fallback';
import { WidgetFactory } from '../widget-factory';
import { BarChart } from './bar-chart';
import ChartWidgetEmptyState from './chart-empty-state';
import ChartWidgetSkeleton from './chart-widget-skeleton';

const financialQueries = getFinancialQueries(axiosInstance);

export const ProfitabilityChartWidget = WidgetFactory.create(
  ({ className }: WidgetProps) => {
    const { data: bars } = financialQueries.profitabilityBreakdown.useSuspenseQuery({
      select(data) {
        return data.data.map(d => ({
          name: format(parse(d.month_num_year, 'yyyy-MM', new Date()), 'MMM'),
          data: {
            profit: d.net_profit
          }
        }));
      }
    });

    if (!bars.length) {
      return <ChartWidgetEmptyState />;
    }

    return <BarChart className={className} data={bars} config={{ profit: { label: 'Profit' } }} />;
  },
  {
    FallbackComponent: WidgetErrorFallback,
    SuspenseFallbackComponent: ChartWidgetSkeleton
  }
);
