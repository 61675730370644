import type { IBill } from '@repo/features/bills';
import { getBillQueries } from '@repo/features/bills';
import type { PaginatedResponse } from '@repo/features/types';
import { useQueryClient } from '@tanstack/react-query';

import axiosInstance from '@/lib/axios';

const billQueries = getBillQueries(axiosInstance);

export default function useBillActions() {
  const queryClient = useQueryClient();

  const { mutateAsync: updateBill } = billQueries.update.useMutation({
    onMutate: async params => {
      const { data: newBill, id, context } = params;
      const updatedBill = { ...newBill, ...context };

      // Get all matching query keys for this bill
      const singleQueryKeys = queryClient
        .getQueryCache()
        .findAll({ queryKey: billQueries.byId.getKey({ id }) })
        .map(query => query.queryKey);

      const listQueryKeys = queryClient
        .getQueryCache()
        .findAll({ queryKey: billQueries.paginated.getKey() })
        .map(query => query.queryKey);

      // Cancel all related queries
      await Promise.all([
        ...singleQueryKeys.map(key => queryClient.cancelQueries({ queryKey: key })),
        ...listQueryKeys.map(key => queryClient.cancelQueries({ queryKey: key }))
      ]);

      // Store previous values for all matching queries
      const previousSingleQueries = singleQueryKeys.reduce<Record<string, IBill | undefined>>((acc, key) => {
        acc[JSON.stringify(key)] = queryClient.getQueryData(key);
        return acc;
      }, {});

      const previousListQueries = listQueryKeys.reduce<Record<string, any>>((acc, key) => {
        acc[JSON.stringify(key)] = queryClient.getQueryData(key);
        return acc;
      }, {});

      // Update all matching single queries
      singleQueryKeys.forEach(key => {
        const previousData = queryClient.getQueryData(key);
        if (previousData) {
          queryClient.setQueryData(key, {
            ...previousData,
            ...updatedBill
          } as IBill);
        }
      });

      // Update all matching list queries
      listQueryKeys.forEach(key => {
        const previousData = queryClient.getQueryData(key) as PaginatedResponse<IBill>;
        if (previousData) {
          queryClient.setQueryData(key, {
            ...previousData,
            records: previousData.records.map(r => (r._id === id ? ({ ...r, ...updatedBill } as IBill) : r))
          });
        }
      });

      return {
        previousSingleQueries,
        previousListQueries,
        updatedBill,
        singleQueryKeys,
        listQueryKeys
      };
    },

    onError: (err, { id }, context) => {
      if (!context) return;

      // Restore all single queries
      context.singleQueryKeys.forEach(key => {
        const previousData = context.previousSingleQueries[JSON.stringify(key)];
        if (previousData) {
          queryClient.setQueryData(key, previousData);
        }
      });

      // Restore all list queries
      context.listQueryKeys.forEach(key => {
        const previousData = context.previousListQueries[JSON.stringify(key)];
        if (previousData) {
          queryClient.setQueryData(key, previousData);
        }
      });
    },

    onSettled: async bill => {
      if (!bill?._id) return;

      // Invalidate all related queries
      await Promise.all([
        queryClient.invalidateQueries({
          queryKey: billQueries.byId.getKey({ id: bill._id })
        }),
        queryClient.invalidateQueries({
          queryKey: billQueries.paginated.getKey()
        })
      ]);
    }
  });

  const { mutateAsync: uploadBillFile } = billQueries.signAndUpload.useMutation({
    onSuccess: async () => {
      await queryClient.invalidateQueries({
        queryKey: billQueries.getKey()
      });
    }
  });

  return {
    updateBill,
    uploadBillFile
  };
}
