import { create } from 'zustand';
import { createJSONStorage, persist } from 'zustand/middleware';

export type FinancialState = 'positive' | 'negative' | 'shaky';

export interface AppStateState {
  isActive: boolean;
  financialState?: FinancialState;
  accountsConnected: boolean;
  isPlaidModalOpen: boolean;
  isOnboardingCompleted: boolean;
  isGlobalChatFocused: boolean;
  isUseTealData: boolean;
  isTaxDeductionActivationShown: boolean;
  taxDeductionLastActivationTime?: string; //ISO date
}

export interface AppStateActions {
  activate: (isActive?: boolean) => void;
  setAccountsConnected: (accountsConnected: boolean) => void;
  setIsPlaidModalOpen: (isPlaidModalOpen: boolean) => void;
  setIsOnboardingCompleted: (isOnboardingCompleted: boolean) => void;
  setIsGlobalChatFocused: (isGlobalChatFocused: boolean) => void;
  setIsUseTealData: (isUseTealData: boolean) => void;
  activateTaxDeduction: (activatedDate: string) => void;
}

export type AppStateStore = AppStateState & AppStateActions;

const initialState: AppStateState = {
  isActive: false,
  financialState: undefined,
  accountsConnected: false,
  isOnboardingCompleted: false,
  isPlaidModalOpen: false,
  isGlobalChatFocused: false,
  isUseTealData: false,
  isTaxDeductionActivationShown: false,
  taxDeductionLastActivationTime: undefined
};

export const useAppStateStore = create<AppStateStore>()(
  persist(
    set => ({
      ...initialState,
      activateTaxDeduction: (activatedDate: string) => {
        set({
          isTaxDeductionActivationShown: true,
          taxDeductionLastActivationTime: activatedDate
        });
      },
      activate: (isActive?: boolean) => {
        if (typeof isActive !== 'undefined') {
          set({ isActive });
          return;
        }
        set({ isActive: true });
      },
      setIsGlobalChatFocused: (isGlobalChatFocused: boolean) => {
        set({ isGlobalChatFocused });
      },
      changeFinancialState: (financialState: FinancialState) => {
        set({ financialState });
      },
      setAccountsConnected: (accountsConnected: boolean) => {
        set({ accountsConnected });
      },
      setIsPlaidModalOpen: (isPlaidModalOpen: boolean) => {
        set({ isPlaidModalOpen });
      },
      setIsOnboardingCompleted: (isOnboardingCompleted: boolean) => {
        set({ isOnboardingCompleted });
      },
      setIsUseTealData: (isUseTealData: boolean) => {
        set({ isUseTealData });
      }
    }),
    {
      partialize: ({ isActive, isTaxDeductionActivationShown, taxDeductionLastActivationTime }) => ({
        isActive,
        isTaxDeductionActivationShown,
        taxDeductionLastActivationTime
      }), //({ isActive }),
      version: 0.1,
      name: 'app_state'
    }
  )
);
