import { SimpleMetric } from '@/sections/widgets/metrics/simple-metric';
import { WidgetProps } from '@/sections/widgets/types';
import { WidgetFactory } from '@/sections/widgets/widget-factory';
import { getInvoiceQueries } from '@repo/features/invoices';
import { startOfMonth } from 'date-fns';

import axiosInstance from '@/lib/axios';
import { cn } from '@/lib/utils';
import { Badge } from '@/components/ui/badge';
import { Separator } from '@/components/ui/separator';

const invoicesQueries = getInvoiceQueries(axiosInstance);
const now = new Date();
const monthStart = startOfMonth(now);

export const CurrentMonthRevenueByStatus = WidgetFactory.create(({ className }: WidgetProps) => {
  const {
    data: { paid, pending }
  } = invoicesQueries.currentMonth.useSuspenseQuery({
    variables: {
      dueDateFrom: monthStart,
      dueDateTo: now,
      expand: ['aggs', 'customer'],
      sortBy: 'dueDate',
      sortOrder: 'asc',
      limit: 5
    },
    select(data) {
      return {
        paid: data.aggs?.paid ?? 0,
        pending: data.aggs?.pending ?? 0
      };
    }
  });

  return (
    <div className={cn(className, 'flex justify-between mr-34 my-8')}>
      <div className="flex flex-col items-start gap-1">
        <SimpleMetric className="text-lg" metric={`$${paid.toFixed(2)}`} />
        <Badge className="text-4xs font-normal uppercase bg-neutral-800 text-white">Paid</Badge>
      </div>
      <Separator className="h-8 max-w-[2px]" />
      <div className="flex flex-col items-start gap-1">
        <SimpleMetric className="text-lg" metric={`$${pending.toFixed(2)}`} />
        <Badge className="text-4xs font-normal uppercase bg-neutral-800 text-white">Pending</Badge>
      </div>
    </div>
  );
});
