import React from 'react';
import { useAuth } from '@/auth/hooks';
import DotsIcon from '@/icons/3Dots.svg?react';
import ChatIcon from '@/icons/Chat.svg?react';
import FinancesIcon from '@/icons/Finances.svg?react';
import HomeIcon from '@/icons/Home.svg?react';
import LibraryIcon from '@/icons/Library.svg?react';
import TaxesIcon from '@/icons/Taxes.svg?react';
import ProfileMenu from '@/sections/profile/profile-menu';
import { Link, linkOptions } from '@tanstack/react-router';

import { useBreakpoint } from '@/hooks/use-breakpoint';
import { Avatar, AvatarFallback } from '@/components/ui/avatar';
import { Button } from '@/components/ui/button';
import {
  Sidebar,
  SidebarContent,
  SidebarFooter,
  SidebarGroup,
  SidebarGroupContent,
  SidebarHeader,
  SidebarMenu,
  SidebarMenuButton,
  SidebarMenuItem,
  useSidebar
} from '@/components/ui/sidebar';
import Logo from '@/components/logo';

const navConfig = linkOptions([
  {
    label: 'Home',
    Icon: HomeIcon,
    to: '/'
  },
  {
    label: 'All Chats',
    Icon: ChatIcon,
    to: '/chats'
  },
  {
    label: 'Finances',
    Icon: FinancesIcon,
    to: '/finances/cashflow'
  },
  {
    label: 'Books &milo',
    Icon: TaxesIcon,
    to: '/books'
  },
  {
    label: 'Library',
    Icon: LibraryIcon,
    to: '/library/transactions',
    items: linkOptions([
      {
        label: 'Transactions',
        to: '/library/transactions'
      },
      {
        label: 'Invoices',
        to: '/library/invoices'
      },
      {
        label: 'Customers',
        to: '/library/customers'
      },
      {
        label: 'Bills & Receipts',
        to: '/library/bills'
      },
      {
        label: 'Vendors',
        to: '/library/vendors'
      }
    ])
  }
]);

export function AppSidebar() {
  const { delegationWithOrg } = useAuth();
  const { setOpenMobile } = useSidebar();
  const { isBelowLg } = useBreakpoint('lg');
  return (
    <Sidebar>
      <SidebarHeader className="h-34 justify-center items-center border-b p-0 hidden lg:flex">
        <Logo beta className="mr-[17px]" />
      </SidebarHeader>
      <SidebarContent className="py-8 px-4">
        <SidebarGroup>
          <SidebarGroupContent>
            <SidebarMenu>
              {navConfig.map(item => (
                <React.Fragment key={item.to}>
                  <SidebarMenuItem>
                    <Link
                      onClick={() => {
                        setOpenMobile(false);
                      }}
                      to={item.to}
                    >
                      {({ isActive }: { isActive: boolean }) => (
                        <SidebarMenuButton asChild className="py-9 px-8 space-x-4" isActive={isActive}>
                          <div>
                            <item.Icon />
                            <span className="text-xs">{item.label}</span>
                          </div>
                        </SidebarMenuButton>
                      )}
                    </Link>
                  </SidebarMenuItem>
                </React.Fragment>
              ))}
            </SidebarMenu>
          </SidebarGroupContent>
        </SidebarGroup>
      </SidebarContent>
      <SidebarFooter className="p-8">
        <div className="flex items-center justify-between">
          <div className="flex items-center gap-3">
            <Avatar className="size-12">
              {/*<AvatarImage src={user?.photoURL} alt={user?.displayName} />*/}
              <AvatarFallback className="bg-fun-cyan font-semibold text-3xs text-black">
                {delegationWithOrg?.organization.name.charAt(0).toUpperCase()}
              </AvatarFallback>
            </Avatar>
            <div className="lg:block ml-2 font-archivo text-[15px] tracking-tight ">
              {delegationWithOrg?.organization.name}
            </div>
          </div>
          <ProfileMenu>
            <Button size="auto" variant="icon">
              <DotsIcon className="size-6" />
            </Button>
          </ProfileMenu>
        </div>
      </SidebarFooter>
    </Sidebar>
  );
}

// {navConfig.map(item => (
//     <React.Fragment key={item.to}>
//       {'items' in item ? (
//           <Collapsible asChild className="group/collapsible" defaultOpen key={item.label}>
//             <SidebarMenuItem>
//               <CollapsibleTrigger asChild>
//                 <SidebarMenuButton className="py-9 px-8 space-x-4" tooltip={item.label}>
//                   {item.Icon ? <item.Icon /> : null}
//                   <span className="text-xs">{item.label}</span>
//                   <ChevronRight className="ml-auto transition-transform duration-200 group-data-[state=open]/collapsible:rotate-90" />
//                 </SidebarMenuButton>
//               </CollapsibleTrigger>
//               <CollapsibleContent>
//                 <SidebarMenuSub>
//                   {item.items?.map(subItem => (
//                       <SidebarMenuSubItem key={subItem.to}>
//                         <Link className="space-x-4" to={subItem.to}>
//                           {({ isActive }: { isActive: boolean }) => (
//                               <SidebarMenuSubButton asChild className="py-9 px-8" isActive={isActive}>
//                                 <span className="text-xs">{subItem.label}</span>
//                               </SidebarMenuSubButton>
//                           )}
//                         </Link>
//                       </SidebarMenuSubItem>
//                   ))}
//                 </SidebarMenuSub>
//               </CollapsibleContent>
//             </SidebarMenuItem>
//           </Collapsible>
//       ) : (
//           <SidebarMenuItem>
//             <Link to={item.to}>
//               {({ isActive }: { isActive: boolean }) => (
//                   <SidebarMenuButton className="py-9 px-8 space-x-4" isActive={isActive}>
//                     <item.Icon />
//                     <span className="text-xs">{item.label}</span>
//                   </SidebarMenuButton>
//               )}
//             </Link>
//           </SidebarMenuItem>
//       )}
//     </React.Fragment>
// ))}
