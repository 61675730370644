import { BarChart } from '@/sections/widgets/charts/bar-chart';
import { WidgetProps } from '@/sections/widgets/types';
import { WidgetFactory } from '@/sections/widgets/widget-factory';
import { getFinancialQueries } from '@repo/features/financial-data';
import { format, startOfMonth } from 'date-fns';

import axiosInstance from '@/lib/axios';

const financialQueries = getFinancialQueries(axiosInstance);

const now = new Date();
const startDate = startOfMonth(now);

export const CurrentMonthCashflowGraph = WidgetFactory.create(({}: WidgetProps) => {
  const { data: cashflow } = financialQueries.currentMonthCashflow.useSuspenseQuery({
    variables: {
      startDate: format(startDate, 'yyyy-MM-dd'),
      endDate: format(now, 'yyyy-MM-dd'),
      // TODO need to adjust the interval according to time of the month
      // goal is to have many bars instead of just one
      interval: 5
    },
    select(data) {
      return data.data.map(m => ({
        name: `${m.bucket}`,
        data: {
          cashIn: m.moneyIn,
          cashOut: Math.abs(m.moneyOut)
        }
      }));
    }
  });

  return (
    <BarChart
      config={{
        cashIn: {
          label: 'Cash In',
          color: 'hsl(var(--fun-green))'
        },
        cashOut: {
          label: 'Cash Out',
          color: 'hsl(var(--fun-red))'
        }
      }}
      data={cashflow}
    />
  );
});
