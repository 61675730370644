import { fDateTime } from '@/utils/format-time';

import { cn } from '@/lib/utils';

export type RowDateProps = {
  date: string;
  className?: string;
};

export default function RowDate({ date, className }: RowDateProps) {
  return (
    <div className={cn('text-4xs text-white text-ellipsis', className)}>{fDateTime(date)}</div>
  );
}
