import { cn } from '@/lib/utils';

export type LibraryTableHeadProps = {
  children: React.ReactNode;
  className?: string;
};

export default function LibraryTableHead({ children, className }: LibraryTableHeadProps) {
  return <div className={cn('text-2xs text-neutral-500 font-medium', className)}>{children}</div>;
}
