import { useBusinessStore } from '@/sections/business/use-business-store';
import { getFinancialQueries } from '@repo/features/financial-data';
import { format } from 'date-fns';
import { useShallow } from 'zustand/react/shallow';

import axiosInstance from '@/lib/axios';

import type { WidgetProps } from '../types';
import WidgetErrorFallback from '../widget-error-fallback';
import { WidgetFactory } from '../widget-factory';
import { FinancialFacadeMetric } from './financial-facade-metric';
import MetricWidgetSkeleton from './metric-widget-skeleton';

const financialQueries = getFinancialQueries(axiosInstance);

export const RevenueMetricWidget = WidgetFactory.create(
  ({ className }: WidgetProps) => {
    const { openDrawer } = useBusinessStore(
      useShallow(state => ({
        openDrawer: state.openDrawer
      }))
    );
    const { data: revenue } = financialQueries.profitabilityBreakdown.useSuspenseQuery({
      select(data) {
        return data.data.at(-1)?.revenue ?? 0;
      }
    });

    return (
      <FinancialFacadeMetric
        className={className}
        label="Revenue This Month"
        metric={`$${revenue}`}
        onClick={() => openDrawer('revenue')}
      />
    );
  },
  {
    FallbackComponent: WidgetErrorFallback,
    SuspenseFallbackComponent: MetricWidgetSkeleton
  }
);
