import { HeaderPortal } from '@/layouts/common/header-portal';
import MiloAppHeader from '@/sections/milo/milo-app-header';
import { MoneySavedMetricWidget } from '@/sections/widgets/metrics/money-saved-metric-widget';
import { TimeSavedMetricWidget } from '@/sections/widgets/metrics/time-saved-metric-widget';
import TaxDeductionWizard from '@/sections/widgets/tax-deduction-wizard';
import { getTransactionsQueries } from '@repo/features/transactions';
import { createFileRoute } from '@tanstack/react-router';
import { subMonths } from 'date-fns';

import axiosInstance from '@/lib/axios';

import { getAchievementQueries } from '../../../../../../../packages/features/src/achievements/queries';

export const Route = createFileRoute('/_guarded/_main/milo')({
  component: MiloHomePage
});

const endDate = new Date().toISOString();
const startDate = subMonths(endDate, 6).toISOString();
const transactionQueries = getTransactionsQueries(axiosInstance);
const achievmentQueries = getAchievementQueries(axiosInstance);
function MiloHomePage() {
  const { data } = transactionQueries.paginated.useSuspenseQuery({
    variables: {
      limit: 10
    }
  });
  const { data: pages } = achievmentQueries.infinityPaginated.useInfiniteQuery({
    variables: {
      limit: 10
    }
  });
  // Mutation to start a conversation
  const startConversationMutation = achievmentQueries.startConversation.useMutation({
    onSuccess: conv => {
      console.log('Conversation started:', conv);
    },
    onError: error => {
      console.log('error starting conv');
    }
  });

  const handleStartConversation = (achievementId: string) => {
    startConversationMutation.mutate({ id: achievementId });
  };

  const achievements = pages?.pages[0].records;
  return (
    <>
      <HeaderPortal position="replace">
        <MiloAppHeader />
      </HeaderPortal>
      <MoneySavedMetricWidget />
      <TimeSavedMetricWidget />
      <div className="p-10">
        <div className="p-4 bg-gray-100">
          <div className="grid gap-4">
            {achievements?.map(achievement => (
              <div
                key={achievement._id}
                className="flex items-center justify-between p-4 bg-white shadow rounded-lg border border-gray-200"
                onClick={() => handleStartConversation(achievement._id!)}
              >
                <div>
                  <h3 className="text-lg font-bold text-gray-900">{achievement.title}</h3>
                  <p className="text-sm text-gray-500">{achievement.subtitle}</p>
                  <div className="text-sm text-gray-400 mt-1">Wed, 18 Dec</div>
                </div>
                <div className="flex flex-col items-center">
                  <span className="text-lg font-bold text-white bg-black px-4 py-2 rounded-lg">
                    ${achievement.amount}
                  </span>
                  <span className="text-sm text-gray-400 mt-1">Saved Annually</span>
                </div>
              </div>
            ))}
          </div>
        </div>
        {/*<InvoiceWizard />*/}
        <TaxDeductionWizard transactions={data.records} />
      </div>
    </>
  );
}
