import DotsIcon from '@/icons/3Dots.svg?react';
import BackIcon from '@/icons/Back.svg?react';
import CloseIcon from '@/icons/Close.svg?react';
import PlusIcon from '@/icons/Plus.svg?react';
import { zodResolver } from '@hookform/resolvers/zod';
import { getCustomerQueries, ICustomer } from '@repo/features/customers';
import { useNavigate, useRouter } from '@tanstack/react-router';
import { useFieldArray, useForm } from 'react-hook-form';
import z from 'zod';

import axiosInstance from '@/lib/axios';
import { cn } from '@/lib/utils';
import { useBreakpoint } from '@/hooks/use-breakpoint';
import { Button } from '@/components/ui/button';
import {
  Drawer,
  DrawerContent,
  DrawerDescription,
  DrawerFooter,
  DrawerHeader,
  DrawerTitle
} from '@/components/ui/drawer';
import { Form, FormControl, FormField, FormItem, FormLabel, FormMessage } from '@/components/ui/form';
import { Input } from '@/components/ui/input';

export type CustomerContactsProps = {
  id: string;
};
const formSchema = z.object({
  contactInfo: z.array(
    z.object({
      contactName: z.string().min(1),
      phoneNumber: z.string().min(1).optional(),
      email: z.string().min(1).email('Email is incorrect'),
      address: z.object({
        city: z.string().min(1),
        street: z.string().min(1)
      })
    })
  )
});

type FormFields = z.infer<typeof formSchema>;
const customerQueries = getCustomerQueries(axiosInstance);
export default function CustomerContactsView({ id }: CustomerContactsProps) {
  const { history, navigate } = useRouter();
  const { isBelowLg } = useBreakpoint('lg');
  const { data: customer } = customerQueries.byId.useSuspenseQuery({
    variables: {
      id
    }
  });

  const { mutateAsync: updateCustomer } = customerQueries.update.useMutation();

  const form = useForm<FormFields>({
    resolver: zodResolver(formSchema),
    defaultValues: {
      contactInfo: customer.contactInfo || []
    }
  });

  const { fields, append, remove } = useFieldArray({
    control: form.control,
    name: 'contactInfo'
  });

  const onSubmit = async (values: FormFields) => {
    try {
      await updateCustomer({
        id,
        data: {
          contactInfo: values.contactInfo
        }
      });
    } catch (e) {
      console.error(e);
    }
  };
  const renderContactForm = (index: number) => (
    <div className="flex flex-col gap-8 bg-neutral-700 rounded-lg py-7 px-6">
      <div className="flex justify-between items-center">
        <div className="text-sm font-bold ">#{index + 1}</div>
        <div className="shrink-0">
          <Button
            onClick={() => {
              remove(index);
            }}
            size="icon"
            variant="icon"
          >
            <CloseIcon className="size-4" />
          </Button>
        </div>
      </div>
      <FormField
        control={form.control}
        name={`contactInfo.${index}.contactName`}
        render={({ field }) => (
          <FormItem className="w-full">
            <FormLabel>Customer Name</FormLabel>
            <FormControl>
              <Input placeholder="Name" {...field} />
            </FormControl>
            <FormMessage />
          </FormItem>
        )}
      />
      <FormField
        control={form.control}
        name={`contactInfo.${index}.email`}
        render={({ field }) => (
          <FormItem>
            <FormLabel>Email</FormLabel>
            <FormControl>
              <Input placeholder="Email" {...field} />
            </FormControl>
            <FormMessage />
          </FormItem>
        )}
      />
      <FormField
        control={form.control}
        name={`contactInfo.${index}.phoneNumber`}
        render={({ field }) => (
          <FormItem className="w-full">
            <FormLabel>Phone Number</FormLabel>
            <FormControl>
              <Input placeholder="Phone Number" {...field} />
            </FormControl>
            <FormMessage />
          </FormItem>
        )}
      />
      <FormField
        control={form.control}
        name={`contactInfo.${index}.address.city`}
        render={({ field }) => (
          <FormItem>
            <FormLabel>City</FormLabel>
            <FormControl>
              <Input placeholder="City" {...field} />
            </FormControl>
            <FormMessage />
          </FormItem>
        )}
      />
      <FormField
        control={form.control}
        name={`contactInfo.${index}.address.street`}
        render={({ field }) => (
          <FormItem>
            <FormLabel>City</FormLabel>
            <FormControl>
              <Input placeholder="Street" {...field} />
            </FormControl>
            <FormMessage />
          </FormItem>
        )}
      />
    </div>
  );
  return (
    <Drawer direction={isBelowLg ? 'bottom' : 'right'} onClose={() => navigate({ to: '..' })} open>
      <DrawerContent
        className={cn(
          'h-full w-full lg:w-[28.125rem] bg-background rounded-none lg:rounded-tl-lg lg:rounded-bl-lg lg:right-0 lg:left-auto'
        )}
        hideHandle={isBelowLg}
      >
        <DrawerHeader className="py-4 px-8 h-[4rem] flex items-center justify-between">
          <DrawerTitle className="hidden">Customer Documents</DrawerTitle>
          <DrawerDescription className="hidden">List of Documents</DrawerDescription>
          <Button
            onClick={() => {
              history.back();
            }}
            size="icon"
            variant="icon"
          >
            <BackIcon className="size-5" />
          </Button>
          <Button
            onClick={() => {
              append({
                contactName: '',
                phoneNumber: '',
                email: '',
                address: {
                  city: '',
                  street: ''
                }
              });
            }}
            size="icon"
            variant="icon"
          >
            <PlusIcon className="size-[0.875rem]" />
          </Button>
        </DrawerHeader>

        <div className="flex h-full flex-col flex-1 overflow-y-auto p-10 pt-0 gap-6">
          <div className="text-lg font-bold">Contact Information</div>
          <Form {...form}>
            <form className="flex flex-col w-full gap-12" onSubmit={form.handleSubmit(onSubmit)}>
              {fields.map((field, index) => renderContactForm(index))}
              <div
                className={cn('', {
                  hidden: fields.length === 0
                })}
              >
                <Button
                  className="w-full"
                  disabled={!form.formState.isValid}
                  isLoading={form.formState.isSubmitting}
                  type="submit"
                >
                  Save Changes
                </Button>
              </div>
            </form>
          </Form>
        </div>
      </DrawerContent>
    </Drawer>
  );
}
