import type { ChangeEvent } from 'react';
import React, { useCallback, useRef, useState } from 'react';
import ChevronRightIcon from '@/assets/icons/chevron-right-icon';
import Clip from '@/assets/icons/clip-icon';
import { useAppStateStore } from '@/stores/app-state';
import type { IConversation } from '@repo/features/conversation';
import { useNavigate } from '@tanstack/react-router';
import { cva, type VariantProps } from 'class-variance-authority';
import { nanoid } from 'nanoid';
import useSendMessage from 'src/sections/milo-input/use-send-message';
import { useShallow } from 'zustand/react/shallow';

import { cn } from '@/lib/utils';
import useResponsiveness from '@/hooks/use-responsiveness';
import { Button } from '@/components/ui/button';
import { Collapsible, CollapsibleContent } from '@/components/ui/collapsible';
import { ScrollArea, ScrollBar } from '@/components/ui/scroll-area';
import { Textarea } from '@/components/ui/textarea';
import FilePreview from '@/components/file-preview';
import Suggestion from '@/components/suggestion';

const miloInputVariants = cva('flex flex-row items-center justify-between rounded-full w-full gap-4', {
  variants: {
    variant: {
      dark: 'border border-primary-400 hover:bg-neutral-500 bg-neutral-700  [&_textarea]:placeholder:text-neutral-200 border-none',
      light: 'border border-white hover:bg-white/10 text-white [&_textarea]:placeholder:text-neutral-500'
    },
    size: {
      default: 'p-4',
      sm: 'p-2'
    },
    multiline: {
      true: '',
      false: ''
    }
  },
  compoundVariants: [
    {
      multiline: true,
      size: 'sm',
      className: 'min-h-14'
    },
    {
      multiline: false,
      size: 'sm',
      className: 'h-14'
    },
    {
      multiline: true,
      size: 'default',
      className: 'min-h-18'
    },
    {
      multiline: false,
      size: 'default',
      className: 'h-18'
    }
  ],
  defaultVariants: {
    size: 'default',
    variant: 'dark',
    multiline: false
  }
});

const clipIconVariants = cva('size-6', {
  variants: {
    variant: {
      dark: 'fill-black',
      light: 'fill-white'
    }
  },
  defaultVariants: {
    variant: 'dark'
  }
});

const sendButtonVariants = cva('rounded-full size-11 shrink-0', {
  variants: {
    variant: {
      dark: 'bg-primary hover:bg-primary', // we use this now
      light: 'bg-white hover:bg-white [&>svg]:fill-black [&>svg]:stroke-black'
    }
  },
  defaultVariants: {
    variant: 'dark'
  }
});

type Props = {
  className?: string;
  // onAddMessage: (message: string, files: File[], cleanFiles: () => void) => void;
  conversation?: IConversation;
  suggestions?: string[];
  disabled?: boolean;
  isLoading?: boolean;
  label?: string;
  onFileChange?: (files: File[]) => void;
  pushIntoConversation?: boolean;
  // progressObject?: { [key: string]: number | undefined };
} & VariantProps<typeof miloInputVariants>;

export default function MiloInput({
  disabled,
  conversation,
  isLoading,
  size = 'default',
  className,
  variant = 'dark',
  label,
  suggestions = [],
  pushIntoConversation
}: Props) {
  const setIsGlobalChatFocused = useAppStateStore(useShallow(state => state.setIsGlobalChatFocused));
  const [textAreaHeight, setTextAreaHeight] = useState<number>(0);
  const textareaRef = useRef<HTMLTextAreaElement | null>(null);
  const navigate = useNavigate();

  const { sendMessage, fileProgress } = useSendMessage({
    conversationId: conversation?._id,
    activityId: conversation?.activityItem
  });

  const responsiveness = useResponsiveness();
  const fileRef = useRef<HTMLInputElement>(null);
  const [files, setFiles] = useState<File[]>([]);
  const isMultiline = textAreaHeight > 36 || files.length > 0;
  const handleAttach = useCallback(() => {
    if (fileRef.current) {
      fileRef.current.click();
    }
  }, []);

  const [message, setMessage] = useState('');

  const handleChangeMessage = useCallback((event: React.ChangeEvent<HTMLTextAreaElement>) => {
    setMessage(event.target.value);
  }, []);

  const cleanFiles = () => {
    setFiles([]);
  };

  const onMessageSend = useCallback(async () => {
    await sendMessage(
      {
        message,
        files,
        cleanFiles
      },
      cData => {
        if (pushIntoConversation) {
          navigate({
            to: `/conversation/$id`,
            params: {
              id: cData.conversationId
            },
            state: _state => ({
              ..._state,
              msgs: cData.msgs
            }),
            resetScroll: true
          });
        }
      }
    );
    setMessage('');
  }, [files, message, navigate, pushIntoConversation, sendMessage]);

  const handleKeySendMessage = useCallback(
    async (event: React.KeyboardEvent<HTMLTextAreaElement>) => {
      try {
        if (event.key === 'Enter' && !event.shiftKey) {
          await onMessageSend();
        }
      } catch (error) {
        console.error(error);
      }
    },
    [onMessageSend]
  );

  const onFileChange = (e: ChangeEvent<HTMLInputElement>) => {
    if (!e.target.files) return;
    const fArray = Array.from(e.target.files);
    setFiles(prev => [...prev, ...fArray]);
    e.target.value = '';
  };

  return (
    <>
      {suggestions.length > 0 && (
        <ScrollArea>
          <div className="flex shrink-0 gap-5 mb-10 lg:mb-12 w-full">
            {suggestions.map(s => (
              <Suggestion
                className="shrink-0"
                conversationId={conversation!._id}
                key={s}
                onClick={() => {
                  setMessage(s);
                }}
                suggestion={
                  {
                    firstMsg: s,
                    name: s,
                    description: s
                  } as any
                }
              />
            ))}
          </div>
          <ScrollBar className="hidden" orientation="horizontal" />
        </ScrollArea>
      )}
      <div>
        <div
          className={cn(miloInputVariants({ size, variant, className, multiline: isMultiline }), {
            'rounded-xl items-end': isMultiline
          })}
        >
          <div className="w-full">
            <Collapsible asChild open={files.length > 0}>
              <CollapsibleContent>
                <div className="flex flex-row items-center justify-start gap-2 p-4 overflow-y-scroll">
                  {files.map(file => (
                    <FilePreview
                      file={file}
                      key={nanoid()}
                      onDelete={_f => {
                        setFiles(prev => prev.filter(f => f.name !== _f.name));
                      }}
                      progress={file.name in fileProgress ? fileProgress[file.name] : undefined}
                      variant={variant}
                    />
                  ))}
                </div>
              </CollapsibleContent>
            </Collapsible>
            <div
              className={cn('flex flex-1 items-center', {
                'items-end': textAreaHeight > 36
              })}
            >
              {/*<Button className="rounded-full size-11" onClick={handleAttach} size="icon" variant="icon">*/}
              {/*  <Clip className={cn(clipIconVariants({ variant }))} />*/}
              {/*</Button>*/}
              <Textarea
                className={cn(
                  'h-10 min-h-0 text-xs resize-none pl-7 px-4 border-none shadow-none outline-none ring-0 focus-visible:ring-0'
                )}
                disabled={disabled || isLoading}
                maxRows={4}
                minRows={0}
                onBlur={() => {
                  setTimeout(() => {
                    // It fixes send button click when keyboard is open
                    setIsGlobalChatFocused(false);
                  }, 300);
                }}
                onChange={handleChangeMessage}
                onFocus={() => {
                  setIsGlobalChatFocused(true);
                }}
                onHeightChange={height => {
                  setTextAreaHeight(height);
                }}
                onKeyUp={handleKeySendMessage}
                placeholder={isLoading ? 'Processing...' : label}
                value={message}
              />
            </div>
          </div>
          {/*<Button*/}
          {/*  className={cn(sendButtonVariants({ variant }))}*/}
          {/*  onClick={async () => {*/}
          {/*    await onMessageSend();*/}
          {/*  }}*/}
          {/*  // disabled={message.length === 0 && files.length === 0}*/}
          {/*  size="icon"*/}
          {/*  type="button"*/}
          {/*>*/}
          {/*  <ChevronRightIcon />*/}
          {/*</Button>*/}
        </div>
        <input className="hidden" multiple onChange={onFileChange} ref={fileRef} type="file" />
      </div>
    </>
  );
}
