// import ChevronRightIcon from '@/assets/icons/chevron-right-icon';
import React, { useState } from 'react';
import { useAuth } from '@/auth/hooks';
import { PATH_AFTER_LOGIN } from '@/config-global.ts';
import AccountSettingsIcon from '@/icons/AccountSettings.svg?react';
import ChevronDownIcon from '@/icons/ChevronDown.svg?react';
import ConnectedIcon from '@/icons/Connected.svg?react';
import FlameIcon from '@/icons/Flame.svg?react';
import SignOutIcon from '@/icons/SignOut.svg?react';
import SupportIcon from '@/icons/Support.svg?react';
import { useProfileStore } from '@/sections/profile/use-profile-store';
import { useQueryClient } from '@tanstack/react-query';
import { useNavigate } from '@tanstack/react-router';
import { useShallow } from 'zustand/react/shallow';

import { cn } from '@/lib/utils';
import { useBreakpoint } from '@/hooks/use-breakpoint';
import { Avatar, AvatarFallback } from '@/components/ui/avatar';
import {
  Drawer,
  DrawerClose,
  DrawerContent,
  DrawerDescription,
  DrawerFooter,
  DrawerHeader,
  DrawerTitle,
  DrawerTrigger
} from '@/components/ui/drawer';
import { DropdownMenu, DropdownMenuContent, DropdownMenuTrigger } from '@/components/ui/dropdown-menu';
import { Separator } from '@/components/ui/separator';
import MenuRow from '@/components/menu-row';

function OrganizationSelect() {
  const { user } = useAuth();
  const { isBelowLg } = useBreakpoint('lg');
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const { selectOrganization, selectedOrgId, delegationWithOrg } = useAuth();
  const [isOrgSelectorOpen, setIsOrgSelectorOpen] = useState<boolean>(false);

  function MobileDrawerVariant() {
    return (
      <Drawer
        onOpenChange={_open => {
          setIsOrgSelectorOpen(_open);
        }}
        open={isOrgSelectorOpen}
      >
        <DrawerContent className="flex flex-col min-h-[500px]">
          <DrawerHeader className="hidden">
            <DrawerTitle>Organization Select</DrawerTitle>
            <DrawerDescription>Select another organization</DrawerDescription>
          </DrawerHeader>
          <div className="flex-1 mt-4 overflow-y-scroll max-h-[400px] ">
            {user?.organizations.map(organization => (
              <MenuRow
                checkmark={organization.organization._id === selectedOrgId}
                key={organization.organization._id}
                onClick={async () => {
                  selectOrganization(organization.organization._id);
                  setIsOrgSelectorOpen(false);
                  await queryClient.invalidateQueries();
                  await navigate({
                    to: PATH_AFTER_LOGIN
                  });
                }}
              >
                {organization.organization.name}
              </MenuRow>
            ))}
          </div>
        </DrawerContent>
      </Drawer>
    );
  }
  function DesktopDropdownVariant({ children }: { children: React.ReactNode }) {
    return (
      <div>
        <DropdownMenu
          onOpenChange={_open => {
            setIsOrgSelectorOpen(_open);
          }}
          open={isOrgSelectorOpen}
        >
          <DropdownMenuTrigger asChild>{children}</DropdownMenuTrigger>
          <DropdownMenuContent className="max-h-[500px] overflow-y-scroll">
            {user?.organizations.map(organization => (
              <MenuRow
                checkmark={organization.organization._id === selectedOrgId}
                key={organization.organization._id}
                onClick={async () => {
                  selectOrganization(organization.organization._id);
                  setIsOrgSelectorOpen(false);
                  await queryClient.invalidateQueries();
                  await navigate({
                    to: PATH_AFTER_LOGIN
                  });
                }}
              >
                {organization.organization.name}
              </MenuRow>
            ))}
          </DropdownMenuContent>
        </DropdownMenu>
      </div>
    );
  }
  const elementToClick = (
    <div className="flex items-center justify-between">
      <div
        className="flex items-center gap-3 cursor-pointer"
        onClick={e => {
          e.preventDefault();
          setIsOrgSelectorOpen(true);
        }}
      >
        <Avatar className="size-12">
          {/*<AvatarImage src={user?.photoURL} alt={user?.displayName} />*/}
          <AvatarFallback className="bg-fun-cyan font-semibold text-3xs text-black">
            {delegationWithOrg?.organization.name.charAt(0).toUpperCase()}
          </AvatarFallback>
        </Avatar>
        <div className="ml-2 text-2xs font-semibold ">{delegationWithOrg?.organization.name}</div>
        <ChevronDownIcon />
      </div>
    </div>
  );
  return isBelowLg ? (
    <>
      {elementToClick}
      <MobileDrawerVariant />
    </>
  ) : (
    <DesktopDropdownVariant>{elementToClick}</DesktopDropdownVariant>
  );
}

export default function ProfileMenu({ className, children }: { className?: string; children: React.ReactNode }) {
  const { isBelowLg } = useBreakpoint('lg');
  const { activeMenu, openMenu, closeMenu } = useProfileStore(
    useShallow(state => ({
      activeMenu: state.activeMenu,
      openMenu: state.openMenu,
      closeMenu: state.closeMenu
    }))
  );
  const { logout } = useAuth();

  function MenuContent() {
    return (
      <>
        <MenuRow
          chevron
          icon={<ConnectedIcon />}
          onClick={() => {
            openMenu('connected_accounts');
          }}
        >
          <div>Connected Accounts</div>
        </MenuRow>
        <MenuRow
          chevron
          icon={<AccountSettingsIcon />}
          onClick={() => {
            openMenu('account_settings');
          }}
        >
          Account Settings
        </MenuRow>
        <MenuRow
          chevron
          icon={<SupportIcon />}
          onClick={() => {
            openMenu('support');
          }}
        >
          Support
        </MenuRow>
        <MenuRow disabled icon={<FlameIcon />}>
          Subscription
        </MenuRow>
        <MenuRow
          icon={<SignOutIcon />}
          onClick={async () => {
            closeMenu();
            await logout();
          }}
        >
          Logout
        </MenuRow>
      </>
    );
  }
  if (isBelowLg)
    return (
      <Drawer
        onOpenChange={_open => {
          console.log('open in drawer');
          if (_open) {
            openMenu('profile_menu');
          } else {
            closeMenu();
          }
        }}
        open={activeMenu === 'profile_menu'}
      >
        <DrawerTrigger asChild>{children}</DrawerTrigger>
        <DrawerContent aria-description="Profile Menu" className="flex flex-col">
          <DrawerHeader className="hidden">
            <DrawerTitle>Profile Menu</DrawerTitle>
            <DrawerDescription>Profile menu item here.Choose one.</DrawerDescription>
          </DrawerHeader>
          <div className="flex-1">
            <div className="p-8">
              <OrganizationSelect />
            </div>
            <Separator />
            <MenuContent />
          </div>
          <DrawerFooter className="pt-2">
            <DrawerClose asChild>{/*<Button variant="outline">Cancel</Button>*/}</DrawerClose>
          </DrawerFooter>
        </DrawerContent>
      </Drawer>
    );

  return (
    <DropdownMenu
      modal
      onOpenChange={_open => {
        if (_open) {
          openMenu('profile_menu');
        } else {
          closeMenu();
        }
      }}
      open={activeMenu === 'profile_menu'}
    >
      <DropdownMenuTrigger asChild className={cn('', className)}>
        {children}
      </DropdownMenuTrigger>
      <DropdownMenuContent>
        <div className="p-8">
          <OrganizationSelect />
        </div>

        <MenuContent />
      </DropdownMenuContent>
    </DropdownMenu>
  );
}
