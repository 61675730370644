import { cn } from '@/lib/utils';
import { useBreakpoint } from '@/hooks/use-breakpoint';
import {
  Drawer,
  DrawerContent,
  DrawerDescription,
  DrawerHeader,
  DrawerTitle,
  DrawerTrigger
} from '@/components/ui/drawer';
import { DropdownMenu, DropdownMenuContent, DropdownMenuTrigger } from '@/components/ui/dropdown-menu';

export type ResponsiveActionsMenuProps = {
  children: React.ReactNode;
  open?: boolean;
  onOpenChange?: (open: boolean) => void;
  dropdownTrigger: React.ReactNode;
  drawerContentProps?: React.ComponentProps<typeof DrawerContent>;
  drawerProps?: React.ComponentProps<typeof Drawer>;
};

export default function ResponsiveActionsMenu({
  onOpenChange,
  dropdownTrigger,
  children,
  drawerContentProps,
  drawerProps
}: ResponsiveActionsMenuProps) {
  const { isBelowLg } = useBreakpoint('lg');
  const { className: drawerContentClassName, ...drawerContentOtherProps } = drawerContentProps || {};
  if (isBelowLg) {
    return (
      <Drawer onOpenChange={onOpenChange} {...drawerProps}>
        <DrawerTrigger asChild>{dropdownTrigger}</DrawerTrigger>
        <DrawerContent
          aria-description="Profile Menu"
          className={cn('flex flex-col', drawerContentClassName)}
          {...drawerContentOtherProps}
        >
          <DrawerHeader className="sr-only">
            <DrawerTitle>Actions Menu</DrawerTitle>
            <DrawerDescription>Actions Menu</DrawerDescription>
          </DrawerHeader>
          <div className="flex-1">{children}</div>
        </DrawerContent>
      </Drawer>
    );
  }
  return (
    <DropdownMenu onOpenChange={onOpenChange}>
      <DropdownMenuTrigger asChild>{dropdownTrigger}</DropdownMenuTrigger>
      <DropdownMenuContent className="max-h-[500px] overflow-y-scroll">{children}</DropdownMenuContent>
    </DropdownMenu>
  );
}
