import { fCurrency } from '@/utils/format-number';

import { cn } from '@/lib/utils';

export type RowAmountProps = {
  amount: number;
  className?: string;
};

export default function RowAmount({ amount = 0, className }: RowAmountProps) {
  return (
    <div className={cn('text-xs font-bold', className)}>{amount > 0 ? `+${fCurrency(amount)}` : fCurrency(amount)}</div>
  );
}
