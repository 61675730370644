import { Skeleton } from '@/components/ui/skeleton';

export type SkeletonProps = {
  // children: React.ReactNode;
};

export default function WidgetSkeleton({}: SkeletonProps) {
  return (
    <div className="space-y-5">
      <div className="flex items-center space-x-4">
        <Skeleton className="h-12 w-12 rounded-full" />
        <div className="space-y-2">
          <Skeleton className="h-4 w-[4rem]" />
          <Skeleton className="h-3 w-14" />
        </div>
      </div>
      <div className="space-y-3">
        <Skeleton className="h-3 w-[85%]" />
        <Skeleton className="h-3 w-[95%]" />
        <Skeleton className="h-3 w-[85%]" />
        <Skeleton className="h-3 w-[95%]" />
      </div>
    </div>
  );
}
