import HamburgerIcon from '@/icons/Hamburger.svg?react';

import { Button } from '@/components/ui/button';
import { useSidebar } from '@/components/ui/sidebar';

export type SidebarControlButtonProps = {};

export default function SidebarControlButton(props: SidebarControlButtonProps) {
  const { setOpenMobile } = useSidebar();
  return (
    <Button
      className="shrink-0"
      onClick={() => {
        setOpenMobile(true);
      }}
      size="icon"
      variant="icon-bordered"
      {...props}
    >
      <HamburgerIcon className="size-5 shrink-0 fill-white" />
    </Button>
  );
}
