import * as React from 'react';
import { useEffect, useState } from 'react';
import headphones1 from '@/assets/gifs/headphones1.gif';
import ThreeDotsIcon from '@/icons/3Dots.svg?react';
import DeleteIcon from '@/icons/Delete.svg?react';
import DisconnectIcon from '@/icons/Disconnect.svg?react';
import ReconnectIcon from '@/icons/Reconnect.svg?react';
import VIcon from '@/icons/V.svg?react';
import { useProfileStore } from '@/sections/profile/use-profile-store';
import { useAppStateStore } from '@/stores/app-state';
import { fToNow } from '@/utils/format-time';
import type { FinancialAccount } from '@repo/features/financial-accounts';
import { getFinancialAccountsQueries } from '@repo/features/financial-accounts';
import { useQueryClient } from '@tanstack/react-query';
import { parseISO } from 'date-fns';
import { useShallow } from 'zustand/react/shallow';

import axiosInstance from '@/lib/axios';
import { useBreakpoint } from '@/hooks/use-breakpoint';
import {
  Drawer,
  DrawerContent,
  DrawerDescription,
  DrawerFooter,
  DrawerHeader,
  DrawerTitle
} from '@/components/ui/drawer';
import { DropdownMenu, DropdownMenuContent, DropdownMenuTrigger } from '@/components/ui/dropdown-menu';
import { RadioGroup, RadioGroupItem } from '@/components/ui/radio-group';
import { Separator } from '@/components/ui/separator';
import { useConfirmationStore } from '@/components/confirmation-dialog/use-confirmation-store';
import ConnectAccountButton from '@/components/connect-account-button';
import DrawerSearchHeader from '@/components/drawer-search-header';
import MenuRow from '@/components/menu-row';

const financialAccountsQueries = getFinancialAccountsQueries(axiosInstance);

function BankAccountActions({
  isOpen,
  onOpenChange,
  account
}: {
  isOpen: boolean;
  onOpenChange?: (isOpen: boolean) => void;
  account: FinancialAccount;
}) {
  const { isBelowLg } = useBreakpoint('lg');
  const queryClient = useQueryClient();
  const confirm = useConfirmationStore(state => state.confirm);
  const { mutateAsync: deleteAccount } = financialAccountsQueries.delete.useMutation({
    onSuccess: async () => {
      await queryClient.invalidateQueries({ queryKey: financialAccountsQueries.getAll.getKey() });
    }
  });

  const { mutateAsync: reconnectAccount } = financialAccountsQueries.enable.useMutation({
    onSuccess: async () => {
      await queryClient.invalidateQueries({ queryKey: financialAccountsQueries.getAll.getKey() });
    }
  });

  const { mutateAsync: disconnectAccount } = financialAccountsQueries.disable.useMutation({
    onSuccess: async () => {
      await queryClient.invalidateQueries({ queryKey: financialAccountsQueries.getAll.getKey() });
    }
  });

  const onReconnectClick = async () => {
    onOpenChange && onOpenChange(false);
    await reconnectAccount({
      financialAccountId: account._id
    });
  };
  const onDisconnectClick = async () => {
    onOpenChange && onOpenChange(false);
    await confirm({
      title: `Please confirm disconnecting your ${account.name} - ${account.mask}`,
      description: 'Disconnecting your account will stop pulling in your transactions and updating them here.',
      modalTitle: 'Disconnect Account',
      confirmVariant: 'destructive',
      confirmText: 'Disconnect',
      onConfirm: async () => {
        await disconnectAccount({
          financialAccountId: account._id
        });
      }
    });
  };
  const onDeleteClick = async () => {
    onOpenChange && onOpenChange(false);
    await confirm({
      title: `Please confirm deleting your ${account.name} - ${account.mask}`,
      description:
        'Deleting account will remove it from your account list and you will no longer be able to access it.',
      modalTitle: 'Delete Account',
      confirmVariant: 'destructive',
      confirmText: 'Delete',
      onConfirm: async () => {
        await deleteAccount({
          financialAccountId: account._id
        });
      }
    });
  };

  const actions = (
    <>
      {account.status === 'disconnected' && (
        <MenuRow icon={<ReconnectIcon />} onClick={onReconnectClick}>
          Reconnect Account
        </MenuRow>
      )}
      {account.status === 'connected' && (
        <MenuRow icon={<DisconnectIcon />} onClick={onDisconnectClick}>
          Disconnect Account
        </MenuRow>
      )}
      <MenuRow icon={<DeleteIcon />} onClick={onDeleteClick}>
        Delete Account
      </MenuRow>
    </>
  );
  if (!isBelowLg)
    return (
      <DropdownMenu onOpenChange={onOpenChange} open={isOpen}>
        <DropdownMenuTrigger asChild>
          <span style={{ display: 'inline-block', width: 0, height: 0 }} />
        </DropdownMenuTrigger>
        <DropdownMenuContent>{actions}</DropdownMenuContent>
      </DropdownMenu>
    );

  return (
    <Drawer onOpenChange={onOpenChange} open={isOpen}>
      <DrawerContent>
        <DrawerHeader className="hidden">
          <DrawerTitle>Bank Account Actions</DrawerTitle>
          <DrawerDescription>Bank Account Actions</DrawerDescription>
        </DrawerHeader>
        <div className="flex flex-col">{actions}</div>
      </DrawerContent>
    </Drawer>
  );
}
function BankAccountRow({ account }: { account: FinancialAccount }) {
  const [accountType, setAccountType] = useState<'personal' | 'business'>('personal');
  const [isActionsOpened, setIsActionsOpened] = useState(false);
  return (
    <div className="py-10 flex flex-col gap-5 shrink-0">
      <div className="text-base font-bold">{`${account.name} - ${account.mask}`}</div>
      <div className="flex items-center justify-between">
        <div className="inline-flex h-9 rounded-full bg-neutral-300 p-1">
          <RadioGroup
            className="group relative inline-grid grid-cols-[1fr_1fr] items-center gap-0 text-4xs font-medium after:absolute after:inset-y-0 after:w-1/2 after:rounded-full after:bg-background after:shadow-sm after:shadow-black/5 after:outline-offset-2 after:after:transition-transform after:duration-300 after:[transition-timing-function:cubic-bezier(0.16,1,0.3,1)] has-[:focus-visible]:after:outline has-[:focus-visible]:after:outline-2 has-[:focus-visible]:after:outline-ring/70 data-[state=off]:after:translate-x-0 data-[state=on]:after:translate-x-full"
            // data-state={accountType}
            onValueChange={value => {
              setAccountType(value as any);
            }}
            value={accountType}
          >
            <label className="relative z-10 inline-flex h-full min-w-8 cursor-pointer items-center justify-center whitespace-nowrap px-3 group-data-[state=on]:text-muted-foreground/70">
              Business
              <RadioGroupItem className="sr-only" value="off" />
            </label>
            <label className="relative z-10 inline-flex h-full min-w-8 cursor-pointer items-center justify-center whitespace-nowrap px-3 group-data-[state=off]:text-muted-foreground/70">
              Personal
              <RadioGroupItem className="sr-only" value="on" />
            </label>
          </RadioGroup>
        </div>
        <div>
          <ThreeDotsIcon
            className="cursor-pointer"
            onClick={() => {
              setIsActionsOpened(true);
            }}
          />
          <BankAccountActions
            account={account}
            isOpen={isActionsOpened}
            onOpenChange={_isOpen => {
              setIsActionsOpened(_isOpen);
            }}
          />
        </div>
      </div>
      <div className="flex items-center gap-2">
        {/*<div>*/}
        {/*  <VIcon className="size-3" />*/}
        {/*</div>*/}
        <div className="capitalize text-[0.6875rem] font-medium">{account.status}</div>
        <div>
          <svg fill="currentColor" height="3" viewBox="0 0 4 3" width="4" xmlns="http://www.w3.org/2000/svg">
            <circle cx="1.797" cy="1.5" r="1.5" />
          </svg>
        </div>
        <div className="text-4xs text-neutral-500">
          Synced {fToNow(account.balanceUpdatedAt ? parseISO(account.balanceUpdatedAt) : '')}
        </div>
      </div>
    </div>
  );
}

export default function BankAccountsDrawer() {
  const {
    data: { data: financialAccounts = [] }
  } = financialAccountsQueries.getAll.useSuspenseQuery();
  const isPlaidModalOpen = useAppStateStore(useShallow(state => state.isPlaidModalOpen));
  const { closeMenu, isOpen, activeMenu, openMenu } = useProfileStore(
    useShallow(state => ({
      isOpen: state.isOpen,
      closeMenu: state.closeMenu,
      activeMenu: state.activeMenu,
      openMenu: state.openMenu
    }))
  );
  useEffect(() => {
    if (isPlaidModalOpen && isOpen) {
      document.body.style.pointerEvents = 'auto';
    }
  }, [isPlaidModalOpen, isOpen]);

  const renderHeader = (
    <DrawerSearchHeader
      onBackClick={() => {
        closeMenu();
      }}
    />
  );

  return (
    <Drawer
      direction="left"
      dismissible={!isPlaidModalOpen}
      onClose={() => {
        document.body.style.pointerEvents = 'auto';
      }}
      onOpenChange={_isOpen => {
        if (_isOpen) {
          openMenu('bank_accounts');
        } else {
          closeMenu();
        }
      }}
      open={isOpen ? activeMenu === 'bank_accounts' : false}
    >
      <DrawerContent
        className="lg:rounded-tr-lg lg:rounded-br-lg h-full w-full lg:w-[28.1rem] left-0 right-auto rounded-none border-none focus-visible:outline-none overflow-y-scroll"
        hideHandle
      >
        <DrawerHeader className="hidden">
          <DrawerTitle>Bank & Credit Cards</DrawerTitle>
          <DrawerDescription>Your bank accounts and Credit Cards.</DrawerDescription>
        </DrawerHeader>
        {renderHeader}
        <div className="flex flex-1 overflow-y-scroll flex-col p-8">
          {financialAccounts.length ? <div className="text-lg font-bold">Bank & Credit Cards</div> : null}
          {financialAccounts.map(account => (
            <React.Fragment key={account._id}>
              <BankAccountRow account={account} key={account._id} />
              <Separator />
            </React.Fragment>
          ))}
          {!financialAccounts.length && (
            <div className="flex flex-col justify-center items-center w-full h-full">
              <div>
                <img alt="" className="h-28" src={headphones1} />
              </div>
              <div className="text-md font-bold text-black mt-7">No Accounts or Cards Connected Yet</div>
              <div className="text-sm text-neutral-500 text-center w-[300px]">
                Connect your bank accounts to keep track of your finances and get milo's insights.
              </div>
            </div>
          )}
        </div>
        <DrawerFooter className="flex flex-col py-6 px-10 gap-4">
          <ConnectAccountButton>Securely Connect a New Account</ConnectAccountButton>
          <div className="text-4xs text-neutral-500 text-center">
            Connection is secured with Plaid, we do not store your passwords & account information.
          </div>
        </DrawerFooter>
      </DrawerContent>
    </Drawer>
  );
}
