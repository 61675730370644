import ChevronRightIcon from '@/icons/ChevronRight.svg?react';
import { fCurrency } from '@/utils/format-number';
import { fToNow } from '@/utils/format-time';
import { getFinancialAccountsQueries } from '@repo/features/financial-accounts';

import axiosInstance from '@/lib/axios';
import { Button } from '@/components/ui/button';
import { Separator } from '@/components/ui/separator';

import type { WidgetProps } from '../types';
import WidgetErrorFallback from '../widget-error-fallback';
import { WidgetFactory } from '../widget-factory';
import PeekWidgetSkeleton from './peek-widget-skeleton';

const financialAccountsQueries = getFinancialAccountsQueries(axiosInstance);

export const PeekAccountsWidget = WidgetFactory.create(
  ({}: WidgetProps) => {
    const { data: accounts } = financialAccountsQueries.getAll.useSuspenseQuery({
      select(data) {
        return data.data.slice(0, 2);
      }
    });

    return (
      <div className="flex flex-col gap-4">
        {accounts.map(a => (
          <>
            <div className="flex flex-col gap-2 py-7" key={a._id}>
              <div className="text-2xs font-medium">
                {a.name} - {a.mask}
              </div>
              <div className="text-xl font-semibold">{fCurrency(a.balance || 0)}</div>
              <div className="text-4xs text-neutral-200">
                Last updated {fToNow(a.balanceUpdatedAt || new Date().toISOString())}
              </div>
            </div>
            <Separator className="bg-neutral-900" />
          </>
        ))}
        <Button
          asChild
          onClick={() => {
            // TODO open accounts drawer
            console.log('should open connected accounts drawer');
          }}
          variant="ghost"
        >
          <div className="flex justify-center items-center mx-auto cursor-pointer text-xs font-medium">
            <div>Manage Accounts</div>
            <ChevronRightIcon className="size-3 shrink-0 ml-2" />
          </div>
        </Button>
      </div>
    );
  },
  {
    FallbackComponent: WidgetErrorFallback,
    SuspenseFallbackComponent: PeekWidgetSkeleton
  }
);
