import ChevronDownIcon from '@/icons/ChevronDown.svg?react';
import { fCurrency } from '@/utils/format-number';
import type { VariantProps } from 'class-variance-authority';
import { cva } from 'class-variance-authority';

import { cn } from '@/lib/utils';

export type LibraryRowProps = {
  title?: React.ReactNode;
  subtitle?: React.ReactNode;
  date?: React.ReactNode;
  amount?: React.ReactNode;
  icon?: React.ReactNode;
  // status?: RowStatus;
  // variant?: RowVariant;
  // icon?: RowIcon;
  customIcon?: React.ReactNode;
  hideChevron?: boolean;
  tag?: React.ReactNode;
  menu?: React.ReactNode;
  action?: React.ReactNode;
  onClick?: () => void;
  className?: string;
} & VariantProps<typeof libraryRowVariants>;
const libraryRowVariants = cva('', {
  variants: {
    variant: {
      default: 'flex p-10 gap-6 items-center justify-between w-full text-white rounded-xl',
      mobile: '',
      compact: ''
    }
  },
  defaultVariants: {
    variant: 'default'
  }
});
export default function LibraryRow({
  icon,
  className,
  title,
  subtitle,
  tag,
  date,
  amount = 0,
  variant,
  onClick,
  hideChevron = false
}: LibraryRowProps) {
  return (
    <div className={cn(libraryRowVariants({ variant }), className)} onClick={onClick}>
      <div className="flex items-center gap-4">
        {icon}
        <div>
          {title}
          {subtitle}
          {date}
        </div>
      </div>
      <div className="flex items-center justify-center gap-4">
        <div className="flex flex-col gap-2 items-end">
          {amount}
          {tag}
        </div>
        <div>{!hideChevron && <ChevronDownIcon />}</div>
      </div>
    </div>
  );
}
