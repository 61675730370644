// src/hooks/useVendorsQueries.ts
import { AxiosError, AxiosInstance } from 'axios';
import { router } from 'react-query-kit';

import { PaginatedResponse } from '../types';
import { IVendor, IVendorContactInfo, VendorExtensions } from './types';

export const getVendorQueries = (axiosInstance: AxiosInstance) =>
  router('vendor', {
    // Query to fetch all vendors
    paginated: router.query<PaginatedResponse<IVendor>, { limit?: number; expand?: VendorExtensions[] }, AxiosError>({
      fetcher: variables =>
        axiosInstance
          .get('/vendors', {
            params: variables
          })
          .then(res => res.data)
    }),
    infinityPaginated: router.infiniteQuery<
      PaginatedResponse<IVendor>,
      { page?: number; limit?: number; expand?: VendorExtensions[] },
      AxiosError
    >({
      fetcher: (variables, { pageParam }) =>
        axiosInstance.get('/vendors', { params: { ...variables, page: pageParam } }).then(res => res.data),
      getNextPageParam: lastPage => (lastPage.totalPages >= lastPage.page + 1 ? lastPage.page + 1 : null),
      initialPageParam: 1
    }),
    // Query to fetch a single vendor by ID
    byId: router.query<IVendor, { id: string; expand?: VendorExtensions[] }, AxiosError>({
      fetcher: ({ id, ...params }) => axiosInstance.get(`/vendors/${id}`, { params }).then(res => res.data)
    }),
    // Mutation to create a new vendor
    create: router.mutation<IVendor, { data: Partial<IVendor> }, AxiosError>({
      mutationFn: async variables => axiosInstance.post('/vendors', variables.data).then(res => res.data)
    }),
    // Mutation to update a vendor by ID
    update: router.mutation<IVendor, { id: string; data: Partial<IVendor> }, AxiosError>({
      mutationFn: async variables =>
        axiosInstance.patch(`/vendors/${variables.id}`, variables.data).then(res => res.data)
    }),
    // Mutation to delete a vendor by ID
    delete: router.mutation<void, { id: string }, AxiosError>({
      mutationFn: async variables => axiosInstance.delete(`/vendors/${variables.id}`).then(res => res.data)
    }),
    // Mutation to add a contact to a vendor
    addContact: router.mutation<IVendor, { id: string; data: IVendorContactInfo }, AxiosError>({
      mutationFn: async variables =>
        axiosInstance.post(`/vendors/${variables.id}/contacts`, variables.data).then(res => res.data)
    }),
    // Mutation to update a vendor's contact
    updateContact: router.mutation<IVendor, { id: string; contactId: string; data: IVendorContactInfo }, AxiosError>({
      mutationFn: async variables =>
        axiosInstance
          .put(`/vendors/${variables.id}/contacts/${variables.contactId}`, variables.data)
          .then(res => res.data)
    }),
    // Mutation to delete a contact from a vendor
    deleteContact: router.mutation<void, { id: string; contactId: string }, AxiosError>({
      mutationFn: async variables =>
        axiosInstance.delete(`/vendors/${variables.id}/contacts/${variables.contactId}`).then(res => res.data)
    }),

    // make upload function that is sending post to /customers/upload
    upload: router.mutation<{ message: string; success: boolean; issues: String }, { fileId: string }, AxiosError>({
      mutationFn: async variables => {
        return axiosInstance.post('/vendors/import', { fileId: variables.fileId }).then(res => res.data);
      }
    })
  });
