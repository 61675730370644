import type { VariantProps } from 'class-variance-authority';
import { cva } from 'class-variance-authority';

import { cn } from '@/lib/utils';

export type AnswerSelectorProps = {
  children: React.ReactNode;
  selected: boolean;
  className?: string;
  description?: string;
  onClick?: (e: React.MouseEvent, selected: boolean) => void;
} & VariantProps<typeof answerSelectorVariants>;

const answerSelectorVariants = cva(
  'py-7 pl-7 pr-10 rounded-xl w-full flex justify-between items-center',
  {
    variants: {
      variant: {
        primary: 'bg-neutral-600',
        secondary: 'bg-neutral-600'
      }
    },
    defaultVariants: {
      variant: 'primary'
    }
  }
);
export default function AnswerSelector({
  children,
  selected,
  description,
  className,
  variant = 'primary',
  onClick
}: AnswerSelectorProps) {
  return (
    <div
      className={cn(answerSelectorVariants({ variant, className }), {
        'bg-white text-black': selected && variant === 'primary',
        'border border-white': selected && variant === 'secondary'
      })}
      onClick={e => {
        onClick && onClick(e, selected);
      }}
    >
      <div
        className={cn('text-sm', {
          // 'text-white': selected && variant === 'primary',
          'text-base font-bold flex flex-col gap-2': !!description
        })}
      >
        <div>{children}</div>
        <div className="text-2xs font-normal text-neutral-500">{description}</div>
      </div>
      {variant === 'primary' && (
        <div>
          <svg
            className={cn('fill-white', {
              'fill-black': selected
            })}
            height="9"
            viewBox="0 0 12 9"
            width="12"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path d="M11.7223 0.78001C11.3623 0.42001 10.7323 0.42001 10.3723 0.78001L4.96234 6.19001L2.59234 3.82001C2.22234 3.45001 1.61234 3.45001 1.24234 3.82001C0.872344 4.19001 0.872344 4.80001 1.24234 5.17001L4.28234 8.21001C4.46234 8.39001 4.70234 8.49001 4.96234 8.49001C5.22234 8.49001 5.46234 8.39001 5.64234 8.21001L11.7223 2.13001C12.0923 1.76001 12.0923 1.15001 11.7223 0.78001Z" />
          </svg>
        </div>
      )}
    </div>
  );
}
