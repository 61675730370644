import type { ICustomer } from '@repo/features/customers';
import type { ISuggestedAccountingLedger } from '@repo/features/transactions';
import type { IVendor } from '@repo/features/vendors';

export const mergeCategoriesWithCurrentLedger = (
  categories: { name: string; count: number }[],
  suggestedAccountingLedger?: ISuggestedAccountingLedger
) => {
  if (!suggestedAccountingLedger) return categories;
  const modified = [...categories] as { name: string; count: number }[];
  const category = categories.find(_category => _category.name === suggestedAccountingLedger.name);
  if (!category) {
    modified.unshift({ name: suggestedAccountingLedger.name, count: 1 });
  }
  return modified;
};

export const mergeVendorWithCurrentTransactionVendors = (vendors: IVendor[], suggestedVendor?: IVendor) => {
  if (!suggestedVendor) return vendors;
  const modified = [...vendors];
  const vendor = vendors.find(_vendor => _vendor._id === suggestedVendor._id);
  if (!vendor) {
    modified.unshift(suggestedVendor);
  }
  return modified;
};

export const mergeCustomerWithCurrentTransactionCustomers = (customers: ICustomer[], suggestedCustomer?: ICustomer) => {
  if (!suggestedCustomer) return customers;
  const modified = [...customers];
  const customer = customers.find(_customer => _customer._id === suggestedCustomer._id);
  if (!customer) {
    modified.unshift(suggestedCustomer);
  }
  return modified;
};
