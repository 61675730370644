import { cn } from '@/lib/utils';

export type RowSubTitleProps = {
  subtitle: string;
  className?: string;
};

export default function RowSubTitle({ subtitle, className }: RowSubTitleProps) {
  return <div className={cn('text-4xs font-medium', className)}>{subtitle}</div>;
}
