import { cn } from '@/lib/utils';

export type LibraryStatsCardProps = {
  title: string;
  amount: string | number;
  className?: string;
};

export default function LibraryStatsCard({ title, amount, className }: LibraryStatsCardProps) {
  return (
    <div className={cn('py-6 pl-8 pr-10 rounded-xl bg-neutral-700 flex flex-col gap-1 flex-1', className)}>
      <div className="text-sm">{title}</div>
      <div className="text-2xl font-medium leading-5">{amount}</div>
    </div>
  );
}
