import ChartWidgetEmptyState from '@/sections/widgets/charts/chart-empty-state';
import { WidgetProps } from '@/sections/widgets/types';
import { WidgetFactory } from '@/sections/widgets/widget-factory';
import { ChevronRightIcon } from '@radix-ui/react-icons';
import { getFinancialQueries } from '@repo/features/financial-data';
import { useNavigate } from '@tanstack/react-router';

import axiosInstance from '@/lib/axios';
import { Button } from '@/components/ui/button';
import { Separator } from '@/components/ui/separator';

const financialQueries = getFinancialQueries(axiosInstance);

export const ProfitableCustomersTable = WidgetFactory.create(({ className }: WidgetProps) => {
  const navigate = useNavigate();
  const { data: topRevenue } = financialQueries.topRevenue.useSuspenseQuery({
    select(data) {
      return data.data.slice(0, 5);
    }
  });

  if (!topRevenue.length) {
    return <ChartWidgetEmptyState />;
  }

  return (
    <div className="flex flex-col gap-4">
      {topRevenue.map(a => (
        <>
          <div key={a.customer} className="flex items-center justify-between">
            <div className="text-base font-medium">{a.customer}</div>
            <div className="flex flex-col text-end">
              <div className="text-lg font-semibold">${a.revenue}</div>
              <div className="text-sm">{a.percent_total}% of Revenue</div>
            </div>
          </div>
          <Separator />
        </>
      ))}
      <Button
        asChild
        variant={'ghost'}
        onClick={() => {
          navigate({
            to: '/library/customers'
          });
        }}
      >
        <div className="flex justify-center items-center mx-auto w-fit cursor-pointer">
          <div>View All Customers</div>
          <ChevronRightIcon />
        </div>
      </Button>
    </div>
  );
});
