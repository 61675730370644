import BackIcon from '@/icons/Back.svg?react';
import { headerWrapperVariants } from '@/layouts/common/app-header';
import { useRouter } from '@tanstack/react-router';
import type { VariantProps } from 'class-variance-authority';

import { cn } from '@/lib/utils';
import { Button } from '@/components/ui/button';

export type MiloCenterAppHeaderProps = {
  className?: string;
} & VariantProps<typeof headerWrapperVariants>;

export default function MiloControlCenterAppHeader({ variant = 'secondary', className }: MiloCenterAppHeaderProps) {
  const { history } = useRouter();
  return (
    <div className={cn(headerWrapperVariants({ variant }), className)}>
      <Button
        onClick={() => {
          history.back();
        }}
        size="icon"
        variant="icon"
      >
        <BackIcon className="fill-white size-5" />
      </Button>
      <div className="font-semibold text-base">&milo Control Center</div>
      <div />
    </div>
  );
}
