import { Suspense, type ComponentType } from 'react';
import { ErrorBoundary, type FallbackProps } from 'react-error-boundary';

import MetricWidgetSkeleton from './metrics/metric-widget-skeleton';
import { type WidgetProps } from './types';
import WidgetErrorFallback from './widget-error-fallback';

interface WidgetFactoryOptions {
  FallbackComponent?: ComponentType<FallbackProps>;
  SuspenseFallbackComponent?: ComponentType;
}

export const WidgetFactory = {
  create<T extends WidgetProps>(WidgetImpl: ComponentType<T>, opts: WidgetFactoryOptions = {}) {
    const { FallbackComponent = WidgetErrorFallback, SuspenseFallbackComponent = MetricWidgetSkeleton } = opts;

    return function WrappedWidget(props: T) {
      return (
        <ErrorBoundary FallbackComponent={FallbackComponent}>
          <Suspense fallback={<SuspenseFallbackComponent />}>
            <WidgetImpl {...props} />
          </Suspense>
        </ErrorBoundary>
      );
    };
  }
};
