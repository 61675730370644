import BillDetailsView from '@/sections/library/bill/views/bill-details-view';
import { createFileRoute } from '@tanstack/react-router';

export const Route = createFileRoute('/_guarded/_main/library/bills/$id')({
  component: () => {
    const { id } = Route.useParams();
    const navigate = Route.useNavigate();
    return <BillDetailsView id={id} onClose={() => navigate({ to: '..' })} />;
  },
  wrapInSuspense: true
});
