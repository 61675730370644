import ChevronRightIcon from '@/icons/ChevronRight.svg?react';
import InvoiceBadge from '@/sections/library/invoice/invoice-badge';
import LibraryRow from '@/sections/library/library-row';
import RowAmount from '@/sections/library/library-row/row-amount';
import RowDate from '@/sections/library/library-row/row-date';
import RowSubTitle from '@/sections/library/library-row/row-subtitle';
import RowTitle from '@/sections/library/library-row/row-title';
import type { IInvoice } from '@repo/features/invoices';

import { cn } from '@/lib/utils';

export type InvoiceRowProps = {
  invoice: IInvoice;
  hideTag?: boolean;
  hideChevron?: boolean;
  mode?: 'table' | 'list-item';
  onClick?: () => void;
  className?: string;
};
export default function InvoiceRow({
  invoice,
  hideTag = false,
  hideChevron = false,
  mode = 'list-item',
  onClick,
  className
}: InvoiceRowProps) {
  if (mode === 'table') {
    return (
      <div
        className={cn(
          'grid grid-cols-[1fr_1fr_1fr_11.5rem_11.5rem_11.5rem_auto] py-10 px-16 items-center justify-center cursor-pointer'
        )}
        onClick={onClick}
      >
        <div className="flex items-center">
          <div className="flex items-center gap-3">
            <RowAmount amount={invoice.subtotal} />
          </div>
        </div>
        <div className="flex items-center">
          <RowTitle title={invoice?.customer?.name || ''} />
        </div>
        <div className="flex items-center">
          <RowSubTitle subtitle={`${invoice.invoiceNumber}`} />
        </div>
        <div className="flex items-center">
          <RowDate date={invoice.invoiceDate as any as string} />
        </div>
        <div className="flex items-center">
          <RowDate date={invoice.invoiceDate as any as string} />
        </div>
        <div className="flex items-center">
          <InvoiceBadge variant={invoice.status as any}>{invoice.status}</InvoiceBadge>
        </div>
        <div>
          <ChevronRightIcon className="h-3" />
        </div>
      </div>
    );
  }
  return (
    <LibraryRow
      amount={<RowAmount amount={invoice.subtotal} />}
      className={className}
      date={<RowDate date={invoice.dueDate as any as string} />}
      hideChevron={hideChevron}
      icon={null}
      onClick={onClick}
      subtitle={<RowSubTitle subtitle={`${invoice.invoiceNumber}`} />}
      tag={<InvoiceBadge variant={invoice.status as any}>{invoice.status}</InvoiceBadge>}
      title={<RowTitle title={invoice.customer?.name || ''} />}
    />
  );
}
