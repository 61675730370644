import CustomerDetailsView from '@/sections/library/customer/views/customer-details-view';
import { createFileRoute, Outlet } from '@tanstack/react-router';

export const Route = createFileRoute('/_guarded/_main/library/customers_/$id')({
  component: () => {
    const { id } = Route.useParams();
    return (
      <>
        <CustomerDetailsView id={id} />
        <Outlet />
      </>
    );
  }
});
