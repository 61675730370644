// src/hooks/useTransactionsQueries.ts
import { AxiosError, AxiosInstance, AxiosProgressEvent } from 'axios';
import { router } from 'react-query-kit';

import { PaginatedResponse } from '../types';
import { GetTopCategoriesResponse, GetTransactionsParams, ITransaction, UpdateTransaction } from './types';

export const getTransactionsQueries = (axiosInstance: AxiosInstance) =>
  router('transaction', {
    // Query to fetch transactions with pagination and optional description search
    paginated: router.query<PaginatedResponse<ITransaction>, GetTransactionsParams | undefined, AxiosError>({
      fetcher: variables => axiosInstance.get('/transactions', { params: variables }).then(res => res.data)
    }),
    infinityPaginated: router.infiniteQuery<
      PaginatedResponse<ITransaction>,
      GetTransactionsParams | undefined,
      AxiosError
    >({
      fetcher: (variables, { pageParam }) =>
        axiosInstance.get('/transactions', { params: { ...variables, page: pageParam } }).then(res => res.data),
      getNextPageParam: lastPage => (lastPage.totalPages >= lastPage.page + 1 ? lastPage.page + 1 : null),
      initialPageParam: 1
    }),
    byId: router.query<ITransaction, { id: string }, AxiosError>({
      fetcher: variables => axiosInstance.get(`/transactions/${variables.id}`).then(res => res.data)
    }),
    topCategories: router.query<GetTopCategoriesResponse, { limit?: number }, AxiosError>({
      fetcher: variables =>
        axiosInstance.get('/transactions/top-categories', { params: variables }).then(res => res.data)
    }),
    update: router.mutation<ITransaction, { id: string; data: UpdateTransaction }, AxiosError>({
      mutationFn: variables => axiosInstance.put(`/transactions/${variables.id}`, variables.data).then(res => res.data)
    }),
    patch: router.mutation<
      ITransaction,
      { id: string; data: UpdateTransaction; context?: Partial<ITransaction> },
      AxiosError
    >({
      mutationFn: variables =>
        axiosInstance.patch(`/transactions/${variables.id}`, variables.data).then(res => res.data)
    }),
    signAndUpload: router.mutation<
      ITransaction,
      {
        transactionId: string;
        file: File;
        onUploadProgress?: (progressEvent: AxiosProgressEvent) => void;
      },
      AxiosError
    >({
      mutationFn: async ({ file, onUploadProgress, transactionId }) => {
        const {
          data: { signedUrl, fileId }
        } = await axiosInstance.post<{ signedUrl: string; fileId: string }>(`/files/get-signed-url`, {
          filePath: file.name,
          fileContentType: file.type
        });
        await axiosInstance.put(signedUrl, file, {
          headers: { 'Content-Type': file.type },
          onUploadProgress
        });
        const updatedTransaction = await axiosInstance.post<ITransaction>(
          `/transactions/${transactionId}/documentUpload`
        );
        return updatedTransaction.data;
      }
    })
  });
