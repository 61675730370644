import { Fragment } from 'react';
import LibraryLoadMore from '@/sections/library/library-load-more';
import ComingUp from '@/sections/library/transaction/coming-up';
import VendorRow from '@/sections/library/vendor/vendor-row';
import { getVendorQueries } from '@repo/features/vendors';
import { createFileRoute, Outlet } from '@tanstack/react-router';

import axiosInstance from '@/lib/axios';
import { useBreakpoint } from '@/hooks/use-breakpoint';

export const Route = createFileRoute('/_guarded/_main/library/vendors')({
  component: VendorsPage,
  wrapInSuspense: true
});

const vendorQueries = getVendorQueries(axiosInstance);

function VendorsPage() {
  const navigate = Route.useNavigate();
  const { isBelowLg } = useBreakpoint('lg');
  const {
    data: paginated,
    hasNextPage,
    fetchNextPage
  } = vendorQueries.infinityPaginated.useInfiniteQuery({
    variables: {
      expand: ['bills', 'bills.aggs', 'transactions.aggs'],
      limit: 10
    }
  });

  return (
    <>
      <div className="divide-y overflow-y-auto max-h-full">
        {paginated?.pages.map((page, i) => (
          <Fragment key={i}>
            {page.records.map(record => (
              <VendorRow
                className="rounded-none"
                key={record._id}
                mode={isBelowLg ? 'list-item' : 'table'}
                onClick={() =>
                  navigate({
                    to: '/library/vendors/$id',
                    params: { id: record._id! }
                  })
                }
                vendor={record}
              />
            ))}
          </Fragment>
        ))}
        {hasNextPage ? (
          <LibraryLoadMore
            onClick={async () => {
              await fetchNextPage();
            }}
          />
        ) : null}
      </div>
      <Outlet />
    </>
  );
}
