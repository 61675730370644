import { AxiosError, AxiosInstance } from 'axios';
import { router } from 'react-query-kit';

import { IConversation } from '../conversation/types';
import { PaginatedResponse } from '../types';
import {
  AchievementExpansions,
  AchievementSumResponse,
  AchievementType,
  IAchievement,
  MoneySavedAchievementSubType
} from './types';

export const getAchievementQueries = (axiosInstance: AxiosInstance) =>
  router('achievements', {
    // Infinite query for paginated achievements
    infinityPaginated: router.infiniteQuery<
      PaginatedResponse<IAchievement>, // Dynamically infer the response type
      {
        page?: number;
        limit?: number;
        expand?: AchievementExpansions[];
        startDate?: string;
        endDate?: string;
        type?: AchievementType; // 'moneySaved' | 'timeSaved' (optional)
        subType?: MoneySavedAchievementSubType; // 'taxSaving' | 'vendorChange' (optional)
        status?: string; // AchievementStatus
        stub?: boolean;
      },
      AxiosError
    >({
      fetcher: (variables, { pageParam }) =>
        axiosInstance
          .get('/achievements', {
            params: { ...variables, page: pageParam }
          })
          .then(res => res.data),
      getNextPageParam: lastPage => (lastPage.totalPages >= lastPage.page + 1 ? lastPage.page + 1 : null),
      initialPageParam: 1
    }),

    // Query to fetch a single achievement by ID
    byId: router.query<IAchievement, { id: string; expand?: AchievementExpansions[] }, AxiosError>({
      fetcher: ({ id, expand }) =>
        axiosInstance.get(`/achievements/${id}`, { params: { expand } }).then(res => res.data)
    }),

    // Mutation to create a new achievement
    create: router.mutation<
      IAchievement,
      Omit<IAchievement, '_id' | 'createdAt' | 'updatedAt' | 'feedItems'>,
      AxiosError
    >({
      mutationFn: async data => axiosInstance.post('/achievements', data).then(res => res.data)
    }),

    // Mutation to mark an achievement as completed
    markAsCompleted: router.mutation<IAchievement, { id: string }, AxiosError>({
      mutationFn: async ({ id }) => axiosInstance.post(`/achievements/${id}/complete`).then(res => res.data)
    }),

    // Mutation to unlock an achievement
    unlock: router.mutation<IAchievement, { id: string }, AxiosError>({
      mutationFn: async ({ id }) => axiosInstance.post(`/achievements/${id}/unlock`).then(res => res.data)
    }),

    // Mutation to start a conversation on an achievement
    startConversation: router.mutation<IConversation, { id: string }, AxiosError>({
      mutationFn: async ({ id }) => axiosInstance.post(`/achievements/${id}/start-conversation`).then(res => res.data)
    }),

    // Query to get the total sum of achievements by type
    getSum: router.query<AchievementSumResponse, { type: AchievementType; startDate?: string; endDate?: string }, AxiosError>({
      fetcher: ({ type, startDate, endDate }) =>
        axiosInstance.get('/achievements/sum', { params: { type, startDate, endDate } }).then(res => res.data)
    })
  });
