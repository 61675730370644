import * as React from 'react';
import { useAuth } from '@/auth/hooks';
import { businessEntityTypeVariants } from '@/sections/onboarding/questionnaire/steps/business-entity-type-step';
import { primaryIndustryVariants } from '@/sections/onboarding/questionnaire/steps/industry-step';
import { useProfileStore } from '@/sections/profile/use-profile-store';
import { zodResolver } from '@hookform/resolvers/zod';
import { BusinessEntityType, getAuthQueries } from '@repo/features/auth';
import axios from 'axios';
import { useForm } from 'react-hook-form';
import z from 'zod';
import { useShallow } from 'zustand/react/shallow';

import { authAxiosInstance } from '@/lib/axios';
import { Button } from '@/components/ui/button';
import {
  Drawer,
  DrawerContent,
  DrawerDescription,
  DrawerFooter,
  DrawerHeader,
  DrawerTitle
} from '@/components/ui/drawer';
import { Form, FormControl, FormField, FormItem, FormLabel, FormMessage } from '@/components/ui/form';
import { Input } from '@/components/ui/input';
import { Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from '@/components/ui/select';
import DrawerSearchHeader from '@/components/drawer-search-header';

const formSchema = z.object({
  companyMetadata: z.object({
    legal: z
      .object({
        name: z.string().optional(),
        address: z.string().optional()
      })
      .optional(),
    public: z
      .object({
        phone: z.string().optional(),
        website: z.string().optional(),
        email: z.string().email('Email is incorrect').optional().or(z.literal(''))
      })
      .optional(),
    businessEntityType: z.nativeEnum(BusinessEntityType).optional(),
    industry: z.string().optional()
  })
});

type FormFields = z.infer<typeof formSchema>;

const authQueries = getAuthQueries(authAxiosInstance);

export default function BusinessProfileDrawer() {
  const { toggleMenu, closeMenu, isOpen, activeMenu, openMenu } = useProfileStore(
    useShallow(state => ({
      toggleMenu: state.toggleMenu,
      isOpen: state.isOpen,
      closeMenu: state.closeMenu,
      activeMenu: state.activeMenu,
      openMenu: state.openMenu
    }))
  );
  const { mutateAsync: updateCompanyMetadata, isPending } = authQueries.updateOrganizationCompanyMetadata.useMutation();
  const { delegationWithOrg, selectedOrgId } = useAuth();
  const onSubmit = async (values: FormFields) => {
    try {
      await updateCompanyMetadata({
        id: selectedOrgId!,
        data: values.companyMetadata
      });
      // setErrorMsg('');
      // await signUp({ data: values, query: qs.parse(window.location.search || '', { ignoreQueryPrefix: true }) });
    } catch (e) {
      if (axios.isAxiosError(e)) {
        // setErrorMsg((e.response as any).data.message);
      }
    }
  };

  const form = useForm<FormFields>({
    resolver: zodResolver(formSchema),
    mode: 'all',
    defaultValues: {
      companyMetadata: delegationWithOrg?.organization.companyMetadata || {
        public: {
          phone: '',
          website: '',
          email: ''
        },
        legal: {
          name: '',
          address: ''
        },
        businessEntityType: BusinessEntityType['S-CORP'],
        industry: ''
      }
    }
  });

  const {
    formState: { errors, isValid }
  } = form;

  const renderHeader = (
    <DrawerSearchHeader
      onBackClick={() => {
        closeMenu();
      }}
    />
  );

  return (
    <Drawer
      direction="left"
      onOpenChange={() => {
        toggleMenu('business_profile');
      }}
      open={isOpen ? activeMenu === 'business_profile' : false}
    >
      <Form {...form}>
        <DrawerContent
          className="lg:rounded-tr-lg lg:rounded-br-lg h-full w-full lg:w-[28.1rem] left-0 right-auto rounded-none border-none focus-visible:outline-none"
          hideHandle
        >
          <DrawerHeader className="hidden">
            <DrawerTitle>Business Profile</DrawerTitle>
            <DrawerDescription>Your business details for compliance and tax purposes.</DrawerDescription>
          </DrawerHeader>
          {renderHeader}

          <form className="flex flex-col flex-1 gap-10 px-10 overflow-y-scroll">
            <div>
              <div className="text-lg font-bold">Business Profile</div>

              <div className="text-xs">
                Your business details for compliance and tax purposes. Some of this information will be visible on your
                invoices.
              </div>
            </div>

            <FormField
              control={form.control}
              name="companyMetadata.legal.name"
              render={({ field }) => (
                <FormItem className="w-full">
                  <FormLabel>Registered Company Name</FormLabel>
                  <FormControl>
                    <Input size="md" {...field} />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
            <FormField
              control={form.control}
              name="companyMetadata.legal.address"
              render={({ field }) => (
                <FormItem className="w-full">
                  <FormLabel>Registered Company Address</FormLabel>
                  <FormControl>
                    <Input size="md" {...field} />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
            <FormField
              control={form.control}
              name="companyMetadata.public.email"
              render={({ field }) => (
                <FormItem className="w-full">
                  <FormLabel>Company Email</FormLabel>
                  <FormControl>
                    <Input size="md" {...field} />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
            <FormField
              control={form.control}
              name="companyMetadata.public.website"
              render={({ field }) => (
                <FormItem className="w-full">
                  <FormLabel>Company Website</FormLabel>
                  <FormControl>
                    <Input size="md" {...field} />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
            <FormField
              control={form.control}
              name="companyMetadata.public.phone"
              render={({ field }) => (
                <FormItem className="w-full">
                  <FormLabel>Company Phone</FormLabel>
                  <FormControl>
                    <Input size="md" {...field} />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
            <FormField
              control={form.control}
              name="companyMetadata.businessEntityType"
              render={({ field }) => (
                <FormItem className="w-full">
                  <FormLabel>Registered As</FormLabel>
                  <Select defaultValue={field.value} onValueChange={field.onChange}>
                    <FormControl>
                      <SelectTrigger>
                        <SelectValue placeholder="Select a verified email to display" />
                      </SelectTrigger>
                    </FormControl>
                    <SelectContent>
                      {businessEntityTypeVariants
                        .filter(v => !!v.value)
                        .map(variant => (
                          <SelectItem key={variant.value} value={variant.value}>
                            {variant.name}
                          </SelectItem>
                        ))}
                    </SelectContent>
                  </Select>
                  <FormMessage />
                </FormItem>
              )}
            />
            <FormField
              control={form.control}
              name="companyMetadata.industry"
              render={({ field }) => (
                <FormItem className="w-full">
                  <FormLabel>Primary Industry</FormLabel>
                  <Select defaultValue={field.value} onValueChange={field.onChange}>
                    <FormControl>
                      <SelectTrigger>
                        <SelectValue placeholder="Select a verified email to display" />
                      </SelectTrigger>
                    </FormControl>
                    <SelectContent>
                      {primaryIndustryVariants.map(variant => (
                        <SelectItem key={variant} value={variant}>
                          {variant}
                        </SelectItem>
                      ))}
                    </SelectContent>
                  </Select>
                  <FormMessage />
                </FormItem>
              )}
            />
            <div className="flex-1" />
          </form>
          <DrawerFooter className="p-10">
            <Button
              className="w-full"
              disabled={!isValid}
              isLoading={isPending}
              onClick={() => form.handleSubmit(onSubmit)()}
              size="md"
            >
              Save Changes
            </Button>
          </DrawerFooter>
        </DrawerContent>
      </Form>
    </Drawer>
  );
}
