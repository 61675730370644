import ChevronDownIcon from '@/icons/ChevronDown.svg?react';
import PlusIcon from '@/icons/Plus.svg?react';
import BillRow from '@/sections/library/bill/bill-row';
import useBillActions from '@/sections/library/bill/use-bill-actions';
import { mergeVendorWithCurrentTransactionVendors } from '@/sections/library/transaction/helpers';
import LibrarySelectButton from '@/sections/library/transaction/library-select-button';
import { fDate } from '@/utils/format-time';
import { getBillQueries } from '@repo/features/bills';
import { getFileQueries } from '@repo/features/file';
import type { IVendor } from '@repo/features/vendors';
import { getVendorQueries } from '@repo/features/vendors';
import { Outlet, useNavigate } from '@tanstack/react-router';

import axiosInstance from '@/lib/axios';
import { cn } from '@/lib/utils';
import { useBreakpoint } from '@/hooks/use-breakpoint';
import { Collapsible, CollapsibleContent, CollapsibleTrigger } from '@/components/ui/collapsible';
import { Drawer, DrawerContent, DrawerDescription, DrawerHeader, DrawerTitle } from '@/components/ui/drawer';
import FilePreview from '@/components/file-preview';

const fileQueries = getFileQueries(axiosInstance);
const billQueries = getBillQueries(axiosInstance);
const vendorQueries = getVendorQueries(axiosInstance);

export default function BillDetailsView({ id, onClose }: { id: string; onClose: () => void }) {
  const navigate = useNavigate();
  const { isBelowLg } = useBreakpoint('lg');
  const { data: bill } = billQueries.byId.useSuspenseQuery({
    variables: {
      id,
      expand: ['vendor']
    }
  });

  const { updateBill } = useBillActions();

  const { data: vendors = [] } = vendorQueries.paginated.useSuspenseQuery({
    variables: {
      limit: 5
    },
    select: _data => mergeVendorWithCurrentTransactionVendors(_data.records, bill.vendor as IVendor)
  });

  const { data: signedFileUrl } = fileQueries.getSignedUrl.useSuspenseQuery({
    variables: {
      id: bill.fileId
    }
  });

  const renderDocumentsSection = (
    <div className="py-8 px-10 flex flex-col gap-6">
      <div className="text-2xs font-medium">Documents:</div>
      <div>
        <FilePreview className="w-full" signedUrl={signedFileUrl} />
      </div>
    </div>
  );

  const renderVendorSection = (
    <Collapsible>
      <CollapsibleTrigger className="py-8 px-10 flex w-full justify-between items-center gap-6 ">
        <div className="text-2xs font-medium">Vendor:</div>
        <div className="flex items-center gap-2">
          <div className="text-2xs font-medium">{(bill.vendor as IVendor)?.name}</div>
          <div className="[&[data-state=open]>svg]:rotate-180">
            <ChevronDownIcon />
          </div>
        </div>
      </CollapsibleTrigger>
      <CollapsibleContent className="flex gap-4 px-10 data-[state=open]:pb-8 overflow-y-scroll lg:overflow-y-auto lg:flex-wrap scrollbar-hidden">
        {vendors.map(vendor => (
          <LibrarySelectButton
            key={vendor._id}
            onClick={async () => {
              if ((bill?.vendor as IVendor)?._id !== vendor._id) {
                await updateBill({
                  id,
                  data: {
                    vendor: vendor._id
                  },
                  context: {
                    vendor
                  }
                });
              }
            }}
            selected={vendor._id === (bill.vendor as IVendor)?._id}
            size="sm"
          >
            {vendor.name}
          </LibrarySelectButton>
        ))}
        <LibrarySelectButton
          onClick={() =>
            navigate({
              to: '/library/bills/$id/vendor',
              params: { id }
            })
          }
          size="sm"
          variant="add-new"
        >
          <PlusIcon className="size-3" />
          ADD NEW
        </LibrarySelectButton>
      </CollapsibleContent>
    </Collapsible>
  );

  return (
    <>
      <Drawer direction={isBelowLg ? 'bottom' : 'right'} onClose={onClose} open>
        <DrawerContent
          className={cn(
            'h-full w-full lg:w-[28.125rem] bg-neutral-800 lg:rounded-none lg:rounded-tl-lg lg:rounded-bl-lg lg:right-0 lg:left-auto'
          )}
          hideHandle={!isBelowLg}
        >
          <DrawerHeader className="text-left border-b p-0">
            <DrawerTitle className="hidden">Bill Details</DrawerTitle>
            <DrawerDescription className="hidden">Your bill details.</DrawerDescription>
            <div>{bill ? <BillRow bill={bill} hideChevron /> : null}</div>
          </DrawerHeader>
          <div className="flex h-full flex-col flex-1 divide-y overflow-y-auto pb-10">
            <div className="py-8 px-10 flex gap-12">
              <div>
                <div className="text-xs font-semibold">{fDate(bill.createdAt, 'MMM dd, yyyy')}</div>
                <div className="text-4xs">Issue Date</div>
              </div>
              <div>
                <div className="text-xs font-semibold">{fDate(bill.dueDate, 'MMM dd, yyyy')}</div>
                <div className="text-4xs">Due Date</div>
              </div>
            </div>
            {renderDocumentsSection}
            {renderVendorSection}
          </div>
        </DrawerContent>
      </Drawer>
      <Outlet />
    </>
  );
}
