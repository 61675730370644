import VendorDetailsView from '@/sections/library/vendor/views/vendor-details-view';
import { createFileRoute, Outlet } from '@tanstack/react-router';

export const Route = createFileRoute('/_guarded/_main/library/vendors_/$id')({
  component: () => {
    const { id } = Route.useParams();
    return (
      <>
        <VendorDetailsView id={id} />
        <Outlet />
      </>
    );
  }
});
