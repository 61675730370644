import DotsIcon from '@/icons/3Dots.svg?react';
import BackIcon from '@/icons/Back.svg?react';
import ContactsIcon from '@/icons/Contacts.svg?react';
import DocumentIcon from '@/icons/Document.svg?react';
import InfoIcon from '@/icons/Info.svg?react';
import type { IVendor } from '@repo/features/vendors';
import { useRouter } from '@tanstack/react-router';

import { Button } from '@/components/ui/button';
import MenuRow from '@/components/menu-row';
import ResponsiveActionsMenu from '@/components/responsive-actions-menu';

export type VendorDetailsHeaderProps = {
  vendor: IVendor;
};

export default function VendorDetailsHeader({ vendor }: VendorDetailsHeaderProps) {
  const { history, navigate } = useRouter();
  return (
    <div className="px-8 py-6 border-b lg:p-10 lg:h-34 flex flex-row items-center justify-between">
      <div>
        <Button size="icon-xs" variant="icon">
          <BackIcon
            className="size-4"
            onClick={() => {
              history.back();
            }}
          />
        </Button>
      </div>
      <div className="text-base font-semibold">{vendor.name}</div>
      <div>
        <ResponsiveActionsMenu
          dropdownTrigger={
            <Button size="icon-xs" variant="icon-bordered">
              <DotsIcon className="size-4" />
            </Button>
          }
        >
          <MenuRow
            disabled
            icon={<DocumentIcon className="size-[0.875rem]" />}
            onClick={() => navigate({ to: '/library/vendors/$id/documents', params: { id: vendor._id! } })}
          >
            Documents
          </MenuRow>
          <MenuRow
            icon={<ContactsIcon className="size-[0.875rem]" />}
            onClick={() => navigate({ to: '/library/vendors/$id/contacts', params: { id: vendor._id! } })}
          >
            Contacts Information
          </MenuRow>
          <MenuRow
            icon={<InfoIcon className="size-[0.875rem]" />}
            onClick={() => navigate({ to: '/library/vendors/$id/info', params: { id: vendor._id! } })}
          >
            Vendor Information
          </MenuRow>
        </ResponsiveActionsMenu>
      </div>
    </div>
  );
}
