import { useEffect, useState } from 'react';
import DiggingDog from '@/assets/lottie/digging-dog';

// ----------------------------------------------------------------------

export default function SplashScreen({ ...other }: React.ComponentProps<'div'>) {
  const [mounted, setMounted] = useState(false);

  useEffect(() => {
    setMounted(true);
  }, []);

  if (!mounted) {
    return null;
  }

  return (
    <div className="absolute bottom-0 right-0 z-[10000] flex size-full items-center justify-center" {...other}>
      <DiggingDog className="w-[200px]" speed={3} />
    </div>
  );
}
