import ChartWidgetEmptyState from '@/sections/widgets/charts/chart-empty-state';
import { WidgetProps } from '@/sections/widgets/types';
import { WidgetFactory } from '@/sections/widgets/widget-factory';
import { ChevronRightIcon } from '@radix-ui/react-icons';
import { getInvoiceQueries } from '@repo/features/invoices';
import { useNavigate } from '@tanstack/react-router';
import { startOfMonth } from 'date-fns';

import axiosInstance from '@/lib/axios';
import { cn } from '@/lib/utils';
import { Badge } from '@/components/ui/badge';
import { Button } from '@/components/ui/button';
import { Separator } from '@/components/ui/separator';

const invoicesQueries = getInvoiceQueries(axiosInstance);
const now = new Date();
const monthStart = startOfMonth(now);

export const CurrentMonthInvoices = WidgetFactory.create(({ className }: WidgetProps) => {
  const navigate = useNavigate();
  const { data: invoices } = invoicesQueries.currentMonth.useSuspenseQuery({
    variables: {
      dueDateFrom: monthStart,
      dueDateTo: now,
      expand: ['aggs', 'customer'],
      sortBy: 'dueDate',
      sortOrder: 'asc',
      limit: 5
    },
    select(data) {
      return data.records.map(d => ({
        id: d._id,
        customer: d.customer?.name || 'Unknown',
        dueDate: d.dueDate,
        status: d.status,
        amount: d.subtotal
      }));
    }
  });

  return (
    <div className={cn('flex flex-col gap-4', className)}>
      {!invoices?.length && <ChartWidgetEmptyState />}
      {invoices?.length > 0 &&
        invoices.map(i => (
          <>
            <div key={i.id} className="flex justify-between items-center py-8">
              <div className="flex flex-col gap-1">
                <div className="text-base font-medium">{i.customer}</div>
                <div className="text-xs">{i.dueDate}</div>
              </div>
              <div className="flex flex-col gap-1 items-end">
                <div className="text-lg font-semibold">${i.amount}</div>
                <Badge className="text-4xs font-normal uppercase bg-neutral-800 text-white">{i.status}</Badge>
              </div>
            </div>
            <Separator />
          </>
        ))}
      <Button
        asChild
        variant={'ghost'}
        onClick={() => {
          navigate({
            to: '/library/invoices'
          });
        }}
      >
        <div className="flex justify-center items-center mx-auto w-fit cursor-pointer">
          <div>View All Invoices</div>
          <ChevronRightIcon />
        </div>
      </Button>
    </div>
  );
});
