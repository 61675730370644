import { ChevronRightIcon } from '@radix-ui/react-icons';
import { Link } from '@tanstack/react-router';

import { cn } from '@/lib/utils';

import type { WidgetComponentProps } from '../types';

export function MiloMetric({
  icon,
  label,
  metric,
  link,
  className
}: WidgetComponentProps & { metric: number | string; label: string; icon: React.ReactNode }) {
  return (
    <div className={cn(className, 'flex items-center  border-neutral-300 border-2')}>
      <div>{icon}</div>
      <div className="flex flex-col flex-1">
        <div className="text-base">{label}</div>
        <div className="text-3xl font-semibold">{metric}</div>
      </div>
      {!!link && (
        <div>
          <Link {...link}>
            <ChevronRightIcon />
          </Link>
        </div>
      )}
    </div>
  );
}
