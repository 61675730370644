import AlertIcon from '@/icons/Alert.svg?react';
import type { BillStatus } from '@repo/features/bills';
import type { VariantProps } from 'class-variance-authority';
import { cva } from 'class-variance-authority';

import { cn } from '@/lib/utils';

export type BillBadgeProps = {
  children: React.ReactNode;
} & VariantProps<typeof billTagVariants>;

const billTagVariants = cva('h-6  inline-flex gap-1 rounded-md text-6xs font-bold px-2 uppercase items-center', {
  variants: {
    variant: {
      default: 'bg-fun-turquoise/20',
      open: 'bg-fun-yellow',
      overdue: 'bg-fun-red text-white',
      paid: 'bg-fun-green ',
      pending: '',
      canceled: 'border border-1 border-neutral-400'
    }
  },
  defaultVariants: {
    variant: 'default'
  }
});

const renderAlert = (
  <div>
    <AlertIcon className="fill-fun-red size-[0.875rem]" />
  </div>
);

export default function BillBadge({ variant, children }: BillBadgeProps) {
  const renderBadge = <div className={cn('', billTagVariants({ variant }))}>{children}</div>;

  if (variant === 'overdue') {
    return (
      <div className="inline-flex gap-3 items-center">
        {renderAlert}
        {renderBadge}
      </div>
    );
  }
  return renderBadge;
}
