import type { FallbackProps } from 'react-error-boundary';

import { Button } from '@/components/ui/button';

export type WidgetErrorFallbackProps = FallbackProps;

export default function WidgetErrorFallback({ error, resetErrorBoundary }: WidgetErrorFallbackProps) {
  // Call resetErrorBoundary() to reset the error boundary and retry the render.

  return (
    <div className="gap-6 flex flex-col justify-center items-center" role="alert">
      <div>
        <Button onClick={resetErrorBoundary}>Retry</Button>
      </div>
      <div>Content Couldn’t Be Loaded</div>
    </div>
  );
}
