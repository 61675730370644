import * as React from 'react';
import { ReloadIcon } from '@radix-ui/react-icons';
import { Slot } from '@radix-ui/react-slot';
import { cva, type VariantProps } from 'class-variance-authority';

import { cn } from '@/lib/utils';

const buttonVariants = cva(
  'inline-flex items-center rounded-full justify-center whitespace-nowrap font-semibold transition-colors focus-visible:outline-none disabled:pointer-events-none disabled:bg-neutral-300 disabled:text-neutral-500',
  {
    variants: {
      variant: {
        primary: 'bg-primary text-white hover:bg-primary-600',
        secondary: 'bg-white text-black hover:bg-primary-200',
        outline: 'border border-white bg-transparent text-white hover:bg-white/10',
        'outline-black': 'border border-black bg-transparent text-black hover:bg-white/10',
        destructive: 'bg-destructive text-destructive-foreground shadow-sm hover:bg-destructive/90',
        ghost: 'bg-none p-0',
        link: 'text-primary underline-offset-4 hover:underline bg-inherit hover:bg-inherit',
        icon: 'bg-inherit hover:bg-inherit',
        'icon-bordered': 'border-3 border-neutral-700',
        'icon-filled': 'bg-neutral-500'
      },
      size: {
        sm: 'h-12 px-8 text-3xs',
        md: 'h-14 px-10 min-w-[12.5rem] text-2xs',
        lg: 'h-16 px-10 min-w-[12.5rem] text-xs',
        icon: 'size-12',
        'icon-lg': 'size-14',
        'icon-xs': 'size-10',
        auto: 'size-auto'
      }
    },
    defaultVariants: {
      variant: 'primary',
      size: 'md'
    }
  }
);

export interface ButtonProps
  extends React.ButtonHTMLAttributes<HTMLButtonElement>,
    VariantProps<typeof buttonVariants> {
  asChild?: boolean;
  isLoading?: boolean;
  loadingText?: string;
}

const Button = React.forwardRef<HTMLButtonElement, ButtonProps>(
  (
    { className, variant, size, children, isLoading, loadingText = 'Please wait', disabled, asChild = false, ...props },
    ref
  ) => {
    const Comp = asChild ? Slot : 'button';
    return (
      <Comp
        className={cn(buttonVariants({ variant, size, className }))}
        disabled={isLoading || disabled}
        ref={ref}
        type="button"
        {...props}
      >
        {isLoading ? (
          <>
            <ReloadIcon className="mr-2 size-4 animate-spin" />
            {loadingText}
          </>
        ) : (
          children
        )}
      </Comp>
    );
  }
);
Button.displayName = 'Button';

export { Button, buttonVariants };
