import { useAppStateStore } from '@/stores/app-state';
import { getFinancialQueries } from '@repo/features/financial-data';
import { useQuery } from '@tanstack/react-query';
import { format } from 'date-fns';
import { useShallow } from 'zustand/react/shallow';

import axiosInstance from '@/lib/axios';

import StatisticCard from '../statistic-card';

export type CashBalanceMetricProps = {
  title: string;
  endDate?: Date;
  onClick?: () => void;
};

const financialQueries = getFinancialQueries(axiosInstance);

export default function CashBalanceMetric({ endDate, onClick, ...other }: CashBalanceMetricProps) {
  const accountsConnected = useAppStateStore(useShallow(state => state.accountsConnected));

  const {
    data: expectedBalanceResponse,
    isLoading,
    isError
  } = useQuery({
    queryKey: ['financialExpectedBalance', endDate],
    queryFn: () =>
      financialQueries.expectedBalance.fetcher({
        date: format(endDate || new Date(), 'yyyy-MM-dd')
      }),
    enabled: accountsConnected,
    staleTime: 1000 * 60 * 5 // Cache the result for 5 minutes
  });

  // Extract expected end-of-month balance from the API data
  const cashBalance = expectedBalanceResponse?.data?.cash_balance ?? 0;

  return (
    <div className="relative">
      {isLoading ? (
        <StatisticCard amount={'loading..'} {...other} />
      ) : isError ? (
        <StatisticCard amount={'loading..'} {...other} />
      ) : (
        <StatisticCard amount={cashBalance} {...other} />
      )}
    </div>
  );
}
