import { useEffect } from 'react';
import working2 from '@/assets/gifs/working2.gif';
import AnswerSelector from '@/sections/onboarding/questionnaire/answer-selector';
import NextStepSection from '@/sections/onboarding/questionnaire/next-step-section';
import Amplitude from '@/utils/pixels/amplitude/amplitude';
import { motion } from 'framer-motion';
import { useFormContext } from 'react-hook-form';
import { useShallow } from 'zustand/react/shallow';

import { cn } from '@/lib/utils';
import { useBoolean } from '@/hooks/use-boolean';
import { Button } from '@/components/ui/button';
import { Checkbox } from '@/components/ui/checkbox';
import { FormControl, FormField, FormItem, FormLabel, FormMessage } from '@/components/ui/form';
import MotionTextRevealContainer from '@/components/animate/motion-text-reveal';
import { useWizard } from '@/components/form-wizard';

const AnimatedButton = motion(Button);

const businessTaskVariants = [
  'Customer invoicing',
  'Collect customer payments',
  'Bills & expenses',
  'Contractors',
  'Cash flow',
  'Spend',
  'Revenue',
  'Profitability',
  'Accounting',
  'Financial statements',
  'Taxes estimates',
  'Tax planning & optimization'
].sort(() => Math.random() - 0.5);

export default function TasksStep() {
  const { control, getValues } = useFormContext<{ miloNeeds: string[] }>();
  const activeStep = useWizard(useShallow(state => state.activeStep));
  const textRevealed = useBoolean(false);
  const [goToNextStep, stepNumber] = useWizard(useShallow(state => [state.goToNextStep, state.stepNumber]));
  const bottomAnimationConfig = {
    animate: textRevealed.value ? { opacity: 1 } : false,
    initial: { opacity: 0 },
    transition: { duration: 0.4, ease: 'easeInOut' }
  };
  const questionText = 'What can I take off your plate and do for you?';
  useEffect(() => {
    Amplitude.track(`OnBoard_Questionnaire_Q${stepNumber}_View`, {
      questionText: questionText
    });
  }, [stepNumber, questionText]);
  return (
    <>
      <div className="flex flex-1 flex-col gap-8 py-4 px-12 overflow-hidden">
        <div className="flex flex-col gap-4">
          <img className="w-[5.40rem] h-[4.125rem] lg:hidden rotate-y-180" src={working2} />
          <div className="text-4xl">
            <MotionTextRevealContainer
              value={questionText}
              wrapperProps={{
                onAnimationComplete() {
                  textRevealed.onTrue();
                }
              }}
            />
          </div>
        </div>
        <div className="overflow-y-scroll scrollbar-hidden">
          {businessTaskVariants.map(task => (
            <FormItem key={task}>
              <FormField
                control={control}
                defaultValue={[]}
                key={task}
                name="miloNeeds"
                render={({ field }) => {
                  return (
                    <FormItem
                      className={cn('flex flex-row items-start space-y-5 animate-in fade-in duration-[800ms]', {
                        hidden: !textRevealed.value
                      })}
                      key={task}
                    >
                      <FormControl>
                        <Checkbox
                          checked={field.value.includes(task)}
                          className="hidden"
                          onCheckedChange={checked => {
                            checked
                              ? field.onChange([...field.value, task])
                              : field.onChange(field.value.filter(value => value !== task));
                          }}
                        />
                      </FormControl>
                      <FormLabel className="w-full">
                        <AnswerSelector selected={field.value.includes(task)}>{task}</AnswerSelector>
                      </FormLabel>
                    </FormItem>
                  );
                }}
              />
              <FormMessage />
            </FormItem>
          ))}
        </div>
        {activeStep.canSkip ? (
          <AnimatedButton
            {...bottomAnimationConfig}
            className="absolute right-9 lg:right-14 lg:top-14 top-7 text-xs font-semibold"
            onClick={() => {
              goToNextStep();
              Amplitude.track(`OnBoard_Questionnaire_Q${stepNumber}_SkipClick`);
            }}
            size="auto"
            type="button"
            variant="ghost"
          >
            Skip
          </AnimatedButton>
        ) : null}
      </div>
      <NextStepSection
        onClick={() => {
          const values = getValues();
          Amplitude.track(`OnBoard_Questionnaire_Q${stepNumber}_ButtonClick`, {
            answerText: values?.miloNeeds?.filter(t => Boolean(t)).join(', ')
          });
        }}
        startAnimation={textRevealed.value}
      >
        Next
      </NextStepSection>
    </>
  );
}
