import { useAuthStore } from '@/auth/store';
import { useAppStateStore } from '@/stores/app-state';
import amplitude from '@/utils/pixels/amplitude/amplitude';
import { FLAGS } from '@/utils/pixels/amplitude/flags';
import { getAuthQueries } from '@repo/features/auth';
import { getFinancialAccountsQueries } from '@repo/features/financial-accounts';
import { createFileRoute, getRouteApi, redirect, useRouterState, useSearch } from '@tanstack/react-router';
import { z } from 'zod';

import ActionsObserver from '@/lib/actions-observer';
import axiosInstance, { authAxiosInstance } from '@/lib/axios';
import {getAccountingDataQueries} from "@repo/features/accounting-data";
import {getFinancialQueries} from "@repo/features/financial-data";
import {subMonths} from "date-fns";


const financialAccountsApi = getFinancialAccountsQueries(axiosInstance);
const financialDataQueries = getFinancialQueries(axiosInstance);
const authQueries = getAuthQueries(authAxiosInstance);
const homeSearchSchema = z.object({
  showUpdates: z.boolean().optional(),
  showActivities: z.boolean().optional(),
  useTealData: z.boolean().optional()
});
export const Route = createFileRoute('/_guarded')({
  validateSearch: homeSearchSchema,
  loader: async ({ context: { queryClient } }) => {
    const user = await queryClient.ensureQueryData(authQueries.me.getOptions());
    const { data: accountsData } = await queryClient.ensureQueryData(financialAccountsApi.getAll.getOptions());
    const isAccountsConnected = (accountsData?.length ?? 0) > 0;
    useAppStateStore.getState().setAccountsConnected(isAccountsConnected);
    useAppStateStore.getState().activate(true);
    ActionsObserver.notify('USER_SETTLED', { user });
  },
  beforeLoad: async ({ context: { queryClient, auth } }) => {
    const user = await queryClient.ensureQueryData(authQueries.me.getOptions());
    // const stats = await queryClient.ensureQueryData(
    //   financialDataQueries.stats.getOptions({
    //     startDate: subMonths(new Date(), 6).toISOString(),
    //     endDate: new Date().toISOString()
    //   })
    // );
    // console.log('stats', stats);
    const organization = user.organizations.find(
      org => org.organization._id === useAuthStore.getState().selectedOrgId
    )?.organization;
    const { onboardingForm } = await amplitude.variant(FLAGS.ONBOARDING.name, FLAGS.ONBOARDING.defaultValue);
    const isOnboardingCompleted = !!organization?.featureFlags?.organizationSetup?.isOnboardingCompleted;
    if (!auth.accessToken) {
      throw redirect({
        to: '/auth/signup',
        replace: true
      });
    }

    if (!isOnboardingCompleted && onboardingForm && !user.roles.includes('ADMIN')) {
      console.log('redirect to onboarding');
      throw redirect({
        to: '/onboarding'
      });
    }
  }
});
