import type { ChangeEvent } from 'react';
import { useRef } from 'react';
import ChevronDownIcon from '@/icons/ChevronDown.svg?react';
import PlusIcon from '@/icons/Plus.svg?react';
import {
  mergeCategoriesWithCurrentLedger,
  mergeCustomerWithCurrentTransactionCustomers,
  mergeVendorWithCurrentTransactionVendors
} from '@/sections/library/transaction/helpers';
import LibrarySelectButton from '@/sections/library/transaction/library-select-button';
import TransactionCategoryBadge from '@/sections/library/transaction/transaction-category-badge';
import TransactionRow from '@/sections/library/transaction/transaction-row';
import useTransactionActions from '@/sections/library/transaction/use-transaction-actions';
import type { ICustomer } from '@repo/features/customers';
import { getCustomerQueries } from '@repo/features/customers';
import { getTransactionsQueries } from '@repo/features/transactions';
import type { IVendor } from '@repo/features/vendors';
import { getVendorQueries } from '@repo/features/vendors';
import { Outlet, useNavigate } from '@tanstack/react-router';

import axiosInstance from '@/lib/axios';
import { cn } from '@/lib/utils';
import { useBreakpoint } from '@/hooks/use-breakpoint';
import { Button } from '@/components/ui/button';
import { Collapsible, CollapsibleContent, CollapsibleTrigger } from '@/components/ui/collapsible';
import { Drawer, DrawerContent, DrawerDescription, DrawerHeader, DrawerTitle } from '@/components/ui/drawer';
import FilePreview from '@/components/file-preview';
import { RadioSelector } from '@/components/radio-selector';

const transactionsQueries = getTransactionsQueries(axiosInstance);
const vendorQueries = getVendorQueries(axiosInstance);
const customerQueries = getCustomerQueries(axiosInstance);

export default function TransactionDetailsView({ id }: { id: string }) {
  const navigate = useNavigate();
  const fileRef = useRef<HTMLInputElement>(null);
  const { isBelowLg } = useBreakpoint('lg');
  const { data: transaction } = transactionsQueries.byId.useSuspenseQuery({
    variables: {
      id
    }
  });

  const { uploadTransactionFile, updateTransaction } = useTransactionActions();

  const onFileChange = async (e: ChangeEvent<HTMLInputElement>) => {
    if (!e.target.files) return;
    const fArray = Array.from(e.target.files);
    await uploadTransactionFile({
      transactionId: id,
      file: fArray[0]
    });
    // setFiles(prev => [...prev, ...fArray]);
    e.target.value = '';
  };

  const provider = transaction?.vendor
    ? (transaction.vendor as IVendor | undefined)?.name || 'Unknown'
    : (transaction.customer as ICustomer | undefined)?.name || 'Unknown';
  const tagVariant =
    transaction?.suggestedAccountingLedger && transaction?.suggestedAccountingLedger?.score < 1
      ? 'uncertain'
      : 'default';

  const { data: vendors = [] } = vendorQueries.paginated.useSuspenseQuery({
    variables: {
      limit: 5
    },
    select: _data => mergeVendorWithCurrentTransactionVendors(_data.records, transaction.vendor as IVendor)
  });

  const { data: customers = [] } = customerQueries.paginated.useSuspenseQuery({
    variables: {
      limit: 5
    },
    select: _data => mergeCustomerWithCurrentTransactionCustomers(_data.records, transaction.customer as ICustomer)
  });

  const { data: categories = [] } = transactionsQueries.topCategories.useSuspenseQuery({
    variables: {
      limit: 5
    },
    select: _data => mergeCategoriesWithCurrentLedger(_data, transaction.suggestedAccountingLedger)
  });

  const renderCategorySection = (
    <Collapsible>
      <CollapsibleTrigger className="py-8 px-10 flex w-full justify-between items-center gap-6 ">
        <div className="text-2xs font-medium">&milo Category:</div>
        <div className="flex items-center gap-2">
          <TransactionCategoryBadge className="bg-transparent text-2xs font-semibold normal-case" variant={tagVariant}>
            {transaction?.suggestedAccountingLedger?.name || 'Uncategorized'}
          </TransactionCategoryBadge>
          <div className="[&[data-state=open]>svg]:rotate-180">
            <ChevronDownIcon />
          </div>
        </div>
      </CollapsibleTrigger>
      <CollapsibleContent className="flex gap-4 px-10 data-[state=open]:pb-8 overflow-y-scroll lg:overflow-y-auto lg:flex-wrap scrollbar-hidden">
        {categories.map(category => (
          <LibrarySelectButton
            key={category.name}
            onClick={async () => {
              if (transaction?.suggestedAccountingLedger?.name !== category.name) {
                await updateTransaction({
                  id,
                  data: {
                    suggestedAccountingLedger: {
                      name: category.name,
                      score: 1
                    }
                  }
                });
              }
            }}
            selected={transaction?.suggestedAccountingLedger?.name === category.name}
            size="sm"
          >
            {category.name}
          </LibrarySelectButton>
        ))}
        <LibrarySelectButton
          onClick={() =>
            navigate({
              to: '/library/transactions/$id/category',
              params: {
                id
              }
            })
          }
          size="sm"
          variant="add-new"
        >
          <PlusIcon className="size-3" /> ADD NEW
        </LibrarySelectButton>
      </CollapsibleContent>
    </Collapsible>
  );

  const renderVendorSection = (
    <Collapsible>
      <CollapsibleTrigger className="py-8 px-10 flex w-full justify-between items-center gap-6 ">
        <div className="text-2xs font-medium">{transaction.amount < 0 ? 'Vendor / Contractor:' : 'Customer:'}</div>
        <div className="flex items-center gap-2">
          <div className="text-2xs font-medium">{provider}</div>
          <div className="[&[data-state=open]>svg]:rotate-180">
            <ChevronDownIcon />
          </div>
        </div>
      </CollapsibleTrigger>
      <CollapsibleContent className="flex gap-4 px-10 data-[state=open]:pb-8 overflow-y-scroll lg:overflow-y-auto lg:flex-wrap scrollbar-hidden">
        {transaction.amount < 0
          ? vendors.map(vendor => (
              <LibrarySelectButton
                key={vendor._id}
                onClick={async () => {
                  if ((transaction?.vendor as IVendor | undefined)?._id !== vendor._id) {
                    await updateTransaction({
                      id,
                      data: {
                        vendor: vendor._id
                      },
                      context: {
                        vendor
                      }
                    });
                  }
                }}
                selected={vendor._id === (transaction.vendor as IVendor | undefined)?._id}
                size="sm"
              >
                {vendor.name}
              </LibrarySelectButton>
            ))
          : customers.map(customer => (
              <LibrarySelectButton
                key={customer._id}
                onClick={async () => {
                  if ((transaction?.customer as ICustomer | undefined)?._id !== customer._id) {
                    await updateTransaction({
                      id,
                      data: {
                        customer: customer._id
                      },
                      context: {
                        customer
                      }
                    });
                  }
                }}
                selected={customer._id === (transaction.customer as ICustomer | undefined)?._id}
                size="sm"
              >
                {customer.name}
              </LibrarySelectButton>
            ))}
        {transaction.amount < 0 ? (
          <LibrarySelectButton
            onClick={() =>
              navigate({
                to: '/library/transactions/$id/vendor',
                params: { id }
              })
            }
            size="sm"
            variant="add-new"
          >
            <PlusIcon className="size-3" />
            ADD NEW
          </LibrarySelectButton>
        ) : (
          <LibrarySelectButton
            onClick={() =>
              navigate({
                to: '/library/transactions/$id/customer',
                params: { id }
              })
            }
            size="sm"
            variant="add-new"
          >
            <PlusIcon className="size-3" />
            ADD NEW
          </LibrarySelectButton>
        )}
      </CollapsibleContent>
    </Collapsible>
  );

  const renderDocumentsSection = (
    <div className="py-8 px-10 flex flex-col gap-6">
      <div className="text-2xs font-medium">Documents:</div>
      <div>
        {transaction.fileIds?.map(fileId => <FilePreview className="w-full" key={fileId} signedUrl={fileId} />)}
      </div>
      <input className="hidden" multiple onChange={onFileChange} ref={fileRef} type="file" />
      <div className="flex justify-center">
        <Button onClick={() => fileRef.current?.click()} variant="outline-black">
          Upload Document
        </Button>
      </div>
    </div>
  );

  const renderSections = (
    <>
      {renderCategorySection}
      {renderVendorSection}
      <div className="py-8 px-10 flex justify-between items-center gap-6">
        <div className="text-2xs font-medium">Document Match:</div>
        <div className="text-2xs font-medium text-neutral-500">No Match Found</div>
      </div>
      {renderDocumentsSection}
    </>
  );

  return (
    <>
      <Drawer direction={isBelowLg ? 'bottom' : 'right'} onClose={() => navigate({ to: '..' })} open>
        <DrawerContent
          className={cn(
            'h-full w-full lg:w-[28.125rem] lg:rounded-none lg:rounded-tl-lg lg:rounded-bl-lg lg:right-0 lg:left-auto',
            {
              'bg-fun-red': transaction && transaction.amount < 0,
              'bg-fun-green': transaction && transaction.amount > 0
            }
          )}
          hideHandle={!isBelowLg}
        >
          <DrawerHeader className="p-8 pt-6 text-left">
            <DrawerTitle className="hidden">Transaction Details</DrawerTitle>
            <DrawerDescription className="hidden">Your transaction details.</DrawerDescription>
            <div>
              {transaction ? <TransactionRow className="bg-background" hideChevron transaction={transaction} /> : null}
            </div>
          </DrawerHeader>
          <div className="flex h-full flex-col flex-1 bg-background divide-y overflow-y-auto pb-10">
            <div className="py-8 px-10 flex flex-col gap-6">
              <div className="text-2xs leading-[150%] font-medium">
                {transaction.amount > 0 ? 'Income Type:' : 'Expense Type:'}
              </div>
              <RadioSelector
                onChange={async value => {
                  await updateTransaction({
                    id,
                    data: {
                      personal: value === 'personal'
                    }
                  });
                }}
                options={[
                  { value: 'personal', label: 'Personal' },
                  {
                    value: 'business',
                    label: 'Business'
                  }
                ]}
                size="sm"
                value={transaction.personal ? 'personal' : 'business'}
              />
            </div>
            {!transaction.personal && renderSections}
          </div>
        </DrawerContent>
      </Drawer>
      <Outlet />
    </>
  );
}
