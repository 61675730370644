import DotsIcon from '@/icons/3Dots.svg?react';
import PlusIcon from '@/icons/Plus.svg?react';
import UploadIcon from '@/icons/Upload.svg?react';

import { Button } from '@/components/ui/button';
import MenuRow from '@/components/menu-row';
import ResponsiveActionsMenu from '@/components/responsive-actions-menu';

export type InvoiceFiltersProps = {};

export default function InvoiceFilters({}: InvoiceFiltersProps) {
  return (
    <div className="px-8 py-6 lg:p-10 lg:h-34 flex flex-row items-center justify-between">
      <div className="text-base font-semibold text-white">All Invoices</div>
      <div>
        <ResponsiveActionsMenu
          dropdownTrigger={
            <Button size="icon-xs" variant="icon-bordered">
              <DotsIcon className="size-4 rotate-90 fill-white" />
            </Button>
          }
        >
          <MenuRow disabled icon={<PlusIcon className="size-[0.875rem]" />}>
            Create a New Invoice
          </MenuRow>
          <MenuRow disabled icon={<UploadIcon className="size-[0.875rem]" />}>
            Import Your Invoices
          </MenuRow>
        </ResponsiveActionsMenu>
      </div>
    </div>
  );
}
