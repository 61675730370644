import VendorContactsView from '@/sections/library/vendor/views/vendor-contacts-view'
import { createFileRoute } from '@tanstack/react-router'

export const Route = createFileRoute(
  '/_guarded/_main/library/vendors_/$id/contacts',
)({
  component: ContactsPage,
  wrapInSuspense: true,
})

function ContactsPage() {
  const { id } = Route.useParams()
  return <VendorContactsView id={id} />
}
