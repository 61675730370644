import { useMemo } from 'react';
import { format } from 'date-fns';
import { Bar, BarChart as RechartsBarChart, Text, XAxis } from 'recharts';

import { cn } from '@/lib/utils';
import { ChartContainer, ChartTooltip, ChartTooltipContent, type ChartConfig } from '@/components/ui/chart';

import type { WidgetComponentProps } from '../types';

type DataItem<K extends string> = {
  name: string;
  data: Record<K, number>;
};

type Config<K extends string> = Record<K, ChartConfig[string]>;

export function BarChart<K extends string>({
  className,
  data,
  config = {} as Config<K>
}: WidgetComponentProps & {
  className?: string;
  data: DataItem<K>[];
  config?: Config<K>;
}) {
  const bars = useMemo(() => (data.length > 0 ? Object.keys(data[0].data) : []), [data]);

  return (
    <ChartContainer className={cn('min-h-[200px] w-full', className)} config={config}>
      <RechartsBarChart barGap={3} barSize={15} data={data}>
        <XAxis
          axisLine={false}
          dataKey="name"
          tickFormatter={value => format(value, 'MMM d')}
          tickLine={false}
          tickMargin={9}
        />

        <ChartTooltip content={<ChartTooltipContent />} />
        {bars.map((bar, i) => (
          <Bar
            dataKey={`data.${bar}`}
            fill={`var(--color-${bar})`}
            key={`bar_${bar}`}
            markerWidth={10}
            name={bar}
            radius={[10, 10, 0, 0]}
          />
        ))}
      </RechartsBarChart>
    </ChartContainer>
  );
}
