import { useAuth } from '@/auth/hooks';
import { useAppStateStore } from '@/stores/app-state';
import { useShallow } from 'zustand/react/shallow';

import { cn } from '@/lib/utils';
import { useBreakpoint } from '@/hooks/use-breakpoint';
import MotionTextRevealContainer from '@/components/animate/motion-text-reveal';
import ConnectAccountButton from '@/components/connect-account-button';

export type WelcomeCardProps = {
  className?: string;
};

const getIntroText = ({ accountsConnected }: { accountsConnected: boolean }): string => {
  if (!accountsConnected) {
    return `You're missing the full story. Let &milo read between the lines. Connect your accounts to automate your bookkeeping, maximize profits, and nail your taxes.`;
  }
  return "Here's a snapshot of your financials. There are some interesting insights we should discuss. Shall we dive in and see what's happening with your business?";
};

export default function WelcomeCard({ className }: WelcomeCardProps) {
  const { user } = useAuth();
  const { isBelowLg } = useBreakpoint('lg');
  const [isActive, financialState, accountsConnected] = useAppStateStore(
    useShallow(state => [state.isActive, state.financialState, state.accountsConnected])
  );

  const getGreetingText = (): string => {
    if (!accountsConnected) return `Connect Your Bank Account`;
    return `Your Financial Story`;
  };

  const introText = getIntroText({
    accountsConnected
  });
  return (
    <div
      className={cn(
        'bg-primary lg:w-[36.875rem] lg:h-[44.125rem] p-10 lg:p-16 lg:rounded-xl relative shrink-0', // added relative here
        className,
        {
          hidden: isBelowLg && accountsConnected
        }
      )}
    >
      <div className="flex flex-col justify-center items-center lg:items-start gap-6 lg:gap-10">
        <div>
          <div className="font-bold lg:font-semibold text-md lg:text-5xl lg:text-fun-green text-white text-center lg:text-left">
            {user?.firstName ? (
              <MotionTextRevealContainer
                className="font-bold"
                transition={{ duration: 0.5 }}
                value={getGreetingText()}
              />
            ) : null}
          </div>

          <div className={cn('text-sm lg:text-md text-white lg:mt-4 text-center lg:text-left')}>
            <MotionTextRevealContainer value={introText} />
          </div>
        </div>
        {!accountsConnected && (
          <div>
            <ConnectAccountButton size="md" variant="secondary">
              Securely Connect Now
            </ConnectAccountButton>
          </div>
        )}
        {!accountsConnected && (
          <div className="text-4xs text-white opacity-75 text-center lg:text-3xs">
            Connection is secured with Plaid, we do not store your passwords & account information.
          </div>
        )}

        {/* GIF pinned to the bottom */}
        <div className="absolute -bottom-1 -right-4 lg:right-0 flex justify-center ">
          <img alt="milo" className="h-24" src="/assets/illustrations/worried-dog.gif" />
        </div>
      </div>
    </div>
  );
}
