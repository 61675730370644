import PaidIcon from '@/icons/Paid.svg?react';
import ReceivedIcon from '@/icons/Received.svg?react';

export type TransactionTypeProps = {
  type: 'paid' | 'received';
};

export default function TransactionType({ type }: TransactionTypeProps) {
  return <div>{type === 'received' ? <ReceivedIcon /> : <PaidIcon />}</div>;
}
