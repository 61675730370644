import React, { useMemo } from 'react';
import BackIcon from '@/icons/Back.svg?react';
import AllDoneStep from '@/sections/widgets/tax-deduction-wizard/steps/all-done-step';
import CategorizationStep from '@/sections/widgets/tax-deduction-wizard/steps/categorization-step';
import type { ITransaction } from '@repo/features/transactions';
import { usePrevious } from '@uidotdev/usehooks';
import { AnimatePresence, motion } from 'framer-motion';
import { useShallow } from 'zustand/react/shallow';

import { Button } from '@/components/ui/button';
import { Progress } from '@/components/ui/progress';
import type { StepConfig } from '@/components/form-wizard';
import { useWizard, Wizard } from '@/components/form-wizard';
import { WizardProvider } from '@/components/form-wizard/wizard/context';

const isCategorizationStep = (id: string) => id.startsWith('categorization_');
function Wrapper() {
  const { activeStep, stepNumber } = useWizard(
    useShallow(state => ({
      activeStep: state.activeStep,
      stepNumber: state.stepNumber
    }))
  );

  const prevStepNumber = usePrevious(stepNumber) ?? stepNumber;
  const direction = stepNumber > prevStepNumber ? 1 : -1;

  return (
    <AnimatePresence custom={direction} initial={false} mode="popLayout">
      <motion.div
        animate="animate"
        className="h-full"
        custom={direction}
        exit="exit"
        initial="initial"
        key={activeStep.id}
        transition={{
          type: 'just',
          duration: 0.3
        }}
        variants={{
          initial: dir => {
            return {
              x: `${100 * dir}%`,
              opacity: 0
            };
          },
          animate: {
            x: 0,
            opacity: 1
          },
          exit: dir => ({
            x: `${100 * -dir}%`,
            opacity: 0
          })
        }}
      >
        {activeStep.component}
      </motion.div>
    </AnimatePresence>
  );
}

function Header() {
  const [goToPreviousStep, activeStep, progress] = useWizard(
    useShallow(state => [state.goToPreviousStep, state.activeStep, state.progress])
  );
  if (isCategorizationStep(activeStep.id)) {
    return (
      <div className="p-7 flex flex-col gap-2">
        <div className="text-6xs">{activeStep.id}</div>
        <div className="text-2xs font-semibold">What Expense Category is This?</div>
      </div>
    );
  }
  switch (activeStep.id) {
    case 'personal_business':
      return (
        <div className="p-7 flex flex-col gap-2">
          <div className="text-6xs">{activeStep.id}</div>
          <div className="text-2xs font-semibold">Please Select - Personal or Business?</div>
        </div>
      );
    case 'all_done':
      return (
        <div className="p-7 flex flex-col gap-2">
          <div className="text-6xs">{activeStep.id}</div>
          <div className="text-2xs font-semibold">You’re All Done!</div>
        </div>
      );
    default:
      return <div />;
  }
}

function Footer() {
  const [goToPreviousStep, goToNextStep, goToStep, activeStep, progress] = useWizard(
    useShallow(state => [state.goToPreviousStep, state.goToNextStep, state.goToStep, state.activeStep, state.progress])
  );
  const defaultFooter = (
    <div className="px-8 py-6 flex flex-col gap-5">
      <div>
        <Progress value={progress} />
      </div>
      <div className="flex justify-between">
        {!activeStep.hidePrevious ? (
          <Button onClick={goToPreviousStep} size="auto" variant="icon">
            <BackIcon className="size-5" />
          </Button>
        ) : (
          <div />
        )}
        <div>{progress}%</div>
      </div>
    </div>
  );
  if (isCategorizationStep(activeStep.id)) {
    return defaultFooter;
  }
  switch (activeStep.id) {
    case 'all_done':
      return (
        <div className="px-8 py-6 flex flex-col gap-5">
          <Button onClick={goToNextStep}>Great!</Button>
        </div>
      );
    default:
      return defaultFooter;
  }
}
const TaxDeductionWizardContent: React.FC<{ transactions: ITransaction[] }> = ({ transactions }) => {
  const steps = useMemo(
    () =>
      [
        ...transactions.map(transaction => ({
          id: `categorization_${transaction._id}`,
          component: <CategorizationStep transaction={transaction} />,
          disableNextOnErrors: true
        })),
        {
          id: 'all_done',
          component: <AllDoneStep />,
          disableNextOnErrors: true,
          hidePrevious: true,
          ignoreProgress: true
        }
      ] satisfies StepConfig[],
    [transactions]
  );

  async function onSubmit(values: any) {}

  return (
    <Wizard
      className="bg-neutral-700 rounded-xl min-h-[24rem] relative overflow-hidden grid grid-rows-[auto_1fr_auto] grid-cols-1 w-full"
      footer={<Footer />}
      header={<Header />}
      // onCompleted={async () => {}}
      steps={steps}
      wrapper={<Wrapper />}
    />
  );
};

function TaxDeductionWizard({ transactions }: { transactions: ITransaction[] }) {
  return (
    <WizardProvider>
      <TaxDeductionWizardContent transactions={transactions} />
    </WizardProvider>
  );
}

export default TaxDeductionWizard;
