import { HeaderPortal } from '@/layouts/common/header-portal';
import BillRow from '@/sections/library/bill/bill-row';
import LibraryStatsCard from '@/sections/library/library-stats-card';
import VendorDetailsHeader from '@/sections/library/vendor/vendor-details-header';
import { fCurrency } from '@/utils/format-number';
import { getVendorQueries } from '@repo/features/vendors';
import { Outlet, useNavigate } from '@tanstack/react-router';

import axiosInstance from '@/lib/axios';
import { useBreakpoint } from '@/hooks/use-breakpoint';

const vendorQueries = getVendorQueries(axiosInstance);

export default function VendorDetailsView({ id }: { id: string }) {
  const { isBelowLg } = useBreakpoint('lg');
  const navigate = useNavigate();
  const { data: vendor } = vendorQueries.byId.useSuspenseQuery({
    variables: {
      id,
      expand: ['bills.aggs', 'transactions.aggs']
    }
  });
  return (
    <>
      <HeaderPortal position="replace">
        <VendorDetailsHeader vendor={vendor} />
      </HeaderPortal>
      <div className="h-full space-y-3">
        <div className="flex flex-col lg:flex-row gap-4 lg:gap-8 p-8">
          <LibraryStatsCard amount={fCurrency(vendor?.transactions?.aggs.total || 0)} title="Total Spend" />
          <LibraryStatsCard amount={fCurrency(vendor?.bills?.aggs.overdue || 0)} title="Overdue" />
          <LibraryStatsCard amount={fCurrency(vendor?.bills?.aggs.due || 0)} title="Open Bills (Not Due Yet)" />
        </div>
        <div>
          <div className="px-8 lg:px-7 lg:py-0 py-5 text-xs font-bold">Bills:</div>
          <div className="divide-y  lg:p-7">
            {vendor?.bills?.records.map(bill => (
              <BillRow
                bill={{ ...bill, vendor }}
                className="rounded-none"
                key={bill._id}
                mode={isBelowLg ? 'list-item' : 'table'}
                onClick={() =>
                  navigate({ to: '/library/vendors/$id/bills/$billId', params: { id: vendor._id!, billId: bill._id } })
                }
              />
            ))}
          </div>
        </div>
      </div>
      <Outlet />
    </>
  );
}
