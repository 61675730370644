import type { ICustomer } from '@repo/features/customers';
import { useNavigate } from '@tanstack/react-router';

import { Button } from '@/components/ui/button';
import {
  Drawer,
  DrawerContent,
  DrawerDescription,
  DrawerFooter,
  DrawerHeader,
  DrawerTitle
} from '@/components/ui/drawer';

export type CustomerDocumentsProps = {
  id: string;
};

export default function CustomerDocumentsView({ id }: CustomerDocumentsProps) {
  const navigate = useNavigate();
  return (
    <Drawer onClose={() => navigate({ to: '..' })} open>
      <DrawerContent className="flex flex-col">
        <DrawerHeader className="hidden">
          <DrawerTitle>Customer Documents</DrawerTitle>
          <DrawerDescription>List of Documents</DrawerDescription>
        </DrawerHeader>
        <div className="flex flex-col justify-center flex-1 p-8 gap-8">
          <div className="text-xs font-bold">Documents:</div>
        </div>
      </DrawerContent>
    </Drawer>
  );
}
