import { getFinancialQueries } from '@repo/features/financial-data';
import { format } from 'date-fns';

import axiosInstance from '@/lib/axios';

import type { WidgetProps } from '../types';
import WidgetErrorFallback from '../widget-error-fallback';
import { WidgetFactory } from '../widget-factory';
import MetricWidgetSkeleton from './metric-widget-skeleton';
import { SimpleMetric } from './simple-metric';
import {fCurrency} from "@/utils/format-number";

const financialQueries = getFinancialQueries(axiosInstance);

export const CurrentBalanceSimpleMetricWidget = WidgetFactory.create(
  ({ className }: WidgetProps) => {
    const { data: balance } = financialQueries.expectedBalance.useSuspenseQuery({
      variables: {
        date: format(new Date(), 'yyyy-MM-dd')
      },

      select(data) {
        return data.data.cash_balance;
      }
    });

    return <SimpleMetric className={className} metric={fCurrency(balance)} />;
  },
  {
    FallbackComponent: WidgetErrorFallback,
    SuspenseFallbackComponent: MetricWidgetSkeleton
  }
);
