import { HeaderPortal } from '@/layouts/common/header-portal'
import MiloControlCenterAppHeader from '@/sections/milo/center/milo-control-center-app-header'
import { createFileRoute } from '@tanstack/react-router'

export const Route = createFileRoute('/_guarded/_main/milo_/center')({
  component: RouteComponent,
})

function RouteComponent() {
  return (
    <HeaderPortal position="replace">
      <MiloControlCenterAppHeader />
    </HeaderPortal>
  )
}
