import { mergeCategoriesWithCurrentLedger } from '@/sections/library/transaction/helpers';
import LibrarySelectButton from '@/sections/library/transaction/library-select-button';
import { fCurrency } from '@/utils/format-number';
import { fDateTime } from '@/utils/format-time';
import type { ICustomer } from '@repo/features/customers';
import type { FinancialAccount } from '@repo/features/financial-accounts';
import type { ITransaction } from '@repo/features/transactions';
import { getTransactionsQueries } from '@repo/features/transactions';
import type { IVendor } from '@repo/features/vendors';
import type { VariantProps } from 'class-variance-authority';
import { cva } from 'class-variance-authority';

import axiosInstance from '@/lib/axios';
import { cn } from '@/lib/utils';

export type TransactionCardProps = {
  transaction: ITransaction;
  hideTag?: boolean;
  actions?: React.ReactNode;
  className?: string;
} & VariantProps<typeof transactionVariants>;
const transactionVariants = cva('flex flex-col justify-between w-full bg-white rounded-xl', {
  variants: {
    variant: {
      default: 'bg-fun-orange'
    }
  },
  defaultVariants: {
    variant: 'default'
  }
});

export default function TransactionCard({ transaction, actions = null, className, variant }: TransactionCardProps) {
  const amount = transaction.amount > 0 ? `+${fCurrency(transaction.amount)}` : fCurrency(transaction.amount);
  const provider = transaction?.vendor
    ? (transaction.vendor as IVendor).name
    : transaction.customer
      ? (transaction.customer as ICustomer).name
      : 'Unknown';
  const renderAmount = <div className="text-md font-bold">{amount}</div>;
  const renderProvider = <div className="text-md font-bold text-ellipsis">{provider}</div>;
  const renderAccountName = (
    <div className="text-4xs font-medium">{(transaction.financialAccount as FinancialAccount).name}</div>
  );
  const renderTransactionTime = <div className="text-4xs text-ellipsis text-black">{fDateTime(transaction.time)}</div>;

  return (
    <div className={cn(transactionVariants({ variant }), className)}>
      <div className="flex justify-between w-full gap-6 p-10">
        <div className="flex items-center gap-4">
          <div>
            {renderProvider}
            {renderAccountName}
            {renderTransactionTime}
          </div>
        </div>
        <div className="flex items-center justify-center gap-4">
          <div className="flex flex-col gap-2 items-end">{renderAmount}</div>
        </div>
      </div>
      {actions}
    </div>
  );
}
