import ChartWidgetEmptyState from '@/sections/widgets/charts/chart-empty-state';
import { TableChart } from '@/sections/widgets/charts/table-chart';
import { WidgetProps } from '@/sections/widgets/types';
import { WidgetFactory } from '@/sections/widgets/widget-factory';
import { ChevronRightIcon } from '@radix-ui/react-icons';
import { getFinancialQueries } from '@repo/features/financial-data';
import { useNavigate } from '@tanstack/react-router';

import axiosInstance from '@/lib/axios';
import { cn } from '@/lib/utils';
import { Button } from '@/components/ui/button';

const financialQueries = getFinancialQueries(axiosInstance);

export const RevenueByCustomerChart = WidgetFactory.create(({ className }: WidgetProps) => {
  const navigate = useNavigate();
  const { data: revenue } = financialQueries.topRevenue.useSuspenseQuery({
    select(data) {
      return data.data.slice(0, 5).map(d => ({
        name: d.customer,
        value: d.revenue
      }));
    }
  });

  if (!revenue.length) {
    return <ChartWidgetEmptyState />;
  }

  return (
    <div className={cn('flex flex-col gap-4', className)}>
      <TableChart data={revenue} />
      <Button
        asChild
        variant={'ghost'}
        onClick={() => {
          navigate({
            to: '/library/customers'
          });
        }}
      >
        <div className="flex justify-center items-center mx-auto w-fit cursor-pointer">
          <div>View All Customers</div>
          <ChevronRightIcon />
        </div>
      </Button>
    </div>
  );
});
