import { Fragment } from 'react';
import InvoiceRow from '@/sections/library/invoice/invoice-row';
import LibraryLoadMore from '@/sections/library/library-load-more';
import LibraryTableHead from '@/sections/library/library-table-head';
import LibraryTableHeader from '@/sections/library/library-table-header';
import { getInvoiceQueries } from '@repo/features/invoices';
import { createFileRoute, Outlet } from '@tanstack/react-router';

import axiosInstance from '@/lib/axios';
import { useBreakpoint } from '@/hooks/use-breakpoint';

export const Route = createFileRoute('/_guarded/_main/library/invoices')({
  component: () => <InvoicesPage />
});

const invoiceQueries = getInvoiceQueries(axiosInstance);
function InvoicesPage() {
  const {
    data: paginated,
    hasNextPage,
    fetchNextPage
  } = invoiceQueries.infinityPaginated.useInfiniteQuery({
    variables: {
      expand: ['customer']
    }
  });
  const navigate = Route.useNavigate();
  const { isBelowLg } = useBreakpoint('lg');
  return (
    <>
      <div className="divide-y overflow-y-auto max-h-full">
        <LibraryTableHeader className="grid-cols-[1fr_1fr_1fr_11.5rem_11.5rem_11.5rem_6px] hidden lg:grid">
          <LibraryTableHead>Amount</LibraryTableHead>
          <LibraryTableHead>Customer</LibraryTableHead>
          <LibraryTableHead>Invoice ID</LibraryTableHead>
          <LibraryTableHead>Issue Date</LibraryTableHead>
          <LibraryTableHead>Due Date</LibraryTableHead>
          <LibraryTableHead>Status</LibraryTableHead>
          <div />
        </LibraryTableHeader>
        {paginated?.pages.map((page, i) => (
          <Fragment key={i}>
            {page.records.map(record => (
              <InvoiceRow
                className="rounded-none"
                invoice={record}
                key={record._id}
                mode={isBelowLg ? 'list-item' : 'table'}
                onClick={() =>
                  navigate({
                    to: '/library/invoices/$id',
                    params: { id: record._id }
                  })
                }
              />
            ))}
          </Fragment>
        ))}
        {hasNextPage ? (
          <LibraryLoadMore
            onClick={async () => {
              await fetchNextPage();
            }}
          />
        ) : null}
      </div>
      <Outlet />
    </>
  );
}
