import { forwardRef } from 'react';
import { PATH_AFTER_LOGIN } from '@/config-global.ts';
import { Link } from '@tanstack/react-router';

import { cn } from '@/lib/utils';
import BetaIndicator from '@/components/beta-indicator';

// ----------------------------------------------------------------------

export interface LogoProps extends React.HTMLAttributes<HTMLDivElement> {
  disabledLink?: boolean;
  fill?: string;
  beta?: boolean;
}

function BetaWrapper({
  children,
  beta,
  className
}: {
  children?: React.ReactNode;
  beta?: boolean;
  className?: string;
}) {
  if (beta)
    return (
      <div className={cn('relative flex flex-col', className)}>
        <BetaIndicator className="absolute -left-3.5 -top-2.5 self-start" />
        {children}
      </div>
    );
  return children;
}

const Logo = forwardRef<HTMLDivElement, LogoProps>(
  ({ disabledLink = false, beta, fill = 'black', className, ...other }, ref) => {
    const logo = (
      <div className={cn('inline-flex', className)} {...other}>
        <svg className="h-6" fill={fill} viewBox="0 0 63 18" xmlns="http://www.w3.org/2000/svg">
          <path d="M7.90587 17.8127L2.4655 11.8123C2.04598 11.3366 1.73984 10.8834 1.5471 10.4528C1.36318 10.0055 1.27073 9.52582 1.2752 9.04218C1.2752 8.42989 1.4339 7.86312 1.75132 7.34187C2.07132 6.80741 2.52173 6.36292 3.06035 6.05001C3.61603 5.7215 4.26214 5.55725 4.99868 5.55725C5.57691 5.55725 6.08687 5.64235 6.52856 5.81254C6.98212 5.97085 7.36761 6.17468 7.68502 6.42403C8.00204 6.67338 8.2401 6.92847 8.3992 7.18929L6.4775 9.07602C6.2622 8.78116 6.04136 8.57139 5.81497 8.44672C5.58547 8.3221 5.3281 8.25776 5.06695 8.2597C4.7721 8.2597 4.5283 8.3448 4.33555 8.51499C4.14281 8.68518 4.04644 8.88901 4.04644 9.12648C4.04644 9.31923 4.09195 9.5007 4.18298 9.67089C4.27322 9.82921 4.39789 10.0047 4.55699 10.1975L11.3569 17.8127H7.90587ZM4.04703 17.9997C3.23054 17.9997 2.51616 17.8354 1.90389 17.5069C1.3035 17.1784 0.833311 16.7365 0.493337 16.1812C0.164446 15.6145 0 14.9856 0 14.2945C0 13.5346 0.192744 12.8604 0.578232 12.2718C0.975197 11.671 1.58153 11.1836 2.39723 10.8096L3.63799 12.7812C3.33206 12.8944 3.10547 13.0701 2.95824 13.3084C2.81033 13.5357 2.73345 13.8019 2.7374 14.0731C2.7374 14.2999 2.78846 14.5096 2.89057 14.7024C3.00376 14.8947 3.16247 15.0477 3.36669 15.1613C3.57051 15.2745 3.81411 15.3311 4.09749 15.3311C4.36436 15.3352 4.62783 15.2708 4.86273 15.1441C5.1002 15.0083 5.30996 14.7986 5.49202 14.5148L7.53186 16.5214C7.35059 16.7478 7.08423 16.9744 6.73278 17.2012C6.39281 17.4276 5.9905 17.6201 5.52585 17.7788C5.04748 17.9302 4.54874 18.0047 4.04703 17.9997Z" />
          <path d="M13.6406 17.9998V5.97467H17.9204V17.9998H13.6406ZM21.1915 17.9998V11.0406C21.1915 10.5324 21.0357 10.139 20.7243 9.86034C20.4124 9.56508 20.0271 9.41745 19.5684 9.41745C19.2399 9.41745 18.953 9.48295 18.7076 9.61396C18.4614 9.74536 18.2645 9.93395 18.1169 10.1797C17.9863 10.4093 17.9208 10.6962 17.9204 11.0406L16.2474 10.3768C16.2474 9.40913 16.4607 8.58114 16.8874 7.89286C17.3046 7.21296 17.9 6.66025 18.609 6.29466C19.3305 5.91748 20.1504 5.72888 21.0686 5.72888C21.8879 5.72888 22.6258 5.92559 23.2824 6.319C23.9526 6.69402 24.5058 7.24743 24.8805 7.9178C25.2743 8.60647 25.4712 9.41784 25.4712 10.3519V17.9998H21.1915ZM28.7423 17.9998V11.0406C28.7423 10.5324 28.5866 10.139 28.2751 9.86034C27.9632 9.56508 27.578 9.41745 27.1192 9.41745C26.8074 9.41745 26.5204 9.48295 26.2584 9.61396C26.0123 9.74536 25.8154 9.93395 25.6677 10.1797C25.5371 10.4093 25.4716 10.6962 25.4712 11.0406L22.9624 10.9177C22.9952 9.8352 23.2329 8.90905 23.6754 8.13924C24.1 7.38682 24.7318 6.77215 25.4956 6.36828C26.2828 5.94202 27.1683 5.72888 28.1522 5.72888C29.0867 5.72888 29.9229 5.92559 30.6611 6.319C31.3988 6.71282 31.9727 7.28671 32.3827 8.04069C32.809 8.77805 33.0221 9.66323 33.0221 10.6962V17.9998H28.7423Z" />
          <path d="M35.4044 17.9999V5.97544H39.7085V17.9999H35.4044ZM37.544 4.57375C36.9044 4.57375 36.3715 4.36062 35.9452 3.93435C35.519 3.49225 35.3058 2.94309 35.3058 2.28687C35.3058 1.64767 35.519 1.10663 35.9452 0.66374C36.3715 0.220851 36.9044 -0.00039526 37.544 5.30076e-07C38.216 5.30076e-07 38.757 0.221247 39.1671 0.66374C39.5937 1.10663 39.807 1.64767 39.807 2.28687C39.807 2.94309 39.5937 3.49225 39.1671 3.93435C38.7574 4.36062 38.2164 4.57375 37.544 4.57375Z" />
          <path d="M42.0908 17.9999V0.0736084H46.3706V17.9999H42.0908Z" />
          <path d="M55.3936 17.9997C54.0982 17.9997 52.9422 17.7292 51.9254 17.1881C50.9257 16.6305 50.1306 15.8765 49.5401 14.9262C48.9496 13.9589 48.6543 12.8768 48.6543 11.6799C48.6543 10.4831 48.9412 9.41759 49.5151 8.48352C50.1056 7.53244 50.909 6.78638 51.9254 6.24533C52.9426 5.68846 54.0903 5.40982 55.3687 5.40942C56.6482 5.40942 57.7879 5.68826 58.7876 6.24593C59.8044 6.78657 60.608 7.53244 61.1985 8.48352C61.7886 9.41798 62.0836 10.4835 62.0836 11.6799C62.0836 12.8764 61.7886 13.9585 61.1985 14.9262C60.6246 15.8765 59.8293 16.6305 58.8125 17.1881C57.812 17.7292 56.6724 17.9997 55.3936 17.9997ZM55.3693 14.1882C55.8442 14.1882 56.254 14.0899 56.5988 13.8932C56.9561 13.6836 57.2462 13.3766 57.4352 13.008C57.6319 12.6308 57.7303 12.1964 57.7303 11.7049C57.7303 11.2125 57.6236 10.7862 57.4103 10.4261C57.2136 10.0493 56.9431 9.76232 56.5988 9.56522C56.2544 9.35228 55.8446 9.24582 55.3693 9.24582C54.9102 9.24582 54.5001 9.35228 54.1392 9.56522C53.7821 9.77485 53.4922 10.0819 53.3033 10.4504C53.1062 10.8114 53.0077 11.2376 53.0077 11.7292C53.0077 12.2041 53.1062 12.6304 53.3033 13.008C53.4922 13.3765 53.7821 13.6835 54.1392 13.8932C54.5001 14.0899 54.9102 14.1882 55.3693 14.1882Z" />
        </svg>
      </div>
    );

    if (disabledLink) {
      return <BetaWrapper beta={beta}>{logo}</BetaWrapper>;
    }

    return (
      <BetaWrapper beta={beta} className={className}>
        <Link to={PATH_AFTER_LOGIN}>{logo}</Link>
      </BetaWrapper>
    );
  }
);

Logo.displayName = 'Logo';

export default Logo;
