import { useMemo } from 'react';

import { cn } from '@/lib/utils';
import { Separator } from '@/components/ui/separator';

import type { WidgetComponentProps } from '../types';

type DataItem = {
  name: string;
  value: number;
};

export function TableChart({
  className,
  data,
  showTotal,
  totalsLabel = 'Total:'
}: WidgetComponentProps & {
  className?: string;
  data: DataItem[];
  showTotal?: boolean;
  totalsLabel?: string;
}) {
  const maxValue = useMemo(
    () => (data.length > 0 ? data.reduce((max, curr) => (curr.value > max ? curr.value : max), 0) : 0),
    [data]
  );
  const total = useMemo(() => data.reduce((acc, curr) => acc + curr.value, 0), [data]);

  return (
    <div className={cn('flex flex-col gap-4 px-4 py-6', className)}>
      {data.map(datum => (
        <div key={`table_chart_${datum.name}`} className="flex items-center gap-6">
          <div className="flex flex-col flex-1 gap-2">
            <div className="text-sm font-medium">{datum.name}</div>
            <div className="flex w-full h-3 rounded-full overflow-hidden">
              <div
                className="bg-neutral-100 rounded-full"
                style={{ flex: datum.value, maxWidth: `${(datum.value / maxValue) * 100}%` }}
              />
              <div style={{ flex: maxValue - datum.value }} />
            </div>
          </div>
          <div className="text-lg font-bold">${datum.value}</div>
        </div>
      ))}
      {showTotal && (
        <div className="mt-4">
          <Separator className="bg-neutral-400 z-0" />
          <div className="flex items-center mt-4">
            <div className="text-lg font-medium flex-1">{totalsLabel}</div>
            <div className="text-lg font-bold">${total}</div>
          </div>
        </div>
      )}
    </div>
  );
}
