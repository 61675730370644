import ThreeDotsIcon from '@/icons/3Dots.svg?react';
import MiloIcon from '@/icons/Milo.svg?react';

import { cn } from '@/lib/utils';
import { Avatar } from '@/components/ui/avatar';
import { Button } from '@/components/ui/button';
import MenuRow from '@/components/menu-row';
import ResponsiveActionsMenu from '@/components/responsive-actions-menu';

export type MessageLikeWidgetProps = {
  // children: React.ReactNode;
  message: string;
  className?: string;
};

export default function MessageLikeWidget({ message, className }: MessageLikeWidgetProps) {
  return (
    <div className={cn('flex flex-col gap-5', className)}>
      <div className="flex items-start justify-between">
        <div className="flex items-center gap-4">
          <Avatar className="flex bg-primary justify-center items-center size-12">
            <MiloIcon className="size-5 shrink-0" />
          </Avatar>
          <div className="flex flex-col gap-1">
            <div className="text-2xs font-bold">Milo</div>
            <div className="text-neutral-200 text-5xs font-medium">Wed, 18 Dec</div>
          </div>
        </div>
        <ResponsiveActionsMenu
          dropdownTrigger={
            <Button className="text-neutral-200 hover:text-gray-600" size="icon" variant="icon">
              <ThreeDotsIcon className="size-6 rotate-90" />
            </Button>
          }
        >
          <MenuRow icon={null}>Dismiss Message</MenuRow>
          <MenuRow icon={null}>Talk to me about it</MenuRow>
        </ResponsiveActionsMenu>
      </div>

      <div className="mt-4 text-md font-medium">{message}</div>
    </div>
  );
}
