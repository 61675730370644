import BackIcon from '@/icons/Back.svg?react';
import { zodResolver } from '@hookform/resolvers/zod';
import { getCustomerQueries } from '@repo/features/customers';
import { useRouter } from '@tanstack/react-router';
import { useForm } from 'react-hook-form';
import z from 'zod';

import axiosInstance from '@/lib/axios';
import { cn } from '@/lib/utils';
import { useBreakpoint } from '@/hooks/use-breakpoint';
import { Button } from '@/components/ui/button';
import { Drawer, DrawerContent, DrawerDescription, DrawerHeader, DrawerTitle } from '@/components/ui/drawer';
import { Form, FormControl, FormField, FormItem, FormLabel, FormMessage } from '@/components/ui/form';
import { Input } from '@/components/ui/input';

export type CustomerInformationProps = {
  id: string;
};
const formSchema = z.object({
  name: z.string().min(1),
  legalName: z.string().min(1).optional()
});

type FormFields = z.infer<typeof formSchema>;
const customerQueries = getCustomerQueries(axiosInstance);
export default function CustomerInformationView({ id }: CustomerInformationProps) {
  const { history, navigate } = useRouter();
  const { isBelowLg } = useBreakpoint('lg');
  const { data: customer } = customerQueries.byId.useSuspenseQuery({
    variables: {
      id
    }
  });

  const { mutateAsync: updateCustomer } = customerQueries.update.useMutation();

  const form = useForm<FormFields>({
    resolver: zodResolver(formSchema),
    defaultValues: {
      name: customer.name,
      legalName: customer.legalName
    }
  });

  const onSubmit = async (values: FormFields) => {
    try {
      console.log(values);
      await updateCustomer({
        id,
        data: {
          ...values
        }
      });
      // await signUp({ data: values, query: qs.parse(window.location.search || '', { ignoreQueryPrefix: true }) });
    } catch (e) {
      console.error(e);
    }
  };
  const renderCustomerInformationForm = (
    <div className="flex flex-col gap-8 py-7">
      <FormField
        control={form.control}
        name="name"
        render={({ field }) => (
          <FormItem className="w-full">
            <FormLabel>Customer Name</FormLabel>
            <FormControl>
              <Input placeholder="Customer Name" {...field} />
            </FormControl>
            <FormMessage />
          </FormItem>
        )}
      />
      <FormField
        control={form.control}
        name="legalName"
        render={({ field }) => (
          <FormItem className="w-full">
            <FormLabel>Legal Name</FormLabel>
            <FormControl>
              <Input placeholder="Legal Name" {...field} />
            </FormControl>
            <FormMessage />
          </FormItem>
        )}
      />
    </div>
  );
  return (
    <Drawer direction={isBelowLg ? 'bottom' : 'right'} onClose={() => navigate({ to: '..' })} open>
      <DrawerContent
        className={cn(
          'h-full w-full lg:w-[28.125rem] bg-background rounded-none lg:rounded-tl-lg lg:rounded-bl-lg lg:right-0 lg:left-auto'
        )}
        hideHandle={isBelowLg}
      >
        <DrawerHeader className="py-4 px-8 h-[4rem] flex items-center justify-between">
          <DrawerTitle className="hidden">Customer Information</DrawerTitle>
          <DrawerDescription className="hidden">Customer Information Form</DrawerDescription>
          <Button
            onClick={() => {
              history.back();
            }}
            size="icon"
            variant="icon"
          >
            <BackIcon className="size-5" />
          </Button>
        </DrawerHeader>

        <div className="flex h-full flex-col flex-1  overflow-y-auto p-10 pt-0 gap-6">
          <div className="text-lg font-bold">Customer Information</div>
          <Form {...form}>
            <form className="flex flex-col w-full gap-12" onSubmit={form.handleSubmit(onSubmit)}>
              {renderCustomerInformationForm}
              <div>
                <Button
                  className="w-full"
                  disabled={!form.formState.isValid}
                  isLoading={form.formState.isSubmitting}
                  type="submit"
                >
                  Save Changes
                </Button>
              </div>
            </form>
          </Form>
        </div>
      </DrawerContent>
    </Drawer>
  );
}
