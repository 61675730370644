import { useEffect } from 'react';
import running2 from '@/assets/gifs/running2.gif';
import { motion } from 'framer-motion';
import { useShallow } from 'zustand/react/shallow';

import { useWizard } from '@/components/form-wizard';

function LoadingStep() {
  const goToNextStep = useWizard(useShallow(state => state.goToNextStep));

  useEffect(() => {
    const timer = setTimeout(() => {
      goToNextStep();
    }, 7000);

    return () => {
      clearTimeout(timer);
    };
  }, [goToNextStep]);

  return (
    <motion.div
      animate={{ opacity: 1 }}
      className="absolute inset-0 bg-primary flex items-center justify-center z-50"
      exit={{ opacity: 0 }}
      initial={{ opacity: 0 }}
    >
      <div className="flex flex-col justify-center items-center gap-4">
        <div>
          <img alt="Running milo" className="w-34" src={running2} />
        </div>

        <p className="text-white text-md">Preparing milo for you...</p>
      </div>
    </motion.div>
  );
}

export default LoadingStep;
