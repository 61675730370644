import ChevronDownIcon from '@/icons/ChevronDown.svg?react';
import PlusIcon from '@/icons/Plus.svg?react';
import InvoiceRow from '@/sections/library/invoice/invoice-row';
import useInvoiceActions from '@/sections/library/invoice/use-invoice-actions';
import { mergeCustomerWithCurrentTransactionCustomers } from '@/sections/library/transaction/helpers';
import LibrarySelectButton from '@/sections/library/transaction/library-select-button';
import { fDate } from '@/utils/format-time';
import { getCustomerQueries } from '@repo/features/customers';
import { getFileQueries } from '@repo/features/file';
import { getInvoiceQueries } from '@repo/features/invoices';
import { Outlet, useLocation, useNavigate, useRouter } from '@tanstack/react-router';

import axiosInstance from '@/lib/axios';
import { cn } from '@/lib/utils';
import { useBreakpoint } from '@/hooks/use-breakpoint';
import { Collapsible, CollapsibleContent, CollapsibleTrigger } from '@/components/ui/collapsible';
import { Drawer, DrawerContent, DrawerDescription, DrawerHeader, DrawerTitle } from '@/components/ui/drawer';
import FilePreview from '@/components/file-preview';

const fileQueries = getFileQueries(axiosInstance);
const invoiceQueries = getInvoiceQueries(axiosInstance);
const customerQueries = getCustomerQueries(axiosInstance);

export default function InvoiceDetailsView({ id, onClose }: { id: string; onClose: () => void }) {
  const { navigate } = useRouter();
  const { isBelowLg } = useBreakpoint('lg');
  const { data: invoice } = invoiceQueries.byId.useSuspenseQuery({
    variables: {
      id,
      expand: ['customer']
    }
  });

  // console.log('INVOICE', invoice);

  const { data: customers = [] } = customerQueries.paginated.useSuspenseQuery({
    variables: {
      limit: 5
    },
    select: _data => mergeCustomerWithCurrentTransactionCustomers(_data.records, invoice.customer)
  });

  const { updateInvoice } = useInvoiceActions();

  const { data: signedFileUrl } = fileQueries.getSignedUrl.useSuspenseQuery({
    variables: {
      id: invoice.fileId
    }
  });

  const renderDocumentsSection = (
    <div className="py-8 px-10 flex flex-col gap-6">
      <div className="text-2xs font-medium">Documents:</div>
      <div>
        <FilePreview className="w-full" signedUrl={signedFileUrl} />
      </div>
    </div>
  );

  const renderCustomerSection = (
    <Collapsible>
      <CollapsibleTrigger className="py-8 px-10 flex w-full justify-between items-center gap-6 ">
        <div className="text-2xs font-medium">Customer:</div>
        <div className="flex items-center gap-2">
          <div className="text-2xs font-medium">{invoice.customer?.name}</div>
          <div className="[&[data-state=open]>svg]:rotate-180">
            <ChevronDownIcon />
          </div>
        </div>
      </CollapsibleTrigger>
      <CollapsibleContent className="flex gap-4 px-10 data-[state=open]:pb-8 overflow-y-scroll lg:overflow-y-auto lg:flex-wrap scrollbar-hidden">
        {customers.map(customer => (
          <LibrarySelectButton
            key={customer._id}
            onClick={async () => {
              if (invoice?.customerId !== customer._id) {
                await updateInvoice({
                  id,
                  data: {
                    customerId: customer._id as any,
                    customer
                  }
                });
              }
            }}
            selected={customer._id === invoice.customerId}
            size="sm"
          >
            {customer.name}
          </LibrarySelectButton>
        ))}
        <LibrarySelectButton
          onClick={() =>
            navigate({
              to: '/library/invoices/$id/customer',
              params: { id }
            })
          }
          size="sm"
          variant="add-new"
        >
          <PlusIcon className="size-3" />
          ADD NEW
        </LibrarySelectButton>
      </CollapsibleContent>
    </Collapsible>
  );
  return (
    <>
      <Drawer direction={isBelowLg ? 'bottom' : 'right'} onClose={onClose} open>
        <DrawerContent
          className={cn(
            'h-full w-full lg:w-[28.125rem] bg-neutral-800 lg:rounded-none lg:rounded-tl-lg lg:rounded-bl-lg lg:right-0 lg:left-auto'
          )}
          hideHandle={!isBelowLg}
        >
          <DrawerHeader className="text-left border-b p-0">
            <DrawerTitle className="hidden">Invoice Details</DrawerTitle>
            <DrawerDescription className="hidden">Your invoice details.</DrawerDescription>
            <div>{invoice ? <InvoiceRow hideChevron invoice={invoice} /> : null}</div>
          </DrawerHeader>
          <div className="flex h-full flex-col flex-1 divide-y overflow-y-auto pb-10">
            <div className="py-8 px-10 flex gap-12">
              <div>
                <div className="text-xs font-semibold">{fDate(invoice.invoiceDate, 'MMM dd, yyyy')}</div>
                <div className="text-4xs">Issue Date</div>
              </div>
              <div>
                <div className="text-xs font-semibold">{fDate(invoice.dueDate, 'MMM dd, yyyy')}</div>
                <div className="text-4xs">Due Date</div>
              </div>
            </div>
            {renderDocumentsSection}
            {renderCustomerSection}
          </div>
        </DrawerContent>
      </Drawer>
      <Outlet />
    </>
  );
}
