import { HeaderPortal } from '@/layouts/common/header-portal';
import LibraryAppHeader from '@/sections/library/library-app-header';
import BillFilters from '@/sections/library/bill/bill-filters';
import CustomerFilters from '@/sections/library/customer/customer-filters';
import InvoiceFilters from '@/sections/library/invoice/invoice-filters';
import TransactionFilters from '@/sections/library/transaction/transaction-filters';
import VendorFilters from '@/sections/library/vendor/vendor-filters';
import { createFileRoute, Outlet, useMatch } from '@tanstack/react-router';

export const Route = createFileRoute('/_guarded/_main/library')({
  component: LibraryPage
});

function LibraryPage() {
  const transactionsRouteMatch = useMatch({
    from: '/_guarded/_main/library/transactions',
    shouldThrow: false
  });
  const invoicesRouteMatch = useMatch({
    from: '/_guarded/_main/library/invoices',
    shouldThrow: false
  });
  const customersRouteMatch = useMatch({
    from: '/_guarded/_main/library/customers',
    shouldThrow: false
  });

  const vendorsRouteMatch = useMatch({
    from: '/_guarded/_main/library/vendors',
    shouldThrow: false
  });
  const billsRouteMatch = useMatch({
    from: '/_guarded/_main/library/bills',
    shouldThrow: false
  });
  return (
    <>
      <HeaderPortal position="replace">
        <LibraryAppHeader />
        {transactionsRouteMatch !== undefined && <TransactionFilters />}
        {invoicesRouteMatch !== undefined && <InvoiceFilters />}
        {customersRouteMatch !== undefined && <CustomerFilters />}
        {vendorsRouteMatch !== undefined && <VendorFilters />}
        {billsRouteMatch !== undefined && <BillFilters />}
      </HeaderPortal>
      <Outlet />
    </>
  );
}
