import React from 'react';

import { cn } from '@/lib/utils';

import { useHeaderPortalStore } from './header-portal-store';

export function HeaderContainer({ className, ...props }: { className?: string }) {
  const contents = useHeaderPortalStore(state => state.contents);

  const startContents = contents.filter(content => content.position === 'start');
  const endContents = contents.filter(content => content.position === 'end');
  const replaceContents = contents.filter(content => content.position === 'replace');

  // If there is any replace content, only show that!!!!!!!!!!!!!!!
  if (replaceContents.length > 0) {
    return (
      <header className={cn('flex flex-col', className)} id="header-content" {...props}>
        {replaceContents.map(content => (
          <React.Fragment key={content.id}>{content.content}</React.Fragment>
        ))}
      </header>
    );
  }

  return (
    <header className={cn('flex flex-col w-full', className)} id="header-content" {...props}>
      {startContents.map(content => (
        <React.Fragment key={content.id}>{content.content}</React.Fragment>
      ))}
      {endContents.map(content => (
        <React.Fragment key={content.id}>{content.content}</React.Fragment>
      ))}
    </header>
  );
}
