import { useSuspenseQuery } from '@tanstack/react-query';

export type WidgetLoaderWrapperProps = {
  children: React.ReactNode;
  timeout?: number;
  hasError?: boolean;
  id: number;
  className?: string;
};

export default function WidgetLoaderWrapper({
  id,
  children,
  timeout = 2000,
  className,
  hasError = false
}: WidgetLoaderWrapperProps) {
  useSuspenseQuery({
    queryKey: ['test-widget-loader', id],
    queryFn: async () => {
      return await new Promise((resolve, reject) =>
        setTimeout(() => {
          if (hasError) {
            reject(new Error('Error'));
          }
          resolve(true);
        }, timeout)
      );
    },
    retry: false
  });
  return <div className={className}>{children}</div>;
}
