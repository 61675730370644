import BackIcon from '@/icons/Back.svg?react';
import FiltersIcon from '@/icons/Filters.svg?react';
import { headerWrapperVariants } from '@/layouts/common/app-header';
import { useRouter } from '@tanstack/react-router';
import type { VariantProps } from 'class-variance-authority';

import { cn } from '@/lib/utils';
import { Button } from '@/components/ui/button';

export type MiloAppHeaderProps = {
  className?: string;
} & VariantProps<typeof headerWrapperVariants>;

export default function MiloAppHeader({ variant = 'secondary', className }: MiloAppHeaderProps) {
  const { history, navigate } = useRouter();
  return (
    <div className={cn(headerWrapperVariants({ variant }), className)}>
      <Button
        onClick={() => {
          history.back();
        }}
        size="icon"
        variant="icon"
      >
        <BackIcon className="fill-white size-5" />
      </Button>
      <div className="font-semibold text-base">&milo Home</div>
      <div>
        <Button onClick={() => navigate({ to: '/milo/center' })} size="icon" variant="icon-bordered">
          <FiltersIcon className="size-6 shrink-0" />
        </Button>
      </div>
    </div>
  );
}
