import { useEffect } from 'react';
import JumpingDog from '@/assets/lottie/jumping-dog';
import MusicListeningDog from '@/assets/lottie/music-listening-dog';
import WorkingDog from '@/assets/lottie/working-dog';
import MainLayout from '@/layouts/main-layout';
import { getBillingQueries } from '@repo/features/billing';
import { EmbeddedCheckout, EmbeddedCheckoutProvider } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import { useQueryClient } from '@tanstack/react-query';
import { createFileRoute, useNavigate, useRouterState } from '@tanstack/react-router';
import { zodSearchValidator } from '@tanstack/router-zod-adapter';
import { XIcon } from 'lucide-react';
import Z from 'zod';

import axiosInstance from '@/lib/axios';
import { Button } from '@/components/ui/button';
import { Label } from '@/components/ui/label';

export const Route = createFileRoute('/_guarded/billing')({
  validateSearch: zodSearchValidator(
    Z.object({
      session_id: Z.string().startsWith('cs_').optional()
    })
  ),
  component: () => (
    <MainLayout
      headerProps={{
        className: 'hidden lg:flex'
      }}
      inputProps={{
        className: 'hidden'
      }}
    >
      <BillingPage />
    </MainLayout>
  )
});

const stripePromise = loadStripe(import.meta.env.VITE_STRIPE_PUBLIC_KEY, {});

const billingQueries = getBillingQueries(axiosInstance);

function CheckoutPage() {
  const { data: clientSecret } = billingQueries.initiateCheckoutSession.useQuery();

  if (!clientSecret) {
    return null;
  }

  return (
    <EmbeddedCheckoutProvider
      options={{
        clientSecret
      }}
      stripe={stripePromise}
    >
      <EmbeddedCheckout />
    </EmbeddedCheckoutProvider>
  );
}

function ActiveSubscriptionPage() {
  const queryClient = useQueryClient();
  const { data: subscriptionDetails } = billingQueries.getSubscriptionDetails.useQuery();
  const { mutateAsync: cancelSubscription } = billingQueries.cancelSubscription.useMutation({
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: billingQueries.getBilling.getKey()
      });
    }
  });

  if (!subscriptionDetails) {
    return null;
  }

  return (
    <div className="flex flex-col items-start max-w-[600px] mx-auto">
      <MusicListeningDog className="size-[100px]" />
      <div className="text-3xl font-light mx-auto mb-8">Your subscription is active!</div>
      <div>
        <Label className="font-medium italic text-md mb-1" htmlFor="plan">
          Current plan
        </Label>
        <p className="font-light text-md" id="plan">
          {subscriptionDetails.description}
        </p>
      </div>
      <div className="mt-4">
        <Label className="font-medium italic text-md mb-1" htmlFor="next-payment">
          Next payment
        </Label>
        <p className="font-light text-md" id="next-payment">
          {new Date(subscriptionDetails.nextInvoiceDate).toLocaleDateString()} for {subscriptionDetails.price}$
        </p>
      </div>
      <div className="mt-8 mx-auto">
        <Button onClick={() => cancelSubscription()} variant="destructive">
          <XIcon className="mr-1" size={18} />
          Cancel subscription
        </Button>
      </div>
    </div>
  );
}

function ActivatingYourSubscription() {
  return (
    <div className="flex flex-col items-center">
      <WorkingDog className="size-[200px]" />
      <p className="font-light text-lg">Activating your subscription...</p>
    </div>
  );
}

function BillingPage() {
  const navigate = useNavigate();
  const router = useRouterState();

  const { session_id } = Route.useSearch();

  const {
    data: billing,
    isFetching,
    isLoading
  } = billingQueries.getBilling.useQuery({
    refetchInterval: session_id ? 1000 : 10000
  });

  // Need to use isFetching for interval to work, weird
  useEffect(() => {
    if (session_id && billing?.status === 'active') {
      navigate({ to: router.location.pathname });
    }
  }, [isFetching]);

  if (isLoading) {
    return null;
  }

  if (billing?.status === 'active') {
    return <ActiveSubscriptionPage />;
  }

  if (billing?.status === 'checking_out' && billing.stripe.checkoutSessionId === session_id) {
    return <ActivatingYourSubscription />;
  }

  if (billing?.status === 'checking_out' && !session_id) {
    return <CheckoutPage />;
  }

  return <CheckoutPage />;
}
