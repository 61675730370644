import InvoiceDetailsView from '@/sections/library/invoice/views/invoice-details-view';
import { createFileRoute } from '@tanstack/react-router';

export const Route = createFileRoute('/_guarded/_main/library/invoices/$id')({
  component: () => {
    const { id } = Route.useParams();
    const navigate = Route.useNavigate();
    return <InvoiceDetailsView id={id} onClose={() => navigate({ to: '..' })} />;
  },
  wrapInSuspense: true
});
