import {Player as Lottie, IPlayerProps} from '@lottiefiles/react-lottie-player';
import animation from './music-listening-dog.json';
import { memo } from "react";

export type LoaderProps = {} & Omit<IPlayerProps, 'src'>;

function MusicListeningDog(props: LoaderProps) {
  return (
    <Lottie
      src={animation}
      loop
      autoplay
      {...props}
    />
  );
}

export default memo(MusicListeningDog)
