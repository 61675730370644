import InvoiceDetailsView from '@/sections/library/invoice/views/invoice-details-view';
import { createFileRoute } from '@tanstack/react-router';

export const Route = createFileRoute('/_guarded/_main/library/customers_/$id/invoices/$invoiceId')({
  component: () => {
    const { invoiceId } = Route.useParams();
    const navigate = Route.useNavigate();
    return <InvoiceDetailsView id={invoiceId} onClose={() => navigate({ to: '../..' })} />;
  },
  wrapInSuspense: true
});
