import BackIcon from '@/icons/Back.svg?react';
import FilterIcon from '@/icons/Filters.svg?react';
import { ExpectedEndOfMonthSimpleMetricWidget } from '@/sections/widgets/metrics/expected-eom-simple-metric-widget';
import { useShallow } from 'zustand/react/shallow';

import { Button } from '@/components/ui/button';
import { Drawer, DrawerClose, DrawerContent, DrawerDescription, DrawerTitle } from '@/components/ui/drawer';

import { useCashflowStore } from '../use-cashflow-store';
import { ExpectedEndOfMonthBalanceBreakdown } from './expected-eom-balance-breakdown';

export function ExpectedEndOfMonthDrawer() {
  const { closeDrawer, isOpen, activeDrawer, openDrawer } = useCashflowStore(
    useShallow(state => ({
      isOpen: state.isOpen,
      closeDrawer: state.closeDrawer,
      activeDrawer: state.activeDrawer,
      openDrawer: state.openDrawer
    }))
  );

  return (
    <Drawer
      direction="right"
      onClose={() => {
        document.body.style.pointerEvents = 'auto';
      }}
      onOpenChange={_isOpen => {
        if (_isOpen) {
          openDrawer('expected_end_of_month');
        } else {
          closeDrawer();
        }
      }}
      open={isOpen ? activeDrawer === 'expected_end_of_month' : false}
    >
      <DrawerContent
        className="h-full w-full lg:w-[28.125rem] rounded-none lg:rounded-tl-lg lg:rounded-bl-lg lg:right-0 lg:left-auto"
        hideHandle
      >
        <div className="flex justify-between items-center px-6 py-4">
          <DrawerClose asChild>
            <Button className="min-w-8" size="icon" variant="icon">
              <BackIcon className="size-5" />
            </Button>
          </DrawerClose>
          <Button size="icon-lg" variant="icon-bordered">
            <FilterIcon className="size-5" />
          </Button>
        </div>

        <div className="flex flex-1 overflow-y-scroll flex-col p-8">
          <DrawerTitle className="text-3xl font-semibold">Expected End Of The Month Balance</DrawerTitle>
          <DrawerDescription className="text-xs text-neutral-200">
            Your expected cash posdition for the end of the month.
          </DrawerDescription>
          <div className="flex flex-col gap-14 mt-6">
            <ExpectedEndOfMonthSimpleMetricWidget className="text-4xl font-medium" />
            <ExpectedEndOfMonthBalanceBreakdown />
          </div>
        </div>
      </DrawerContent>
    </Drawer>
  );
}
