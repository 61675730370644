import type React from 'react';
import { useEffect, useRef, useState } from 'react';

import type { HeaderPosition } from './header-portal-store';
import { useHeaderPortalStore } from './header-portal-store';

interface HeaderPortalProps {
  children: React.ReactNode;
  position?: HeaderPosition;
  priority?: number;
}

export function HeaderPortal({ children, position = 'end', priority = 0 }: HeaderPortalProps) {
  const [mounted, setMounted] = useState(false);
  const { addContent, removeContent } = useHeaderPortalStore();

  useEffect(() => {
    setMounted(true);
    const id = addContent({
      content: children,
      position,
      priority
    });

    // Cleanup on unmount
    return () => {
      removeContent(id);
    };
  }, [children, position, priority, addContent, removeContent]);

  return null;
}
