import { create } from 'zustand';

type DrawerType = 'profitability' | 'revenue' | 'expenses' | 'none';

interface BusinessState {
  activeDrawer: DrawerType;
  isOpen: boolean;
  openDrawer: (drawer: DrawerType) => void;
  closeDrawer: () => void;
  toggleDrawer: (drawer: DrawerType) => void;
}

export const useBusinessStore = create<BusinessState>(set => ({
  activeDrawer: 'none',
  isOpen: false,

  openDrawer: drawer => {
    set({
      activeDrawer: drawer,
      isOpen: true
    });
  },

  closeDrawer: () => {
    set({
      activeDrawer: 'none',
      isOpen: false
    });
  },

  toggleDrawer: drawer => {
    set(state => {
      if (state.activeDrawer === drawer && state.isOpen) {
        return {
          activeDrawer: 'none',
          isOpen: false
        };
      }
      return {
        activeDrawer: drawer,
        isOpen: true
      };
    });
  }
}));
