import ChevronRightIcon from '@/icons/ChevronRight.svg?react';
import LibraryRow from '@/sections/library/library-row';
import RowAmount from '@/sections/library/library-row/row-amount';
import RowDate from '@/sections/library/library-row/row-date';
import RowSubTitle from '@/sections/library/library-row/row-subtitle';
import RowTitle from '@/sections/library/library-row/row-title';
import TransactionCategoryBadge from '@/sections/library/transaction/transaction-category-badge';
import TransactionType from '@/sections/library/transaction/transaction-type';
import type { ICustomer } from '@repo/features/customers';
import type { FinancialAccount } from '@repo/features/financial-accounts';
import type { ITransaction } from '@repo/features/transactions';
import type { IVendor } from '@repo/features/vendors';

import { cn } from '@/lib/utils';

export type TransactionProps = {
  transaction: ITransaction;
  hideTag?: boolean;
  hideChevron?: boolean;
  mode?: 'table' | 'list-item';
  onClick?: () => void;
  className?: string;
};
export default function TransactionRow({
  transaction,
  hideTag = false,
  hideChevron = false,
  mode = 'list-item',
  onClick,
  className
}: TransactionProps) {
  const transactionType = transaction.amount > 0 ? 'received' : 'paid';
  const provider = transaction?.vendor
    ? (transaction.vendor as IVendor).name
    : transaction.customer
      ? (transaction.customer as ICustomer).name
      : 'Unknown';
  const tagVariant =
    transaction?.suggestedAccountingLedger && transaction?.suggestedAccountingLedger?.score < 1
      ? 'uncertain'
      : 'default';

  const renderType = (
    <TransactionCategoryBadge variant={transaction.personal ? 'personal' : 'business'}>
      {transaction.personal ? 'Personal' : 'Business'}
    </TransactionCategoryBadge>
  );
  const renderCategory = (
    <TransactionCategoryBadge alertPosition={mode === 'table' ? 'right' : 'left'} variant={tagVariant}>
      {transaction?.suggestedAccountingLedger?.name || 'Uncategorized'}
    </TransactionCategoryBadge>
  );
  if (mode === 'table') {
    return (
      <div
        className={cn(
          'grid grid-cols-[1fr_1fr_1fr_11.5rem_11.5rem_7.5rem_11.5rem_auto] py-10 px-16 items-center justify-center cursor-pointer'
        )}
        onClick={onClick}
      >
        <div className="flex items-center">
          <div className="flex items-center gap-3">
            <TransactionType type={transactionType} />
            <div className="text-3xs font-semibold">{transaction.amount > 0 ? 'Received' : 'Paid'}</div>
          </div>
        </div>
        <div className="flex items-center">
          <RowAmount amount={transaction.amount} />
        </div>
        <div className="flex items-center">
          <RowTitle title={provider} />
        </div>
        <div className="flex items-center">
          <RowSubTitle subtitle={(transaction.financialAccount as FinancialAccount).name} />
        </div>
        <div className="flex items-center">
          <RowDate date={transaction.time as any as string} />
        </div>
        <div className="flex items-center">{renderType}</div>
        <div className="flex items-center">{renderCategory}</div>
        <div>{!hideChevron && <ChevronRightIcon className="h-3" />}</div>
      </div>
    );
  }
  return (
    <LibraryRow
      amount={<RowAmount amount={transaction.amount} />}
      className={className}
      date={<RowDate date={transaction.time as any as string} />}
      hideChevron={hideChevron}
      icon={<TransactionType type={transactionType} />}
      onClick={onClick}
      subtitle={<RowSubTitle subtitle={(transaction.financialAccount as FinancialAccount).name} />}
      tag={!hideTag ? renderCategory : null}
      title={<RowTitle title={provider} />}
    />
  );
}
