import { useState } from 'react';
import { zodResolver } from '@hookform/resolvers/zod';
import { getRouteApi } from '@tanstack/react-router';
import qs from 'qs';
import { useForm } from 'react-hook-form';
import { z } from 'zod';

import { Alert, AlertDescription, AlertTitle } from '@/components/ui/alert';
import { Button } from '@/components/ui/button';
import { Form, FormControl, FormField, FormItem, FormMessage } from '@/components/ui/form';
import { Input } from '@/components/ui/input';
import GoogleSignButton from '@/components/google-sign-button';

// ----------------------------------------------------------------------
const formSchema = z
  .object({
    password: z.string().min(6, 'Password must be at least 6 characters.'),
    confirmPassword: z.string()
  })
  .refine(
    values => {
      return values.password === values.confirmPassword;
    },
    {
      message: 'Passwords must match!',
      path: ['confirmPassword']
    }
  );
const routeApi = getRouteApi('/_guested/auth/invite/$token');
export default function AcceptInviteView() {
  const searchParams = routeApi.useSearch();
  const { token } = routeApi.useParams();
  const [errorMsg, setErrorMsg] = useState(searchParams.errorMessage);
  const form = useForm<z.infer<typeof formSchema>>({
    resolver: zodResolver(formSchema),
    defaultValues: {
      password: '',
      confirmPassword: ''
    }
  });
  const onSubmit = async (values: z.infer<typeof formSchema>) => {
    setErrorMsg('');
    // await mutateAsync({ data: { ...values, tokenId: token } });
    window.open(`${AUTH_URL}/invite/accept?${qs.stringify({ ...values, tokenId: token })}`, '_self');
  };

  const renderForm = (
    <Form {...form}>
      <form onSubmit={form.handleSubmit(onSubmit)}>
        <div className="block w-80 text-3xl sm:flex py-8 px-10">
          Hey, to Sign In with email & password please set your password below, or sign in with Google.
        </div>
        <div className="flex flex-col items-start justify-start sm:w-1/2">
          <div className="w-80">
            <div className="mt-10">
              <FormField
                control={form.control}
                name="password"
                render={({ field }) => (
                  <FormItem>
                    <FormControl>
                      <Input placeholder="Password" type="password" {...field} />
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )}
              />
              <FormField
                control={form.control}
                name="confirmPassword"
                render={({ field }) => (
                  <FormItem className="mt-6">
                    <FormControl>
                      <Input placeholder="Confirm Password" type="password" {...field} />
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )}
              />
            </div>
            <div className="mt-10">
              <Button className="w-full" size="lg">
                Sign in
              </Button>
              <div className="my-6 flex items-center justify-center text-xs font-bold uppercase">or</div>
              <GoogleSignButton className="w-full" tokenId={token} type="button" />
            </div>
          </div>
        </div>
      </form>
    </Form>
  );

  return (
    <>
      {!!errorMsg && (
        <Alert className="mb-5" variant="destructive">
          <AlertTitle>Heads up!</AlertTitle>
          <AlertDescription>{errorMsg}</AlertDescription>
        </Alert>
      )}
      {renderForm}
    </>
  );
}
