import { Fragment } from 'react';
import CustomerRow from '@/sections/library/customer/customer-row';
import LibraryLoadMore from '@/sections/library/library-load-more';
import { getCustomerQueries } from '@repo/features/customers';
import { createFileRoute } from '@tanstack/react-router';

import axiosInstance from '@/lib/axios';
import { useBreakpoint } from '@/hooks/use-breakpoint';

export const Route = createFileRoute('/_guarded/_main/library/customers')({
  component: CustomersPage
});

const customerQueries = getCustomerQueries(axiosInstance);
function CustomersPage() {
  const navigate = Route.useNavigate();
  const { isBelowLg } = useBreakpoint('lg');
  const {
    data: paginated,
    hasNextPage,
    fetchNextPage
  } = customerQueries.infinityPaginated.useInfiniteQuery({
    variables: {
      expand: ['invoices.aggs']
    }
  });

  return (
    <div className="divide-y overflow-y-auto max-h-full">
      {paginated?.pages.map((page, i) => (
        <Fragment key={i}>
          {page.records.map(record => (
            <CustomerRow
              className="rounded-none"
              customer={record}
              key={record._id}
              mode={isBelowLg ? 'list-item' : 'table'}
              onClick={() =>
                navigate({
                  to: '/library/customers/$id',
                  params: { id: record._id! }
                })
              }
            />
          ))}
        </Fragment>
      ))}
      {hasNextPage ? (
        <LibraryLoadMore
          onClick={async () => {
            await fetchNextPage();
          }}
        />
      ) : null}
    </div>
  );
}
