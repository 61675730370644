import typographyPlugin from '@tailwindcss/typography';
import type { Config } from 'tailwindcss';
import tailwindCss3d from 'tailwindcss-3d';
import animatePlugin from 'tailwindcss-animate';

const config = {
  darkMode: ['class'],
  content: [
    './index.html',
    './src/**/*.{js,ts,jsx,tsx}',
    './src/routes/*.{js,ts,jsx,tsx}',
    '../../packages/ui/src/**/*.{ts,tsx}'
  ],
  theme: {
    extend: {
      fontFamily: {
        archivo: ['var(--font-archivo)']
      },
      borderWidth: {
        '3': '3px'
      },
      fontSize: {
        '5xl': ['3.75rem', '110%'],
        '4xl': ['2.375rem', '120%'],
        '3xl': ['2.125rem', '130%'],
        '2xl': ['1.75rem', '140%'],
        xl: ['1.5rem', '140%'],
        lg: ['1.25rem', '140%'],
        md: ['1.125rem', '150%'],
        base: ['1rem', '120%'],
        sm: ['0.9375rem', '150%'],
        xs: ['0.875rem', '150%'],
        '2xs': ['0.8125rem', '120%'],
        '3xs': ['0.75rem', '100%'],
        '4xs': ['0.6875rem', '150%'],
        '5xs': ['0.5625rem', '100%'],
        '6xs': ['0.5rem', '100%']
      },
      colors: {
        primary: {
          '100': 'hsl(var(--primary-100))',
          '200': 'hsl(var(--primary-200))',
          '300': 'hsl(var(--primary-300))',
          '400': 'hsl(var(--primary-400))',
          '500': 'hsl(var(--primary-500))',
          '600': 'hsl(var(--primary-600))',
          DEFAULT: 'hsl(var(--primary-500))'
        },
        secondary: {
          '500': 'hsl(var(--secondary-500))',
          DEFAULT: 'hsl(var(--secondary-500))'
        },
        neutral: {
          '200': 'hsl(var(--neutral-200))',
          // '300': 'hsl(var(--neutral-300))',
          '400': 'hsl(var(--neutral-400))',
          '500': 'hsl(var(--neutral-500))',
          '600': 'hsl(var(--neutral-600))',
          '700': 'hsl(var(--neutral-700))',
          '800': 'hsl(var(--neutral-800))',
          '900': 'hsl(var(--neutral-900))'
        },
        fun: {
          cyan: 'hsl(var(--fun-cyan))',
          orange: 'hsl(var(--fun-orange))',
          green: 'hsl(var(--fun-green))',
          yellow: 'hsl(var(--fun-yellow))',
          red: 'hsl(var(--fun-red))',
          pink: 'hsl(var(--fun-pink))',
          turquoise: 'hsl(var(--fun-turquoise))',
          purple: 'hsl(var(--fun-purple))'
        },
        border: 'hsl(var(--border))',
        input: 'hsl(var(--input))',
        ring: 'hsl(var(--ring))',
        background: 'hsl(var(--background))',
        foreground: 'hsl(var(--foreground))',
        destructive: {
          DEFAULT: 'hsl(var(--destructive))',
          foreground: 'hsl(var(--destructive-foreground))'
        },
        muted: {
          DEFAULT: 'hsl(var(--muted))',
          foreground: 'hsl(var(--muted-foreground))'
        },
        accent: {
          DEFAULT: 'hsl(var(--accent))',
          foreground: 'hsl(var(--accent-foreground))'
        },
        popover: {
          DEFAULT: 'hsl(var(--popover))',
          foreground: 'hsl(var(--popover-foreground))'
        },
        card: {
          DEFAULT: 'hsl(var(--card))',
          foreground: 'hsl(var(--card-foreground))'
        },
        white: {
          DEFAULT: '#FFFFFF',
          foreground: '#000'
        },
        sidebar: {
          DEFAULT: 'hsl(var(--sidebar-background))',
          foreground: 'hsl(var(--sidebar-foreground))',
          primary: 'hsl(var(--sidebar-primary))',
          'primary-foreground': 'hsl(var(--sidebar-primary-foreground))',
          accent: 'hsl(var(--sidebar-accent))',
          'accent-foreground': 'hsl(var(--sidebar-accent-foreground))',
          active: 'hsl(var(--sidebar-active))',
          border: 'hsl(var(--sidebar-border))',
          ring: 'hsl(var(--sidebar-ring))'
        }
      },
      spacing: {
        '0': '0',
        '1': '0.1875rem',
        '2': '0.375rem',
        '3': '0.5625rem',
        '4': '0.75rem',
        '5': '0.9375rem',
        '6': '1.125rem',
        '7': '1.3125rem',
        '8': '1.5rem',
        '9': '1.6875rem',
        '10': '1.875rem',
        '11': '2.0625rem',
        '12': '2.25rem',
        '14': '2.625rem',
        '16': '3rem',
        '18': '3.375rem',
        '20': '3.75rem',
        '21': '3.9375rem',
        '24': '4.5rem',
        '28': '5.25rem',
        '32': '6rem',
        '34': '6.375rem',
        '36': '6.75rem',
        '40': '7.5rem',
        '44': '8.25rem',
        '48': '9rem',
        '52': '9.75rem',
        '56': '10.5rem',
        '60': '11.25rem',
        '64': '12rem',
        '72': '13.5rem',
        '80': '15rem',
        '96': '18rem',
        px: '1px',
        '0.5': '0.09375rem',
        '1.5': '0.28125rem',
        '2.5': '0.46875rem',
        '3.5': '0.65625rem'
      },
      borderRadius: {
        lg: 'var(--radius)',
        md: 'calc(var(--radius) - 2px)',
        sm: 'calc(var(--radius) - 4px)'
      },
      keyframes: {
        'accordion-down': {
          from: {
            height: '0'
          },
          to: {
            height: 'var(--radix-accordion-content-height)'
          }
        },
        'accordion-up': {
          from: {
            height: 'var(--radix-accordion-content-height)'
          },
          to: {
            height: '0'
          }
        },
        'slide-down': {
          from: {
            height: '0'
          },
          to: {
            height: 'var(--radix-collapsible-content-height)'
          }
        },
        'slide-up': {
          from: {
            height: 'var(--radix-collapsible-content-height)'
          },
          to: {
            height: '0'
          }
        }
      },
      animation: {
        'accordion-down': 'accordion-down 0.2s ease-out',
        'accordion-up': 'accordion-up 0.2s ease-out',
        'slide-up': 'slide-up 500ms ease-out',
        'slide-down': 'slide-down 500ms ease-out'
      }
    }
  },
  plugins: [typographyPlugin, tailwindCss3d as any, animatePlugin]
} satisfies Config;

export default config;
