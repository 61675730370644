import { getFinancialQueries } from '@repo/features/financial-data';
import { format, startOfMonth } from 'date-fns';

import axiosInstance from '@/lib/axios';
import { cn } from '@/lib/utils';

import { SimpleMetric } from '../../widgets/metrics/simple-metric';
import type { WidgetProps } from '../../widgets/types';
import { WidgetFactory } from '../../widgets/widget-factory';
import {fCurrency} from "@/utils/format-number";

const financialQueries = getFinancialQueries(axiosInstance);
const endDate = new Date();
const startDate = startOfMonth(new Date());

export const CurrentMonthCashflow = WidgetFactory.create(({ className }: WidgetProps) => {
  const {
    data: {
      data: { money_in, money_out }
    }
  } = financialQueries.moneyFlow.useSuspenseQuery({
    variables: {
      startDate: format(startDate, 'yyyy-MM-dd'),
      endDate: format(endDate, 'yyyy-MM-dd')
    }
  });

  return (
    <div className={cn(className, 'flex justify-between')}>
      <div className="flex flex-col">
        <SimpleMetric className="text-fun-green" metric={fCurrency(money_in)} />
        <div className="text-4xs">Cash In</div>
      </div>
      <div className="flex flex-col">
        <SimpleMetric className="text-fun-red" metric={fCurrency(Math.abs(money_out))} />
        <div className="text-4xs">Cash Out</div>
      </div>
    </div>
  );
});
