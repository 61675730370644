/* eslint-disable */

// @ts-nocheck

// noinspection JSUnusedGlobalSymbols

// This file was automatically generated by TanStack Router.
// You should NOT make any changes in this file as it will be overwritten.
// Additionally, you should also exclude this file from your linter and/or formatter to prevent it from being checked or modified.

import { createFileRoute } from '@tanstack/react-router'

// Import Routes

import { Route as rootRoute } from './routes/__root'
import { Route as TestImport } from './routes/test'
import { Route as OnboardingImport } from './routes/onboarding'
import { Route as GuestedImport } from './routes/_guested'
import { Route as GuardedImport } from './routes/_guarded'
import { Route as GuardedTaxesImport } from './routes/_guarded/taxes'
import { Route as GuardedOldfinancesImport } from './routes/_guarded/oldfinances'
import { Route as GuardedBillingImport } from './routes/_guarded/billing'
import { Route as GuardedMainImport } from './routes/_guarded/_main'
import { Route as GuardedMainIndexImport } from './routes/_guarded/_main/index'
import { Route as GuestedAuthForgotPasswordImport } from './routes/_guested/auth/forgot-password'
import { Route as GuestedAuthCallbackImport } from './routes/_guested/auth/callback_'
import { Route as GuestedAuthAuthImport } from './routes/_guested/auth/_auth'
import { Route as GuardedConversationIdImport } from './routes/_guarded/conversation.$id'
import { Route as GuardedMainChatsImport } from './routes/_guarded/_main/chats'
import { Route as GuardedMainBooksImport } from './routes/_guarded/_main/books'
import { Route as GuardedMainMiloRouteImport } from './routes/_guarded/_main/milo/route'
import { Route as GuardedMainLibraryRouteImport } from './routes/_guarded/_main/library/route'
import { Route as GuardedMainFinancesRouteImport } from './routes/_guarded/_main/finances/route'
import { Route as GuestedAuthResetPasswordTokenImport } from './routes/_guested/auth/reset-password.$token'
import { Route as GuestedAuthInviteTokenImport } from './routes/_guested/auth/invite.$token'
import { Route as GuardedMainMiloCenterImport } from './routes/_guarded/_main/milo_/center'
import { Route as GuardedMainFinancesCashflowImport } from './routes/_guarded/_main/finances/cashflow'
import { Route as GuardedMainFinancesBusinessImport } from './routes/_guarded/_main/finances/business'
import { Route as GuardedMainLibraryVendorsRouteImport } from './routes/_guarded/_main/library/vendors/route'
import { Route as GuardedMainLibraryTransactionsRouteImport } from './routes/_guarded/_main/library/transactions/route'
import { Route as GuardedMainLibraryInvoicesRouteImport } from './routes/_guarded/_main/library/invoices/route'
import { Route as GuardedMainLibraryCustomersRouteImport } from './routes/_guarded/_main/library/customers/route'
import { Route as GuardedMainLibraryBillsRouteImport } from './routes/_guarded/_main/library/bills/route'
import { Route as GuardedMainLibraryTransactionsIdImport } from './routes/_guarded/_main/library/transactions/$id'
import { Route as GuardedMainLibraryInvoicesIdImport } from './routes/_guarded/_main/library/invoices/$id'
import { Route as GuardedMainLibraryBillsIdImport } from './routes/_guarded/_main/library/bills/$id'
import { Route as GuardedMainLibraryVendorsIdRouteImport } from './routes/_guarded/_main/library/vendors_/$id/route'
import { Route as GuardedMainLibraryCustomersIdRouteImport } from './routes/_guarded/_main/library/customers_/$id/route'
import { Route as GuardedMainLibraryVendorsIdInfoImport } from './routes/_guarded/_main/library/vendors_/$id/info'
import { Route as GuardedMainLibraryVendorsIdDocumentsImport } from './routes/_guarded/_main/library/vendors_/$id/documents'
import { Route as GuardedMainLibraryVendorsIdContactsImport } from './routes/_guarded/_main/library/vendors_/$id/contacts'
import { Route as GuardedMainLibraryTransactionsIdVendorImport } from './routes/_guarded/_main/library/transactions/$id.vendor'
import { Route as GuardedMainLibraryTransactionsIdCustomerImport } from './routes/_guarded/_main/library/transactions/$id.customer'
import { Route as GuardedMainLibraryTransactionsIdCategoryImport } from './routes/_guarded/_main/library/transactions/$id.category'
import { Route as GuardedMainLibraryInvoicesIdCustomerImport } from './routes/_guarded/_main/library/invoices/$id.customer'
import { Route as GuardedMainLibraryCustomersIdInfoImport } from './routes/_guarded/_main/library/customers_/$id/info'
import { Route as GuardedMainLibraryCustomersIdDocumentsImport } from './routes/_guarded/_main/library/customers_/$id/documents'
import { Route as GuardedMainLibraryCustomersIdContactsImport } from './routes/_guarded/_main/library/customers_/$id/contacts'
import { Route as GuardedMainLibraryBillsIdVendorImport } from './routes/_guarded/_main/library/bills/$id.vendor'
import { Route as GuardedMainLibraryVendorsIdBillsBillIdImport } from './routes/_guarded/_main/library/vendors_/$id/bills/$billId'
import { Route as GuardedMainLibraryCustomersIdInvoicesInvoiceIdImport } from './routes/_guarded/_main/library/customers_/$id/invoices/$invoiceId'

// Create Virtual Routes

const LogoutLazyImport = createFileRoute('/logout')()
const GuestedAuthImport = createFileRoute('/_guested/auth')()
const GuestedAuthAuthSignupLazyImport = createFileRoute(
  '/_guested/auth/_auth/signup',
)()
const GuestedAuthAuthSigninLazyImport = createFileRoute(
  '/_guested/auth/_auth/signin',
)()

// Create/Update Routes

const LogoutLazyRoute = LogoutLazyImport.update({
  id: '/logout',
  path: '/logout',
  getParentRoute: () => rootRoute,
} as any).lazy(() => import('./routes/logout.lazy').then((d) => d.Route))

const TestRoute = TestImport.update({
  id: '/test',
  path: '/test',
  getParentRoute: () => rootRoute,
} as any)

const OnboardingRoute = OnboardingImport.update({
  id: '/onboarding',
  path: '/onboarding',
  getParentRoute: () => rootRoute,
} as any)

const GuestedRoute = GuestedImport.update({
  id: '/_guested',
  getParentRoute: () => rootRoute,
} as any)

const GuardedRoute = GuardedImport.update({
  id: '/_guarded',
  getParentRoute: () => rootRoute,
} as any)

const GuestedAuthRoute = GuestedAuthImport.update({
  id: '/auth',
  path: '/auth',
  getParentRoute: () => GuestedRoute,
} as any)

const GuardedTaxesRoute = GuardedTaxesImport.update({
  id: '/taxes',
  path: '/taxes',
  getParentRoute: () => GuardedRoute,
} as any)

const GuardedOldfinancesRoute = GuardedOldfinancesImport.update({
  id: '/oldfinances',
  path: '/oldfinances',
  getParentRoute: () => GuardedRoute,
} as any)

const GuardedBillingRoute = GuardedBillingImport.update({
  id: '/billing',
  path: '/billing',
  getParentRoute: () => GuardedRoute,
} as any)

const GuardedMainRoute = GuardedMainImport.update({
  id: '/_main',
  getParentRoute: () => GuardedRoute,
} as any)

const GuardedMainIndexRoute = GuardedMainIndexImport.update({
  id: '/',
  path: '/',
  getParentRoute: () => GuardedMainRoute,
} as any)

const GuestedAuthForgotPasswordRoute = GuestedAuthForgotPasswordImport.update({
  id: '/forgot-password',
  path: '/forgot-password',
  getParentRoute: () => GuestedAuthRoute,
} as any)

const GuestedAuthCallbackRoute = GuestedAuthCallbackImport.update({
  id: '/callback_',
  path: '/callback',
  getParentRoute: () => GuestedAuthRoute,
} as any)

const GuestedAuthAuthRoute = GuestedAuthAuthImport.update({
  id: '/_auth',
  getParentRoute: () => GuestedAuthRoute,
} as any)

const GuardedConversationIdRoute = GuardedConversationIdImport.update({
  id: '/conversation/$id',
  path: '/conversation/$id',
  getParentRoute: () => GuardedRoute,
} as any)

const GuardedMainChatsRoute = GuardedMainChatsImport.update({
  id: '/chats',
  path: '/chats',
  getParentRoute: () => GuardedMainRoute,
} as any)

const GuardedMainBooksRoute = GuardedMainBooksImport.update({
  id: '/books',
  path: '/books',
  getParentRoute: () => GuardedMainRoute,
} as any)

const GuardedMainMiloRouteRoute = GuardedMainMiloRouteImport.update({
  id: '/milo',
  path: '/milo',
  getParentRoute: () => GuardedMainRoute,
} as any)

const GuardedMainLibraryRouteRoute = GuardedMainLibraryRouteImport.update({
  id: '/library',
  path: '/library',
  getParentRoute: () => GuardedMainRoute,
} as any)

const GuardedMainFinancesRouteRoute = GuardedMainFinancesRouteImport.update({
  id: '/finances',
  path: '/finances',
  getParentRoute: () => GuardedMainRoute,
} as any)

const GuestedAuthAuthSignupLazyRoute = GuestedAuthAuthSignupLazyImport.update({
  id: '/signup',
  path: '/signup',
  getParentRoute: () => GuestedAuthAuthRoute,
} as any).lazy(() =>
  import('./routes/_guested/auth/_auth.signup.lazy').then((d) => d.Route),
)

const GuestedAuthAuthSigninLazyRoute = GuestedAuthAuthSigninLazyImport.update({
  id: '/signin',
  path: '/signin',
  getParentRoute: () => GuestedAuthAuthRoute,
} as any).lazy(() =>
  import('./routes/_guested/auth/_auth.signin.lazy').then((d) => d.Route),
)

const GuestedAuthResetPasswordTokenRoute =
  GuestedAuthResetPasswordTokenImport.update({
    id: '/reset-password/$token',
    path: '/reset-password/$token',
    getParentRoute: () => GuestedAuthRoute,
  } as any)

const GuestedAuthInviteTokenRoute = GuestedAuthInviteTokenImport.update({
  id: '/invite/$token',
  path: '/invite/$token',
  getParentRoute: () => GuestedAuthRoute,
} as any)

const GuardedMainMiloCenterRoute = GuardedMainMiloCenterImport.update({
  id: '/milo_/center',
  path: '/milo/center',
  getParentRoute: () => GuardedMainRoute,
} as any)

const GuardedMainFinancesCashflowRoute =
  GuardedMainFinancesCashflowImport.update({
    id: '/cashflow',
    path: '/cashflow',
    getParentRoute: () => GuardedMainFinancesRouteRoute,
  } as any)

const GuardedMainFinancesBusinessRoute =
  GuardedMainFinancesBusinessImport.update({
    id: '/business',
    path: '/business',
    getParentRoute: () => GuardedMainFinancesRouteRoute,
  } as any)

const GuardedMainLibraryVendorsRouteRoute =
  GuardedMainLibraryVendorsRouteImport.update({
    id: '/vendors',
    path: '/vendors',
    getParentRoute: () => GuardedMainLibraryRouteRoute,
  } as any)

const GuardedMainLibraryTransactionsRouteRoute =
  GuardedMainLibraryTransactionsRouteImport.update({
    id: '/transactions',
    path: '/transactions',
    getParentRoute: () => GuardedMainLibraryRouteRoute,
  } as any)

const GuardedMainLibraryInvoicesRouteRoute =
  GuardedMainLibraryInvoicesRouteImport.update({
    id: '/invoices',
    path: '/invoices',
    getParentRoute: () => GuardedMainLibraryRouteRoute,
  } as any)

const GuardedMainLibraryCustomersRouteRoute =
  GuardedMainLibraryCustomersRouteImport.update({
    id: '/customers',
    path: '/customers',
    getParentRoute: () => GuardedMainLibraryRouteRoute,
  } as any)

const GuardedMainLibraryBillsRouteRoute =
  GuardedMainLibraryBillsRouteImport.update({
    id: '/bills',
    path: '/bills',
    getParentRoute: () => GuardedMainLibraryRouteRoute,
  } as any)

const GuardedMainLibraryTransactionsIdRoute =
  GuardedMainLibraryTransactionsIdImport.update({
    id: '/$id',
    path: '/$id',
    getParentRoute: () => GuardedMainLibraryTransactionsRouteRoute,
  } as any)

const GuardedMainLibraryInvoicesIdRoute =
  GuardedMainLibraryInvoicesIdImport.update({
    id: '/$id',
    path: '/$id',
    getParentRoute: () => GuardedMainLibraryInvoicesRouteRoute,
  } as any)

const GuardedMainLibraryBillsIdRoute = GuardedMainLibraryBillsIdImport.update({
  id: '/$id',
  path: '/$id',
  getParentRoute: () => GuardedMainLibraryBillsRouteRoute,
} as any)

const GuardedMainLibraryVendorsIdRouteRoute =
  GuardedMainLibraryVendorsIdRouteImport.update({
    id: '/vendors_/$id',
    path: '/vendors/$id',
    getParentRoute: () => GuardedMainLibraryRouteRoute,
  } as any)

const GuardedMainLibraryCustomersIdRouteRoute =
  GuardedMainLibraryCustomersIdRouteImport.update({
    id: '/customers_/$id',
    path: '/customers/$id',
    getParentRoute: () => GuardedMainLibraryRouteRoute,
  } as any)

const GuardedMainLibraryVendorsIdInfoRoute =
  GuardedMainLibraryVendorsIdInfoImport.update({
    id: '/info',
    path: '/info',
    getParentRoute: () => GuardedMainLibraryVendorsIdRouteRoute,
  } as any)

const GuardedMainLibraryVendorsIdDocumentsRoute =
  GuardedMainLibraryVendorsIdDocumentsImport.update({
    id: '/documents',
    path: '/documents',
    getParentRoute: () => GuardedMainLibraryVendorsIdRouteRoute,
  } as any)

const GuardedMainLibraryVendorsIdContactsRoute =
  GuardedMainLibraryVendorsIdContactsImport.update({
    id: '/contacts',
    path: '/contacts',
    getParentRoute: () => GuardedMainLibraryVendorsIdRouteRoute,
  } as any)

const GuardedMainLibraryTransactionsIdVendorRoute =
  GuardedMainLibraryTransactionsIdVendorImport.update({
    id: '/vendor',
    path: '/vendor',
    getParentRoute: () => GuardedMainLibraryTransactionsIdRoute,
  } as any)

const GuardedMainLibraryTransactionsIdCustomerRoute =
  GuardedMainLibraryTransactionsIdCustomerImport.update({
    id: '/customer',
    path: '/customer',
    getParentRoute: () => GuardedMainLibraryTransactionsIdRoute,
  } as any)

const GuardedMainLibraryTransactionsIdCategoryRoute =
  GuardedMainLibraryTransactionsIdCategoryImport.update({
    id: '/category',
    path: '/category',
    getParentRoute: () => GuardedMainLibraryTransactionsIdRoute,
  } as any)

const GuardedMainLibraryInvoicesIdCustomerRoute =
  GuardedMainLibraryInvoicesIdCustomerImport.update({
    id: '/customer',
    path: '/customer',
    getParentRoute: () => GuardedMainLibraryInvoicesIdRoute,
  } as any)

const GuardedMainLibraryCustomersIdInfoRoute =
  GuardedMainLibraryCustomersIdInfoImport.update({
    id: '/info',
    path: '/info',
    getParentRoute: () => GuardedMainLibraryCustomersIdRouteRoute,
  } as any)

const GuardedMainLibraryCustomersIdDocumentsRoute =
  GuardedMainLibraryCustomersIdDocumentsImport.update({
    id: '/documents',
    path: '/documents',
    getParentRoute: () => GuardedMainLibraryCustomersIdRouteRoute,
  } as any)

const GuardedMainLibraryCustomersIdContactsRoute =
  GuardedMainLibraryCustomersIdContactsImport.update({
    id: '/contacts',
    path: '/contacts',
    getParentRoute: () => GuardedMainLibraryCustomersIdRouteRoute,
  } as any)

const GuardedMainLibraryBillsIdVendorRoute =
  GuardedMainLibraryBillsIdVendorImport.update({
    id: '/vendor',
    path: '/vendor',
    getParentRoute: () => GuardedMainLibraryBillsIdRoute,
  } as any)

const GuardedMainLibraryVendorsIdBillsBillIdRoute =
  GuardedMainLibraryVendorsIdBillsBillIdImport.update({
    id: '/bills/$billId',
    path: '/bills/$billId',
    getParentRoute: () => GuardedMainLibraryVendorsIdRouteRoute,
  } as any)

const GuardedMainLibraryCustomersIdInvoicesInvoiceIdRoute =
  GuardedMainLibraryCustomersIdInvoicesInvoiceIdImport.update({
    id: '/invoices/$invoiceId',
    path: '/invoices/$invoiceId',
    getParentRoute: () => GuardedMainLibraryCustomersIdRouteRoute,
  } as any)

// Populate the FileRoutesByPath interface

declare module '@tanstack/react-router' {
  interface FileRoutesByPath {
    '/_guarded': {
      id: '/_guarded'
      path: ''
      fullPath: ''
      preLoaderRoute: typeof GuardedImport
      parentRoute: typeof rootRoute
    }
    '/_guested': {
      id: '/_guested'
      path: ''
      fullPath: ''
      preLoaderRoute: typeof GuestedImport
      parentRoute: typeof rootRoute
    }
    '/onboarding': {
      id: '/onboarding'
      path: '/onboarding'
      fullPath: '/onboarding'
      preLoaderRoute: typeof OnboardingImport
      parentRoute: typeof rootRoute
    }
    '/test': {
      id: '/test'
      path: '/test'
      fullPath: '/test'
      preLoaderRoute: typeof TestImport
      parentRoute: typeof rootRoute
    }
    '/logout': {
      id: '/logout'
      path: '/logout'
      fullPath: '/logout'
      preLoaderRoute: typeof LogoutLazyImport
      parentRoute: typeof rootRoute
    }
    '/_guarded/_main': {
      id: '/_guarded/_main'
      path: ''
      fullPath: ''
      preLoaderRoute: typeof GuardedMainImport
      parentRoute: typeof GuardedImport
    }
    '/_guarded/billing': {
      id: '/_guarded/billing'
      path: '/billing'
      fullPath: '/billing'
      preLoaderRoute: typeof GuardedBillingImport
      parentRoute: typeof GuardedImport
    }
    '/_guarded/oldfinances': {
      id: '/_guarded/oldfinances'
      path: '/oldfinances'
      fullPath: '/oldfinances'
      preLoaderRoute: typeof GuardedOldfinancesImport
      parentRoute: typeof GuardedImport
    }
    '/_guarded/taxes': {
      id: '/_guarded/taxes'
      path: '/taxes'
      fullPath: '/taxes'
      preLoaderRoute: typeof GuardedTaxesImport
      parentRoute: typeof GuardedImport
    }
    '/_guarded/_main/finances': {
      id: '/_guarded/_main/finances'
      path: '/finances'
      fullPath: '/finances'
      preLoaderRoute: typeof GuardedMainFinancesRouteImport
      parentRoute: typeof GuardedMainImport
    }
    '/_guarded/_main/library': {
      id: '/_guarded/_main/library'
      path: '/library'
      fullPath: '/library'
      preLoaderRoute: typeof GuardedMainLibraryRouteImport
      parentRoute: typeof GuardedMainImport
    }
    '/_guarded/_main/milo': {
      id: '/_guarded/_main/milo'
      path: '/milo'
      fullPath: '/milo'
      preLoaderRoute: typeof GuardedMainMiloRouteImport
      parentRoute: typeof GuardedMainImport
    }
    '/_guarded/_main/books': {
      id: '/_guarded/_main/books'
      path: '/books'
      fullPath: '/books'
      preLoaderRoute: typeof GuardedMainBooksImport
      parentRoute: typeof GuardedMainImport
    }
    '/_guarded/_main/chats': {
      id: '/_guarded/_main/chats'
      path: '/chats'
      fullPath: '/chats'
      preLoaderRoute: typeof GuardedMainChatsImport
      parentRoute: typeof GuardedMainImport
    }
    '/_guarded/conversation/$id': {
      id: '/_guarded/conversation/$id'
      path: '/conversation/$id'
      fullPath: '/conversation/$id'
      preLoaderRoute: typeof GuardedConversationIdImport
      parentRoute: typeof GuardedImport
    }
    '/_guested/auth': {
      id: '/_guested/auth'
      path: '/auth'
      fullPath: '/auth'
      preLoaderRoute: typeof GuestedAuthImport
      parentRoute: typeof GuestedImport
    }
    '/_guested/auth/_auth': {
      id: '/_guested/auth/_auth'
      path: '/auth'
      fullPath: '/auth'
      preLoaderRoute: typeof GuestedAuthAuthImport
      parentRoute: typeof GuestedAuthRoute
    }
    '/_guested/auth/callback_': {
      id: '/_guested/auth/callback_'
      path: '/callback'
      fullPath: '/auth/callback'
      preLoaderRoute: typeof GuestedAuthCallbackImport
      parentRoute: typeof GuestedAuthImport
    }
    '/_guested/auth/forgot-password': {
      id: '/_guested/auth/forgot-password'
      path: '/forgot-password'
      fullPath: '/auth/forgot-password'
      preLoaderRoute: typeof GuestedAuthForgotPasswordImport
      parentRoute: typeof GuestedAuthImport
    }
    '/_guarded/_main/': {
      id: '/_guarded/_main/'
      path: '/'
      fullPath: '/'
      preLoaderRoute: typeof GuardedMainIndexImport
      parentRoute: typeof GuardedMainImport
    }
    '/_guarded/_main/library/bills': {
      id: '/_guarded/_main/library/bills'
      path: '/bills'
      fullPath: '/library/bills'
      preLoaderRoute: typeof GuardedMainLibraryBillsRouteImport
      parentRoute: typeof GuardedMainLibraryRouteImport
    }
    '/_guarded/_main/library/customers': {
      id: '/_guarded/_main/library/customers'
      path: '/customers'
      fullPath: '/library/customers'
      preLoaderRoute: typeof GuardedMainLibraryCustomersRouteImport
      parentRoute: typeof GuardedMainLibraryRouteImport
    }
    '/_guarded/_main/library/invoices': {
      id: '/_guarded/_main/library/invoices'
      path: '/invoices'
      fullPath: '/library/invoices'
      preLoaderRoute: typeof GuardedMainLibraryInvoicesRouteImport
      parentRoute: typeof GuardedMainLibraryRouteImport
    }
    '/_guarded/_main/library/transactions': {
      id: '/_guarded/_main/library/transactions'
      path: '/transactions'
      fullPath: '/library/transactions'
      preLoaderRoute: typeof GuardedMainLibraryTransactionsRouteImport
      parentRoute: typeof GuardedMainLibraryRouteImport
    }
    '/_guarded/_main/library/vendors': {
      id: '/_guarded/_main/library/vendors'
      path: '/vendors'
      fullPath: '/library/vendors'
      preLoaderRoute: typeof GuardedMainLibraryVendorsRouteImport
      parentRoute: typeof GuardedMainLibraryRouteImport
    }
    '/_guarded/_main/finances/business': {
      id: '/_guarded/_main/finances/business'
      path: '/business'
      fullPath: '/finances/business'
      preLoaderRoute: typeof GuardedMainFinancesBusinessImport
      parentRoute: typeof GuardedMainFinancesRouteImport
    }
    '/_guarded/_main/finances/cashflow': {
      id: '/_guarded/_main/finances/cashflow'
      path: '/cashflow'
      fullPath: '/finances/cashflow'
      preLoaderRoute: typeof GuardedMainFinancesCashflowImport
      parentRoute: typeof GuardedMainFinancesRouteImport
    }
    '/_guarded/_main/milo_/center': {
      id: '/_guarded/_main/milo_/center'
      path: '/milo/center'
      fullPath: '/milo/center'
      preLoaderRoute: typeof GuardedMainMiloCenterImport
      parentRoute: typeof GuardedMainImport
    }
    '/_guested/auth/invite/$token': {
      id: '/_guested/auth/invite/$token'
      path: '/invite/$token'
      fullPath: '/auth/invite/$token'
      preLoaderRoute: typeof GuestedAuthInviteTokenImport
      parentRoute: typeof GuestedAuthImport
    }
    '/_guested/auth/reset-password/$token': {
      id: '/_guested/auth/reset-password/$token'
      path: '/reset-password/$token'
      fullPath: '/auth/reset-password/$token'
      preLoaderRoute: typeof GuestedAuthResetPasswordTokenImport
      parentRoute: typeof GuestedAuthImport
    }
    '/_guested/auth/_auth/signin': {
      id: '/_guested/auth/_auth/signin'
      path: '/signin'
      fullPath: '/auth/signin'
      preLoaderRoute: typeof GuestedAuthAuthSigninLazyImport
      parentRoute: typeof GuestedAuthAuthImport
    }
    '/_guested/auth/_auth/signup': {
      id: '/_guested/auth/_auth/signup'
      path: '/signup'
      fullPath: '/auth/signup'
      preLoaderRoute: typeof GuestedAuthAuthSignupLazyImport
      parentRoute: typeof GuestedAuthAuthImport
    }
    '/_guarded/_main/library/customers_/$id': {
      id: '/_guarded/_main/library/customers_/$id'
      path: '/customers/$id'
      fullPath: '/library/customers/$id'
      preLoaderRoute: typeof GuardedMainLibraryCustomersIdRouteImport
      parentRoute: typeof GuardedMainLibraryRouteImport
    }
    '/_guarded/_main/library/vendors_/$id': {
      id: '/_guarded/_main/library/vendors_/$id'
      path: '/vendors/$id'
      fullPath: '/library/vendors/$id'
      preLoaderRoute: typeof GuardedMainLibraryVendorsIdRouteImport
      parentRoute: typeof GuardedMainLibraryRouteImport
    }
    '/_guarded/_main/library/bills/$id': {
      id: '/_guarded/_main/library/bills/$id'
      path: '/$id'
      fullPath: '/library/bills/$id'
      preLoaderRoute: typeof GuardedMainLibraryBillsIdImport
      parentRoute: typeof GuardedMainLibraryBillsRouteImport
    }
    '/_guarded/_main/library/invoices/$id': {
      id: '/_guarded/_main/library/invoices/$id'
      path: '/$id'
      fullPath: '/library/invoices/$id'
      preLoaderRoute: typeof GuardedMainLibraryInvoicesIdImport
      parentRoute: typeof GuardedMainLibraryInvoicesRouteImport
    }
    '/_guarded/_main/library/transactions/$id': {
      id: '/_guarded/_main/library/transactions/$id'
      path: '/$id'
      fullPath: '/library/transactions/$id'
      preLoaderRoute: typeof GuardedMainLibraryTransactionsIdImport
      parentRoute: typeof GuardedMainLibraryTransactionsRouteImport
    }
    '/_guarded/_main/library/bills/$id/vendor': {
      id: '/_guarded/_main/library/bills/$id/vendor'
      path: '/vendor'
      fullPath: '/library/bills/$id/vendor'
      preLoaderRoute: typeof GuardedMainLibraryBillsIdVendorImport
      parentRoute: typeof GuardedMainLibraryBillsIdImport
    }
    '/_guarded/_main/library/customers_/$id/contacts': {
      id: '/_guarded/_main/library/customers_/$id/contacts'
      path: '/contacts'
      fullPath: '/library/customers/$id/contacts'
      preLoaderRoute: typeof GuardedMainLibraryCustomersIdContactsImport
      parentRoute: typeof GuardedMainLibraryCustomersIdRouteImport
    }
    '/_guarded/_main/library/customers_/$id/documents': {
      id: '/_guarded/_main/library/customers_/$id/documents'
      path: '/documents'
      fullPath: '/library/customers/$id/documents'
      preLoaderRoute: typeof GuardedMainLibraryCustomersIdDocumentsImport
      parentRoute: typeof GuardedMainLibraryCustomersIdRouteImport
    }
    '/_guarded/_main/library/customers_/$id/info': {
      id: '/_guarded/_main/library/customers_/$id/info'
      path: '/info'
      fullPath: '/library/customers/$id/info'
      preLoaderRoute: typeof GuardedMainLibraryCustomersIdInfoImport
      parentRoute: typeof GuardedMainLibraryCustomersIdRouteImport
    }
    '/_guarded/_main/library/invoices/$id/customer': {
      id: '/_guarded/_main/library/invoices/$id/customer'
      path: '/customer'
      fullPath: '/library/invoices/$id/customer'
      preLoaderRoute: typeof GuardedMainLibraryInvoicesIdCustomerImport
      parentRoute: typeof GuardedMainLibraryInvoicesIdImport
    }
    '/_guarded/_main/library/transactions/$id/category': {
      id: '/_guarded/_main/library/transactions/$id/category'
      path: '/category'
      fullPath: '/library/transactions/$id/category'
      preLoaderRoute: typeof GuardedMainLibraryTransactionsIdCategoryImport
      parentRoute: typeof GuardedMainLibraryTransactionsIdImport
    }
    '/_guarded/_main/library/transactions/$id/customer': {
      id: '/_guarded/_main/library/transactions/$id/customer'
      path: '/customer'
      fullPath: '/library/transactions/$id/customer'
      preLoaderRoute: typeof GuardedMainLibraryTransactionsIdCustomerImport
      parentRoute: typeof GuardedMainLibraryTransactionsIdImport
    }
    '/_guarded/_main/library/transactions/$id/vendor': {
      id: '/_guarded/_main/library/transactions/$id/vendor'
      path: '/vendor'
      fullPath: '/library/transactions/$id/vendor'
      preLoaderRoute: typeof GuardedMainLibraryTransactionsIdVendorImport
      parentRoute: typeof GuardedMainLibraryTransactionsIdImport
    }
    '/_guarded/_main/library/vendors_/$id/contacts': {
      id: '/_guarded/_main/library/vendors_/$id/contacts'
      path: '/contacts'
      fullPath: '/library/vendors/$id/contacts'
      preLoaderRoute: typeof GuardedMainLibraryVendorsIdContactsImport
      parentRoute: typeof GuardedMainLibraryVendorsIdRouteImport
    }
    '/_guarded/_main/library/vendors_/$id/documents': {
      id: '/_guarded/_main/library/vendors_/$id/documents'
      path: '/documents'
      fullPath: '/library/vendors/$id/documents'
      preLoaderRoute: typeof GuardedMainLibraryVendorsIdDocumentsImport
      parentRoute: typeof GuardedMainLibraryVendorsIdRouteImport
    }
    '/_guarded/_main/library/vendors_/$id/info': {
      id: '/_guarded/_main/library/vendors_/$id/info'
      path: '/info'
      fullPath: '/library/vendors/$id/info'
      preLoaderRoute: typeof GuardedMainLibraryVendorsIdInfoImport
      parentRoute: typeof GuardedMainLibraryVendorsIdRouteImport
    }
    '/_guarded/_main/library/customers_/$id/invoices/$invoiceId': {
      id: '/_guarded/_main/library/customers_/$id/invoices/$invoiceId'
      path: '/invoices/$invoiceId'
      fullPath: '/library/customers/$id/invoices/$invoiceId'
      preLoaderRoute: typeof GuardedMainLibraryCustomersIdInvoicesInvoiceIdImport
      parentRoute: typeof GuardedMainLibraryCustomersIdRouteImport
    }
    '/_guarded/_main/library/vendors_/$id/bills/$billId': {
      id: '/_guarded/_main/library/vendors_/$id/bills/$billId'
      path: '/bills/$billId'
      fullPath: '/library/vendors/$id/bills/$billId'
      preLoaderRoute: typeof GuardedMainLibraryVendorsIdBillsBillIdImport
      parentRoute: typeof GuardedMainLibraryVendorsIdRouteImport
    }
  }
}

// Create and export the route tree

interface GuardedMainFinancesRouteRouteChildren {
  GuardedMainFinancesBusinessRoute: typeof GuardedMainFinancesBusinessRoute
  GuardedMainFinancesCashflowRoute: typeof GuardedMainFinancesCashflowRoute
}

const GuardedMainFinancesRouteRouteChildren: GuardedMainFinancesRouteRouteChildren =
  {
    GuardedMainFinancesBusinessRoute: GuardedMainFinancesBusinessRoute,
    GuardedMainFinancesCashflowRoute: GuardedMainFinancesCashflowRoute,
  }

const GuardedMainFinancesRouteRouteWithChildren =
  GuardedMainFinancesRouteRoute._addFileChildren(
    GuardedMainFinancesRouteRouteChildren,
  )

interface GuardedMainLibraryBillsIdRouteChildren {
  GuardedMainLibraryBillsIdVendorRoute: typeof GuardedMainLibraryBillsIdVendorRoute
}

const GuardedMainLibraryBillsIdRouteChildren: GuardedMainLibraryBillsIdRouteChildren =
  {
    GuardedMainLibraryBillsIdVendorRoute: GuardedMainLibraryBillsIdVendorRoute,
  }

const GuardedMainLibraryBillsIdRouteWithChildren =
  GuardedMainLibraryBillsIdRoute._addFileChildren(
    GuardedMainLibraryBillsIdRouteChildren,
  )

interface GuardedMainLibraryBillsRouteRouteChildren {
  GuardedMainLibraryBillsIdRoute: typeof GuardedMainLibraryBillsIdRouteWithChildren
}

const GuardedMainLibraryBillsRouteRouteChildren: GuardedMainLibraryBillsRouteRouteChildren =
  {
    GuardedMainLibraryBillsIdRoute: GuardedMainLibraryBillsIdRouteWithChildren,
  }

const GuardedMainLibraryBillsRouteRouteWithChildren =
  GuardedMainLibraryBillsRouteRoute._addFileChildren(
    GuardedMainLibraryBillsRouteRouteChildren,
  )

interface GuardedMainLibraryInvoicesIdRouteChildren {
  GuardedMainLibraryInvoicesIdCustomerRoute: typeof GuardedMainLibraryInvoicesIdCustomerRoute
}

const GuardedMainLibraryInvoicesIdRouteChildren: GuardedMainLibraryInvoicesIdRouteChildren =
  {
    GuardedMainLibraryInvoicesIdCustomerRoute:
      GuardedMainLibraryInvoicesIdCustomerRoute,
  }

const GuardedMainLibraryInvoicesIdRouteWithChildren =
  GuardedMainLibraryInvoicesIdRoute._addFileChildren(
    GuardedMainLibraryInvoicesIdRouteChildren,
  )

interface GuardedMainLibraryInvoicesRouteRouteChildren {
  GuardedMainLibraryInvoicesIdRoute: typeof GuardedMainLibraryInvoicesIdRouteWithChildren
}

const GuardedMainLibraryInvoicesRouteRouteChildren: GuardedMainLibraryInvoicesRouteRouteChildren =
  {
    GuardedMainLibraryInvoicesIdRoute:
      GuardedMainLibraryInvoicesIdRouteWithChildren,
  }

const GuardedMainLibraryInvoicesRouteRouteWithChildren =
  GuardedMainLibraryInvoicesRouteRoute._addFileChildren(
    GuardedMainLibraryInvoicesRouteRouteChildren,
  )

interface GuardedMainLibraryTransactionsIdRouteChildren {
  GuardedMainLibraryTransactionsIdCategoryRoute: typeof GuardedMainLibraryTransactionsIdCategoryRoute
  GuardedMainLibraryTransactionsIdCustomerRoute: typeof GuardedMainLibraryTransactionsIdCustomerRoute
  GuardedMainLibraryTransactionsIdVendorRoute: typeof GuardedMainLibraryTransactionsIdVendorRoute
}

const GuardedMainLibraryTransactionsIdRouteChildren: GuardedMainLibraryTransactionsIdRouteChildren =
  {
    GuardedMainLibraryTransactionsIdCategoryRoute:
      GuardedMainLibraryTransactionsIdCategoryRoute,
    GuardedMainLibraryTransactionsIdCustomerRoute:
      GuardedMainLibraryTransactionsIdCustomerRoute,
    GuardedMainLibraryTransactionsIdVendorRoute:
      GuardedMainLibraryTransactionsIdVendorRoute,
  }

const GuardedMainLibraryTransactionsIdRouteWithChildren =
  GuardedMainLibraryTransactionsIdRoute._addFileChildren(
    GuardedMainLibraryTransactionsIdRouteChildren,
  )

interface GuardedMainLibraryTransactionsRouteRouteChildren {
  GuardedMainLibraryTransactionsIdRoute: typeof GuardedMainLibraryTransactionsIdRouteWithChildren
}

const GuardedMainLibraryTransactionsRouteRouteChildren: GuardedMainLibraryTransactionsRouteRouteChildren =
  {
    GuardedMainLibraryTransactionsIdRoute:
      GuardedMainLibraryTransactionsIdRouteWithChildren,
  }

const GuardedMainLibraryTransactionsRouteRouteWithChildren =
  GuardedMainLibraryTransactionsRouteRoute._addFileChildren(
    GuardedMainLibraryTransactionsRouteRouteChildren,
  )

interface GuardedMainLibraryCustomersIdRouteRouteChildren {
  GuardedMainLibraryCustomersIdContactsRoute: typeof GuardedMainLibraryCustomersIdContactsRoute
  GuardedMainLibraryCustomersIdDocumentsRoute: typeof GuardedMainLibraryCustomersIdDocumentsRoute
  GuardedMainLibraryCustomersIdInfoRoute: typeof GuardedMainLibraryCustomersIdInfoRoute
  GuardedMainLibraryCustomersIdInvoicesInvoiceIdRoute: typeof GuardedMainLibraryCustomersIdInvoicesInvoiceIdRoute
}

const GuardedMainLibraryCustomersIdRouteRouteChildren: GuardedMainLibraryCustomersIdRouteRouteChildren =
  {
    GuardedMainLibraryCustomersIdContactsRoute:
      GuardedMainLibraryCustomersIdContactsRoute,
    GuardedMainLibraryCustomersIdDocumentsRoute:
      GuardedMainLibraryCustomersIdDocumentsRoute,
    GuardedMainLibraryCustomersIdInfoRoute:
      GuardedMainLibraryCustomersIdInfoRoute,
    GuardedMainLibraryCustomersIdInvoicesInvoiceIdRoute:
      GuardedMainLibraryCustomersIdInvoicesInvoiceIdRoute,
  }

const GuardedMainLibraryCustomersIdRouteRouteWithChildren =
  GuardedMainLibraryCustomersIdRouteRoute._addFileChildren(
    GuardedMainLibraryCustomersIdRouteRouteChildren,
  )

interface GuardedMainLibraryVendorsIdRouteRouteChildren {
  GuardedMainLibraryVendorsIdContactsRoute: typeof GuardedMainLibraryVendorsIdContactsRoute
  GuardedMainLibraryVendorsIdDocumentsRoute: typeof GuardedMainLibraryVendorsIdDocumentsRoute
  GuardedMainLibraryVendorsIdInfoRoute: typeof GuardedMainLibraryVendorsIdInfoRoute
  GuardedMainLibraryVendorsIdBillsBillIdRoute: typeof GuardedMainLibraryVendorsIdBillsBillIdRoute
}

const GuardedMainLibraryVendorsIdRouteRouteChildren: GuardedMainLibraryVendorsIdRouteRouteChildren =
  {
    GuardedMainLibraryVendorsIdContactsRoute:
      GuardedMainLibraryVendorsIdContactsRoute,
    GuardedMainLibraryVendorsIdDocumentsRoute:
      GuardedMainLibraryVendorsIdDocumentsRoute,
    GuardedMainLibraryVendorsIdInfoRoute: GuardedMainLibraryVendorsIdInfoRoute,
    GuardedMainLibraryVendorsIdBillsBillIdRoute:
      GuardedMainLibraryVendorsIdBillsBillIdRoute,
  }

const GuardedMainLibraryVendorsIdRouteRouteWithChildren =
  GuardedMainLibraryVendorsIdRouteRoute._addFileChildren(
    GuardedMainLibraryVendorsIdRouteRouteChildren,
  )

interface GuardedMainLibraryRouteRouteChildren {
  GuardedMainLibraryBillsRouteRoute: typeof GuardedMainLibraryBillsRouteRouteWithChildren
  GuardedMainLibraryCustomersRouteRoute: typeof GuardedMainLibraryCustomersRouteRoute
  GuardedMainLibraryInvoicesRouteRoute: typeof GuardedMainLibraryInvoicesRouteRouteWithChildren
  GuardedMainLibraryTransactionsRouteRoute: typeof GuardedMainLibraryTransactionsRouteRouteWithChildren
  GuardedMainLibraryVendorsRouteRoute: typeof GuardedMainLibraryVendorsRouteRoute
  GuardedMainLibraryCustomersIdRouteRoute: typeof GuardedMainLibraryCustomersIdRouteRouteWithChildren
  GuardedMainLibraryVendorsIdRouteRoute: typeof GuardedMainLibraryVendorsIdRouteRouteWithChildren
}

const GuardedMainLibraryRouteRouteChildren: GuardedMainLibraryRouteRouteChildren =
  {
    GuardedMainLibraryBillsRouteRoute:
      GuardedMainLibraryBillsRouteRouteWithChildren,
    GuardedMainLibraryCustomersRouteRoute:
      GuardedMainLibraryCustomersRouteRoute,
    GuardedMainLibraryInvoicesRouteRoute:
      GuardedMainLibraryInvoicesRouteRouteWithChildren,
    GuardedMainLibraryTransactionsRouteRoute:
      GuardedMainLibraryTransactionsRouteRouteWithChildren,
    GuardedMainLibraryVendorsRouteRoute: GuardedMainLibraryVendorsRouteRoute,
    GuardedMainLibraryCustomersIdRouteRoute:
      GuardedMainLibraryCustomersIdRouteRouteWithChildren,
    GuardedMainLibraryVendorsIdRouteRoute:
      GuardedMainLibraryVendorsIdRouteRouteWithChildren,
  }

const GuardedMainLibraryRouteRouteWithChildren =
  GuardedMainLibraryRouteRoute._addFileChildren(
    GuardedMainLibraryRouteRouteChildren,
  )

interface GuardedMainRouteChildren {
  GuardedMainFinancesRouteRoute: typeof GuardedMainFinancesRouteRouteWithChildren
  GuardedMainLibraryRouteRoute: typeof GuardedMainLibraryRouteRouteWithChildren
  GuardedMainMiloRouteRoute: typeof GuardedMainMiloRouteRoute
  GuardedMainBooksRoute: typeof GuardedMainBooksRoute
  GuardedMainChatsRoute: typeof GuardedMainChatsRoute
  GuardedMainIndexRoute: typeof GuardedMainIndexRoute
  GuardedMainMiloCenterRoute: typeof GuardedMainMiloCenterRoute
}

const GuardedMainRouteChildren: GuardedMainRouteChildren = {
  GuardedMainFinancesRouteRoute: GuardedMainFinancesRouteRouteWithChildren,
  GuardedMainLibraryRouteRoute: GuardedMainLibraryRouteRouteWithChildren,
  GuardedMainMiloRouteRoute: GuardedMainMiloRouteRoute,
  GuardedMainBooksRoute: GuardedMainBooksRoute,
  GuardedMainChatsRoute: GuardedMainChatsRoute,
  GuardedMainIndexRoute: GuardedMainIndexRoute,
  GuardedMainMiloCenterRoute: GuardedMainMiloCenterRoute,
}

const GuardedMainRouteWithChildren = GuardedMainRoute._addFileChildren(
  GuardedMainRouteChildren,
)

interface GuardedRouteChildren {
  GuardedMainRoute: typeof GuardedMainRouteWithChildren
  GuardedBillingRoute: typeof GuardedBillingRoute
  GuardedOldfinancesRoute: typeof GuardedOldfinancesRoute
  GuardedTaxesRoute: typeof GuardedTaxesRoute
  GuardedConversationIdRoute: typeof GuardedConversationIdRoute
}

const GuardedRouteChildren: GuardedRouteChildren = {
  GuardedMainRoute: GuardedMainRouteWithChildren,
  GuardedBillingRoute: GuardedBillingRoute,
  GuardedOldfinancesRoute: GuardedOldfinancesRoute,
  GuardedTaxesRoute: GuardedTaxesRoute,
  GuardedConversationIdRoute: GuardedConversationIdRoute,
}

const GuardedRouteWithChildren =
  GuardedRoute._addFileChildren(GuardedRouteChildren)

interface GuestedAuthAuthRouteChildren {
  GuestedAuthAuthSigninLazyRoute: typeof GuestedAuthAuthSigninLazyRoute
  GuestedAuthAuthSignupLazyRoute: typeof GuestedAuthAuthSignupLazyRoute
}

const GuestedAuthAuthRouteChildren: GuestedAuthAuthRouteChildren = {
  GuestedAuthAuthSigninLazyRoute: GuestedAuthAuthSigninLazyRoute,
  GuestedAuthAuthSignupLazyRoute: GuestedAuthAuthSignupLazyRoute,
}

const GuestedAuthAuthRouteWithChildren = GuestedAuthAuthRoute._addFileChildren(
  GuestedAuthAuthRouteChildren,
)

interface GuestedAuthRouteChildren {
  GuestedAuthAuthRoute: typeof GuestedAuthAuthRouteWithChildren
  GuestedAuthCallbackRoute: typeof GuestedAuthCallbackRoute
  GuestedAuthForgotPasswordRoute: typeof GuestedAuthForgotPasswordRoute
  GuestedAuthInviteTokenRoute: typeof GuestedAuthInviteTokenRoute
  GuestedAuthResetPasswordTokenRoute: typeof GuestedAuthResetPasswordTokenRoute
}

const GuestedAuthRouteChildren: GuestedAuthRouteChildren = {
  GuestedAuthAuthRoute: GuestedAuthAuthRouteWithChildren,
  GuestedAuthCallbackRoute: GuestedAuthCallbackRoute,
  GuestedAuthForgotPasswordRoute: GuestedAuthForgotPasswordRoute,
  GuestedAuthInviteTokenRoute: GuestedAuthInviteTokenRoute,
  GuestedAuthResetPasswordTokenRoute: GuestedAuthResetPasswordTokenRoute,
}

const GuestedAuthRouteWithChildren = GuestedAuthRoute._addFileChildren(
  GuestedAuthRouteChildren,
)

interface GuestedRouteChildren {
  GuestedAuthRoute: typeof GuestedAuthRouteWithChildren
}

const GuestedRouteChildren: GuestedRouteChildren = {
  GuestedAuthRoute: GuestedAuthRouteWithChildren,
}

const GuestedRouteWithChildren =
  GuestedRoute._addFileChildren(GuestedRouteChildren)

export interface FileRoutesByFullPath {
  '': typeof GuardedMainRouteWithChildren
  '/onboarding': typeof OnboardingRoute
  '/test': typeof TestRoute
  '/logout': typeof LogoutLazyRoute
  '/billing': typeof GuardedBillingRoute
  '/oldfinances': typeof GuardedOldfinancesRoute
  '/taxes': typeof GuardedTaxesRoute
  '/finances': typeof GuardedMainFinancesRouteRouteWithChildren
  '/library': typeof GuardedMainLibraryRouteRouteWithChildren
  '/milo': typeof GuardedMainMiloRouteRoute
  '/books': typeof GuardedMainBooksRoute
  '/chats': typeof GuardedMainChatsRoute
  '/conversation/$id': typeof GuardedConversationIdRoute
  '/auth': typeof GuestedAuthAuthRouteWithChildren
  '/auth/callback': typeof GuestedAuthCallbackRoute
  '/auth/forgot-password': typeof GuestedAuthForgotPasswordRoute
  '/': typeof GuardedMainIndexRoute
  '/library/bills': typeof GuardedMainLibraryBillsRouteRouteWithChildren
  '/library/customers': typeof GuardedMainLibraryCustomersRouteRoute
  '/library/invoices': typeof GuardedMainLibraryInvoicesRouteRouteWithChildren
  '/library/transactions': typeof GuardedMainLibraryTransactionsRouteRouteWithChildren
  '/library/vendors': typeof GuardedMainLibraryVendorsRouteRoute
  '/finances/business': typeof GuardedMainFinancesBusinessRoute
  '/finances/cashflow': typeof GuardedMainFinancesCashflowRoute
  '/milo/center': typeof GuardedMainMiloCenterRoute
  '/auth/invite/$token': typeof GuestedAuthInviteTokenRoute
  '/auth/reset-password/$token': typeof GuestedAuthResetPasswordTokenRoute
  '/auth/signin': typeof GuestedAuthAuthSigninLazyRoute
  '/auth/signup': typeof GuestedAuthAuthSignupLazyRoute
  '/library/customers/$id': typeof GuardedMainLibraryCustomersIdRouteRouteWithChildren
  '/library/vendors/$id': typeof GuardedMainLibraryVendorsIdRouteRouteWithChildren
  '/library/bills/$id': typeof GuardedMainLibraryBillsIdRouteWithChildren
  '/library/invoices/$id': typeof GuardedMainLibraryInvoicesIdRouteWithChildren
  '/library/transactions/$id': typeof GuardedMainLibraryTransactionsIdRouteWithChildren
  '/library/bills/$id/vendor': typeof GuardedMainLibraryBillsIdVendorRoute
  '/library/customers/$id/contacts': typeof GuardedMainLibraryCustomersIdContactsRoute
  '/library/customers/$id/documents': typeof GuardedMainLibraryCustomersIdDocumentsRoute
  '/library/customers/$id/info': typeof GuardedMainLibraryCustomersIdInfoRoute
  '/library/invoices/$id/customer': typeof GuardedMainLibraryInvoicesIdCustomerRoute
  '/library/transactions/$id/category': typeof GuardedMainLibraryTransactionsIdCategoryRoute
  '/library/transactions/$id/customer': typeof GuardedMainLibraryTransactionsIdCustomerRoute
  '/library/transactions/$id/vendor': typeof GuardedMainLibraryTransactionsIdVendorRoute
  '/library/vendors/$id/contacts': typeof GuardedMainLibraryVendorsIdContactsRoute
  '/library/vendors/$id/documents': typeof GuardedMainLibraryVendorsIdDocumentsRoute
  '/library/vendors/$id/info': typeof GuardedMainLibraryVendorsIdInfoRoute
  '/library/customers/$id/invoices/$invoiceId': typeof GuardedMainLibraryCustomersIdInvoicesInvoiceIdRoute
  '/library/vendors/$id/bills/$billId': typeof GuardedMainLibraryVendorsIdBillsBillIdRoute
}

export interface FileRoutesByTo {
  '': typeof GuestedRouteWithChildren
  '/onboarding': typeof OnboardingRoute
  '/test': typeof TestRoute
  '/logout': typeof LogoutLazyRoute
  '/billing': typeof GuardedBillingRoute
  '/oldfinances': typeof GuardedOldfinancesRoute
  '/taxes': typeof GuardedTaxesRoute
  '/finances': typeof GuardedMainFinancesRouteRouteWithChildren
  '/library': typeof GuardedMainLibraryRouteRouteWithChildren
  '/milo': typeof GuardedMainMiloRouteRoute
  '/books': typeof GuardedMainBooksRoute
  '/chats': typeof GuardedMainChatsRoute
  '/conversation/$id': typeof GuardedConversationIdRoute
  '/auth': typeof GuestedAuthAuthRouteWithChildren
  '/auth/callback': typeof GuestedAuthCallbackRoute
  '/auth/forgot-password': typeof GuestedAuthForgotPasswordRoute
  '/': typeof GuardedMainIndexRoute
  '/library/bills': typeof GuardedMainLibraryBillsRouteRouteWithChildren
  '/library/customers': typeof GuardedMainLibraryCustomersRouteRoute
  '/library/invoices': typeof GuardedMainLibraryInvoicesRouteRouteWithChildren
  '/library/transactions': typeof GuardedMainLibraryTransactionsRouteRouteWithChildren
  '/library/vendors': typeof GuardedMainLibraryVendorsRouteRoute
  '/finances/business': typeof GuardedMainFinancesBusinessRoute
  '/finances/cashflow': typeof GuardedMainFinancesCashflowRoute
  '/milo/center': typeof GuardedMainMiloCenterRoute
  '/auth/invite/$token': typeof GuestedAuthInviteTokenRoute
  '/auth/reset-password/$token': typeof GuestedAuthResetPasswordTokenRoute
  '/auth/signin': typeof GuestedAuthAuthSigninLazyRoute
  '/auth/signup': typeof GuestedAuthAuthSignupLazyRoute
  '/library/customers/$id': typeof GuardedMainLibraryCustomersIdRouteRouteWithChildren
  '/library/vendors/$id': typeof GuardedMainLibraryVendorsIdRouteRouteWithChildren
  '/library/bills/$id': typeof GuardedMainLibraryBillsIdRouteWithChildren
  '/library/invoices/$id': typeof GuardedMainLibraryInvoicesIdRouteWithChildren
  '/library/transactions/$id': typeof GuardedMainLibraryTransactionsIdRouteWithChildren
  '/library/bills/$id/vendor': typeof GuardedMainLibraryBillsIdVendorRoute
  '/library/customers/$id/contacts': typeof GuardedMainLibraryCustomersIdContactsRoute
  '/library/customers/$id/documents': typeof GuardedMainLibraryCustomersIdDocumentsRoute
  '/library/customers/$id/info': typeof GuardedMainLibraryCustomersIdInfoRoute
  '/library/invoices/$id/customer': typeof GuardedMainLibraryInvoicesIdCustomerRoute
  '/library/transactions/$id/category': typeof GuardedMainLibraryTransactionsIdCategoryRoute
  '/library/transactions/$id/customer': typeof GuardedMainLibraryTransactionsIdCustomerRoute
  '/library/transactions/$id/vendor': typeof GuardedMainLibraryTransactionsIdVendorRoute
  '/library/vendors/$id/contacts': typeof GuardedMainLibraryVendorsIdContactsRoute
  '/library/vendors/$id/documents': typeof GuardedMainLibraryVendorsIdDocumentsRoute
  '/library/vendors/$id/info': typeof GuardedMainLibraryVendorsIdInfoRoute
  '/library/customers/$id/invoices/$invoiceId': typeof GuardedMainLibraryCustomersIdInvoicesInvoiceIdRoute
  '/library/vendors/$id/bills/$billId': typeof GuardedMainLibraryVendorsIdBillsBillIdRoute
}

export interface FileRoutesById {
  __root__: typeof rootRoute
  '/_guarded': typeof GuardedRouteWithChildren
  '/_guested': typeof GuestedRouteWithChildren
  '/onboarding': typeof OnboardingRoute
  '/test': typeof TestRoute
  '/logout': typeof LogoutLazyRoute
  '/_guarded/_main': typeof GuardedMainRouteWithChildren
  '/_guarded/billing': typeof GuardedBillingRoute
  '/_guarded/oldfinances': typeof GuardedOldfinancesRoute
  '/_guarded/taxes': typeof GuardedTaxesRoute
  '/_guarded/_main/finances': typeof GuardedMainFinancesRouteRouteWithChildren
  '/_guarded/_main/library': typeof GuardedMainLibraryRouteRouteWithChildren
  '/_guarded/_main/milo': typeof GuardedMainMiloRouteRoute
  '/_guarded/_main/books': typeof GuardedMainBooksRoute
  '/_guarded/_main/chats': typeof GuardedMainChatsRoute
  '/_guarded/conversation/$id': typeof GuardedConversationIdRoute
  '/_guested/auth': typeof GuestedAuthRouteWithChildren
  '/_guested/auth/_auth': typeof GuestedAuthAuthRouteWithChildren
  '/_guested/auth/callback_': typeof GuestedAuthCallbackRoute
  '/_guested/auth/forgot-password': typeof GuestedAuthForgotPasswordRoute
  '/_guarded/_main/': typeof GuardedMainIndexRoute
  '/_guarded/_main/library/bills': typeof GuardedMainLibraryBillsRouteRouteWithChildren
  '/_guarded/_main/library/customers': typeof GuardedMainLibraryCustomersRouteRoute
  '/_guarded/_main/library/invoices': typeof GuardedMainLibraryInvoicesRouteRouteWithChildren
  '/_guarded/_main/library/transactions': typeof GuardedMainLibraryTransactionsRouteRouteWithChildren
  '/_guarded/_main/library/vendors': typeof GuardedMainLibraryVendorsRouteRoute
  '/_guarded/_main/finances/business': typeof GuardedMainFinancesBusinessRoute
  '/_guarded/_main/finances/cashflow': typeof GuardedMainFinancesCashflowRoute
  '/_guarded/_main/milo_/center': typeof GuardedMainMiloCenterRoute
  '/_guested/auth/invite/$token': typeof GuestedAuthInviteTokenRoute
  '/_guested/auth/reset-password/$token': typeof GuestedAuthResetPasswordTokenRoute
  '/_guested/auth/_auth/signin': typeof GuestedAuthAuthSigninLazyRoute
  '/_guested/auth/_auth/signup': typeof GuestedAuthAuthSignupLazyRoute
  '/_guarded/_main/library/customers_/$id': typeof GuardedMainLibraryCustomersIdRouteRouteWithChildren
  '/_guarded/_main/library/vendors_/$id': typeof GuardedMainLibraryVendorsIdRouteRouteWithChildren
  '/_guarded/_main/library/bills/$id': typeof GuardedMainLibraryBillsIdRouteWithChildren
  '/_guarded/_main/library/invoices/$id': typeof GuardedMainLibraryInvoicesIdRouteWithChildren
  '/_guarded/_main/library/transactions/$id': typeof GuardedMainLibraryTransactionsIdRouteWithChildren
  '/_guarded/_main/library/bills/$id/vendor': typeof GuardedMainLibraryBillsIdVendorRoute
  '/_guarded/_main/library/customers_/$id/contacts': typeof GuardedMainLibraryCustomersIdContactsRoute
  '/_guarded/_main/library/customers_/$id/documents': typeof GuardedMainLibraryCustomersIdDocumentsRoute
  '/_guarded/_main/library/customers_/$id/info': typeof GuardedMainLibraryCustomersIdInfoRoute
  '/_guarded/_main/library/invoices/$id/customer': typeof GuardedMainLibraryInvoicesIdCustomerRoute
  '/_guarded/_main/library/transactions/$id/category': typeof GuardedMainLibraryTransactionsIdCategoryRoute
  '/_guarded/_main/library/transactions/$id/customer': typeof GuardedMainLibraryTransactionsIdCustomerRoute
  '/_guarded/_main/library/transactions/$id/vendor': typeof GuardedMainLibraryTransactionsIdVendorRoute
  '/_guarded/_main/library/vendors_/$id/contacts': typeof GuardedMainLibraryVendorsIdContactsRoute
  '/_guarded/_main/library/vendors_/$id/documents': typeof GuardedMainLibraryVendorsIdDocumentsRoute
  '/_guarded/_main/library/vendors_/$id/info': typeof GuardedMainLibraryVendorsIdInfoRoute
  '/_guarded/_main/library/customers_/$id/invoices/$invoiceId': typeof GuardedMainLibraryCustomersIdInvoicesInvoiceIdRoute
  '/_guarded/_main/library/vendors_/$id/bills/$billId': typeof GuardedMainLibraryVendorsIdBillsBillIdRoute
}

export interface FileRouteTypes {
  fileRoutesByFullPath: FileRoutesByFullPath
  fullPaths:
    | ''
    | '/onboarding'
    | '/test'
    | '/logout'
    | '/billing'
    | '/oldfinances'
    | '/taxes'
    | '/finances'
    | '/library'
    | '/milo'
    | '/books'
    | '/chats'
    | '/conversation/$id'
    | '/auth'
    | '/auth/callback'
    | '/auth/forgot-password'
    | '/'
    | '/library/bills'
    | '/library/customers'
    | '/library/invoices'
    | '/library/transactions'
    | '/library/vendors'
    | '/finances/business'
    | '/finances/cashflow'
    | '/milo/center'
    | '/auth/invite/$token'
    | '/auth/reset-password/$token'
    | '/auth/signin'
    | '/auth/signup'
    | '/library/customers/$id'
    | '/library/vendors/$id'
    | '/library/bills/$id'
    | '/library/invoices/$id'
    | '/library/transactions/$id'
    | '/library/bills/$id/vendor'
    | '/library/customers/$id/contacts'
    | '/library/customers/$id/documents'
    | '/library/customers/$id/info'
    | '/library/invoices/$id/customer'
    | '/library/transactions/$id/category'
    | '/library/transactions/$id/customer'
    | '/library/transactions/$id/vendor'
    | '/library/vendors/$id/contacts'
    | '/library/vendors/$id/documents'
    | '/library/vendors/$id/info'
    | '/library/customers/$id/invoices/$invoiceId'
    | '/library/vendors/$id/bills/$billId'
  fileRoutesByTo: FileRoutesByTo
  to:
    | ''
    | '/onboarding'
    | '/test'
    | '/logout'
    | '/billing'
    | '/oldfinances'
    | '/taxes'
    | '/finances'
    | '/library'
    | '/milo'
    | '/books'
    | '/chats'
    | '/conversation/$id'
    | '/auth'
    | '/auth/callback'
    | '/auth/forgot-password'
    | '/'
    | '/library/bills'
    | '/library/customers'
    | '/library/invoices'
    | '/library/transactions'
    | '/library/vendors'
    | '/finances/business'
    | '/finances/cashflow'
    | '/milo/center'
    | '/auth/invite/$token'
    | '/auth/reset-password/$token'
    | '/auth/signin'
    | '/auth/signup'
    | '/library/customers/$id'
    | '/library/vendors/$id'
    | '/library/bills/$id'
    | '/library/invoices/$id'
    | '/library/transactions/$id'
    | '/library/bills/$id/vendor'
    | '/library/customers/$id/contacts'
    | '/library/customers/$id/documents'
    | '/library/customers/$id/info'
    | '/library/invoices/$id/customer'
    | '/library/transactions/$id/category'
    | '/library/transactions/$id/customer'
    | '/library/transactions/$id/vendor'
    | '/library/vendors/$id/contacts'
    | '/library/vendors/$id/documents'
    | '/library/vendors/$id/info'
    | '/library/customers/$id/invoices/$invoiceId'
    | '/library/vendors/$id/bills/$billId'
  id:
    | '__root__'
    | '/_guarded'
    | '/_guested'
    | '/onboarding'
    | '/test'
    | '/logout'
    | '/_guarded/_main'
    | '/_guarded/billing'
    | '/_guarded/oldfinances'
    | '/_guarded/taxes'
    | '/_guarded/_main/finances'
    | '/_guarded/_main/library'
    | '/_guarded/_main/milo'
    | '/_guarded/_main/books'
    | '/_guarded/_main/chats'
    | '/_guarded/conversation/$id'
    | '/_guested/auth'
    | '/_guested/auth/_auth'
    | '/_guested/auth/callback_'
    | '/_guested/auth/forgot-password'
    | '/_guarded/_main/'
    | '/_guarded/_main/library/bills'
    | '/_guarded/_main/library/customers'
    | '/_guarded/_main/library/invoices'
    | '/_guarded/_main/library/transactions'
    | '/_guarded/_main/library/vendors'
    | '/_guarded/_main/finances/business'
    | '/_guarded/_main/finances/cashflow'
    | '/_guarded/_main/milo_/center'
    | '/_guested/auth/invite/$token'
    | '/_guested/auth/reset-password/$token'
    | '/_guested/auth/_auth/signin'
    | '/_guested/auth/_auth/signup'
    | '/_guarded/_main/library/customers_/$id'
    | '/_guarded/_main/library/vendors_/$id'
    | '/_guarded/_main/library/bills/$id'
    | '/_guarded/_main/library/invoices/$id'
    | '/_guarded/_main/library/transactions/$id'
    | '/_guarded/_main/library/bills/$id/vendor'
    | '/_guarded/_main/library/customers_/$id/contacts'
    | '/_guarded/_main/library/customers_/$id/documents'
    | '/_guarded/_main/library/customers_/$id/info'
    | '/_guarded/_main/library/invoices/$id/customer'
    | '/_guarded/_main/library/transactions/$id/category'
    | '/_guarded/_main/library/transactions/$id/customer'
    | '/_guarded/_main/library/transactions/$id/vendor'
    | '/_guarded/_main/library/vendors_/$id/contacts'
    | '/_guarded/_main/library/vendors_/$id/documents'
    | '/_guarded/_main/library/vendors_/$id/info'
    | '/_guarded/_main/library/customers_/$id/invoices/$invoiceId'
    | '/_guarded/_main/library/vendors_/$id/bills/$billId'
  fileRoutesById: FileRoutesById
}

export interface RootRouteChildren {
  GuardedRoute: typeof GuardedRouteWithChildren
  GuestedRoute: typeof GuestedRouteWithChildren
  OnboardingRoute: typeof OnboardingRoute
  TestRoute: typeof TestRoute
  LogoutLazyRoute: typeof LogoutLazyRoute
}

const rootRouteChildren: RootRouteChildren = {
  GuardedRoute: GuardedRouteWithChildren,
  GuestedRoute: GuestedRouteWithChildren,
  OnboardingRoute: OnboardingRoute,
  TestRoute: TestRoute,
  LogoutLazyRoute: LogoutLazyRoute,
}

export const routeTree = rootRoute
  ._addFileChildren(rootRouteChildren)
  ._addFileTypes<FileRouteTypes>()

/* ROUTE_MANIFEST_START
{
  "routes": {
    "__root__": {
      "filePath": "__root.tsx",
      "children": [
        "/_guarded",
        "/_guested",
        "/onboarding",
        "/test",
        "/logout"
      ]
    },
    "/_guarded": {
      "filePath": "_guarded.tsx",
      "children": [
        "/_guarded/_main",
        "/_guarded/billing",
        "/_guarded/oldfinances",
        "/_guarded/taxes",
        "/_guarded/conversation/$id"
      ]
    },
    "/_guested": {
      "filePath": "_guested.tsx",
      "children": [
        "/_guested/auth"
      ]
    },
    "/onboarding": {
      "filePath": "onboarding.tsx"
    },
    "/test": {
      "filePath": "test.tsx"
    },
    "/logout": {
      "filePath": "logout.lazy.tsx"
    },
    "/_guarded/_main": {
      "filePath": "_guarded/_main.tsx",
      "parent": "/_guarded",
      "children": [
        "/_guarded/_main/finances",
        "/_guarded/_main/library",
        "/_guarded/_main/milo",
        "/_guarded/_main/books",
        "/_guarded/_main/chats",
        "/_guarded/_main/",
        "/_guarded/_main/milo_/center"
      ]
    },
    "/_guarded/billing": {
      "filePath": "_guarded/billing.tsx",
      "parent": "/_guarded"
    },
    "/_guarded/oldfinances": {
      "filePath": "_guarded/oldfinances.tsx",
      "parent": "/_guarded"
    },
    "/_guarded/taxes": {
      "filePath": "_guarded/taxes.tsx",
      "parent": "/_guarded"
    },
    "/_guarded/_main/finances": {
      "filePath": "_guarded/_main/finances/route.tsx",
      "parent": "/_guarded/_main",
      "children": [
        "/_guarded/_main/finances/business",
        "/_guarded/_main/finances/cashflow"
      ]
    },
    "/_guarded/_main/library": {
      "filePath": "_guarded/_main/library/route.tsx",
      "parent": "/_guarded/_main",
      "children": [
        "/_guarded/_main/library/bills",
        "/_guarded/_main/library/customers",
        "/_guarded/_main/library/invoices",
        "/_guarded/_main/library/transactions",
        "/_guarded/_main/library/vendors",
        "/_guarded/_main/library/customers_/$id",
        "/_guarded/_main/library/vendors_/$id"
      ]
    },
    "/_guarded/_main/milo": {
      "filePath": "_guarded/_main/milo/route.tsx",
      "parent": "/_guarded/_main"
    },
    "/_guarded/_main/books": {
      "filePath": "_guarded/_main/books.tsx",
      "parent": "/_guarded/_main"
    },
    "/_guarded/_main/chats": {
      "filePath": "_guarded/_main/chats.tsx",
      "parent": "/_guarded/_main"
    },
    "/_guarded/conversation/$id": {
      "filePath": "_guarded/conversation.$id.tsx",
      "parent": "/_guarded"
    },
    "/_guested/auth": {
      "filePath": "_guested/auth",
      "parent": "/_guested",
      "children": [
        "/_guested/auth/_auth",
        "/_guested/auth/callback_",
        "/_guested/auth/forgot-password",
        "/_guested/auth/invite/$token",
        "/_guested/auth/reset-password/$token"
      ]
    },
    "/_guested/auth/_auth": {
      "filePath": "_guested/auth/_auth.tsx",
      "parent": "/_guested/auth",
      "children": [
        "/_guested/auth/_auth/signin",
        "/_guested/auth/_auth/signup"
      ]
    },
    "/_guested/auth/callback_": {
      "filePath": "_guested/auth/callback_.tsx",
      "parent": "/_guested/auth"
    },
    "/_guested/auth/forgot-password": {
      "filePath": "_guested/auth/forgot-password.tsx",
      "parent": "/_guested/auth"
    },
    "/_guarded/_main/": {
      "filePath": "_guarded/_main/index.tsx",
      "parent": "/_guarded/_main"
    },
    "/_guarded/_main/library/bills": {
      "filePath": "_guarded/_main/library/bills/route.tsx",
      "parent": "/_guarded/_main/library",
      "children": [
        "/_guarded/_main/library/bills/$id"
      ]
    },
    "/_guarded/_main/library/customers": {
      "filePath": "_guarded/_main/library/customers/route.tsx",
      "parent": "/_guarded/_main/library"
    },
    "/_guarded/_main/library/invoices": {
      "filePath": "_guarded/_main/library/invoices/route.tsx",
      "parent": "/_guarded/_main/library",
      "children": [
        "/_guarded/_main/library/invoices/$id"
      ]
    },
    "/_guarded/_main/library/transactions": {
      "filePath": "_guarded/_main/library/transactions/route.tsx",
      "parent": "/_guarded/_main/library",
      "children": [
        "/_guarded/_main/library/transactions/$id"
      ]
    },
    "/_guarded/_main/library/vendors": {
      "filePath": "_guarded/_main/library/vendors/route.tsx",
      "parent": "/_guarded/_main/library"
    },
    "/_guarded/_main/finances/business": {
      "filePath": "_guarded/_main/finances/business.tsx",
      "parent": "/_guarded/_main/finances"
    },
    "/_guarded/_main/finances/cashflow": {
      "filePath": "_guarded/_main/finances/cashflow.tsx",
      "parent": "/_guarded/_main/finances"
    },
    "/_guarded/_main/milo_/center": {
      "filePath": "_guarded/_main/milo_/center.tsx",
      "parent": "/_guarded/_main"
    },
    "/_guested/auth/invite/$token": {
      "filePath": "_guested/auth/invite.$token.tsx",
      "parent": "/_guested/auth"
    },
    "/_guested/auth/reset-password/$token": {
      "filePath": "_guested/auth/reset-password.$token.tsx",
      "parent": "/_guested/auth"
    },
    "/_guested/auth/_auth/signin": {
      "filePath": "_guested/auth/_auth.signin.lazy.tsx",
      "parent": "/_guested/auth/_auth"
    },
    "/_guested/auth/_auth/signup": {
      "filePath": "_guested/auth/_auth.signup.lazy.tsx",
      "parent": "/_guested/auth/_auth"
    },
    "/_guarded/_main/library/customers_/$id": {
      "filePath": "_guarded/_main/library/customers_/$id/route.tsx",
      "parent": "/_guarded/_main/library",
      "children": [
        "/_guarded/_main/library/customers_/$id/contacts",
        "/_guarded/_main/library/customers_/$id/documents",
        "/_guarded/_main/library/customers_/$id/info",
        "/_guarded/_main/library/customers_/$id/invoices/$invoiceId"
      ]
    },
    "/_guarded/_main/library/vendors_/$id": {
      "filePath": "_guarded/_main/library/vendors_/$id/route.tsx",
      "parent": "/_guarded/_main/library",
      "children": [
        "/_guarded/_main/library/vendors_/$id/contacts",
        "/_guarded/_main/library/vendors_/$id/documents",
        "/_guarded/_main/library/vendors_/$id/info",
        "/_guarded/_main/library/vendors_/$id/bills/$billId"
      ]
    },
    "/_guarded/_main/library/bills/$id": {
      "filePath": "_guarded/_main/library/bills/$id.tsx",
      "parent": "/_guarded/_main/library/bills",
      "children": [
        "/_guarded/_main/library/bills/$id/vendor"
      ]
    },
    "/_guarded/_main/library/invoices/$id": {
      "filePath": "_guarded/_main/library/invoices/$id.tsx",
      "parent": "/_guarded/_main/library/invoices",
      "children": [
        "/_guarded/_main/library/invoices/$id/customer"
      ]
    },
    "/_guarded/_main/library/transactions/$id": {
      "filePath": "_guarded/_main/library/transactions/$id.tsx",
      "parent": "/_guarded/_main/library/transactions",
      "children": [
        "/_guarded/_main/library/transactions/$id/category",
        "/_guarded/_main/library/transactions/$id/customer",
        "/_guarded/_main/library/transactions/$id/vendor"
      ]
    },
    "/_guarded/_main/library/bills/$id/vendor": {
      "filePath": "_guarded/_main/library/bills/$id.vendor.tsx",
      "parent": "/_guarded/_main/library/bills/$id"
    },
    "/_guarded/_main/library/customers_/$id/contacts": {
      "filePath": "_guarded/_main/library/customers_/$id/contacts.tsx",
      "parent": "/_guarded/_main/library/customers_/$id"
    },
    "/_guarded/_main/library/customers_/$id/documents": {
      "filePath": "_guarded/_main/library/customers_/$id/documents.tsx",
      "parent": "/_guarded/_main/library/customers_/$id"
    },
    "/_guarded/_main/library/customers_/$id/info": {
      "filePath": "_guarded/_main/library/customers_/$id/info.tsx",
      "parent": "/_guarded/_main/library/customers_/$id"
    },
    "/_guarded/_main/library/invoices/$id/customer": {
      "filePath": "_guarded/_main/library/invoices/$id.customer.tsx",
      "parent": "/_guarded/_main/library/invoices/$id"
    },
    "/_guarded/_main/library/transactions/$id/category": {
      "filePath": "_guarded/_main/library/transactions/$id.category.tsx",
      "parent": "/_guarded/_main/library/transactions/$id"
    },
    "/_guarded/_main/library/transactions/$id/customer": {
      "filePath": "_guarded/_main/library/transactions/$id.customer.tsx",
      "parent": "/_guarded/_main/library/transactions/$id"
    },
    "/_guarded/_main/library/transactions/$id/vendor": {
      "filePath": "_guarded/_main/library/transactions/$id.vendor.tsx",
      "parent": "/_guarded/_main/library/transactions/$id"
    },
    "/_guarded/_main/library/vendors_/$id/contacts": {
      "filePath": "_guarded/_main/library/vendors_/$id/contacts.tsx",
      "parent": "/_guarded/_main/library/vendors_/$id"
    },
    "/_guarded/_main/library/vendors_/$id/documents": {
      "filePath": "_guarded/_main/library/vendors_/$id/documents.tsx",
      "parent": "/_guarded/_main/library/vendors_/$id"
    },
    "/_guarded/_main/library/vendors_/$id/info": {
      "filePath": "_guarded/_main/library/vendors_/$id/info.tsx",
      "parent": "/_guarded/_main/library/vendors_/$id"
    },
    "/_guarded/_main/library/customers_/$id/invoices/$invoiceId": {
      "filePath": "_guarded/_main/library/customers_/$id/invoices/$invoiceId.tsx",
      "parent": "/_guarded/_main/library/customers_/$id"
    },
    "/_guarded/_main/library/vendors_/$id/bills/$billId": {
      "filePath": "_guarded/_main/library/vendors_/$id/bills/$billId.tsx",
      "parent": "/_guarded/_main/library/vendors_/$id"
    }
  }
}
ROUTE_MANIFEST_END */
