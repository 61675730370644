import DotsIcon from '@/icons/3Dots.svg?react';
import PlusIcon from '@/icons/Plus.svg?react';
import UploadIcon from '@/icons/Upload.svg?react';

import { Button } from '@/components/ui/button';
import MenuRow from '@/components/menu-row';
import ResponsiveActionsMenu from '@/components/responsive-actions-menu';

export type CustomerFiltersProps = {};

export default function CustomerFilters({}: CustomerFiltersProps) {
  return (
    <div className="px-8 py-6 border-b border-b-neutral-900 lg:p-10 lg:h-34 flex flex-row items-center justify-between">
      <div className="text-base text-white font-semibold">All Customers</div>
      <div>
        <ResponsiveActionsMenu
          dropdownTrigger={
            <Button size="icon-xs" variant="icon-bordered">
              <DotsIcon className="size-4 fill-white rotate-90" />
            </Button>
          }
        >
          <MenuRow disabled icon={<PlusIcon className="size-[0.875rem]" />}>
            Create a New Customer
          </MenuRow>
          <MenuRow disabled icon={<UploadIcon className="size-[0.875rem]" />}>
            Import Your Customers
          </MenuRow>
        </ResponsiveActionsMenu>
      </div>
    </div>
  );
}
