import { useState } from 'react';

import { useBreakpoint } from '@/hooks/use-breakpoint';
import { Button } from '@/components/ui/button';
import { Dialog, DialogContent, DialogHeader, DialogTitle } from '@/components/ui/dialog';
import {
  Drawer,
  DrawerContent,
  DrawerDescription,
  DrawerFooter,
  DrawerHeader,
  DrawerTitle
} from '@/components/ui/drawer';
import { Input } from '@/components/ui/input';

export type CategoryModalProps = {
  open: boolean;
  onOpenChange: (open: boolean) => void | Promise<void>;
  onSave: (value: string) => void | Promise<void>;
  isLoading?: boolean;
  title?: string;
  description?: string;
  className?: string;
};

export default function TransactionCategoryModal({
  open,
  onOpenChange,
  onSave,
  isLoading = false,
  title = 'Add New Category',
  description = 'Add New Category',
  className = ''
}: CategoryModalProps) {
  const { isBelowLg } = useBreakpoint('lg');
  const [value, setValue] = useState('');

  const handleSave = async () => {
    await onSave(value);
    setValue('');
  };

  const handleOpenChange = async (newOpen: boolean) => {
    if (!newOpen) {
      setValue('');
    }
    await onOpenChange(newOpen);
  };

  const renderContent = () => (
    <div className="flex flex-col justify-center flex-1 p-8 gap-8">
      <div className="text-center">
        <div className="text-md font-bold">{title}</div>
      </div>
      <Input
        className="text-xs placeholder:text-neutral-500 placeholder:text-xs"
        onChange={({ target }) => {
          setValue(target.value);
        }}
        placeholder="Enter text"
        value={value}
      />
      <div className="flex-1" />
    </div>
  );

  if (!isBelowLg) {
    return (
      <Dialog onOpenChange={handleOpenChange} open={open}>
        <DialogContent className={`sm:max-w-[425px] ${className}`}>
          <DialogHeader>
            <DialogTitle>{title}</DialogTitle>
          </DialogHeader>
          {renderContent()}
          <div className="px-8 pb-8">
            <Button className="w-full" disabled={!value} isLoading={isLoading} onClick={handleSave} size="md">
              Save
            </Button>
          </div>
        </DialogContent>
      </Dialog>
    );
  }

  return (
    <Drawer onOpenChange={handleOpenChange} open={open}>
      <DrawerContent className={`flex flex-col ${className}`}>
        <DrawerHeader className="hidden">
          <DrawerTitle>{title}</DrawerTitle>
          <DrawerDescription>{description}</DrawerDescription>
        </DrawerHeader>
        {renderContent()}
        <DrawerFooter className="p-8 pt-0">
          <Button className="w-full" disabled={!value} isLoading={isLoading} onClick={handleSave} size="md">
            Save
          </Button>
        </DrawerFooter>
      </DrawerContent>
    </Drawer>
  );
}
