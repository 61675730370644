import { useEffect } from 'react';
import jumping2 from '@/assets/gifs/jumping2.gif';
import Amplitude from '@/utils/pixels/amplitude/amplitude';
import { motion } from 'framer-motion';
import { useShallow } from 'zustand/react/shallow';

import { useBoolean } from '@/hooks/use-boolean';
import { useBreakpoint } from '@/hooks/use-breakpoint';
import { Button } from '@/components/ui/button';
import MotionTextRevealContainer from '@/components/animate/motion-text-reveal';
import ConnectAccountButton from '@/components/connect-account-button';
import { useWizard } from '@/components/form-wizard';

const AnimatedButton = motion(Button);
const AnimatedConnectAccountButton = motion(ConnectAccountButton);

export default function ConnectAccountStep() {
  const [goToNextStep, activeStep] = useWizard(useShallow(state => [state.goToNextStep, state.activeStep]));
  const { isBelowLg } = useBreakpoint('lg');
  const textRevealed = useBoolean(false);
  const bottomAnimationConfig = {
    animate: textRevealed.value ? { opacity: 1 } : false,
    initial: { opacity: 0 },
    transition: { duration: 0.4, ease: 'easeInOut' }
  };
  const stepNumber = useWizard(useShallow(state => state.stepNumber));
  const questionText = 'Securely Connect Accounts';
  useEffect(() => {
    Amplitude.track(`OnBoard_Questionnaire_Q${stepNumber}_View`, {
      questionText: questionText
    });
  }, []);
  const renderNavigation = (
    <div className="w-full lg:w-auto flex flex-col text-center justify-center px-12 pt-6 pb-10">
      <AnimatedConnectAccountButton
        onPlaidConnectSuccess={() => {
          goToNextStep();
        }}
        {...bottomAnimationConfig}
      >
        {questionText}
      </AnimatedConnectAccountButton>
      <motion.div
        {...bottomAnimationConfig}
        className="text-4xs lg:text-xs mt-5 text-neutral-200 lg:absolute lg:bottom-14 lg:left-1/2 lg:-translate-x-1/2 w-full"
      >
        Connection is secured with Plaid, we do not store your passwords & account information.
      </motion.div>
    </div>
  );
  return (
    <>
      <div className="lg:relative flex h-full flex-col gap-8 py-4 px-14 lg:py-52 lg:items-center">
        <div>
          <img className="w-20 lg:hidden rotate-y-180" src={jumping2} />
        </div>
        <div className="lg:text-center flex flex-col gap-4">
          <div className="text-4xl lg:text-5xl">
            <MotionTextRevealContainer value="Finally, connect your financial accounts." />
          </div>
          <div className="text-md lg:text-lg">
            <MotionTextRevealContainer
              value="Unleash &milo powers, unlock smart insights, actual profit and tax savings."
              wrapperProps={{
                onAnimationComplete() {
                  textRevealed.onTrue();
                }
              }}
            />
          </div>
        </div>
        {!isBelowLg ? renderNavigation : null}
      </div>
      {isBelowLg ? renderNavigation : null}
      {activeStep.canSkip ? (
        <AnimatedButton
          {...bottomAnimationConfig}
          className="absolute right-9 lg:right-14 lg:top-14 top-7 text-xs font-semibold"
          onClick={() => {
            Amplitude.track(`OnBoard_Questionnaire_Q${stepNumber}_SkipClick`);
          }}
          size="auto"
          type="submit"
          variant="ghost"
        >
          Skip
        </AnimatedButton>
      ) : null}
    </>
  );
}
