import { useEffect } from 'react';
import jumping2 from '@/assets/gifs/jumping2.gif';
import LyingDog from '@/assets/icons/lying-dog';
import introVideo from '@/assets/video/intro-video.mp4';
import AuthHeader from '@/layouts/common/auth-header';
import Amplitude from '@/utils/pixels/amplitude/amplitude';
import { FLAGS } from '@/utils/pixels/amplitude/flags';
import useAmplitudeFlag from '@/utils/pixels/amplitude/userAmplitudeFlag';
import { useRouterState } from '@tanstack/react-router';

import { cn } from '@/lib/utils';
import { useBoolean } from '@/hooks/use-boolean';
import { useBreakpoint } from '@/hooks/use-breakpoint';
import { Button, buttonVariants } from '@/components/ui/button';
import { Separator } from '@/components/ui/separator';
import Logo from '@/components/logo';

// ----------------------------------------------------------------------

interface AuthLayoutProps {
  children: React.ReactNode;
  headerProps?: React.ComponentProps<typeof AuthHeader>;
  showSplash?: boolean;
}

const links = [
  { name: 'X', href: 'https://x.com/andMiloCompany' },
  {
    name: 'LinkedIn',
    href: 'https://www.linkedin.com/company/andmilo'
  },
  {
    name: 'Contact',
    href: 'mailto:support@andmilo.com'
  }
];

export default function AuthLayout({ children, headerProps = {}, showSplash = false }: AuthLayoutProps) {
  const { location } = useRouterState();
  const { isBelowLg } = useBreakpoint('lg');
  const isIntroductionAlreadyShown = useBoolean(false);
  const isSignUpPage = location.pathname === '/auth/signup';
  const amplitudeFlag = useAmplitudeFlag(FLAGS.ONBOARDING.name, FLAGS.ONBOARDING.defaultValue);
  const isIntroductionCanBeShown = isBelowLg && isSignUpPage && !isIntroductionAlreadyShown.value;
  const isIntroductionShouldBeShown = isIntroductionCanBeShown && (amplitudeFlag.flags.introSplashShow || showSplash);
  useEffect(() => {
    if (isIntroductionShouldBeShown && isSignUpPage) {
      Amplitude.track('IntroSplash_Mobile_View');
    }
    // else {
    //   if (isSignUpPage) {
    //     Amplitude.track('SignUp_View');
    //   } else {
    //     Amplitude.track('SignIn_View ');
    //   }
    // }
  }, [isIntroductionShouldBeShown, isSignUpPage]);
  const renderIntroduction = (
    <div
      className={cn(
        'min-h-dvh relative lg:w-[37.8125rem] flex flex-col h-dvh p-10 lg:py-14 lg:px-20 lg:flex lg:flex-col bg-primary',
        {
          hidden: !isIntroductionShouldBeShown
        }
      )}
    >
      <div>
        <Logo beta className="" fill="#fff" />
      </div>
      <div className="flex flex-1 flex-col gap-8 justify-center mt-6 lg:mt-0">
        <div className="flex flex-col gap-4 text-2xl leading-[2.1rem] text-white">
          <div className="rotate-y-180 w-20">
            <img alt="milo" src={jumping2} />
          </div>
          {isSignUpPage ? <div className="">Hey there! I am &milo, your AI-powered financial companion.</div> : null}
        </div>
        {!isSignUpPage && <div className="text-white text-5xl">Hey there! Welcome Back, Good to See You Back! </div>}
        {!isSignUpPage && <div className="text-md lg:text-lg text-white">What can I do for you today?</div>}
        {isSignUpPage ? (
          <video autoPlay className="rounded-xl" loop muted playsInline>
            <source src={introVideo} type="video/mp4" />
            Your browser does not support the video tag.
          </video>
        ) : null}
        {/*{isSignUpPage ? <div className="text-md text-white">Sign up to Get Started</div> : null}*/}
      </div>

      <div className="flex flex-col gap-6">
        <div className="flex-1 flex-col hidden lg:flex">
          <LyingDog className="lg:block w-21 h-16 self-end" />
          <Separator className="h-0.5 bg-white" />
        </div>
        {isBelowLg ? (
          <Button
            className="lg:hidden"
            onClick={() => {
              isIntroductionAlreadyShown.onTrue();
              Amplitude.track('IntroSplash_Mobile_ButtonClick');
            }}
            size="lg"
            variant="secondary"
          >
            Get Started
          </Button>
        ) : null}

        <div className="lg:flex justify-end hidden">
          <ul className="flex gap-12 item">
            {links.map(link => (
              <li key={link.name}>
                <Button asChild className="p-0" size="auto" variant="link">
                  <a className="text-white text-xs font-normal" href={link.href} rel="noopener" target="_blank">
                    {link.name}
                  </a>
                </Button>
              </li>
            ))}
            <li>
              <span
                className={cn(
                  buttonVariants({
                    variant: 'link',
                    size: 'auto'
                  }),
                  'text-white text-xs font-normal hover:no-underline'
                )}
              >
                Copyright© &milo
              </span>
            </li>
          </ul>
        </div>
      </div>
    </div>
  );

  const renderContent = (
    <div className="flex flex-1 h-dvh flex-col">
      {!isIntroductionShouldBeShown && <AuthHeader {...headerProps} />}
      <div className="flex flex-1 h-dvh flex-col items-center lg:justify-center overflow-hidden">{children}</div>
    </div>
  );

  return (
    <main
      className={cn('flex h-dvh flex-1 flex-col lg:flex-row overflow-hidden lg:px-0 lg:py-0', {
        'py-0 px-0': isIntroductionShouldBeShown
      })}
    >
      {renderIntroduction}

      {renderContent}
    </main>
  );
}
