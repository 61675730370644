import { useCashflowStore } from '@/sections/cashflow/use-cashflow-store';
import { fCurrency } from '@/utils/format-number';
import { getFinancialQueries } from '@repo/features/financial-data';
import { format } from 'date-fns';
import { useShallow } from 'zustand/react/shallow';

import axiosInstance from '@/lib/axios';
import { cn } from '@/lib/utils';

import type { WidgetProps } from '../types';
import WidgetErrorFallback from '../widget-error-fallback';
import { WidgetFactory } from '../widget-factory';
import { FinancialFacadeMetric } from './financial-facade-metric';
import MetricWidgetSkeleton from './metric-widget-skeleton';

const financialQueries = getFinancialQueries(axiosInstance);

export const CurrentBalanceMetricWidget = WidgetFactory.create(
  ({ className }: WidgetProps) => {
    const { openDrawer } = useCashflowStore(
      useShallow(state => ({
        openDrawer: state.openDrawer
      }))
    );
    const { data: balance } = financialQueries.expectedBalance.useSuspenseQuery({
      variables: {
        date: format(new Date(), 'yyyy-MM-dd')
      },

      select(data) {
        return data.data.cash_balance;
      }
    });

    return (
      <FinancialFacadeMetric
        className={cn(className, {
          'text-fun-green': balance > 0,
          'text-fun-red': balance < 0
        })}
        label="Current Available Balance"
        metric={fCurrency(balance)}
        onClick={() => {
          openDrawer('current_balance');
        }}
      />
    );
  },
  {
    FallbackComponent: WidgetErrorFallback,
    SuspenseFallbackComponent: MetricWidgetSkeleton
  }
);
