import { create } from 'zustand';

export type HeaderPosition = 'start' | 'end' | 'replace';

export interface HeaderContent {
  id: string;
  content: React.ReactNode;
  position: HeaderPosition;
  priority?: number;
}

export interface HeaderPortalState {
  contents: HeaderContent[];
  addContent: (content: Omit<HeaderContent, 'id'>) => string;
  removeContent: (id: string) => void;
  clearContents: () => void;
}

export const useHeaderPortalStore = create<HeaderPortalState>(set => ({
  contents: [],
  addContent: content => {
    const id = crypto.randomUUID();
    set(state => ({
      contents: [...state.contents, { ...content, id }].sort((a, b) => (b.priority ?? 0) - (a.priority ?? 0))
    }));
    return id;
  },
  removeContent: id => {
    set(state => ({
      contents: state.contents.filter(content => content.id !== id)
    }));
  },
  clearContents: () => {
    set({ contents: [] });
  }
}));
