import * as React from 'react';
import { useAuth } from '@/auth/hooks';
import { useProfileStore } from '@/sections/profile/use-profile-store';
import { zodResolver } from '@hookform/resolvers/zod';
import { getAuthQueries } from '@repo/features/auth';
import axios from 'axios';
import { useForm } from 'react-hook-form';
import z from 'zod';
import { useShallow } from 'zustand/react/shallow';

import { authAxiosInstance } from '@/lib/axios';
import { Button } from '@/components/ui/button';
import {
  Drawer,
  DrawerContent,
  DrawerDescription,
  DrawerFooter,
  DrawerHeader,
  DrawerTitle
} from '@/components/ui/drawer';
import { Form, FormControl, FormDescription, FormField, FormItem, FormLabel, FormMessage } from '@/components/ui/form';
import { Input } from '@/components/ui/input';
import DrawerSearchHeader from '@/components/drawer-search-header';

const formSchema = z.object({
  emailSettings: z.object({
    onBehalfOf: z.string().optional().or(z.literal(''))
  }),
  miloEmail: z.string().optional()
});

type FormFields = z.infer<typeof formSchema>;

const authQueries = getAuthQueries(authAxiosInstance);

export default function MiloSettingsDrawer() {
  const { toggleMenu, closeMenu, isOpen, activeMenu, openMenu } = useProfileStore(
    useShallow(state => ({
      toggleMenu: state.toggleMenu,
      isOpen: state.isOpen,
      closeMenu: state.closeMenu,
      activeMenu: state.activeMenu,
      openMenu: state.openMenu
    }))
  );
  const { mutateAsync: updateOrganization } = authQueries.updateOrganization.useMutation();
  const { delegationWithOrg, selectedOrgId } = useAuth();
  const organization = delegationWithOrg!.organization;
  const onSubmit = async (values: FormFields) => {
    try {
      await updateOrganization({
        id: selectedOrgId!,
        data: values
      });
      // setErrorMsg('');
      // await signUp({ data: values, query: qs.parse(window.location.search || '', { ignoreQueryPrefix: true }) });
    } catch (e) {
      if (axios.isAxiosError(e)) {
        // setErrorMsg((e.response as any).data.message);
      }
    }
  };

  const form = useForm<FormFields>({
    resolver: zodResolver(formSchema),
    defaultValues: {
      emailSettings: organization.emailSettings,
      miloEmail: organization.miloEmail
    }
  });
  const renderHeader = (
    <DrawerSearchHeader
      onBackClick={() => {
        closeMenu();
      }}
    />
  );

  return (
    <Drawer
      direction="left"
      onOpenChange={() => {
        toggleMenu('milo_settings');
      }}
      open={isOpen ? activeMenu === 'milo_settings' : false}
    >
      <DrawerContent
        className="lg:rounded-tr-lg lg:rounded-br-lg h-full w-full lg:w-[28.1rem] left-0 right-auto rounded-none border-none focus-visible:outline-none"
        hideHandle
      >
        <DrawerHeader className="hidden">
          <DrawerTitle>You & milo</DrawerTitle>
          <DrawerDescription>
            Your can interact with &milo via this email, send a questions or request and get answered directly to your
            email.
          </DrawerDescription>
        </DrawerHeader>
        {renderHeader}
        <Form {...form}>
          <form className="flex flex-col flex-1 gap-10 px-10">
            <div>
              <div className="text-lg font-bold">You & milo</div>
              <div className="text-xs">
                Your can interact with &milo via this email, send a questions or request and get answered directly to
                your email. "Hey &milo file this invoice...".
              </div>
            </div>
            <FormField
              control={form.control}
              name="miloEmail"
              render={({ field }) => (
                <FormItem className="w-full">
                  <FormLabel>milo Email Address</FormLabel>
                  <FormDescription className="mt-0">
                    Share this email with your contractors, vendors, customers and &milo will take it and reply on your
                    behalf.
                  </FormDescription>
                  <FormControl>
                    <Input disabled size="md" {...field} />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
            <FormField
              control={form.control}
              name="emailSettings.onBehalfOf"
              render={({ field }) => (
                <FormItem className="w-full">
                  <FormLabel>Email’s Sender Name</FormLabel>
                  <FormDescription className="mt-0">
                    When milo sends an email on your behalf, this is the sender name that will appear on the email.
                  </FormDescription>
                  <FormControl>
                    <Input size="md" {...field} />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
            <div className="flex-1" />
          </form>
        </Form>
        <DrawerFooter className="p-10">
          <Button
            className="w-full"
            disabled={!form.formState.isValid}
            onClick={() => form.handleSubmit(onSubmit)()}
            size="md"
          >
            Save Changes
          </Button>
        </DrawerFooter>
      </DrawerContent>
    </Drawer>
  );
}
