import PlusIcon from '@/icons/Plus.svg?react';
import LibrarySelectButton from '@/sections/library/transaction/library-select-button';
import TransactionCategoryModal from '@/sections/library/transaction/transaction-category-modal';
import useTransactionActions from '@/sections/library/transaction/use-transaction-actions';
import TransactionCard from '@/sections/quests/transaction-card';
import type { ITransaction } from '@repo/features/transactions';
import { getTransactionsQueries } from '@repo/features/transactions';
import { useShallow } from 'zustand/react/shallow';

import axiosInstance from '@/lib/axios';
import { useBoolean } from '@/hooks/use-boolean';
import { useWizard } from '@/components/form-wizard';

export type CategorizationStepProps = {
  transaction: ITransaction;
};
const transactionQueries = getTransactionsQueries(axiosInstance);
export default function CategorizationStep({ transaction }: CategorizationStepProps) {
  const [goToNextStep] = useWizard(useShallow(state => [state.goToNextStep]));
  const { updateTransaction } = useTransactionActions();
  const { data: categories } = transactionQueries.topCategories.useSuspenseQuery();
  const isCategoryModalOpen = useBoolean(false);
  return (
    <div className="">
      <TransactionCard
        actions={
          <div className="px-8 py-7 flex scrollbar-hidden gap-4 bg-black bg-opacity-[4%] overflow-x-auto">
            {categories.map(category => (
              <LibrarySelectButton
                key={category.name}
                onClick={async () => {
                  if (transaction?.suggestedAccountingLedger?.name !== category.name) {
                    await updateTransaction({
                      id: transaction._id,
                      data: {
                        suggestedAccountingLedger: {
                          name: category.name,
                          score: 1
                        }
                      }
                    });
                  }
                  goToNextStep();
                }}
                selected={transaction?.suggestedAccountingLedger?.name === category.name}
                size="sm"
              >
                {category.name}
              </LibrarySelectButton>
            ))}
            <LibrarySelectButton
              onClick={() => {
                isCategoryModalOpen.onTrue();
              }}
              size="sm"
            >
              <PlusIcon className="mr-1 size-[0.5rem]" />
              ADD NEW
            </LibrarySelectButton>
          </div>
        }
        className="bg-transparent"
        transaction={transaction}
      />
      <TransactionCategoryModal
        onOpenChange={isCategoryModalOpen.setValue}
        onSave={async value => {
          await updateTransaction({
            id: transaction._id,
            data: {
              suggestedAccountingLedger: {
                name: value,
                score: 1
              }
            }
          });
          isCategoryModalOpen.onFalse();
          goToNextStep();
        }}
        open={isCategoryModalOpen.value}
      />
    </div>
  );
}
