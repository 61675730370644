import type { IInvoice } from '@repo/features/invoices';
import { getInvoiceQueries } from '@repo/features/invoices';
import type { PaginatedResponse } from '@repo/features/types';
import { useQueryClient } from '@tanstack/react-query';

import axiosInstance from '@/lib/axios';

const invoiceQueries = getInvoiceQueries(axiosInstance);

export default function useInvoiceActions() {
  const queryClient = useQueryClient();

  const { mutateAsync: updateInvoice } = invoiceQueries.update.useMutation({
    onMutate: async params => {
      const { data: newInvoice, id, context } = params;
      const updatedInvoice = { ...newInvoice, ...context };

      // Get all matching query keys for this invoice
      const singleQueryKeys = queryClient
        .getQueryCache()
        .findAll({ queryKey: invoiceQueries.byId.getKey({ id }) })
        .map(query => query.queryKey);

      const listQueryKeys = queryClient
        .getQueryCache()
        .findAll({ queryKey: invoiceQueries.paginated.getKey() })
        .map(query => query.queryKey);

      // Cancel all related queries
      await Promise.all([
        ...singleQueryKeys.map(key => queryClient.cancelQueries({ queryKey: key })),
        ...listQueryKeys.map(key => queryClient.cancelQueries({ queryKey: key }))
      ]);

      // Store previous values for all matching queries
      const previousSingleQueries = singleQueryKeys.reduce<Record<string, IInvoice | undefined>>((acc, key) => {
        acc[JSON.stringify(key)] = queryClient.getQueryData(key);
        return acc;
      }, {});

      const previousListQueries = listQueryKeys.reduce<Record<string, any>>((acc, key) => {
        acc[JSON.stringify(key)] = queryClient.getQueryData(key);
        return acc;
      }, {});

      // Update all matching single queries
      singleQueryKeys.forEach(key => {
        const previousData = queryClient.getQueryData(key);
        if (previousData) {
          queryClient.setQueryData(key, {
            ...previousData,
            ...updatedInvoice
          } as IInvoice);
        }
      });

      // Update all matching list queries
      listQueryKeys.forEach(key => {
        const previousData = queryClient.getQueryData(key) as PaginatedResponse<IInvoice>;
        if (previousData) {
          queryClient.setQueryData(key, {
            ...previousData,
            records: previousData.records.map(r => (r._id === id ? ({ ...r, ...updatedInvoice } as IInvoice) : r))
          });
        }
      });

      return {
        previousSingleQueries,
        previousListQueries,
        updatedInvoice,
        singleQueryKeys,
        listQueryKeys
      };
    },

    onError: (err, { id }, context) => {
      if (!context) return;

      // Restore all single queries
      context.singleQueryKeys.forEach(key => {
        const previousData = context.previousSingleQueries[JSON.stringify(key)];
        if (previousData) {
          queryClient.setQueryData(key, previousData);
        }
      });

      // Restore all list queries
      context.listQueryKeys.forEach(key => {
        const previousData = context.previousListQueries[JSON.stringify(key)];
        if (previousData) {
          queryClient.setQueryData(key, previousData);
        }
      });
    },

    onSettled: async invoice => {
      if (!invoice?._id) return;

      // Invalidate all related queries
      await Promise.all([
        queryClient.invalidateQueries({
          queryKey: invoiceQueries.byId.getKey({ id: invoice._id })
        }),
        queryClient.invalidateQueries({
          queryKey: invoiceQueries.paginated.getKey()
        })
      ]);
    }
  });

  const { mutateAsync: uploadInvoiceFile } = invoiceQueries.signAndUpload.useMutation({
    onSuccess: async () => {
      await queryClient.invalidateQueries({
        queryKey: invoiceQueries.getKey()
      });
    }
  });

  return {
    updateInvoice,
    uploadInvoiceFile
  };
}
