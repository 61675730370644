import { RevnueSimpleMetricWidget } from '@/sections/widgets/metrics/revenue-simple-metric-widget';
import { format } from 'date-fns';
import { ArrowLeftIcon } from 'lucide-react';
import { useShallow } from 'zustand/react/shallow';

import { Button } from '@/components/ui/button';
import { Drawer, DrawerClose, DrawerContent, DrawerDescription, DrawerTitle } from '@/components/ui/drawer';
import { Separator } from '@/components/ui/separator';

import { useBusinessStore } from '../use-business-store';
import { CurrentMonthInvoices } from './current-month-invoices';
import { CurrentMonthRevenueByStatus } from './current-month-revenue-by-status';
import { RevenueByCustomerChart } from './revenue-by-customer-chart';

const thisMonth = format(new Date(), 'MMM yyyy');

export function RevenueDrawer() {
  const { closeDrawer, isOpen, activeDrawer, openDrawer } = useBusinessStore(
    useShallow(state => ({
      isOpen: state.isOpen,
      closeDrawer: state.closeDrawer,
      activeDrawer: state.activeDrawer,
      openDrawer: state.openDrawer
    }))
  );

  return (
    <Drawer
      direction="right"
      onClose={() => {
        document.body.style.pointerEvents = 'auto';
      }}
      onOpenChange={_isOpen => {
        if (_isOpen) {
          openDrawer('revenue');
        } else {
          closeDrawer();
        }
      }}
      open={isOpen ? activeDrawer === 'revenue' : false}
    >
      <DrawerContent
        className="lg:rounded-tr-lg lg:rounded-br-lg h-full w-full lg:w-[30rem] right-0 left-auto rounded-none border-none focus-visible:outline-none overflow-y-scroll"
        hideHandle
      >
        <div className="flex text-start">
          <DrawerClose asChild>
            <Button className="min-w-8" variant="icon">
              <ArrowLeftIcon className="size-8" />
            </Button>
          </DrawerClose>
        </div>

        <div className="flex flex-1 overflow-y-scroll flex-col p-8">
          <DrawerTitle className="text-2xl">
            Revenue, <br />
            {thisMonth}
          </DrawerTitle>
          <DrawerDescription className="text-gray-400">
            Here’s your total revenue for this month, showing all the money your business earned.
          </DrawerDescription>
          <RevnueSimpleMetricWidget />
          <div className="text-lg font-semibold">Top Revenue, By Customer</div>
          <div className="text-xs text-gray-400 font-">Ranked by the total income they've brough in</div>
          <RevenueByCustomerChart />
          <div className="text-lg font-semibold">By Payment Status</div>
          <div className="text-xs text-gray-400 font-">
            Based on recorded invoices, paid or due to be paid by the end of the month
          </div>
          <CurrentMonthRevenueByStatus />
          <Separator />
          <CurrentMonthInvoices />
        </div>
      </DrawerContent>
    </Drawer>
  );
}
