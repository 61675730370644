import React, { useContext, useEffect } from 'react';
import MiloAvatar from '@/assets/icons/milo-avatar';
import DiggingDog from '@/assets/lottie/digging-dog';
import ChatMessageList from '@/sections/chat/chat-message-list';
import { SocketContext } from '@/socket/socket-context';
import { useConversationsStateStore } from '@/stores/conversations-state';
import { getActivityQueries } from '@repo/features/activity';
import { getConversationQueries } from '@repo/features/conversation';
import { useQueryClient } from '@tanstack/react-query';
import { useShallow } from 'zustand/react/shallow';

import axiosInstance from '@/lib/axios.ts';
import { useBreakpoint } from '@/hooks/use-breakpoint';
import ConversationPageTitle from '@/components/conversation-page-title';
import MiloInput from 'src/sections/milo-input';

export type ChatViewProps = {
  conversationId: string;
};

const conversationQueries = getConversationQueries(axiosInstance);
const activityQueries = getActivityQueries(axiosInstance);

function ChatView({ conversationId }: ChatViewProps) {
  const socket = useContext(SocketContext);
  const queryClient = useQueryClient();

  const invalidateOnConnect = () => {
    console.log(
      `[CHAT_DEBUG]: reconnect dectected, invalidating conversation query for conversationId: ${conversationId}`
    );
    queryClient.invalidateQueries({
      queryKey: conversationQueries.byId.getKey({
        id: conversationId
      })
    });
  };

  useEffect(() => {
    socket.on('connect', invalidateOnConnect);
    return () => {
      socket.off('connect', invalidateOnConnect);
    };
  }, [socket]);

  const { data: conversation, isLoading } = conversationQueries.byId.useSuspenseQuery({
    variables: {
      id: conversationId
    }
  });

  const { data: activity } = activityQueries.byId.useQuery({
    variables: {
      id: conversation.activityItem
    },
    enabled: !!conversation.activityItem
  });

  const [conversationsInProgress] = useConversationsStateStore(useShallow(state => [state.conversationsInProgress]));
  const isMessageLoading = conversationsInProgress.includes(conversationId);
  const { isBelowMd } = useBreakpoint('md');
  // const { messagesEndRef } = useMessagesScroll(conversation.thread.messages, true);
  const isWaitingForSupport = !conversation.agentActive;
  const renderLoading = (
    <div className="animate-in slide-in-from-bottom animate-out slide-out-to-bottom">
      <div className="flex flex-row items-center text-right">
        <MiloAvatar className="size-8" />
      </div>

      <div className="mt-1 flex flex-col items-start">
        <div className="break-words overflow-visible">
          <div className="font-inter font-normal prose prose-sm py-3">
            {isWaitingForSupport ? "Waiting for support's answer..." : <DiggingDog className="w-[7rem]" />}
          </div>
        </div>
      </div>
    </div>
  );
  return (
    <div className="grid grid-rows-[auto_1fr_auto] grid-cols-[100%] h-full relative">
      {/* Header */}
      <ConversationPageTitle
        category={activity?.category || 'category'}
        showBackButton
        subject={activity ? activity.subject : ''}
        time={conversation.thread.messages[conversation.thread.messages.length - 1].createdAt}
      />

      {/* Chat messages area */}
      <ChatMessageList messages={conversation.thread.messages || []}>
        {isMessageLoading ? renderLoading : null}
      </ChatMessageList>
      {/* Input area */}
      <div className="sticky bottom-0 p-5 bg-white lg:bg-inherit lg:pb-14 lg:pt-4 lg:flex flex-col rounded-tr-lg rounded-tl-lg lg:justify-center lg:items-center lg:rounded-none">
        <div className="lg:w-[60rem]">
          <MiloInput
            conversation={conversation}
            label="Type to milo here..."
            size={isBelowMd ? 'sm' : 'default'}
            suggestions={
              conversation.thread.messages[conversation.thread.messages.length - 1].metadata?.suggestions || []
            }
          />
        </div>
      </div>
    </div>
  );
}

export default ChatView;
